import React, { PureComponent } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
class CategoryListSkeleton extends PureComponent{
    
    render(){
        return(
            <div> 

                <h4> <Skeleton width={260}/> </h4>
              
                <div className="cards-grid-container  pt-2" style={{paddingBottom:"0px"}}>
                {this.props.items.length > 0 ? (
                    this.props.items.map(
                    (item) => (
                        <div className="cards-grid-item2" style={{height:"100px"}}>
                       
                        
                            <span className="cards-grid-icon-wrapper2" style={{flex:"unset !important",paddingBottom:"0px !important;"}}>
                            
                                <Skeleton height={120} className="cards-grid-icon img-category" ></Skeleton>
                            </span>
                            <span className="cards-grid-title " style={{textTransform:"capitalize"}}>
                           <Skeleton></Skeleton>
                            </span>
                        </div>
                    )
                    )
                ) : (
                    <div></div>
                )}
                </div>
            <br/>
            <span className="mt-1">     
                    <Skeleton width={280}/>
                </span>
            </div>
        )
    }
}

export default CategoryListSkeleton;
import React, { PureComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class TopStoresSkeleton extends PureComponent{
    
    render(){

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 9,
            slidesToScroll: 1,
            initialSlide: 0,
            lazyLoad: true,
            autoplay: true,
            speed:2500,
            autoplaySpeed: 2500,
            pauseOnHover: true,
            responsive: [
              {
                breakpoint: 1124,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              }
            ]
          };
        return(
            <div>
                
                <ul className="featured-grid2 clearfix flex flex-2 flex-22 "  style={{ padding: "0px" }}>
                <Slider {...settings}>
                {this.props.items.length > 0 ? (
                this.props.items.map(
                (item) => (            
                   
                <li key={item} className="storelist mr-2 ml-2 bg-white">
                <p style={{marginTop:"auto"}}>
                 
                       <center><Skeleton width={95} height={85}/></center>
                       <span className="coupon-count">
                            <b style={{textTransform:"capitalize",verticalAlign:"middle"}}> <Skeleton/></b><br></br>
                            
                            </span>
                </p>
              </li>
                    ))
                    )
                :(
                    <div></div>
                )
                }
                </Slider>
                </ul>
                
            </div>
        )
    }
}
export default TopStoresSkeleton;
import React, { PureComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
class TopDealsSkeleton extends PureComponent{

    render(){
        return(
            <div className="card mt-2">
            <div className="card-body">
                <h4>Top Deals</h4>
                <div className="row">
                {this.props.items.length > 0 ? (
              this.props.items.map((item) => (
                   
                <div key={item}
                className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable"
             
                >
                <div className="card  text-white  single-deal  ">
                    
                    <Skeleton
                    style={{ height: "100px", width: "100%" }}
                    />
                    <Skeleton
                        className="ml-3 "
                        height={50}
                        width={60}
                        
                    />
                    <div
                    className=" p-1"
                   
                    >
                    <div className="details">
                        
                        
                        <div className="card-title mt-3 mb-0 ">
                        <Skeleton height={25}>
                          
                        </Skeleton>
                        </div>
                        <br></br>
                        <h5
                       
                        >
                            
                            <Skeleton   height={25} width={70}/> &nbsp;
                            <Skeleton   height={25} width={100}/>
                        
                        </h5>
                    </div>
                    </div>
                </div>
                </div>
                ))
                ):(
                    <div></div>
                )
                }     
                </div>
            
            </div>
        </div>
        )
    }
}

export default TopDealsSkeleton;
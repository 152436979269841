import React, { Component } from "react";
import axios from "axios";

import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./ScrollToTop";

import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';

import StoresList from "./components/StoresList";
import CategoryList from "./components/CategoryList";
import Store from "./components/Store";
import CategoryDetails from "./components/CategoryDetails";
import Home from "./components/Home";
import OurPages from "./components/OurPages";
import AllCoupons from "./components/AllCoupons";
import AllDeals from "./components/AllDeals";
import Login from "./components/Login";
import UserPanel from "./components/UserPanel";
import Register from "./components/Register";
import UnSubscribe from "./components/UnSubscribe";
import VerifyEmail from "./components/VerifyEmail";
import ForgotPassword from "./components/ForgotPassword";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { getUser, removeUserSession } from "./utils/Common";

const user = getUser();
class Layout extends Component {
  constructor(props){
    super(props);
    this.state = {
      categories: [],
      coupon_types: [
        ["/coupons/coupon", "Coupon"],
        ["/coupons/promo", "Promo"],
        ["/coupons/free-shipping", "Free Shipping"],
        ["/coupons/clearance", "Clearance"],
        ["/coupons/weekly-ads", "Weekly Ad"],
        ["/coupons/today-ads", "Today's Ad"],
        ["/coupons/sale", "Sale"],
        ["/coupons/event", "Event"],
        ["/coupons/gift", "Gift"],
        ["/coupons/student", "Student"],
        ["/coupons/referral", "Referral"],
        ["/coupons/reward", "Reward"],
        ["/coupons/cash-back", "Cash Back"],
        ["/coupons/rebate", "Rebate"],
        ["/coupons/printable-coupon", "Printable Coupon"],
        ["/coupons/seasonal", "Seasonal"],
        ["/coupons/bogo", "BOGO"],
        ["/coupons/new-arrival","New Arrival"]
        
      ]
    }
  }

  componentDidMount(){

    var categories =  localStorage.getItem("categories");

    if (!categories) {
      axios
        .get(`http://adscombined.com/v1/public/api/AllCategories`)
        .then((response) => {
          if (response.data.categories) {
            var categories = response.data.categories;
           
            if (categories.length > 0) {
              localStorage.setItem("categories",JSON.stringify(categories));
            
            return  this.setState({ categories: categories });
              
            }
          }
        });
    }else{
     
        return this.setState({categories:JSON.parse(categories)})
    }
   
  }

  render() {
    return (
      <div>
         
        <Header categories={this.state.categories} user={user}></Header>
       
        <Route exact path="/">
            <Home categories={this.state.categories} />
          </Route>
        
          <Route exact path="/onlinestores">
            <StoresList categories={this.state.categories}  type={"Store"} />
          </Route>
        
        
          <Route path="/forgot_password">
            <ForgotPassword></ForgotPassword>
            </Route>
          <Route
            exact
            path="/deals"
            render={(props) => <AllDeals {...props}  />}
          />
          <Route exact path="/onlinebrands">
            <StoresList categories={this.state.categories} type={"Brand"} />
          </Route>
          <Route exact path="/onlineretailers">
            <StoresList categories={this.state.categories} type={"Retailer"} />
          </Route>
          <Route path="/allcategories">
            <CategoryList categories={this.state.categories} />
          </Route>
         
          <PrivateRoute  exact path="/profile">
            {getUser()?
            <UserPanel pageOpened={"My Profile"} />
            : 
            // <Login></Login>
            <UserPanel pageOpened={"My Profile"} />
            }
           
          </PrivateRoute >
         
          <PrivateRoute   exact path="/subscriptions">
            
            {getUser()?
              <UserPanel pageOpened={"My Subscriptions"} categories={this.state.categories}/>
            : 
            <Login></Login>
            }
          </PrivateRoute >
         
          <PrivateRoute  exact path="/favourites">
          {getUser()?
           
           <UserPanel pageOpened={"My Favourites"} />
            : 
            <Login></Login>
            }
          </PrivateRoute >
          <Switch>
     
          {this.state.coupon_types.map((ctype) =>

            <Route
              key= {ctype.toString()}
              exact
              path={ctype[0]}
              render={(props) => <AllCoupons {...props} coupon_type={ctype[1]} />}
            />

          )}
          
          <Route
            exact
            path="/coupons/all"
            render={(props) => <AllCoupons {...props} coupon_type="all" />}
          />
           <PublicRoute 
            path="/login" component={Login}
          />
       
          <PublicRoute 
            path="/register" component={Register}
          />
          <Route
            exact
            path="/coupons/brands"
            render={(props) => <AllCoupons {...props} coupon_type="BrandCoupons" />}
          />
          
          <Route
            exact
            path="/deals/:id"
            render={(props) => <Store {...props} record_type="deals" />}
          />
           
           <Route
            exact
            path="/subscriptions/:subscription_id"
            render={(props) => 
              getUser()?
              <UserPanel pageOpened={"EditSubscription"} {...props} categories={this.state.categories}/>
             : 
             <Login></Login>}
              >
              
            </Route>
        
          <Route 
            exact
            path="/unsubscribe/:token"
            render = {(props) => <UnSubscribe {...props} />}
          />

          <Route 
            exact
            path="/verify_email/:unique_token"
            render = {(props) => <VerifyEmail {...props} />}
          />
          <Route 
            exact
            path="/coupons/:cat_id" 
            render={(props) => <CategoryDetails {...props} categories={this.state.categories} />}
            />
          <Route
            exact
            path="/coupons/:cat_id/:sub_cat"
            render={(props) => <CategoryDetails {...props} categories={this.state.categories} />}
          />
           
           <Route
            exact
            path="/onlinestores/:category"
            render={(props) => <StoresList {...props} categories={this.state.categories}  type={"Store"} />}
          />
          <Route
            exact
            path="/onlinestores/:category/:sub_category"
            render={(props) => <StoresList {...props} categories={this.state.categories}  type={"Store"} />}
          />

          <Route
            exact
            path="/onlinebrands/:category"
            render={(props) => <StoresList {...props} categories={this.state.categories}   type={"Brand"} />}
          />
          
          <Route
            exact
            path="/onlineretailers/:category"
            render={(props) => <StoresList {...props} categories={this.state.categories}   type={"Retailer"} />}
          />
          <Route
            exact
            path="/onlineretailers/:category/:sub_category"
            render={(props) => <StoresList {...props} categories={this.state.categories}  type={"Retailer"} />}
          />
          <Route
            exact
            path="/onlinebrands/:category/:sub_category"
            render={(props) => <StoresList {...props} categories={this.state.categories}   type={"Brand"} />}
          />
            </Switch>
            
          <Route path="/terms-and-conditions">
            <OurPages pageOpened={"Terms and Conditions"} />
          </Route>
          <Route path="/about-us">
            <OurPages pageOpened={"About Us"} />
          </Route>
          <Route path="/contact-us">
            <OurPages pageOpened={"Contact Us"} />
          </Route>
          <Route path="/privacy-policy">
            <OurPages pageOpened={"Privacy Policy"} />
          </Route>
          <Route path="/faqs">
            <OurPages pageOpened={"FAQs"} />
          </Route>
        
        
          <ScrollToTop></ScrollToTop>
        <Footer categories={this.state.categories}></Footer>
      </div>
    );
  }
}
export default Layout;

import React, { PureComponent } from 'react';
import { BrowserRouter as Router,  Switch,  Route,  Link } from "react-router-dom";

import Subscriptions from './Subscriptions';
import Favourites from './Favourites';
import Profile from './Profile';
import { getUser, removeUserSession } from "../utils/Common";
import EditSubscription from './EditSubscription';

class UserPanel extends PureComponent{
    
    constructor(props){
       
        super(props);
     
        this.state= {
            pageOpened: this.props.pageOpened,
            menuItems:[
                
                ["/favourites","My Favourites"],
                ["/subscriptions","My Subscriptions"],
                ["/profile","My Profile"]
              ]
        }
        this.logout = this.logout.bind(this);
    }

    logout(){

        removeUserSession();
        window.location.href = '/login';
    }
    menuClickHandle(menu){
        return this.setState({pageOpened:menu});
    }

    renderComponent() {
        switch (this.state.pageOpened) {
         
            case "My Profile":
                return (
                   <Profile
                   />
                );
            case "My Subscriptions":
                return (
                  <Subscriptions categories={this.props.categories}
                  />
                );
            case "My Favourites":
                return (
                  <Favourites
                />
                );
            case "EditSubscription":
                return (
                    <EditSubscription subscription_id = { this.props.match.params.subscription_id}></EditSubscription>
                );
        }
      }

    render(){

        return(
            <div>
                <section className="main-container pb-5">
                    <div className="container" style={{ padding: "0px" }}>
                        <br />
                        <div className="row">
                        <div className="col-md-12 ">
                            <nav aria-label="breadcrumb">
                           
                                {this.state.pageOpened == "EditSubscription" ?
                                 <ol
                                    className="breadcrumb"
                                    style={{ backgroundColor: "transparent" }}
                                >
                                 <li className="breadcrumb-item">
                                     <a href="\">Home</a>
                                 </li>
                                    <li className="breadcrumb-item">
                                        <a href="\subscriptions">My Subscriptions</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {this.state.pageOpened}
                                    </li>
                                </ol>
                                   
                                :
                                <ol
                                className="breadcrumb"
                                    style={{ backgroundColor: "transparent" }}
                                >
                                    <li className="breadcrumb-item">
                                        <a href="\">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {this.state.pageOpened}
                                    </li>
                                </ol>
                                }
                                
                            </nav>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <Router>
                                            <ul class="menu list-unstyled pb-0 mb-0">
                                            { this.state. menuItems.map(item =>
                                                this.state.pageOpened == item[1] || (this.state.pageOpened == "EditSubscription" && item[1] == "My Subscriptions") ? (
                                                <li className="menu-item btn-pill navbar-active" style={{color:"rgb(255, 255, 255) !important"}}>
                                                <Link  className="nav-link active-link" onClick={()=>this.menuClickHandle(item[1])} to={item[0]} style={{color:"white !important"}}>
                                                    {item[1]}
                                                </Link >
                                                </li>
                                                ):(
                                                <li className="menu-item btn-pill ">
                                                <Link  className="nav-link" onClick={()=>this.menuClickHandle(item[1])} to={item[0]}>
                                                    {item[1]}
                                                </Link >
                                                </li>
                                                )
                                            )
                                            }
                                                <li className="menu-item btn-pill ">
                                                <Link  className="nav-link" onClick={this.logout}  >
                                                    Logout
                                                </Link >
                                                </li>
                                            </ul>
                                        </Router>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-9 ">
                                <div className="card">
                                    <div className="card-body" style={{padding:"3%"}}>
                                        
                                        {this.renderComponent()}
                                    </div>
                                </div>
                                <h4></h4>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default UserPanel;
import React, { PureComponent } from "react";
import axios from "axios";
import Moment from 'react-moment';
import Toast from "light-toast";
import Popup from "reactjs-popup";
import { getToken} from "../utils/Common";
import Skeleton from "react-loading-skeleton";
import { isMobile, isBrowser,isTablet } from "react-device-detect";
import {Link} from "react-router-dom";

import CouponTypeDisplay from "./CouponTypeDisplay";
import DealsSkeleton from "./skeletons/DealsSkeleton";
import SearchOffersSkeleton from "./skeletons/FavouriteOffersSkeleton";
import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();

class Favourites extends PureComponent {

  constructor(props){
    super(props);

    this.state = {
      coupons: [],
      deals:[],
      isLoading: true,
      sepecial_coupon: [],
      sc_id: "",
      open: false,
      open2: false,
      active_tab:"d",
      sd_id: "",
      sepecial_deal: []
      
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);
    this.removeFavourite = this.removeFavourite.bind(this);


    this.openModal2 = this.openModal2.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);
    this.redirectToStore2 = this.redirectToStore2.bind(this);
  }

  
  openModal2(coupon) {
    if (this.state.sepecial_coupon.length < 1) {
      this.setState({ open2: true, sepecial_coupon: coupon });
    }
  }
  

  closeModal2() {
    this.setState({ open2: false });
  }

  redirectToStore2(coupon) {
    Toast.info(
      <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {coupon.StoreURL}
      </div>,
      3000,
      () => {
        window.open(
          window.location.href.split("?")[0] + "?c=" + coupon.id,
          "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
          //this.openModal(coupon)
          (window.location.href = coupon.SourceUrl),
          2000
        );
      }
    );
  }


  removeFavourite(type,id){
    var user_id = "";
    if(user ){
        user_id = "?&user_id="+user.id;
    }

    if(id){

     //If Remove Deal
     
     this.setState({isLoading:true});
     axios
     .get(
       `http://adscombined.com/v1/public/api/remove_favourite${user_id}&favourite_id=${id}`,{
         // headers: {
         //   'Accept' :'application/json',
         //   'Access-Control-Allow-Origin' :'*',
         //   'Authorization': `Bearer ${this.state.token}`
         // }
       }
     )
     .then(
       (response) => {
         if (response.data.flag) {
           if (response.data.coupons) {
             const coupons = response.data.coupons;
             this.setState({coupons:coupons});
           }
           if (response.data.deals) {
             const deals = response.data.deals;
             this.setState({deals:deals});
           }
           this.setState({isLoading:false});
         } else {
           this.setState({isLoading:false});
         }
       },
       (error) => {
         this.setState({isLoading:false});
         console.log(error);
       }
     );

    }
    window.scrollTo(0, 0);
  }

  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(deal) {
    if (this.state.sepecial_deal.length < 1) {
        this.setState({ open: true, sepecial_deal: deal });
    }
  }

  closeModal() {
    this.setState({ open: false });
  }

  redirectToStore(deal) {
    Toast.info(
        <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {deal.StoreURL}
        </div>,
        3000,
        () => {
        window.open(
            window.location.href.split("?")[0] + "?d=" + deal.id,
            "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
            //this.openModal(coupon)
            (window.location.href = deal.SourceUrl),
            2000
        );
        }
    );
  }

  shorten(text,max) {
    return text && text.length > max ? text.slice(0,max).split(' ').slice(0, -1).join(' ') : text
  }

  getSaving(price1,price2){
    
    var  price11 =0;
    if(price1 !=="" && price1!=="none" && price1){
      price11  = price1.replace ( /[^\d.]/g, '' ); 
    } 
    var price22 = 0;
    if(price2!=="" && price2!=="none" && price2){
      
      price22  =price2.replace ( /[^\d.]/g, '' ); 
    }
    var perc = 0;
    if(price11 >0){
      perc = (price22 *100) / price11;
    }
    
    perc = perc.toFixed(0);
    return perc >0 ? perc : 0;
  }
  componentDidMount(){

    //Checking for Special Coupon
    const params1 = new URLSearchParams(window.location.search);
    if (params1.has("c")) {
      var sc_id = "";
      sc_id = params1.get("c");
      var user_id = "";
      if(user ){
        user_id = "&user_id="+user.id;
      }
      this.setState({active_tab:"c"});
      axios
        .get(
          `http://adscombined.com/v1/public/api/CouponDetails?coupon_id=${encodeURIComponent(
            sc_id+user_id
          )}`
        )
        .then(
          (response) => {
            if (response.data.flag) {
              if (response.data.coupon_data) {
                const sepecial_coupon = response.data.coupon_data;

                this.openModal(sepecial_coupon);
              }
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

    //Checking for Special Deal
    const params = new URLSearchParams(window.location.search);
    var keywod= "";
    if(params.has("q")){
       keywod = params.get('q');
       this.setState({selected_keyword:keywod});
      window.history.pushState({}, document.title, "/deals" );
    }else{
      keywod= this.state.keyword;
    }

    if (params.has("d")) {
      var sd_id = "";
      sd_id = params.get("d");
      this.setState({active_tab:"d"});
      var user_id = "";
      if(user ){
          user_id = "&user_id="+user.id;
      }
      axios
        .get(
          `http://adscombined.com/v1/public/api/DealDetails?deal_id=${sd_id+user_id}`
        )
        .then(
          (response) => {
            if (response.data.flag) {
              if (response.data.deal_data) {
                const sepecial_deal = response.data.deal_data;

                this.openModal(sepecial_deal);
              }
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }




    var user_id = "";
    if(user ){
        user_id = "?&user_id="+user.id;
    }

    if(this.state.coupons.length<1 || this.state.deals.length<1){
      this.setState({isLoading:true});
      axios
      .get(
        `http://adscombined.com/v1/public/api/favourite_list${user_id}`,{
          // headers: {
          //   'Accept' :'application/json',
          //   'Access-Control-Allow-Origin' :'*',
          //   'Authorization': `Bearer ${this.state.token}`
          // }
        }
      )
      .then(
        (response) => {
          if (response.data.flag) {
            if (response.data.coupons) {
              const coupons = response.data.coupons;
              this.setState({coupons:coupons});
            }
            if (response.data.deals) {
              const deals = response.data.deals;
              this.setState({deals:deals});
            }
            this.setState({isLoading:false});
          } else {
            this.setState({isLoading:false});
          }
        },
        (error) => {
          this.setState({isLoading:false});
          console.log(error);
        }
      );
    
    
      }

     

  }


  render() {
    const contentStyle = { width: isBrowser ? '70% !important' : isTablet ? '70% !important': '90% !important' };
    var  colors =  [ "#CD4CC3",'#f1c500', '#4E0404', '#8175c7',"#212F3C", '#ff9900', "#0B6B90", "#22A9DD", "#6BCB6B"];
    var count = 0; 
    var add_count = 0;
    return (
        <div>
          <Popup
          open={this.state.open2}
          closeOnDocumentClick
          onClose={this.closeModal2}
          {...{ contentStyle }}
        >
          <div className="modal">
            <a className="close" onClick={this.closeModal2}>
              &times;
            </a>
            <div class="row" style={{ padding: "10px" }}>
              <div className="col-md-4 pl-0">
                <center>
                  <img
                  alt={this.state.sepecial_coupon.StoreName+" Coupons & Deals"}
                  title={this.state.sepecial_coupon.StoreName+ " Coupons & Deals"}
                    src={
                      this.state.sepecial_coupon.Logo
                        ? window.$logos_url+this.state.sepecial_coupon.Logo.replace(".PNG",".png")
                        : process.env.PUBLIC_URL + "/default_logo.png"
                    }
                    width="100"
                    height="100"
                    id="store_logo"
                    style={{
                      objectFit:"scale-down"
                    }}
                  ></img>
                  <h5>
                    <b style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </b>
                  </h5>
                </center>
              </div>

              <div className="col-md-8" style={{ padding: "inherit" }}>
             
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "teal",
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.sepecial_coupon.Offer &&
                  this.state.sepecial_coupon.Offer !== "none" &&
                  this.state.sepecial_coupon.Offer !== "None" ? (
                    <CouponTypeDisplay
                      coupontype={this.state.sepecial_coupon.Offer}
                      source="couponpage"
                    />
                  ) : (
                    <CouponTypeDisplay
                      coupontype={this.state.sepecial_coupon.Type}
                      source="couponpage"
                    />
                  )}
                </span>

                <h3>
                  <span id="coupon_title">
                    {this.state.sepecial_coupon.Title}
                  </span>
                </h3>

                <br />
                <b></b>
                {this.state.sepecial_coupon.Code &&
                this.state.sepecial_coupon.Code != "None" ? (
                  <span>
                    <input
                      id="code-value"
                      type="text"
                      className="code-copy"
                      value={this.state.sepecial_coupon.Code}
                      style={{
                        borderRadius: "0px",
                        width: "fit-content",
                        cursor: "copy",
                      }}
                      readOnly
                    />

                    <span>
                      <button
                        className="btn"
                        onClick={this.myFunction}
                        onMouseOut={this.outFunc}
                        id="myTooltip"
                        style={{ verticalAlign: "inherit" }}
                      >
                        Copy Code
                      </button>
                    </span>

                    <br />
                    <br />
                  </span>
                ) : (
                  <div></div>
                )}

                <a href={this.state.sepecial_coupon.SourceUrl}>
                  <b>
                    {" "}
                    Continue to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </span>
                  </b>{" "}
                </a>
              </div>
            </div>
          </div>
        </Popup>
          <Popup
            open={this.state.open}
            closeOnDocumentClick
            onClose={this.closeModal}
            {...{ contentStyle }}
          >
          <div className="modal">
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
            <div class="row" style={{ padding: "10px" }}>
              <div className="col-md-4 pl-0">
                <center>
                  <img
                  title={this.state.sepecial_deal.StoreImage+ " Coupons, Promotion Codes & Deals"}
                  alt={this.state.sepecial_deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                    src={
                      this.state.sepecial_deal.Logo
                        ? window.$logos_url+this.state.sepecial_deal.Logo.replace(".PNG",".png")
                        : process.env.PUBLIC_URL + "/default_logo.png"
                    }
                    width="100"
                    height="100"
                    id="store_logo"
                    style={{objectFit:"scale-down"}}
                  ></img>
                  <h5>
                    <b style={{ textTransform: "capitalize" }} title={this.state.sepecial_deal.StoreName+" Coupons, Promotion Codes & Deals"}>
                      {this.state.sepecial_deal.StoreName}
                    </b>
                  </h5>
                </center>
              </div>

              <div className="col-md-8 " style={{ padding: "inherit" }}>
             
                {this.state.sepecial_deal.Image &&
                this.state.sepecial_deal.Image !== "none" &&
                this.state.sepecial_deal.Image !== "None" ? (
                  <span>
                    <img
                    
                      className=" deals-img-popup pl-0 pr-0"
                      src={this.state.sepecial_deal.Image}
                     alt={this.state.sepecial_deal.Title}
                     title={this.state.sepecial_deal.Title}
                    ></img>

                    <br />
                    <br />
                  </span>
                ) : (
                  <span></span>
                )}

                <h4>
                {this.state.sepecial_deal.Saving ? 

                  <div
                      className=" "
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'bottom', 
                        width: 'fit-content',
                        position: 'absolute', 
                        top: '0px', 
                        right: '0px', 
                        textTransform: 'uppercase', 
                        fontSize:"18px",
                        textAlign:"center",
                        padding: '5px', 
                        color:"white",
                        paddingBottom: '25px', 
                        fontWeight: 700
                      }}
                  >
                      {this.state.sepecial_deal.Saving}  <br/> Save
                  </div>
                  :
                  this.state.sepecial_deal.PriceDifference && this.state.sepecial_deal.PriceDifference>0 ?
                    <div
                        className=" "
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                          backgroundSize: 'cover', 
                          backgroundPosition: 'bottom', 
                          width: 'fit-content',
                          position: 'absolute', 
                          top: '0px', 
                          fontSize:"18px",
                          color:"white",
                          right: '0px', 
                          textTransform: 'uppercase', 
                          textAlign:"center",
                          padding: '5px', 
                          paddingBottom: '25px', 
                          fontWeight: 700
                        }}
                    >
                        ${this.state.sepecial_deal.PriceDifference} <br/> Save
                    </div>
                    :
                    <div></div>
                  }
                 
                </h4>
                <h3 id="coupon_title">
                    {this.state.sepecial_deal.Title}
                  </h3>
                <br />
                <b></b>
                {this.state.sepecial_deal.Code &&
                this.state.sepecial_deal.Code != "None" ? (
                  <span>
                    <input
                      id="code-value"
                      type="text"
                      className="code-copy"
                      value={this.state.sepecial_deal.Code}
                      style={{
                        borderRadius: "0px",
                        width: "fit-content",
                        cursor: "copy",
                      }}
                      readOnly
                    />

                    <span>
                      <button
                        className="btn"
                        onClick={this.myFunction}
                        onMouseOut={this.outFunc}
                        id="myTooltip"
                        style={{ verticalAlign: "inherit" }}
                      >
                        Copy Code
                      </button>
                    </span>

                    <br />
                    <br />
                  </span>
                ) : (
                  <div></div>
                )}

                <a href={this.state.sepecial_deal.SourceUrl}>
                  <b>
                    {" "}
                    Continue to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_deal.StoreName}
                    </span>
                  </b>{" "}
                </a>
              </div>
            </div>
          </div>
        </Popup>
            <h4>
            My Favourites
            </h4>
            <br></br>
            <div className="row">
              <div className="col-md-12 favourite_tabs">

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class={"nav-link "+(this.state.active_tab == "d"? "active":"")} id="deal-tab" data-toggle="tab" href="#deal" role="tab" aria-controls="deal" aria-selected="true" style={{color:"#495057 !important"}}>Favourite Deals</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class={"nav-link  "+(this.state.active_tab == "c"? "active":"")} id="coupon-tab" data-toggle="tab" href="#coupons" role="tab" aria-controls="coupons" aria-selected="false" style={{color:"#495057 !important"}}>Favourite Coupons</a>
                </li>
               
              </ul>
              <div class="tab-content p-2" id="myTabContent">
                <div class={"tab-pane fade  "+(this.state.active_tab == "d"? "show active":"")} id="deal" role="tabpanel" aria-labelledby="deal-tab">
                    
                    <div class="row">
                      
                    {
                      this.state.isLoading ? 
                      <DealsSkeleton items={[1,2,3,4,5,6]} />
                        
                      :
                    
                        this.state.deals.length > 0 ?
                          this.state.deals.map((deal) =>
                            
                          <div className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable" title={"Click to get "+deal.Title} >
              
                            <div className="card  text-white   single-deal  " >
                              <i  onClick={()=>this.removeFavourite("deal",deal.favourite_id)} className="fa fa-trash" style={{padding:"5px",zIndex:"10",backgroundColor:"#f56f36",position:"absolute",top:"0",left:"0",width:"30px",borderRadius:"3px",margin:"0px"}} title="Remove from Favourites"></i>
                            {/* <AddtoFavourite type={"heart-deal"} id={deal.id} favourite_type={"deal"}  is_favourite={deal.favourite} /> */}
                              {deal.Saving ? 
            
                              <div style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                                backgroundSize: 'cover', 
                                backgroundPosition: 'bottom', 
                                width: 'fit-content',
                                position: 'absolute', 
                                top: '0px', 
                                right: '0px', 
                                textTransform: 'uppercase', 
                                textAlign:"center",
                                padding: '5px', 
                                paddingBottom: '20px', 
                                fontWeight: 700
                              }}> 
                                {deal.Saving} <br />Save
                              </div>
                              :
                              deal.PriceDifference && deal.PriceDifference>0 ?
                                <div
                                    className=" "
                                    style={{
                                      backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                                      backgroundSize: 'cover', 
                                      backgroundPosition: 'bottom', 
                                      width: 'fit-content',
                                      position: 'absolute', 
                                      textAlign:"center",
                                      top: '0px', 
                                      right: '0px', 
                                      textTransform: 'uppercase', 
                                      padding: '5px', 
                                      paddingBottom: '20px', 
                                      fontWeight: 700
                                    }}
                                >
                                    ${deal.PriceDifference}<br />Save
                                </div>
                                :
                                <div></div>
                              }
                              <img src={deal.Image} className="card-img" 
                              onClick={() => this.redirectToStore(deal)}
                               alt={deal.Title} style={{objectFit:"scale-down",height:"250px",width:"100%"}}/>
                              
                              <div className="card-img-overlay p-1" style={{top:"unset",background:' #00000099'}}>
                            
                              <div className="details">
                              <Link
                                className="logo  cust_link featured"
                                to={"coupons/" + deal.StoreURL}
                                title={
                                  deal.StoreName +" Coupons, Promotion Codes & Deals"
                                }
                              >
                              {deal.Logo && deal.Logo!=="none" && deal.Logo!=="None" ?
                                (
                                  <img 
                                  src={window.$logos_url+deal.Logo.replace(".PNG",".png")}  height={50} width={70} 
                                  title={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                                  alt={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                                  style={{
                                    position:"absolute",
                                    top:"-35px",
                                    borderRadius:"5px",
                                    background: "white",
                                    objectFit:"scale-down"
                                  }}
                                  ></img>
                                )
                                :
                                (
                                  <img src={process.env.PUBLIC_URL + "/default_logo.png"} 
                                  title={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                                  alt={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                                  style={{
                                    position:"absolute",
                                    top:"-35px",
                                    borderRadius:"5px",
                                    background: "white",
                                    objectFit:"scale-down"
                                  }}
                                  height={50} width={70} ></img>
                                )
                              }
                              </Link>
                              
                              <div className="card-title mt-3 mb-0 "  >
                                  <h3 
                                  onClick={() => this.redirectToStore(deal)}
                                  
                                  title={"Click to get "+deal.Title} style={{fontSize: "14px"}}> {deal.Title && deal.Title.length <40 ? deal.Title : this.shorten(deal.Title,40)+".."}</h3>
                              </div>
                                <h5  style={{fontSize:"1.0rem !important"}}>
                                  <span style={{ fontSize: "14px" }}>
                                      <span style={{ textDecoration: "line-through" }}>
                                      {deal.Price1}
                                      </span>{" "}
                                      &nbsp;
                                  </span>
                                  <b style={{ color: "#00e809" ,fontSize:"28px"}}>{deal.Price2}</b>&nbsp;
                                
                                </h5>
                                  
                              </div>
                            </div>
                          </div>     
                        </div> 

                          )
                        
                        :
                        <div className="col-md-12">
                          <p>No Favourite Deals Found.</p>
                        </div>
                    
                    }
                    
                  </div>
                </div>
                <div class={"tab-pane fade "+(this.state.active_tab == "c"? "show active":"")} id="coupons" role="tabpanel" aria-labelledby="coupons-tab">
                  <div class="row">
                    
                    {
                        this.state.isLoading ? 
                        <SearchOffersSkeleton items={[1,2,3,4]} />
                          
                        :
                      
                          this.state.coupons.length > 0 ?
                            this.state.coupons.map((coupon) =>
                              
                            <div className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable"
                            style={{}}
                            title={"Click to get "+coupon.Title}>
                              
                              <div
                                className="card  single-deal"
                                style={{ borderRadius: "5px" }}
                              >
                                  <div className="home-offer-text" style={{    background:colors[count]}}>
                                  <center>
                                        <small onClick={() => this.redirectToStore2(coupon)} className="coupon-type-text" style={{ textTransform: "uppercase" }}>
                                      
                                          {coupon.Offer && coupon.Offer!="None" && coupon.Offer!="none" ? (
                                          <h5 style={{fontWeight:"bold"}}>{coupon.Offer}</h5>
                                          ) : (
                                          <h5 style={{fontWeight:"bold"}}>{coupon.Type}</h5>
                                          )}
                                        </small>
                                      </center>
                                    </div>
                                  <span style={{display:"none"}}>
                                    {add_count++}
                                    {count<=7 ?count++ : count = 0 }
                                  </span> 
                                  <div
                                    className="card-body"
                                    style={{
                                      padding: "10px !important",
                                      padding: "0.8rem",
                                    }}
                                  >
                                     <i onClick={()=>this.removeFavourite("coupon",coupon.favourite_id)} className="fa fa-trash" style={{zIndex:"10",padding:"5px",backgroundColor:"#f56f36",color:"white",position:"absolute",top:"0",left:"0",width:"30px",borderRadius:"3px",margin:"0px"}} title="Remove from Favourites"></i>
                              
                                  <Link
                                    className="logo  cust_link featured"
                                    to={"/coupons/" + coupon.StoreURL}
                                    title={
                                      coupon.StoreName +" Coupons, Promotion Codes & Deals"
                                    }
                                  >
                                      {coupon.Logo && coupon.Logo != "Logo Name" && coupon.Logo != "none" ? (
                                    <div
                                        
                                      className="center deals-img"
                                      style={{
                                        marginTop:"-25px",
                                        height: "40px",
                                        width: "100px",
                                        backgroundColor:"white",
                                        border:"1px solid lightgray",
                                        backgroundImage: "url(" + window.$logos_url+coupon.Logo.replace(".PNG",".png") + ")",
                                      }}
                                    ></div>
                                  ) : (
                                    <div
                                    
                                      className="center deals-img"
                                      style={{
                                        marginTop:"-25px",
                                        height: "50px",
                                        backgroundColor:"white",
                                        width: "100px",
                                        border:"1px solid lightgray",
                                        backgroundImage:
                                          "url("+process.env.PUBLIC_URL + "/default_logo.png)",
                                      }}
                                    ></div>
                                  )}
                                  </Link>
                                    <div
                                      
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        marginTop: "0.25rem",
                                        marginBottom:"0.25rem !important"
                                      }}
                                    >
                                     
                                      {" "}
                                      <h3
                                      onClick={() => this.redirectToStore2(coupon)}
                                        style={{
                                        fontSize: "14px"}} title={"Click to get "+coupon.Title}> 
                                        {coupon.Title && coupon.Title.length < 40 ? coupon.Title : this.shorten(coupon.Title,40)+".."}
                                      </h3>
                                    </div>

                                  
                                    <p>
                                    
                                      
                                    <Link to={"/coupons/" +  window.$categories[coupon.Category]} style={{position:"absolute",bottom:"5px"}}>
                                  
                                  <small >{coupon.Category}</small>
                                    
                                    </Link>
                                    </p>
                                  </div>
                                </div>
                            
                            </div>
                            )
                          :
                          (
                            <div className="col-md-12">
                              <p>No Favourite Coupons Found. </p>
                            </div>
                          )
                    }
                  </div>
                </div>
               
              </div>

              </div>
            </div>
        </div>
    );
  }
}

export default Favourites;

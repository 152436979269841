import React, { PureComponent } from "react";
import axios from "axios";
import Toast from "light-toast";
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";
import { isMobile, isBrowser,isTablet } from "react-device-detect";
import Moment from 'react-moment';

import DealsSkeleton from "./skeletons/DealsSkeleton";
import ReactPaginate from "react-paginate";
import Advertisement from "./Advertisement";
import AddtoFavourite from "./AddtoFavourite";

import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();

class Deals extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      total:0,
      loading: false,
      offset: 0,
      perPage: isBrowser ? 60 : 30,
      currentPage: 0,
      date_updated: "",
      sepecial_deal: [],
      sd_id: "",
      open: false,
    };
    
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);
    this.receivedData = this.receivedData.bind(this);
  }

  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(deal) {
    if (this.state.sepecial_deal.length < 1) {
      this.setState({ open: true, sepecial_deal: deal });
    }
  }

  closeModal() {
    this.setState({ open: false });
  }

  shorten(text, max) {
    return text && text.length > max
      ? text.slice(0, max).split(" ").slice(0, -1).join(" ")
      : text;
  }

  redirectToStore(deal) {
    Toast.info(
      <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {deal.StoreURL}
      </div>,
      3000,
      () => {
        window.open(
          window.location.href.split("?")[0] + "?d=" + deal.id,
          "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
          //this.openModal(coupon)
          (window.location.href = deal.SourceUrl),
          2000
        );
      }
    );
  }


  receivedData() {
    const params = new URLSearchParams(window.location.search);

    if (params.has("d")) {
      var sd_id = "";
      sd_id = params.get("d");
      var user_id = "";
      if(user ){
          user_id = "&user_id="+user.id;
      }
      axios
        .get(
          `http://adscombined.com/v1/public/api/DealDetails?deal_id=${sd_id}${user_id}`
        )
        .then(
          (response) => {
            if (response.data.flag) {
              if (response.data.deal_data) {
                const sepecial_deal = response.data.deal_data;

                this.openModal(sepecial_deal);
              }
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

    this.setState({ loading: true }, () => {
      var store_id = null;

      if (this.props.store_id) {
        store_id = this.props.store_id;
        var user_id = "";
        if(user ){
            user_id = "&user_id="+user.id;
        }
        axios
          .post(
            `http://adscombined.com/v1/public/api/StoreDeals?store_url=${encodeURIComponent(
              store_id
            )}&Page=${
              this.state.currentPage
            }&PerPage=${this.state.perPage}${user_id}`
          )
          .then(
            (response) => {
              console.log(response);
              if (response.data.deals) {
                const deals = response.data.deals;
                if(this.state.date_updated===""){
                  if(deals.length>0){
                    if(deals[0].DateUpdated){
                     
                      this.setState({date_updated: deals[0].DateUpdated});
                    }
                  }
                }
                this.setState({
                  total: this.props.store_info.DealsCount,
                  deals:deals,
                  pageCount: Math.ceil(
                    this.props.store_info.DealsCount / this.state.perPage
                  ),
                });
                
                this.setState({ loading: false });
              } else {
                this.setState({ loading: false });
              }
            },
            (error) => {
              this.setState({ loading: false });
              console.log(error);
            }
          );
      }
    });
  }

  componentDidMount() {
  
    this.receivedData();
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
    window.scrollTo(0, 0);
  };

  render() {
    var add_count = 0;
    const contentStyle = { width: isBrowser ? '70% !important' : isTablet ? '70% !important': '90% !important' };
    return (
      <div>
       <h1 className="ac-h1" title={this.props.store_name+" Deals"}  style={{textTransform:"capitalize",fontSize:"2rem",fontWeight:"bold"}}>
          {this.props.store_name ? this.props.store_name: "" }  <Link title="Click to view all deals" to="/coupons/all">Deals</Link>
        </h1>
       {this.state.date_updated!==""? 
        (
          <h5>Verified {this.state.store_name} <Link to={"/deals"} title="All Deals">Deals</Link> for  <Moment format="dddd, MMMM D, Y">
          {this.state.date_updated}
          </Moment> </h5>
        )
        :(<span></span>)
        }
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          {...{ contentStyle }}
        >
          <div className="modal">
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
            <div class="row" style={{ padding: "10px" }}>
              <div className="col-md-4 pl-0">
                <center>
                <Link
                    className="logo  cust_link featured"
                    to={"/coupons/" + this.state.sepecial_deal.StoreURL}
                    title={
                      this.state.sepecial_deal.StoreName +" Coupons, Promotion Codes & Deals"
                    }
                  >
                  <img 
                    alt={this.state.sepecial_deal.StoreName +" Coupons, Promotion Codes & Deals"}
                    title={this.state.sepecial_deal.StoreName +" Coupons, Promotion Codes & Deals"}
                    src={
                      this.state.sepecial_deal.Logo
                        ?window.$logos_url+this.state.sepecial_deal.Logo.replace(".PNG",".png")
                        : process.env.PUBLIC_URL + "/default_logo.png"
                    }
                    width="100"
                    height="100"
                    id="store_logo"
                  ></img>
                  <h5>
                    <b style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_deal.StoreName}
                    </b>
                  </h5>
                  </Link>
                </center>
              </div>

              <div className="col-md-8" style={{ padding: "inherit" }}>
              <AddtoFavourite type={"heart"} source={"sdeals"} id={this.state.sepecial_deal.id} favourite_id = {this.state.sepecial_deal.favourite_id} favourite_type={"deal"} is_favourite={this.state.sepecial_deal.favourite}/>
                {this.state.sepecial_deal.Image &&
                this.state.sepecial_deal.Image !== "none" &&
                this.state.sepecial_deal.Image !== "None" ? (
                  <span>
                    <img
                      className=" deals-img-popup  pr-0"
                      src ={ this.state.sepecial_deal.Image}
                     alt={this.state.sepecial_deal.Title}
                    ></img>

                    <br />
                    <br />
                  </span>
                ) : (
                  <span></span>
                )}

                <h4>
                {this.state.sepecial_deal.Saving ? 

                  <div
                      className=" "
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'bottom', 
                        width: 'fit-content',
                        position: 'absolute', 
                        top: '0px', 
                        right: '0px', 
                        textTransform: 'uppercase', 
                        fontSize:"18px",
                        textAlign:"center",
                        padding: '5px', 
                        color:"white",
                        paddingBottom: '25px', 
                        fontWeight: 700
                      }}
                  >
                      {this.state.sepecial_deal.Saving}  <br/> Save
                  </div>
                  :
                  this.state.sepecial_deal.PriceDifference && this.state.sepecial_deal.PriceDifference>0 ?
                    <div
                        className=" "
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                          backgroundSize: 'cover', 
                          backgroundPosition: 'bottom', 
                          width: 'fit-content',
                          position: 'absolute', 
                          top: '0px', 
                          fontSize:"18px",
                          color:"white",
                          right: '0px', 
                          textTransform: 'uppercase', 
                          textAlign:"center",
                          padding: '5px', 
                          paddingBottom: '25px', 
                          fontWeight: 700
                        }}
                    >
                        ${this.state.sepecial_deal.PriceDifference} <br/> Save
                    </div>
                    :
                    <div></div>
                  }
                  
                </h4>
                <h3 id="coupon_title">
                    {this.state.sepecial_deal.Title}
                  </h3>
                <br />
                <b></b>
                {this.state.sepecial_deal.Code &&
                this.state.sepecial_deal.Code != "None" ? (
                  <span>
                    <input
                      id="code-value"
                      type="text"
                      className="code-copy"
                      value={this.state.sepecial_deal.Code}
                      style={{
                        borderRadius: "0px",
                        width: "fit-content",
                        cursor: "copy",
                      }}
                      readOnly
                    />

                    <span>
                      <button
                        className="btn"
                        onClick={this.myFunction}
                        onMouseOut={this.outFunc}
                        id="myTooltip"
                        style={{ verticalAlign: "inherit" }}
                      >
                        Copy Code
                      </button>
                    </span>

                    <br />
                    <br />
                  </span>
                ) : (
                  <div></div>
                )}

                <a href={this.state.sepecial_deal.SourceUrl}>
                  <b>
                    {" "}
                    Continue to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_deal.StoreName}
                    </span>
                  </b>{" "}
                </a>
              </div>
            </div>
          </div>
        </Popup>
        
       
       
        
        <div className="mb-2  well well-sm border-div bg-white borderZero p-2">
          <div class="btn-group">
            <button className="btn-info   btn-pills ml-2 mt-2 mb-2">
              All Deals {this.props.store_info.DealsCount>0 ? "("+this.props.store_info.DealsCount+")" : ""}
            </button>
          </div>
        </div>
        <div
          className="container-fluid "
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >



          <div className="card">
            <div className="card-body">
              {this.state.loading ? (
                <DealsSkeleton />
              ) : (
                <div>

                  {this.state.deals && this.state.deals.length > 0 ? (
                    <div>
                        {this.props.store_info.DealsCount >0 ? 
                          <span className="col-md-12">Showing <b>{this.state.offset+1 }</b> to <b>{this.state.offset+this.state.perPage > this.props.store_info.DealsCount ? this.props.store_info.DealsCount : this.state.offset+this.state.perPage  }</b> out of <b>{this.props.store_info.DealsCount}</b> results    <br/></span>
                        :
                          <span></span>
                        }
                      <div className="row">
                        {this.state.deals.map((deal) =>

                          add_count > 9 ? 
                          <div
                            className="card-deck col-md-4 mt-2"
                            >
                              <Advertisement ad_type="column"></Advertisement>
                              <span style={{display:"none"}}>{add_count=0}</span>
                          </div>
                          :
                            deal.Image != "None" &&
                            deal.Image != "" &&
                            deal.Image != "none" &&
                            (deal.Title == "banner" ||
                              deal.Title == "None" ||
                              deal.Title == "") ? (
                              <div
                                className="card-deck col-md-4 mt-2"
                               
                                style={{ cursor: "pointer" }}
                              >  <span style={{display:"none"}}>{add_count++}</span>
                                <AddtoFavourite type={"heart-deal"} source={"deals"} id={deal.id} favourite_id = {deal.favourite_id} favourite_type={"deal"} is_favourite={deal.favourite} />
                                <div className="card  single-deal">
                                  <img
                                    style={{
                                      height: "-webkit-fill-available",
                                      width: "100%",
                                      objectFit:"scale-down",
                                    }}
                                    src={deal.Image}
                                    title={"Click to get "+deal.Title}
                                    alt={"Click to get "+deal.Title}
                                  ></img>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="card-deck pl-1 pr-1 col-md-4 mt-2 clickable"
                               
                               
                              >  <span style={{display:"none"}}>{add_count++}</span>
                                <div className="card  text-white  single-deal  ">
                                <AddtoFavourite type={"heart-deal"} source={"deals"}  favourite_id = {deal.favourite_id} favourite_type= {"deal"} id={deal.id} is_favourite={deal.favourite}/>
                                {deal.Saving ? 
                                  
                                  <div style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                                    backgroundSize: 'cover', 
                                    backgroundPosition: 'bottom', 
                                    width: 'fit-content',
                                    position: 'absolute', 
                                    top: '0px', 
                                    right: '0px', 
                                    textTransform: 'uppercase', 
                                    textAlign:"center",
                                    padding: '5px', 
                                    paddingBottom: '20px', 
                                    fontWeight: 700
                                  }}> 
                                    {deal.Saving} <br />Save
                                  </div>
                                  :
                                  deal.PriceDifference && deal.PriceDifference>0 ?
                                    <div
                                        className=" "
                                        style={{
                                          backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                                          backgroundSize: 'cover', 
                                          backgroundPosition: 'bottom', 
                                          width: 'fit-content',
                                          position: 'absolute', 
                                          textAlign:"center",
                                          top: '0px', 
                                          right: '0px', 
                                          textTransform: 'uppercase', 
                                          padding: '5px', 
                                          paddingBottom: '20px', 
                                          fontWeight: 700
                                        }}
                                    >
                                        ${deal.PriceDifference}<br />Save
                                    </div>
                                    :
                                    <div></div>
                                  }
                                  <img
                                    alt={deal.Title}
                                    onClick={() => this.redirectToStore(deal)}
                                    title={"Click to get "+deal.Title}
                                    src={deal.Image}
                                    className="card-img"
                                    alt="..."
                                    style={{ height: "300px", width: "100%",objectFit:"scale-down" }}
                                  />

                                  <div
                                    className="card-img-overlay p-1"
                                    style={{
                                      top: "unset",
                                      background: " #00000099",
                                    }}
                                  >
                                    
                                    <div className="details">
                                    <Link
                                      className="logo  cust_link featured"
                                      to={"/coupons/" + deal.StoreURL}
                                      title={
                                        deal.StoreName +" Coupons, Promotion Codes & Deals"
                                      }
                                    >
                                      {deal.Logo &&
                                      deal.Logo !== "none" &&
                                      deal.Logo !== "None" ? (
                                        <img
                                          title={deal.StoreName+  " Coupons, Promotion Codes & Deals"}
                                          alt={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                                          src={window.$logos_url+deal.Logo.replace(".PNG",".png")}
                                          height={50}
                                          width={60}
                                          style={{
                                            position: "absolute",
                                            top: "-35px",
                                            borderRadius: "5px",
                                            objectFit:"fill",
                                            background: "white"
                                          }}
                                        ></img>
                                      ) : (
                                        <img
                                          title={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                                          alt={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                                          src={process.env.PUBLIC_URL + "/default_logo.png"}
                                          style={{
                                            position: "absolute",
                                            top: "-35px",
                                            borderRadius: "5px",
                                            objectFit:"fill",
                                            background: "white"
                                          }}
                                          height={50}
                                          width={60}
                                        ></img>
                                      )}
                                      </Link>
                                      <div className="card-title mt-3 mb-0 ">
                                        <h3 style={{ fontSize: "18px" }} title={"Click to get"+ deal.Title} onClick={() => this.redirectToStore(deal)}>
                                          {" "}
                                          {deal.Title && deal.Title.length < 40
                                            ? deal.Title
                                            : this.shorten(deal.Title, 40) +
                                              ".."}
                                        </h3>
                                      </div>

                                      <h5
                                        style={{
                                          fontSize: "1.0rem !important",
                                        }}
                                        onClick={() => this.redirectToStore(deal)}
                                      >
                                        <span style={{ fontSize: "14px" }}>
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {deal.Price1}
                                          </span>{" "}
                                          &nbsp;
                                        </span>
                                        <b style={{ color: "limegreen",fontSize:"28px" }}>
                                          {deal.Price2}
                                        </b>
                                        &nbsp;
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                     
                    </div>
                  ) : (
                    <h5>No Deals Found</h5>
                  )}
                </div>
              )}

              { this.props.store_info.DealsCount > this.state.perPage ? (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  forcePage={this.state.currentPage}
                  pageCount={this.state.pageCount>0 ? this.state.pageCount: Math.ceil(
                    this.props.store_info.DealsCount / this.state.perPage
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Deals;

import React, { PureComponent } from 'react';
import { BrowserRouter as Router,  Switch,  Route,  Link } from "react-router-dom";

import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import FAQs from './FAQs';

class OurPages extends PureComponent{

    constructor(props){
        super(props);
        this.state= {
            pageOpened: this.props.pageOpened,
            menuItems:[
                ["/about-us","About Us"],
                ["/contact-us","Contact Us"],               
                ["/faqs","FAQs"],
                ["/terms-and-conditions","Terms and Conditions"],
                ["/privacy-policy","Privacy Policy"]
              ]
        }

    }

    menuClickHandle(menu){
        return this.setState({pageOpened:menu});
    }

    renderComponent() {
        switch (this.state.pageOpened) {
            case "FAQs":
            return (
              <FAQs                
              />
            );
            case "Terms and Conditions":
            return (
              <TermsAndConditions
              />
            );
            case "Privacy Policy":
            return (
              <PrivacyPolicy
              />
            );
            case "Contact Us":
            return (
              <ContactUs
              />
            );
            case "About Us":
            return (
              <AboutUs
              />
            );
        }
      }

    render(){

        return(
            <div>
                <section className="main-container">
                    <div className="container" style={{ padding: "0px" }}>
                        <br />
                        <div className="row">
                        <div className="col-md-12 ">
                            <nav aria-label="breadcrumb">
                            <ol
                                className="breadcrumb"
                                style={{ backgroundColor: "transparent" }}
                            >
                                <li className="breadcrumb-item">
                                    <a href="\">Home</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {this.state.pageOpened}
                                </li>
                            </ol>
                            </nav>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <Router>
                                            <ul class="menu list-unstyled ">
                                            { this.state. menuItems.map(item =>
                                                this.state.pageOpened == item[1] ? (
                                                <li className="menu-item btn-pill navbar-active" style={{color:"rgb(255, 255, 255) !important"}}>
                                                <Link  className="nav-link active-link" onClick={()=>this.menuClickHandle(item[1])} to={item[0]} style={{color:"white !important"}}>
                                                    {item[1]}
                                                </Link >
                                                </li>
                                                ):(
                                                <li className="menu-item btn-pill ">
                                                <Link  className="nav-link" onClick={()=>this.menuClickHandle(item[1])} to={item[0]}>
                                                    {item[1]}
                                                </Link >
                                                </li>
                                                )
                                            )
                                            }
                                               
                                            </ul>
                                        </Router>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-9 ">
                                <div className="card">
                                    <div className="card-body" style={{padding:"3%"}}>
                                        
                                        {this.renderComponent()}
                                    </div>
                                </div>
                                <h4></h4>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default OurPages;
import React, { PureComponent } from "react";
import Skeleton from "react-loading-skeleton";
class TopAdsSkeleton extends PureComponent {
  render() {
    return (
      <div className="card ">
        <div className="card-body">
       
          <div className="row">
            {this.props.items.length > 0 ? (
              this.props.items.map((item) => (
                <div key={item} className=" col-md-2 ">
                    <center>
                 
                
                  <Skeleton  className="ml-1 w-100" height={100}></Skeleton>
                  <center style={{marginTop:"-25px"}}>
                    <Skeleton width={100} height={50} ></Skeleton>
                  </center>
                 
                  </center> 
                  <div >
                    <div className="card-title mt-2 mb-0" style={{height:"80px"}}>
                      <h5 style={{ fontSize: "1.0rem" }}>
                        
                       
                        <Skeleton width={90} height={20}></Skeleton>
                            <br></br>
                            <br></br>
                        <Skeleton  height={20}></Skeleton>
                        
                      </h5>
                    </div>
                 
                  
                  </div>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default TopAdsSkeleton;

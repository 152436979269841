import React, { PureComponent } from "react";
import axios from "axios";
import CategoriesSkeleton from './skeletons/CategoriesSkeleton';
import { BrowserRouter as Router,  Switch,  Route,  Link } from "react-router-dom";

class StoreCategories extends PureComponent{
   state = {
      categories: [],
      loading:false
   }
   componentDidMount(){

      if(this.props.categories.length>0){
         const categories = this.props.categories;
         this.setState({categories});
      }else{

         this.setState({ loading: true }, () => {

            axios.get(`http://adscombined.com/v1/public/api/AllCategories`).then((response) => {
          
            
               if(response.data.categories){

                  
                  const categories = response.data.categories;
                  this.setState({categories});
                  this.setState({loading:false});
               
               }else{
                  this.setState({loading:false});
                  document.getElementById('no_store_msg').style.display = "block";
               }
            }, (error) => {
               this.setState({loading:false});
               console.log(error);
            });
         })
      }
      
      
   }
    render(){
        let link_type = "onlinestores";
        if(this.props.record_type == "Brand"){
            link_type = "onlinebrands";
        }
        if(this.props.record_type == "Retailer"){
            link_type = "onlineretailers";
        }
        return(
            <div>
               
                {
                    this.props.selected_category && this.props.selected_category != "" ?
                        this.props.selected_subcategory && this.props.selected_subcategory !="" ? 

                        <h5 >
                            <a href={"/"+link_type+"/"+window.$categories[this.props.selected_category]} >
                                {this.props.selected_category }
                            </a>
                       
                        <span style={{fontSize:"16px"}}> 
                        <span >{" > "}</span>
                            <a style={{color:"#3a3939 "}} href={"/"+link_type+"/"+window.$categories[this.props.selected_category]+"/"+window.$categories[this.props.selected_subcategory]} >
                                { this.props.selected_subcategory}
                            </a>

                        </span>
                        </h5>
                        :
                        <h5 >  <a href={"/"+link_type+"/"+window.$categories[this.props.selected_category]} >{this.props.selected_category}</a></h5>
                    :
                    <h5 >Top Categories</h5>
                }
             
               {this.state.loading?
               (
                  <CategoriesSkeleton/>
               ):
               (
                 
                  <div>
                     {this.state.categories.length>0 ? (

                        this.props.selected_category && this.props.selected_category != "" ? (

                            this.state.categories.map( category=>
                                category.Category == this.props.selected_category ? (
                                    category.sub_categories.map( subcategory=>
                                    <a href={"/"+link_type+"/"+window.$categories[category.Category]+"/"+window.$categories[subcategory.SubCategory]} key={category.Category.toString()+subcategory.SubCategory.toString()} title={category.Category + " and "+subcategory.SubCategory+" Coupons & Promotion Codes"}  className="no_decoration">
                                        <div class="btn-group">
                                            <button className="btn-default  bg-orange btn-pills ml-2 mt-1 mb-1">{subcategory.SubCategory}</button>
                                        </div>
                                    
                                    </a>
                                    )
                                ):""
                            )
                            
                        )
                        :
                        (
                            this.state.categories.map( category=>
                                <a href={"/"+link_type+"/"+window.$categories[category.Category]} key={category.Category.toString()} title={category.Category + " Stores or Brands"}  className="no_decoration">
                                    <div class="btn-group">
                                        <button className="btn-default   btn-pills ml-2 mt-1 mb-1">{category.Category}</button>
                                    </div>
                                
                                </a>
                            
                            )
                        )
                    )
                    :
                    (
                        <p>
                            No Categories Found
                        </p>
                    )
                    }
                     
                     <p className="mb-0">
                        <a href={"/"+link_type} title={"All Categories Stores or Brands"}>
                           View all Categories.
                        </a>
                     </p>
                  </div>
               )
               }
            </div>
        );
    }
}

export default StoreCategories;


import React, { PureComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
class CouponsSkeleton extends PureComponent{
    
    render(){
        return(
            <div>
                
                <div className="no_borders   mt-2" style={{border:"0px !important"}}>
                    <div className="card-body">
                        <div className="row d-flex justify-content-start card-strip"> 
                        
                            <div className="deal-value">
                                <Skeleton height={100}/>
                            </div>
                        
                            <div className="info ">
                                <div className="row px-3 mb-2">
                                    <h4 className="dark-text " style={{width:"60%"}} ><Skeleton height={50} /></h4>
                                    <p className="mt-1 ml-4 "><Skeleton height={40} width={50} /> </p>
                                    
                                </div>
                                <div className="row px-3">
                                    <p className="mb-1"></p>
                                </div>

                                <Skeleton/>
                            </div>
                        </div>
                    </div>
                </div>  
                <div className="no_borders  mt-2" style={{border:"0px !important"}}>
                    <div className="card-body">
                        <div className="row d-flex justify-content-start card-strip"> 
                        
                            <div className="deal-value">
                                <Skeleton height={100}/>
                            </div>
                        
                            <div className="info ">
                                <div className="row px-3 mb-2">
                                    <h4 className="dark-text " style={{width:"60%"}} ><Skeleton height={50} /></h4>
                                    <p className="mt-1 ml-4 "><Skeleton height={40} width={50} /> </p>
                                    
                                </div>
                                <div className="row px-3">
                                    <p className="mb-1"></p>
                                </div>

                                <Skeleton/>
                            </div>
                        </div>
                    </div>
                </div>  
                <div className="no_borders mt-2" style={{border:"0px !important"}}>
                    <div className="card-body">
                        <div className="row d-flex justify-content-start card-strip"> 
                        
                            <div className="deal-value">
                                <Skeleton height={100}/>
                            </div>
                        
                            <div className="info ">
                                <div className="row px-3 mb-2">
                                    <h4 className="dark-text " style={{width:"60%"}} ><Skeleton height={50} /></h4>
                                    <p className="mt-1 ml-4 "><Skeleton height={40} width={50} /> </p>
                                    
                                </div>
                                <div className="row px-3">
                                    <p className="mb-1"></p>
                                </div>

                                <Skeleton/>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        )
    }
}

export default CouponsSkeleton;
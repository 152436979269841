import React, { PureComponent } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import Toast from "light-toast";
import Popup from "reactjs-popup";
import Moment from "react-moment";
import Helmet from "react-helmet";
import { isMobile, isBrowser,isTablet } from "react-device-detect";

import CouponTypeDisplay from "./CouponTypeDisplay";
import SearchOffersSkeleton from "./skeletons/SearchOffersSkeleton";
import Advertisement from "./Advertisement";
import AddtoFavourite from "./AddtoFavourite";

import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();


class AllCoupons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      coupon_type: this.props.coupon_type,
      total_coupons:0,
      loading: false,
      offset: 0,
      page_title:"",
      perPage: isBrowser ? 60 : 30,
      currentPage: 0,
      total: 0,
      other_coupons:[],
      checked: false,
      selected_keyword:null,
      sub_types_counts:[],
      search_counts:[],
      sepecial_coupon: [],
      sub_type:null,
      sc_id: "",
      open: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);
  }

  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(coupon) {
    if (this.state.sepecial_coupon.length < 1) {
      this.setState({ open: true, sepecial_coupon: coupon });
    }
  }
  

  closeModal() {
    this.setState({ open: false });
  }

  redirectToStore(coupon) {
    Toast.info(
      <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {coupon.StoreURL}
      </div>,
      3000,
      () => {
        window.open(
          window.location.href.split("?")[0] + "?c=" + coupon.id,
          "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
          //this.openModal(coupon)
          (window.location.href = coupon.SourceUrl),
          2000
        );
      }
    );
  }

  receivedData() {
    
    var ctype = sessionStorage.getItem("selected_coupon_tab");
    if(ctype && ctype !=""){
      this.setState({coupon_type:ctype});
    }
    
    var sub_type ="";
    if(this.props.coupon_type !="all"){

     sessionStorage.removeItem("selected_coupon_subtype_tab");
     sessionStorage.setItem("selected_coupon_tab",this.props.coupon_type);
     this.setState({coupon_type:this.props.coupon_type})
     
    }else{

      sub_type = sessionStorage.getItem("selected_coupon_subtype_tab");
      if(sub_type && sub_type !=""){
        this.setState({sub_type:sub_type});
      }
   
    }


    const params = new URLSearchParams(window.location.search);
    if (params.has("c")) {
      var sc_id = "";
      sc_id = params.get("c");
      var user_id = "";
      if(user ){
        user_id = "&user_id="+user.id;
      }
      axios
        .get(
          `http://adscombined.com/v1/public/api/CouponDetails?coupon_id=${encodeURIComponent(
            sc_id+user_id
          )}`
        )
        .then(
          (response) => {
            if (response.data.flag) {
              if (response.data.coupon_data) {
                const sepecial_coupon = response.data.coupon_data;

                this.openModal(sepecial_coupon);
              }
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
   
    
    this.setState({ loading: true }, () => {

      const params = new URLSearchParams(window.location.search);
      var keywod= "";
      if(params.has("q")){
        keywod = "&Keyword="+encodeURIComponent(params.get('q'));
        this.setState({selected_keyword:params.get('q')});
        window.history.pushState({}, document.title, "/coupons/all" );
        
      }else{
        if(this.state.selected_keyword){
          keywod = "&Keyword="+this.state.selected_keyword;
        }
      }
      if (this.props.coupon_type) {
            var filter =  this.props.coupon_type !="all" ?  this.props.coupon_type : this.state.coupon_type;
            var filter2 = this.state.sub_type && this.state.sub_type !="" ? this.state.sub_type : "";
            
            var user_id = "";
            if(user ){
              user_id = "&user_id="+user.id;
            }
          axios
            .post(
              `http://adscombined.com/v1/public/api/FilterCoupons?Filter=${encodeURIComponent(
                filter
              )}&SubType=${encodeURIComponent(filter2)}${keywod+user_id}&Page=${
                this.state.currentPage
              }&PerPage=${this.state.perPage}`
            )
            .then(
              (response) => {
                if (response.data ) {
                  if (response.data.coupons) {
                   
                    const coupons = response.data.coupons;
                    var other_coupons = [];

                    this.setState({
                      total: response.data.total_coupons,
                      other_coupons: response.data.other_coupons,
                      page_title:response.data.PageTitle,
                      total_coupons: response.data.total_coupons,
                      search_counts: response.data.counts,
                      sub_types_counts: response.data.sub_types_counts,
                      coupons,
                      loading:false,
                      
                    });
                  }
                } else {
                  this.setState({ loading: false });
                }
                this.setState({ loading: false });
              },
              (error) => {
                this.setState({ loading: false });
                console.log(error);
              }
            );
        }
       
    });
  }

  componentDidMount() {

   
    this.receivedData();
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
    window.scrollTo(0, 0);
  };

  getStore(ctype, sub_type="") {
   
    if (ctype != "") {
      if(ctype == "Event" || ctype == "Seasonal" ){
        sessionStorage.setItem("selected_coupon_subtype_tab", sub_type);
      }else{
        sessionStorage.removeItem("selected_coupon_subtype_tab");
      }
      sessionStorage.setItem("selected_coupon_tab", ctype);
      this.setState({ coupon_type: ctype,sub_type:sub_type, offset: 0,coupons:[],currentPage:0  });

      this.receivedData();
    }
  }

  
  shorten(text, max) {
    return text && text.length > max
      ? text.slice(0, max).split(" ").slice(0, -1).join(" ")
      : text;
  }
  render() {
    //Promo, Free Shipping,Clearance,Weekly Ad,Todays Ad,Sale,Event,Gift,Coupon,Student,Referal,Reward,Cash Back,Rebate,Printable Coupon,Seasonal
    const contentStyle = { width: isBrowser ? '70% !important' : isTablet ? '70% !important': '90% !important' };
   
   var  colors =  [ "#CD4CC3",'#f1c500', '#4E0404', '#8175c7',"#212F3C", '#ff9900', "#0B6B90", "#22A9DD", "#6BCB6B"];
   var count = 0; 
   var add_count = 0;
   return (
        <div>
        <section className="main-container">
       <div className="container" style={{ padding: "0px" }}>
      <br />
      <div className="col-md-12 mb-3">
        <Advertisement  ad_type="full width"></Advertisement>
      </div>
      <div className="row">
        <div className="col-md-12 ">
         {this.state.page_title!==""?
        <Helmet>
          <title>{this.state.page_title}</title>
        </Helmet>
        :
        <div>
           <Helmet>
            <title>Coupons | {window.$sitename}</title>
          </Helmet>
        </div>
        }
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          {...{ contentStyle }}
        >
          <div className="modal">
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
            <div class="row" style={{ padding: "10px" }}>
              <div className="col-md-4 pl-0">
                <center>
                  <img
                  alt={this.state.sepecial_coupon.StoreName+" Coupons & Deals"}
                  title={this.state.sepecial_coupon.StoreName+ " Coupons & Deals"}
                    src={
                      this.state.sepecial_coupon.Logo
                        ? window.$logos_url+this.state.sepecial_coupon.Logo.replace(".PNG",".png")
                        : process.env.PUBLIC_URL + "/default_logo.png"
                    }
                    width="100"
                    height="100"
                    id="store_logo"
                    style={{
                      objectFit:"scale-down"
                    }}
                  ></img>
                  <h5>
                    <b style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </b>
                  </h5>
                </center>
              </div>

              <div className="col-md-8" style={{ padding: "inherit" }}>
              <AddtoFavourite type={"heart"} source={"sallcoupons"} favourite_id = {this.state.sepecial_coupon.favourite_id} id={this.state.sepecial_coupon.id} favourite_type={"coupon"} is_favourite={this.state.sepecial_coupon.favourite} />
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "teal",
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.sepecial_coupon.Offer &&
                  this.state.sepecial_coupon.Offer !== "none" &&
                  this.state.sepecial_coupon.Offer !== "None" ? (
                    <CouponTypeDisplay
                      coupontype={this.state.sepecial_coupon.Offer}
                      source="couponpage"
                    />
                  ) : (
                    <CouponTypeDisplay
                      coupontype={this.state.sepecial_coupon.Type}
                      source="couponpage"
                    />
                  )}
                </span>

                <h3>
                  <span id="coupon_title">
                    {this.state.sepecial_coupon.Title}
                  </span>
                </h3>

                <br />
                <b></b>
                {this.state.sepecial_coupon.Code &&
                this.state.sepecial_coupon.Code != "None" ? (
                  <span>
                    <input
                      id="code-value"
                      type="text"
                      className="code-copy"
                      value={this.state.sepecial_coupon.Code}
                      style={{
                        borderRadius: "0px",
                        width: "fit-content",
                        cursor: "copy",
                      }}
                      readOnly
                    />

                    <span>
                      <button
                        className="btn"
                        onClick={this.myFunction}
                        onMouseOut={this.outFunc}
                        id="myTooltip"
                        style={{ verticalAlign: "inherit" }}
                      >
                        Copy Code
                      </button>
                    </span>

                    <br />
                    <br />
                  </span>
                ) : (
                  <div></div>
                )}

                <a href={this.state.sepecial_coupon.SourceUrl}>
                  <b>
                    {" "}
                    Continue to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </span>
                  </b>{" "}
                </a>
              </div>
            </div>
          </div>
        </Popup>
        <h1 className="ac-h1" title={"Showing '"+this.props.coupon_type+"' Offers "}  style={{fontSize:"2rem",fontWeight:"bold"}}>
                  Showing  '<span style={{textTransform:"capitalize"}}>{this.props.coupon_type}</span>' Offers,
                  </h1>
                  
        < div className="mb-2  well well-sm border-div bg-white borderZero p-2">
         { this.props.coupon_type=="all"? 
         <div class="btn-group">
           <button
             onClick={() => this.getStore("all")}
             className={
               this.state.coupon_type == "all"
                 ? "btn-info btn-pills ml-2 mt-2 mb-2"
                 : " btn-pills ml-2 mt-2 mb-2"
             }
           >
             All Offers{" "}
             {this.state.total_coupons >0 ? "("+this.state.total_coupons+")" : ""}
           </button>
         </div>
         :<span></span>
         }
         {this.state.search_counts.PromoCount && (this.props.coupon_type == "Promo" || this.props.coupon_type=="all") > 0 ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Promo")}
               className={
                 this.state.coupon_type == "Promo"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Promo ({this.state.search_counts.PromoCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.FreeShippingCount > 0  && (this.props.coupon_type == "Free Shipping" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Free Shipping")}
               className={
                 this.state.coupon_type == "Free Shipping"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Free Shipping ({this.state.search_counts.FreeShippingCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.ClearanceCount > 0 && (this.props.coupon_type == "Clearance" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Clearance")}
               className={
                 this.state.coupon_type == "Clearance"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Clearance ({this.state.search_counts.ClearanceCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.WeeklyAdCount > 0  && (this.props.coupon_type == "Weekly Ad" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Weekly Ad")}
               className={
                 this.state.coupon_type == "Weekly Ad"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Weekly Ad ({this.state.search_counts.WeeklyAdCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.TodaysAdCount > 0 && (this.props.coupon_type == "Today's Ad" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Today's Ad")}
               className={
                 this.state.coupon_type == "Today's Ad"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Today's Ad ({this.state.search_counts.TodaysAdCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.SaleCount > 0 && (this.props.coupon_type == "Sale" || this.props.coupon_type=="all") ?  (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Sale")}
               className={
                 this.state.coupon_type == "Sale"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Sale ({this.state.search_counts.SaleCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.EventsCount > 0  && (this.props.coupon_type == "Event" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Event")}
               className={
                 this.state.coupon_type == "Event"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Events ({this.state.search_counts.EventsCount})
             </button>

           
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.GiftsCount > 0 && (this.props.coupon_type == "Gift" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Gift")}
               className={
                 this.state.coupon_type == "Gift"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Gifts ({this.state.search_counts.GiftsCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.CouponCount > 0 && (this.props.coupon_type == "Coupon" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Coupon")}
               className={
                 this.state.coupon_type == "Coupon"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Coupon ({this.state.search_counts.CouponCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.StudentCount > 0  && (this.props.coupon_type == "Student" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Student")}
               className={
                 this.state.coupon_type == "Student"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               {" "}
               Student ({this.state.search_counts.StudentCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.ReferralCount > 0  && (this.props.coupon_type == "Referral" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Referral")}
               className={
                 this.state.coupon_type == "Referral"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Referral ({this.state.search_counts.ReferralCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.RewardCount > 0  && (this.props.coupon_type == "Reward" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Reward")}
               className={
                 this.state.coupon_type == "Reward"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Reward ({this.state.search_counts.RewardCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.CashBackCount > 0  && (this.props.coupon_type == "Cash Back" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Cash Back")}
               className={
                 this.state.coupon_type == "Cash Back"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               {" "}
               Cash Back ({this.state.search_counts.CashBackCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.RebateCount > 0  && (this.props.coupon_type == "Rebate" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Rebate")}
               className={
                 this.state.coupon_type == "Rebate"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Rebate ({this.state.search_counts.RebateCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}

         {this.state.search_counts.PrintableCouponCount > 0 && (this.props.coupon_type == "Printable Coupon" || this.props.coupon_type=="all") ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Printable Coupon")}
               className={
                 this.state.coupon_type == "Printable Coupon"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Printable Coupon (
               {this.state.search_counts.PrintableCouponCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.SeasonalCount > 0 && ( this.props.coupon_type == "Seasonal" || this.props.coupon_type=="all" ) ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("Seasonal")}
               className={
                 this.state.coupon_type == "Seasonal"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               Seasonal ({this.state.search_counts.SeasonalCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
         {this.state.search_counts.BOGOCount > 0  && (this.props.coupon_type == "BOGO" || this.props.coupon_type=="all" ) ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("BOGO")}
               className={
                 this.state.coupon_type == "BOGO"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               BOGO ({this.state.search_counts.BOGOCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}

        {this.state.search_counts.NewArrivalCount > 0  && (this.props.coupon_type == "BOGO" || this.props.coupon_type=="all" ) ? (
           <div class="btn-group">
             <button
               onClick={() => this.getStore("New Arrival")}
               className={
                 this.state.coupon_type == "New Arrival"
                   ? "btn-info btn-pills ml-2 mt-2 mb-2"
                   : " btn-pills ml-2 mt-2 mb-2"
               }
             >
               New Arrival ({this.state.search_counts.NewArrivalCount})
             </button>
           </div>
         ) : (
           <span></span>
         )}
       
        
        {!this.state.loading && this.state.sub_types_counts &&  this.state.coupon_type &&  (this.state.coupon_type == "Event" || this.state.coupon_type=="Seasonal") ? 
        
          <h5 className="ml-2"><hr/><i>Avaiable {this.state.coupon_type}s:</i></h5> 
        :""}

        {!this.state.loading && this.state.sub_types_counts && this.state.sub_types_counts.map(sub_type=>{
          return  sub_type.count && sub_type.count>0 ? 
            <button
          onClick={() => this.getStore( this.state.coupon_type,sub_type.sub_type)}
          className={
            (this.state.coupon_type == "Event" || this.state.coupon_type=="Seasonal") && this.state.sub_type == sub_type.sub_type
              ? "btn-info btn-pills ml-2 mt-2 mb-2"
              : " btn-pills ml-2 mt-2 mb-2"
          }
          >
          {sub_type.sub_type} ({sub_type.count})
          </button> : "";
        })}
     </div>


        <div
          className="container-fluid "
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
          <div className="row">

            <div className="col-md-9 p-0">
            {this.state.loading ? (
           
              <SearchOffersSkeleton items={[1,2,3,4]} />
          
              ) : (
                <div>
                    {
                      this.state.total>0?
                      <span>Showing <b>{this.state.offset+1 }</b> to <b>{this.state.offset+this.state.perPage > this.state.total ? this.state.total : this.state.offset+this.state.perPage  }</b> out of <b>{this.state.total}</b> results<br/></span>
                      :
                      <span></span>
                    } 
                  
                  {this.state.total > 0 ? (
                    <div>
                    <div className="row">
                    
                      {this.state.coupons.map((coupon) => (
                          
                          add_count >12 ? 
                          <div className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable">
                            <Advertisement ad_type=""></Advertisement>
                            <span style={{display:"none"}}>{add_count = 0}</span>
                          </div>
                          :

                          <div className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable"
                          style={{}}
                          title={"Click to get "+coupon.Title}>
                          


                            <div
                              className="card  single-deal"
                              style={{ borderRadius: "5px" }}
                            >
                              <AddtoFavourite type={"heart"} source={"allcoupons"} id={coupon.id} favourite_id = {coupon.favourite_id} favourite_type={"coupon"} is_favourite={coupon.favourite} />
                              <div className="home-offer-text" style={{    background:colors[count]}}>
                              <center>
                                    <small onClick={() => this.redirectToStore(coupon)} className="coupon-type-text" style={{ textTransform: "uppercase" }}>
                                  
                                      {coupon.Offer && coupon.Offer!="None" && coupon.Offer!="none" ? (
                                      <h5 style={{fontWeight:"bold"}}>{coupon.Offer}</h5>
                                      ) : (
                                      <h5 style={{fontWeight:"bold"}}>{coupon.Type}</h5>
                                      )}
                                    </small>
                                  </center>
                                </div>
                              <span style={{display:"none"}}>
                                {add_count++}
                                {count<=7 ?count++ : count = 0 }
                              </span> 
                              <div
                                className="card-body"
                                style={{
                                  padding: "10px !important",
                                  padding: "0.8rem",
                                }}
                              >
                              <Link
                                className="logo  cust_link featured"
                                to={"/coupons/" + coupon.StoreURL}
                                title={
                                  coupon.StoreName +" Coupons, Promotion Codes & Deals"
                                }
                              >
                                  {coupon.Logo && coupon.Logo != "Logo Name" && coupon.Logo != "none" ? (
                                <img
                                    
                                  className="center deals-img"
                                  style={{
                                    marginTop: '-35px', height: '90px', width: '90px', border: '5px solid #f1f1f1', backgroundColor: 'white', objectFit: 'fill', borderRadius: '47px'
                                    
                                  }}
                                  src={window.$logos_url+coupon.Logo.replace(".PNG",".png")}
                                ></img>
                              ) : (
                                <img
                                
                                  className="center deals-img"
                                  style={{
                                    marginTop: '-35px', height: '90px', width: '90px', border: '5px solid #f1f1f1', backgroundColor: 'white', objectFit: 'fill', borderRadius: '47px'
                                   
                                  }}
                                  src={process.env.PUBLIC_URL + "/default_logo.png"}
                                ></img>
                              )}
                              </Link>
                                <div
                                  
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    marginTop: "0.25rem",
                                    marginBottom:"0.25rem !important"
                                  }}
                                >
                                  {" "}
                                  <h3
                                  onClick={() => this.redirectToStore(coupon)}
                                    style={{
                                    fontSize: "14px"}} title={"Click to get "+coupon.Title}> 
                                    {coupon.Title && coupon.Title.length < 55 ? coupon.Title : this.shorten(coupon.Title,55)+".."}
                                  </h3>
                                </div>
                                <center>
                                  { coupon.Code ?
                                    <div
                                      title={"Click to get " + coupon.Title}
                                      onClick={() => this.redirectToStore(coupon)}
                                      className="badge "
                                      style={{
                                        background:"rgb(253 253 188)",
                                        padding: "5px",
                                        border: "2px dotted " + colors[count],
                                        fontSize: "18px",
                                        color: colors[count-1],
                                        cursor: "pointer",
                                      }}
                                    >
                                      {coupon.Code}
                                    </div>
                                  :""}
                                </center>
                               
                                <p>
                                
                                 
                                <Link to={"/coupons/" +  window.$categories[coupon.Categories]} >
                              
                              <small >{coupon.Categories}</small>
                                
                                </Link>

                                
                                </p>
                                <span style={{fontSize:"12px",}}>
                            {coupon.updated_at ? 
                                 <span>
                                 <i>Last Seen: 
                                {" "}
                                 <b>
                                   <Moment format="MMMM D, Y" >
                                 {coupon.updated_at} 
                                   </Moment></b></i>
                                 </span>
                                 :""}
                                  <br></br>
                                    {coupon.created_at ? 
                                      <span>
                                      <i>  Found On: 
                                      {" "}
                                     <b>
                                       <Moment format="MMMM D, Y" >
                                     {coupon.created_at} 
                                       </Moment></b></i>
                                       </span>
                                    : ""
                                    }
                                
                                   </span> 
                              </div>
                            </div>
                        
                        </div>
                        ))}
                    </div>
                    {
                      this.state.other_coupons.length>0?
                       <div>
                         <br/>
                         < div className="mb-2  well well-sm border-div bg-white borderZero p-3">
                     
                     <h4>Similar Offers available:</h4>
                     
                     </ div>
                     <div className="row">
                         {this.state.other_coupons.map((coupon) => (
                           
                           add_count >12 ? 
                           <div className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable">
                             <Advertisement ad_type=""></Advertisement>
                             <span style={{display:"none"}}>{add_count = 0}</span>
                           </div>
                           :
 
                           <div className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable"
                           style={{}}
                           title={"Click to get "+coupon.Title}>
                           
 
 
                             <div
                               className="card  single-deal"
                               style={{ borderRadius: "5px" }}
                             >
                                <AddtoFavourite type={"heart"} source={"allcoupons"} favourite_id = {coupon.favourite_id} id={coupon.id} favourite_type={"coupon"} is_favourite={coupon.favourite} />
                               <div className="home-offer-text" style={{    background:colors[count]}}>
                               <center>
                                     <small onClick={() => this.redirectToStore(coupon)} className="coupon-type-text" style={{ textTransform: "uppercase" }}>
                                   
                                       {coupon.Offer && coupon.Offer!="None" && coupon.Offer!="none" ? (
                                       <h5 style={{fontWeight:"bold"}}>{coupon.Offer}</h5>
                                       ) : (
                                       <h5 style={{fontWeight:"bold"}}>{coupon.Type}</h5>
                                       )}
                                     </small>
                                   </center>
                                 </div>
                               <span style={{display:"none"}}>
                                 {add_count++}
                                 {count<=7 ?count++ : count = 0 }
                               </span> 
                               <div
                                 className="card-body"
                                 style={{
                                   padding: "10px !important",
                                   padding: "0.8rem",
                                 }}
                               >
                               <Link
                                 className="logo  cust_link featured"
                                 to={"/coupons/" + coupon.StoreURL}
                                 title={
                                   coupon.StoreName +" Coupons, Promotion Codes & Deals"
                                 }
                               >
                                   {coupon.Logo && coupon.Logo != "Logo Name" && coupon.Logo != "none" ? (
                                 <img
                                     
                                   className="center deals-img"
                                   style={{
                                    marginTop: '-35px', height: '90px', width: '90px', border: '5px solid #f1f1f1', backgroundColor: 'white', objectFit: 'fill', borderRadius: '47px'
                                     
                                   }}
                                   src = {window.$logos_url+coupon.Logo.replace(".PNG",".png")}
                                 ></img>
                               ) : (
                                 <img
                                 
                                   className="center deals-img"
                                   style={{
                                    marginTop: '-35px', height: '90px', width: '90px', border: '5px solid #f1f1f1', backgroundColor: 'white', objectFit: 'fill', borderRadius: '47px'
                               
                                     
                                     
                                   }}
                                   src={process.env.PUBLIC_URL + "/default_logo.png"}
                                 ></img>
                               )}
                               </Link>
                                 <div
                                   
                                   style={{
                                     fontSize: "14px",
                                     fontWeight: "500",
                                     marginTop: "0.25rem",
                                     marginBottom:"0.25rem !important"
                                   }}
                                 >
                                   {" "}
                                   <h3
                                   onClick={() => this.redirectToStore(coupon)}
                                     style={{
                                     fontSize: "14px"}} title={"Click to get "+coupon.Title}> 
                                     {coupon.Title && coupon.Title.length < 40 ? coupon.Title : this.shorten(coupon.Title,40)+".."}
                                   </h3>
                                 </div>
 
                               
                                 <p>
                                 
                                   
                                 <Link to={"/coupons/" +  window.$categories[coupon.Categories]} >
                               
                               <small >{coupon.Categories}</small>
                                 
                                 </Link>
                                 </p>
                                 <span style={{fontSize:"12px",}}>
                            {coupon.updated_at ? 
                                 <span>
                                 Last Seen: 
                                {" "}
                                 <i><b>
                                   <Moment format="MMMM D, Y" >
                                 {coupon.updated_at} 
                                   </Moment></b></i>
                                 </span>
                                 :""}
                                   <br></br>
                                    {coupon.created_at ? 
                                      <span>
                                      Found On: 
                                      {" "}
                                       <i><b>
                                       <Moment format="MMMM D, Y" >
                                     {coupon.created_at} 
                                       </Moment></b></i>
                                       </span>
                                    : ""
                                    }
                                
                                   </span> 
                               </div>
                             </div>
                         
                         </div>
                         ))}
                      </div>
                       
                     </div>
                      : 
                      <span></span>
                    }
                   
                    </div>
                  ) : (
                    <div className="card single-coupen mt-2">
                      <div className="card-body">
                        <h5>No Coupons Found.</h5>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <center>
                {this.state.total > this.state.perPage && this.state.other_coupons.length<1? (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    forcePage={this.state.currentPage}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(
                      this.state.total / this.state.perPage
                    )}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                ) : (
                  <div></div>
                )}
              </center>
            </div>

            <div className="col-md-3 p-0">
              <div className="card sticky_ad" style={{marginTop:"30px"}}>

                <Advertisement ad_type=""></Advertisement>
              </div>      
            </div>
          </div>

         
        </div>
        </div>
        </div>
        </div>
        </section>
      </div>
    );
  }
}

export default AllCoupons;

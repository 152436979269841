import React, { PureComponent } from "react";
import Skeleton from "react-loading-skeleton";
class HomeCashbackSkeleton extends PureComponent {
  render() {
    return (
      <div className="card ">
        <div className="card-body">
       
          <div className="row">
            {this.props.items.length > 0 ? (
              this.props.items.map((item) => (
                <div className=" col-md-2 " key={item}>
                    <center>
                 
                
                  <Skeleton width={80} height={50} className="ml-1"></Skeleton>
                  
                 
                  <div className="details">
                    <div className="card-title mt-2 mb-0">
                      <h5 style={{ fontSize: "1.0rem" }}>
                       
                        <Skeleton width={130} height={25}></Skeleton>
                      </h5>
                    </div>

                    <div className="bottom-body">
                      <h5
                        className="card-title"
                        style={{ fontSize: "1.0rem !important" }}
                      >
                        <span style={{ fontSize: "14px" }}>
                          <span style={{ textDecoration: "line-through" }}>
                            <Skeleton></Skeleton>
                            <Skeleton width={130}></Skeleton>
                          </span>{" "}
                          &nbsp;
                        </span>
                      
                        &nbsp;
                      </h5>
                    </div>
                  </div>
                  </center> 
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default HomeCashbackSkeleton;

import React,{PureComponent} from "react";
import axios from "axios";
import {Link} from "react-router-dom";

class VerifyEmail extends PureComponent{

    constructor(props){
        super(props);
        this.state={
            feedback:false,
            error_type:"",
            message:"",
            loading:false
        }
        
    }

    componentDidMount(){
        if(this.props.match.params.unique_token!=""){
            this.setState({loading:true});
            axios
            .get(`http://38.17.54.210:8300/api/confirm_email?unique_token=${encodeURIComponent(this.props.match.params.unique_token)}`)
            .then(
            (response) => {
                if(response.data.flag){
                    
                    this.setState({message:response.data.message,feedback:true});
                    window.history.pushState({}, document.title, "/login" );
                } else {
                    this.setState({error_type:"danger",message:response.data.message});
                }
                this.setState({loading:false});
            },
            (error) => {
                this.setState({loading:false});
                this.setState({error_type:"danger",message:error.response});
            }
            );
        }
    }

    render(){

        return(
            <span>
                <section className="main-container">
                    <div className="container" style={{ padding: "0px" }}>
                        <br></br>
                        <div className="row pb-3">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-4">

                                <div className="card">
                                    <div className="card-body">
                                    <br></br>
                                        {this.state.loading?
                                            <center>
                                                
                                                <h5>Verifying Email....</h5>
                                                <br></br><br></br>
                                            </center>
                                        :
                                        
                                        <span>

                                            {this.state.feedback?
                                                <span>
                                                    <h3 className="p-3" style={{textAlign:"center",background:"",color:"#f56f36",fontWeight:"bold"}}>{this.state.message} </h3>
                                                    You can now access to your account by  {" "}
                                                        <Link  to="/login">
                                                        <b>Login</b>
                                                        </Link> here.
                                                        <br></br><br></br>
                                                </span>
                                            
                                            :
                                            
                                                this.state.error_type && this.state.message!="" ?
                                                    <div  className={"alert alert-"+this.state.error_type+" alert-dismissible fade show"} role="alert">
                                                        {this.state.message}
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>

                                                </div>
                                                :
                                                <span></span>
                                    
                                            }
                                            </span>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                    </div>
                </section>
            </span>
        )
    }

}

export default VerifyEmail;


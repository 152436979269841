import React, { PureComponent } from "react";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";
class TopSalesAndCouponsSkeleton extends PureComponent {
  render() {
   
    return (
      <div className="card ">
        <div className="card-body">
          <h4>{this.props.title}</h4>

          <div className="row">
            {this.props.items.length > 0 ? (
               
              this.props.items.map((item) => (
                
                <div
                key={item}
                  style={{
                  
                    Width: "300px !important",
                    Height: "389px !important",
                   
                  }}
                  className="profile_panel panel col-md-3"
                >
                  <Skeleton
                    style={{ height:"137px" }}
                    className="twt-feed"
                  >
                    <p style={{ cursor: "pointer", maxWidth: "280px" }}
                    >
                      <h5 style={{ fontWeight: "bold",height:"48px" }}>
                       <Skeleton></Skeleton>
                      </h5>
                    </p>

                    
                  </Skeleton>
                  <center  style={{marginTop:"-40px"}}>
                      <Skeleton
                      
                      height={80}
                      width={80}
                        style={{borderRadius:"50px"}}
                      
                     />
                     </center>
                  <div >
                    <center>
                  
                    <br/>
                   
                        <Skeleton height={30} width={160}></Skeleton>
                    </center>
                    <center style={{ fontSize: "12px", paddingTop: "5px" }}><br />
                      <Skeleton width={90}></Skeleton>
                      <p>
                        <i>
                          <b>
                           <Skeleton width={180}></Skeleton>
                          </b>
                        </i>
                      </p>
                    </center>
                  </div>{" "}
                 
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default TopSalesAndCouponsSkeleton;

import React, { PureComponent } from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { getToken} from "../utils/Common";
import Skeleton from "react-loading-skeleton";
import NewSubscription from "./NewSubscription";
import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();

class Subscriptions extends PureComponent {
  
  constructor(props){
    super(props);
    this.state = { 
      subscriptions:[],
      token:getToken(),
      isLoading:false,
      subscription_edit: null
    }
    this.removeSubscription = this.removeSubscription.bind(this);
    this.refreshSubscriptionList = this.refreshSubscriptionList.bind(this);
    this.recieveData = this.recieveData.bind(this);
  }
  
  refreshSubscriptionList(){
    this.componentDidMount();
  }



  removeSubscription(id){

    var user_id = "";
    if(user ){
        user_id = "?&user_id="+user.id;
    }
    
    if(id){
      this.setState({isLoading:true});
      axios
      .get(
        `http://adscombined.com/v1/public/api/remove_subscription${user_id}&subscription_id=${id}`,{
          // headers: {
          //   'Accept' :'application/json',
          //   'Access-Control-Allow-Origin' :'*',
          //   'Authorization': `Bearer ${this.state.token}`
          // }
        }
      )
      .then(
        (response) => {
          if (response.data.flag) {
            if (response.data.subscriptions) {
              const subscriptions = response.data.subscriptions;
              
              this.setState({subscriptions:subscriptions});
              this.setState({isLoading:false});
            }
          } else {
            this.setState({isLoading:false});
          }
        },
        (error) => {
          this.setState({isLoading:false});
          console.log(error);
        }
      );
    
    
    }
  }

  recieveData(){
    var user_id = "";
    if(user ){
        user_id = "?&user_id="+user.id;
    }
    
      this.setState({isLoading:true});
      axios
      .get(
        `http://adscombined.com/v1/public/api/subscription_list${user_id}`,{
          // headers: {
          //   'Accept' :'application/json',
          //   'Access-Control-Allow-Origin' :'*',
          //   'Authorization': `Bearer ${this.state.token}`
          // }
        }
      )
      .then(
        (response) => {
          if (response.data.flag) {
            if (response.data.subscriptions) {
              const subscriptions = response.data.subscriptions;
              
              this.setState({subscriptions:subscriptions});
              this.setState({isLoading:false});
            }
          } else {
            this.setState({isLoading:false});
          }
        },
        (error) => {
          this.setState({isLoading:false});
          console.log(error);
        }
      );
    
    

     
  }
  componentDidMount(){
    
    this.recieveData();
    
  }


  render() {
    var count = 1;
    return (
        <div>
            <NewSubscription categories={this.props.categories} action={this.recieveData} subscription_edit ={this.state.subscription_edit ?  this.state.subscription_edit : null} />
            <br></br>
            <h4>
              My Subscriptions
            </h4>
                <br></br>
            <div className="row">
              
              <div className="col-md-12 table-responsive">
                {
                  this.state.isLoading? 
                   <span>
                    <table  style={{border:"0px",width:"100%"}}>
                    <thead>
                      <tr >
                        <th ><Skeleton style={{height:"40px"}} /></th>
                        <th><Skeleton style={{height:"40px"}} /></th>
                        <th><Skeleton  style={{height:"40px"}}/></th>
                        <th><Skeleton style={{height:"40px"}}/></th>
                        <th><Skeleton style={{height:"40px"}}/></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >
                        <Skeleton style={{height:"30px"}} />
                        </td>
                        <td>
                        <Skeleton  style={{height:"30px"}}/>
                        </td>
                        <td >
                        <Skeleton  style={{height:"30px"}}/>
                        </td>
                        <td >
                        <Skeleton style={{height:"30px"}}/>
                        </td>
                        <td >
                        <Skeleton style={{height:"30px"}}/>
                        </td>
                      </tr>
                    </tbody>
                    </table>
                   </span>
                  :
                  this.state.subscriptions.length>0?
                  <table className=" table" style={{width:"100%"}}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Subscription Type</th>
                        <th>Category</th>
                        <th>Alert Frequency</th>
                        <th>Subscribed On</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        
                          this.state.subscriptions.map((subscription) =>

                            <tr>
                            <td>
                              {count}
                              <span style={{display:"none"}}>{count++}</span>
                            </td>
                            <td>
                              {subscription.email? subscription.email  :""}
                            </td>
                            <td>
                              {subscription.alert_preference}
                            </td>
                            <td>
                              {subscription.alert_category? subscription.alert_category: ""} 
                            </td>
                            <td>

                              {subscription.frequency ? subscription.frequency : ""}
                            </td>
                            <td>
                              <Moment format="dddd, MMMM D, Y">
                                {subscription.created_at}
                              </Moment>
                            </td>
                            
                           
                            <td>
                              <a href={"/subscriptions/"+subscription.id}>
                                <i   className="fa fa-pencil"style={{backgroundColor:"rgb(245, 111, 54)",padding:"5px",color:"white",width:"33px",borderRadius:"3px",cursor:"pointer"}} title={"Edit Subscription"}></i>
                              </a>
                            </td>
                            <td>
                              <i onClick={()=>this.removeSubscription(subscription.id)} className="fa fa-trash"style={{backgroundColor:"rgb(245, 111, 54)",padding:"5px",color:"white",width:"33px",borderRadius:"3px",cursor:"pointer"}} title={"Remove Subscription"}></i>
                            </td>
                          </tr>
                          
                          )
                        
                      
                      }
                    
                    </tbody>
                  </table>
                    :
                    <span><p>No Subscriptions Found.</p></span>
                }
               
              
              </div>
            </div>
     
        </div>
    );
  }
}

export default Subscriptions;

import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import renderHTML from 'react-render-html';


import CategoryDetailsSkeleton from "./skeletons/CategoryDetailsSkeleton";
import CategoryCoupens from "./CategoryCoupens";
import BreadcrumSkeleton from "./skeletons/BreadcrumSkeleton";
import Advertisement from "./Advertisement";
import Store from "./Store";
import { Fragment } from "react";

class CategoryDetails extends PureComponent {
  constructor(props){
    super(props);
   
    this.state = {
      categorydetail: [],
      record_type: "coupons",
      category_id: props.match.params.cat_id,
      sub_category: null,
      sub_cat_details:null,
      all_categories:this.props.categories,
      category_counts: [],
      coupon_type: "all",
      expanded: ["2"],
      couponscount: 0,
      loading: false,
    }
    this.getCategorySlug = this.getCategorySlug.bind(this);
    this.getCategoryFromSlug = this.getCategoryFromSlug.bind(this);
    this.getLowerCase = this.getLowerCase.bind(this);
  }
  
  getLowerCase(str){
    return str.toLowerCase(str);
  }
  
  changeCouponType(type) {
    this.setState({ coupon_type: type });
    this.setState({ record_type:"coupons"});
    
  }

  showComponent(componentName) {
    this.setState({ record_type: componentName });
    this.setState({coupon_type:"all"});
   
  }
  renderComponent() {
    switch (this.state.record_type) {
      case "coupons":
        return (
          <CategoryCoupens
           
            cat_id={this.state.category_id}
            sub_cat={this.state.sub_category}
            
            coupon_type={this.state.coupon_type}
            category_counts = {this.state.category_counts}
          />
        );
      
    }
  }
 
  getCategoryFromSlug( value) { 
    for (var prop in window.$categories) { 
        if (window.$categories.hasOwnProperty(prop)) { 
            if (window.$categories[prop] === value) 
            return prop; 
        } 
    } 
  } 
  componentDidMount() {
    var category_id = "";
    if(this.state.category_id){
      
        category_id = this.getCategoryFromSlug(this.state.category_id);
        this.setState({category_id: category_id});
      
    }
   

    
    if (
      this.props.match.params.sub_cat &&
      this.props.match.params.sub_cat !== null
    ) {
      var sub_cat =this.getCategoryFromSlug(this.props.match.params.sub_cat);
      if(sub_cat){
        this.setState({ sub_category: sub_cat });
      }
     
      this.setState({ loading: true }, () => {});
      
      axios
        .post(
          `http://adscombined.com/v1/public/api/CategoryCounts?category=${encodeURIComponent(category_id)}&sub_category=${encodeURIComponent(sub_cat.toString())}`
        )
        .then(
          (response) => {
            if (response.data) {
              if (response.data.flag) {
                const category_counts = response.data;
                // console.log(response.data);
                this.setState({ category_counts: category_counts });
                this.setState({
                  couponscount: category_counts.TotalCouponsCount,
                });

              }

              
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      axios
        .post(
          `http://adscombined.com/v1/public/api/CategoryCounts?category=${encodeURIComponent(category_id)}`
        )
        .then(
          (response) => {
            if (response.data) {
              if (response.data.flag) {
                const category_counts = response.data;
                console.log(response.data);
                this.setState({ category_counts: category_counts });
                this.setState({
                  couponscount: response.data.TotalCouponsCount,
                });
              }

              
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    if(this.state.all_categories.length>0){
    
      var allcategories = this.state.all_categories;
      this.setState({all_categories:allcategories});
      var i = 0;
      this.setState({ loading: false });
      for (i = 0; i < allcategories.length; i++) {
        if (allcategories[i].Category == category_id) {
         
          this.setState({ categorydetail: allcategories[i] });
          if(sub_cat && sub_cat!="" && allcategories[i].sub_categories){
            allcategories[i].sub_categories.map(sub_category=>{
              
              if(sub_category == sub_category.SubCategory){
                  this.setState({sub_cat_details:sub_category});
              }
            })
          }
          break;
        }
      }
    }else{

      this.setState({ loading: true }, () => {
        axios
          .get(`http://adscombined.com/v1/public/api/AllCategories`)
          .then(
            (response) => {
              if (response.data.categories) {
                var allcategories = response.data.categories;
                this.setState({all_categories:allcategories});
                var i = 0;
                this.setState({ loading: false });
                for (i = 0; i < allcategories.length; i++) {
                  if (allcategories[i].Category == category_id) {
                    
                    this.setState({ categorydetail: allcategories[i] });
                    
                    if(sub_cat && sub_cat!="" && allcategories[i].sub_categories){
                      allcategories[i].sub_categories.map(sub_category=>{
                     
                        if(sub_cat == sub_category.SubCategory){
                            this.setState({sub_cat_details:sub_category});
                        }
                      })
                    }
  
                    break;
                  }
                }
                
              }
            },
            (error) => {
              this.setState({ loading: false });
              console.log(error);
            }
          );
      });
    }
    
  }

  Capitalize(str) {
    str = str.trim();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getCategorySlug(category){

    if(category){
      category = category.replace("&","");
      category = category.replace("$","");
      category = category.replace(",","");
      category = category.replace(".","");
      
      var category = category.replace(/\s/g,"-");
      var category = category.replace("--","-");
      return category;

    }
    
  }

  render() {
    if(!window.$categories[this.state.category_id]){
      return(
        <Store record_type="coupons" store_id={this.state.category_id}/>
      )
    }else{

    return (
      <div>
        <Helmet>
          <title>
            {this.state.sub_category || this.state.categorydetail.Category
              ? this.state.sub_category !== null
                ? this.Capitalize(this.state.sub_category) +
                  "'s Coupons "
                : this.Capitalize(this.state.categorydetail.Category) +
                  "'s Coupons "
              : " Coupons"}
            |{window.$sitename}
          </title>
          <meta
            name="description"
            content={
              "In " +
              window.$sitename +
              " you can find best Deals, Promos, Free Shipping, New Arrival, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks and Seasonal Coupons for " +
              this.state.sub_category +
              " or " +
              this.state.categorydetail.Category +
              "."
            }
          />
          <meta
            name="keywords"
            content={
              "Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, New Arrival, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks, Seasonal Coupons"
            }
          />
        </Helmet>
        <section className="main-container">
          <div className="container" style={{ padding: "0px" }}>
            <br />
            <div className="row">
              <div className="col-md-12 ">
               
                  <nav aria-label="breadcrumb">
                  {this.state.loading ? (
                  
                      <BreadcrumSkeleton />
                  ) : (
                   this.state.sub_category !== null && this.state.categorydetail.Category !==""? (
                      <ol
                        className="breadcrumb"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/allcategories">All Categories</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <a href={ "/coupons/" +window.$categories[this.state.categorydetail.Category ]}>
                            {this.state.categorydetail.Category }
                          </a>
                      </li>
                      <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ textTransform: "capitalize" }}
                    > <span>{this.state.sub_category}</span>
                      </li>
                      
                    </ol>
                   
                    ):(
                      <ol
                        className="breadcrumb"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/allcategories">All Categories</Link>
                        </li>
                        <li
                        className="breadcrumb-item active"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      > <span>{this.state.categorydetail.Category}</span>
                        </li>
                      </ol>
                    )
                   
                    )}
                </nav>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <Advertisement  ad_type="full width"></Advertisement>
            </div>
            <div className="row">
              
              <div className="col-md-4 ">
              
                {this.state.loading ? (
                  <div className="card single-coupen mt-2">
                    <div className="card-body">
                      <CategoryDetailsSkeleton />
                    </div>
                  </div>
                ) : (
                  <div>
                    
                  {this.state.sub_category !== null ? (
                    <div>
                        <div className="card">
                          <div className="card-body">
                            <center>
                            <span>
                            
                                <img

                                  alt={this.state.sub_category+ " Coupons & Promotion Codes"}
                                  className="lazy loaded"
                                  width={100}
                                  height={100}
                                  src={window.$site_url + 'category_images/sub/'+this.getCategorySlug(this.getLowerCase(this.state.sub_category))+'.png'} 
                                  data-loaded="true"
                                />
                              
                            <br/>
                              <span>
                                <h3 style={{ textTransform: "capitalize" }}>
                                  {this.state.sub_category}{" "}
                                </h3>
                                
           
                                <b></b>
                                <p style={{ textTransform: "capitalize" }}>
                                  {" "}
                                  <a
                                    href={
                                      "/coupons/" +
                                      window.$categories[this.state.categorydetail.Category ]} title={this.state.categorydetail.Category+ " Coupons & Promotion Codes"} >
                                      {this.state.categorydetail.Category}
                                  </a>
                                </p>
                                {this.state.sub_cat_details && this.state.sub_cat_details.Description?<p style={{textAlign:"left"}}>{renderHTML(this.state.sub_cat_details.Description)}     </p>: "" }

                         
                              </span>
                              
                              </span>
                              </center>
                              
                        <br />
                            <p>Other Categories in <b><i>"<a
                                    href={
                                      "/coupons/" +
                                      window.$categories[this.state.categorydetail.Category ]} title={this.state.categorydetail.Category+" Coupons & Promotion Codes"}>
                                      {this.state.categorydetail.Category}
                                  </a>"</i></b></p>
                                
                                <div className="p-1">
                                
                                  {this.state.categorydetail.sub_categories? (
                                  this.state.categorydetail.sub_categories.map((subCategory) => (
                                    <a
                                          href={
                                            "/coupons/" +
                                            window.$categories[this.state.categorydetail.Category ]+
                                            "/" +
                                            window.$categories[subCategory.SubCategory]
                                          } 
                                          title={this.Capitalize(subCategory.SubCategory)+" Coupons & Promotion Codes"}
                                          className={`pill invert mr-1  ml-1 mt-2 no-decoration`}
                                          style={{cursor: "pointer", width: "fit-content"}}
                                        >
                                        
                                          <span style={{whiteSpace: "nowrap"}}> 
                                          {subCategory.SubCategory}
                                          </span>
                                    </a>
                                  )
                                  )
                                  ):
                                  (<div></div>)
                                }
                              </div>
                               
                            </div>
                          </div>
                        </div>
                        ) : (
                          <div>
                            <div className="card">
                              <div className="card-body">
                                <center>
                                <span>
                                  
                                    <img
                                      alt={this.state.categorydetail.Category + " Coupons & Promotion Codes"}
                                      className="lazy loaded"
                                      width={100}
                                      height={100}
                                      src={window.$site_url + 'category_images/'+this.getCategorySlug(this.state.categorydetail.Category)+'.png'} 
                                      data-loaded="true"
                                    />
                                
                                  
                                  
                                  <br/>
                                    <h3 style={{ textTransform: "capitalize" }}>
                                      {this.state.categorydetail.Category}{" "}
                                    </h3>
                                </span>
                              </center>
                              

                              
                              {this.state.categorydetail.Description?<p>{renderHTML(this.state.categorydetail.Description)}     </p>: "" }

                         
    
                              <br />
                              <p>Categories in <b><i>"<a
                                    href={
                                      "/coupons/" +
                                      window.$categories[this.state.categorydetail.Category ]} title={this.state.categorydetail.Category+ " Coupons & Promotion Codes"}>
                                      {this.state.categorydetail.Category}
                                  </a>"</i></b></p>
                                <div className="p-1">
                                
                                  {this.state.categorydetail.sub_categories? (
                                  this.state.categorydetail.sub_categories.map((subCategory) => (
                                    <a
                                          href={
                                            "/coupons/" +
                                            window.$categories[this.state.categorydetail.Category ]+
                                            "/" +
                                            window.$categories[subCategory.SubCategory]
                                          } 
                                          title={this.Capitalize(subCategory.SubCategory)+ " Coupons & Promotion Codes"}
                                          className={`pill invert mr-1  ml-1 mt-2 no-decoration`}
                                          style={{cursor: "pointer", width: "fit-content"}}
                                        >
                                        
                                          <span style={{whiteSpace: "nowrap"}}> 
                                          {subCategory.SubCategory}
                                          </span>
                                    </a>
                                  )
                                  )
                                  ):
                                  (<div></div>)
                                }
                              </div>
                               
                            </div>
                          </div>
                        </div>
                        )}
                        
                        
                  </div>
                )}
                <br/>
                            <div className="card sticky_ad">
                      <Advertisement ad_type=""></Advertisement>
                        </div>
              </div>
              <div className="col-md-8">{this.renderComponent()}</div>
            </div>
          </div>
        </section>
        <br/>
      </div>
    );
  }
}
}
export default CategoryDetails;

import React, { PureComponent } from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import Toast from "light-toast";
import Popup from "reactjs-popup";
import { isMobile, isBrowser,isTablet } from "react-device-detect";
import Moment from "react-moment";

import CouponTypeDisplay from "./CouponTypeDisplay";
import CouponsSkeleton from "./skeletons/CouponsSkeleton";
import Advertisement from "./Advertisement";
import AddtoFavourite from "./AddtoFavourite";
import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();

class Coupens extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      coupon_type: props.coupon_type,
      all_coupons: [],
      loading: false,
      offset: 0,
      sub_types_counts:null,
      perPage: isBrowser ? 60 : 30,
      currentPage: 0,
      total: 0,
      date_updated:"",
      sub_type:"",
      sepecial_coupon: [],
      page_title:"",
      sc_id: "",
      open: false,
    };
    
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.getStore = this.getStore.bind(this);
    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);
    this.receivedData = this.receivedData.bind(this);
    
  }

  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(coupon) {
    if (this.state.sepecial_coupon.length < 1) {
      this.setState({ open: true, sepecial_coupon: coupon });
    }
  }

  closeModal() {
    this.setState({ open: false });
  }

  redirectToStore(coupon) {
    Toast.info(
      <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {coupon.StoreURL}
      </div>,
      3000,
      () => {
        window.open(
          window.location.href.split("?")[0] + "?c=" + coupon.id,
          "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
          //this.openModal(coupon)
          (window.location.href = coupon.SourceUrl),
          2000
        );
      }
    );
  }
  componentWillReceiveProps() {

    this.setState({ coupon_type: this.props.coupon_type,sub_type:this.props.sub_type });
    this.receivedData();
  }

  receivedData() {
    
    const params = new URLSearchParams(window.location.search);

    if (params.has("c")) {
      var sc_id = "";
      sc_id = params.get("c");
      var user_id = "";
      if(user ){
          user_id = "&user_id="+user.id;
      }
      axios.get(`http://adscombined.com/v1/public/api/CouponDetails?coupon_id=${encodeURIComponent(sc_id)}${user_id}`).then((response) => {

        if(response.data.flag){
            
            if(response.data.coupon_data ){
                
                const sepecial_coupon = response.data.coupon_data;
                
                this.openModal(sepecial_coupon);
            }
        }else{
        }
      }, (error) => {
          console.log(error);
      })
    }

    this.setState({ loading: true }, () => {
      var store_id = null;

        var user_id = "";
        if(user ){
            user_id = "&user_id="+user.id;
        }
        if (this.props.store_id) { 
          store_id = this.props.store_id;
          axios
            .post(
              `http://adscombined.com/v1/public/api/StoreCoupons?store_url=${store_id}&Page=${
                this.state.currentPage
              }&PerPage=${this.state.perPage}&Filter=${this.props.coupon_type}&SubType=${encodeURIComponent(this.props.sub_type)}${user_id}`
            )
            .then(
              (response) => {
                if (response.data.coupons) {
                 

                  this.setState({ all_coupons: response.data.coupons });
                  var pagetitle = "";
                  if(response.data.PageTitle){
                    pagetitle = response.data.PageTitle;
                  }
                  if(this.state.date_updated===""){
                    if(response.data.coupons.length>0)
                    {
                      if(response.data.coupons[0].DateUpdated){
                        this.setState({date_updated:response.data.coupons[0].DateUpdated})
                      }
                    }
                  }
                  
                  this.setState({
                    page_title:pagetitle,
                    total: response.data.total_coupons,
                    sub_types_counts: response.data.sub_types_counts,
                    coupons:response.data.coupons,
                    pageCount: Math.ceil(
                      response.data.total_coupons / this.state.perPage
                    ),
                  });

                  this.setState({ loading: false });
                } else {
                  this.setState({ loading: false });
                }
              },
              (error) => {
                this.setState({ loading: false });
                console.log(error);
              }
            );
        }
      
    });
  }

  componentDidMount() {
    if (this.state.coupons.length < 1) {
      this.receivedData();
    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
    window.scrollTo(0, 0);
  };

  getStore(ctype,subtype="") {
    if (ctype != "") {

      this.setState({ sub_type:subtype,coupon_type: ctype, offset: 0, coupons:[],currentPage:0 });
      this.props.action(ctype,subtype);
      
    }
  }

  
  shorten(text,max) {
    return text && text.length > max ? text.slice(0,max).split(' ').slice(0, -1).join(' ') : text
  }

  render() {

    var add_count = 0;
    const contentStyle = { width: isBrowser ? '70% !important' : isTablet ? '70% !important': '90% !important' };
    //Promo, Free Shipping,Clearance,Weekly Ad,Todays Ad,Sale,Event,Gift,Coupon,Student,Referral,Reward,Cash Back,Rebate,Printable Coupon,Seasonal
    return (
      <div>
        <h1 className="ac-h1" title={this.props.store_name+" Coupons"}  style={{textTransform:"capitalize",fontSize:"2rem",fontWeight:"bold"}}>
          {this.props.store_name}  <Link to={"/coupons/all"} title="All Coupons">Coupons</Link>
        </h1>
        {this.state.date_updated!==""? 
        (
          <h5>Verified {this.props.store_name} <Link to={"/coupons/all"} title="All Coupons">Coupons</Link> for  <Moment format="dddd, MMMM D, Y">
          {this.state.date_updated}
          </Moment> </h5>
        )
        :(<span></span>)
        }  
        {this.state.page_title!==""?
        <Helmet>
          <title>{this.state.page_title}</title>
        </Helmet>
        :
        <div></div>
        }
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          {...{contentStyle}}
        >
          <div className="modal">
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
            <div class="row" style={{ padding: "10px" }}>
              <div className="col-md-3">
                <center>
                <Link
                    className="logo  cust_link featured"
                    to={"/coupons/" + this.state.sepecial_coupon.StoreURL}
                    title={
                      this.state.sepecial_coupon.StoreName +" Coupons, Promotion Codes & Deals"
                    }
                  >
                  <img
                    alt = {this.state.sepecial_coupon.Title+" Coupons, Promotion Codes & Deals"}
                    title = {this.state.sepecial_coupon.Title+ " Coupons, Promotion Codes & Deals"}
                    src={
                      this.state.sepecial_coupon.Logo
                        ? window.$logos_url+this.state.sepecial_coupon.Logo.replace(".PNG",".png")
                        : process.env.PUBLIC_URL + "/default_logo.png"
                    }
                    width="100"
                    height="100"
                    id="store_logo"
                  ></img>
                  <h5>
                    <b style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </b>
                  </h5>
                  </Link>
                </center>
                
              </div>

              <div className="col-md-9" style={{ padding: "inherit" }}>
              <AddtoFavourite type={"heart"} source={"scoupons"}  favourite_id = {this.state.sepecial_coupon.favourite_id} id={this.state.sepecial_coupon.id} favourite_type={"coupon"} is_favourite={this.state.sepecial_coupon.favourite} />
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "teal",
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.sepecial_coupon.Offer && this.state.sepecial_coupon.Offer!=="none" && this.state.sepecial_coupon.Offer!=="None" ? (
                    <CouponTypeDisplay
                      coupontype={this.state.sepecial_coupon.Offer}
                      source="couponpage"
                    />
                  ) : (
                    <CouponTypeDisplay
                      coupontype={this.state.sepecial_coupon.Type}
                      source="couponpage"
                    />
                  )}
                </span>

                
                  <h3 id="coupon_title">
                    {this.state.sepecial_coupon.Title}
                  </h3>
               

                <br />
                <b></b>
                {this.state.sepecial_coupon.Code && this.state.sepecial_coupon.Code!="None" ? (
                  <span>
                     <input
                  id="code-value"
                  type="text"
                  className="code-copy"
                  value={this.state.sepecial_coupon.Code}
                  style={{
                    borderRadius: "0px",
                    width: "fit-content",
                    cursor: "copy",
                  }}
                  readOnly
                />

                <span>
                  <button
                    className="btn"
                    onClick={this.myFunction}
                    onMouseOut={this.outFunc}
                    id="myTooltip"
                    style={{ verticalAlign: "inherit" }}
                  >
                    Copy Code
                  </button>
                </span>

                <br />
                <br />
                  </span>
                  
                ): (<div></div>)}
                
                <a href={this.state.sepecial_coupon.SourceUrl}>
                  <b>
                    {" "}
                    Continue to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </span>
                  </b>{" "}
                </a>


              </div>
            </div>
          </div>
        </Popup>
        <div className="mb-2  well well-sm border-div bg-white borderZero p-2">
          <div class="btn-group">
            <button
              onClick={() => this.getStore("all")}
              className={
                this.props.coupon_type == "all"
                  ? "btn-info btn-pills ml-2 mt-2 mb-2"
                  : " btn-pills ml-2 mt-2 mb-2"
              }
            >
              All Offers {this.props.store_info.TotalCouponsCount > 0 ? "("+this.props.store_info.TotalCouponsCount+")" : ""}
            </button>
          </div>
          {this.props.store_info.PromoCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Promo")}
                className={
                  this.props.coupon_type == "Promo"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Promo ({this.props.store_info.PromoCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.FreeShippingCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Free Shipping")}
                className={
                  this.props.coupon_type == "Free Shipping"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Free Shipping ({this.props.store_info.FreeShippingCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.ClearanceCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Clearance")}
                className={
                  this.props.coupon_type == "Clearance"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Clearance ({this.props.store_info.ClearanceCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
            
          {this.props.store_info.WeeklyAdCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Weekly Ad")}
                className={
                  this.props.coupon_type == "Weekly Ad"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Weekly Ad ({this.props.store_info.WeeklyAdCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.TodaysAdCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Today's Ad")}
                className={
                  this.props.coupon_type == "Today's Ad"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Today's Ad ({this.props.store_info.TodaysAdCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.SaleCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Sale")}
                className={
                  this.props.coupon_type == "Sale"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Sale ({this.props.store_info.SaleCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.EventsCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Event")}
                className={
                  this.props.coupon_type == "Event"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Events ({this.props.store_info.EventsCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.GiftsCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Gift")}
                className={
                  this.props.coupon_type == "Gift"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Gifts ({this.props.store_info.GiftsCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.CouponCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Coupon")}
                className={
                  this.props.coupon_type == "Coupon"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Coupon ({this.props.store_info.CouponCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.StudentCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Student")}
                className={
                  this.props.coupon_type == "Student"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                {" "}
                Student ({this.props.store_info.StudentCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.ReferralCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Referral")}
                className={
                  this.props.coupon_type == "Referral"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Referral ({this.props.store_info.ReferralCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.RewardCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Reward")}
                className={
                  this.props.coupon_type == "Reward"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Reward ({this.props.store_info.RewardCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.CashBackCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Cash Back")}
                className={
                  this.props.coupon_type == "Cash Back"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                {" "}
                Cash Back ({this.props.store_info.CashBackCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.RebateCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Rebate")}
                className={
                  this.props.coupon_type == "Rebate"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Rebate ({this.props.store_info.RebateCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}

          {this.props.store_info.PrintableCouponCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Printable Coupon")}
                className={
                  this.props.coupon_type == "Printable Coupon"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Printable Coupon ({this.props.store_info.PrintableCouponCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.SeasonalCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("Seasonal")}
                className={
                  this.props.coupon_type == "Seasonal"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                Seasonal ({this.props.store_info.SeasonalCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.store_info.BOGOCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("BOGO")}
                className={
                  this.props.coupon_type == "BOGO"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                BOGO ({this.props.store_info.BOGOCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}

        {this.props.store_info.NewArrivalCount > 0 ? (
            <div class="btn-group">
              <button
                onClick={() => this.getStore("New Arrival")}
                className={
                  this.props.coupon_type == "New Arrival"
                    ? "btn-info btn-pills ml-2 mt-2 mb-2"
                    : " btn-pills ml-2 mt-2 mb-2"
                }
              >
                New Arrival ({this.props.store_info.NewArrivalCount})
              </button>
            </div>
          ) : (
            <span></span>
          )}
          
    
        {!this.state.loading && this.state.sub_types_counts &&  (  this.props.coupon_type == "Event" ||   this.props.coupon_type=="Seasonal") ? 
            
            <h5 className="ml-2"><hr/><i>Avaiable {  this.props.coupon_type}s:</i></h5> 
          :""}

          {!this.state.loading && this.state.sub_types_counts &&  (  this.props.coupon_type == "Event" ||   this.props.coupon_type=="Seasonal")  &&  this.state.sub_types_counts.map(sub_type=>{
            return  sub_type.count && sub_type.count>0 ? 
              <button
              onClick={() => this.getStore(   this.props.coupon_type,sub_type.sub_type)}
              className={
              (  this.props.coupon_type == "Event" ||   this.props.coupon_type=="Seasonal") && this.props.sub_type == sub_type.sub_type
                ? "btn-info btn-pills ml-2 mt-2 mb-2"
                : " btn-pills ml-2 mt-2 mb-2"
              }
              >
              {sub_type.sub_type} ({sub_type.count})
              </button> : "";
          })}
        </div>
        
        <div
          className="container-fluid mt-0"
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
          {this.state.loading ? (
            <div className="card single-coupen mt-2">
              <div className="card-body">
                <CouponsSkeleton />
              </div>
            </div>
          ) : (
            <div>
              {this.state.coupons.length > 0 ? (
                <div>
                  {this.state.coupons.map((coupon) => (
                      add_count>9 ?

                      <div className="card single-coupen mt-2" >
                        <Advertisement ad_type="full width"></Advertisement>
                        <span style={{display:"none"}}>

                          {add_count = 0}
                        </span>
                      </div> 

                      :
                      <div className="card single-coupen mt-2" >
                        <span style={{display:"none"}}>{add_count++}</span>
                        <div className="card-body">
                        <AddtoFavourite source={"coupons"}  type={"heart"} id={coupon.id} favourite_id = {coupon.favourite_id} favourite_type={"coupon"} is_favourite={coupon.favourite} />
                            {!coupon.Image && coupon.Image !=="" ? (
                              <div className="row d-flex justify-content-start card-strip">
                              <h5 className="deal-value" onClick={() => this.redirectToStore(coupon)} style={{cursor:"pointer"}} title={"Click to get "+coupon.Title}>
                              {coupon.Offer && coupon.Offer!="None" && coupon.Offer!="none" ? (
                                <CouponTypeDisplay coupontype={coupon.Offer}  source="couponpage" />
                              ) : (
                                <CouponTypeDisplay coupontype={coupon.Type}  source="couponpage" />
                              )}

                              </h5>
                              
                              <div className="info ">
                              <div className="row px-3 mb-2">
                                <h3 className="dark-text " onClick={() => this.redirectToStore(coupon)} style={{cursor:"pointer",fontSize: "1.5rem"}} title={"Click to get "+coupon.Title}>
                                  {coupon.Title}
                                </h3>
                                {/* <p className="mt-1 mr-4 extended-title">
                                {coupon.Status}
                              </p> */}
                              </div>
                              <div className="row px-3">
                                <p className="mb-1">
                              
                                </p>
                              </div>
                              <div className=" px-3 ">
                              {coupon.updated_at ? 
                                      <span style={{fontSize:"12px"}}>
                                      <i>Last Seen: 
                                      {" "}
                                      <b>
                                       <Moment format="MMMM D, Y" >
                                     {coupon.updated_at} 
                                       </Moment></b></i>
                                       <br/>    </span>
                                    : ""
                                    }
                                <button
                                  onClick={() => this.redirectToStore(coupon)}
                                  className="btn btn-info  "
                                  target="blank"
                                  style={{
                                    borderRadius: "0px",
                                    textDecoration: "none",
                                    color: "white",
                                  }}
                                  title={"Click to get "+coupon.Title}
                                >
                                  Get this Coupon
                                </button>
                                {coupon.created_at ? 
                                      <div className=" mt-3" style={{float:"right ",fontSize:"12px"}}>
                                      <i> Found On: 
                                      {" "}
                                       <b>
                                       <Moment format="MMMM D, Y" >
                                     {coupon.created_at} 
                                       </Moment></b></i>
                                       </div>
                                    : ""
                                    }
                              </div>
                              </div>
                              </div>
                            )
                            :
                            (
                              
                                <img alt={coupon.Title} onClick={() => this.redirectToStore(coupon)} src={coupon.Image} style={{cursor:"pointer",width:"100%",height:"150px",objectFit:"cover"}} title={"Click to get "+coupon.Title}/>
                              
                            )
                          }
                            
                        </div>
                      </div>
                    ))}

                </div>
              ) : (
                <div className="card single-coupen mt-2">
                  <div className="card-body">
                    <h5>No Coupons Found.</h5>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.total> this.state.perPage ?   
            <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={this.state.currentPage}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            />
            :
            <div></div>
            
          }
        </div>
      </div>
    );
  }
}

export default Coupens;

import React, { PureComponent } from "react";
import Skeleton from "react-loading-skeleton";

class BreadcrumSkeleton extends PureComponent{

    render(){

        return(

                <div>
                   
                    <Skeleton className="mr-2" height={25} width={90}></Skeleton>
                
                    <Skeleton className="mr-2" height={25} width={100}></Skeleton>
                
                
                    <Skeleton className="mr-2" height={25} width={120}></Skeleton>
                    
                    
                </div>
        );
    }
}
export default BreadcrumSkeleton;
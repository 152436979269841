import React, { PureComponent } from "react";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";


import Advertisement from "./Advertisement";
import CategoryListSkeleton from "./skeletons/CategoryListSkeleton";


class CategoryList extends PureComponent {
 
  constructor(props){
    super(props);
    this.state = {
      allcategories: [],
      loading: false,
    };

    this.getCategorySlug = this.getCategorySlug.bind(this);
    this.getLowerCase = this.getLowerCase.bind(this);
  }
  componentDidMount() {
    if(this.props.categories.length<1){
      this.setState({ loading: true }, () => {
        axios
          .get(`http://adscombined.com/v1/public/api/AllCategories`)
          .then(
            (response) => {
              if (response.data.categories) {
                const allcategories = response.data.categories;
                this.setState({ allcategories });
                this.setState({ loading: false });
              } else {
                this.setState({ loading: false });
                document.getElementById("msg-div").style.display = "block";
              }
            },
            (error) => {
              this.setState({ loading: false });
              console.log(error);
            }
          );
      });
    }else{
      
      const allcategories = this.props.categories;
      this.setState({ allcategories });
      this.setState({ loading: false });
    }
    
  }
  getCategorySlug(category){
    category = category.replace("&","");
    category = category.replace("$","");
    category = category.replace(",","");
    category = category.replace(".","");
    
    var category = category.replace(/\s/g,"-");
    var category = category.replace("--","-");
    return category;
  }
  getLowerCase(str){
    return str.toLowerCase(str);
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  render() {
    var count = 0;
    return (
      <div>
        <Helmet>
          <title>All Categories | {window.$sitename}</title>
          <meta
            name="description"
            content={
              "In " +
              window.$sitename +
              " you can find best Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks and Seasonal Coupons."
            }
          />
          <meta
            name="keywords"
            content={
              "Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks, Seasonal Coupons"
            }
          />
        </Helmet>
        <section className="main-container">
          <div className="container" style={{ padding: "0px" }}>
           
            <div className="row">
              <div className="col-md-12 ">
                <nav aria-label="breadcrumb">
                  <ol
                    className="breadcrumb"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <li className="breadcrumb-item">
                      <Link to="\">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      All Categories 
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <Advertisement  ad_type="full width"></Advertisement>
            </div>
            <div className="row">
              <div className="col-md-12">
              <h1 className="ac-h1" title="Best Deals and Coupons in All Categories" style={{fontSize:"2rem",fontWeight:"bold"}}>
                  Best <Link to={"/deals"} title="All Deals">Deals</Link> and <Link to={"/coupons/all"} title="All Coupons">Coupons</Link> in All <Link to={"/allcategories"} title="All Categories">Categories</Link>
                </h1>
              </div>
              </div>
            <div className="row">
              <div className="col-md-9">
              
                {this.state.loading ? (
                  <div className="card  mb-2">
                    <div className="card-body">
                      <CategoryListSkeleton items={[1,2,3,4,5,6,7,8,9,10,11,12]} />
                    </div>
                  </div>
                ) : (
                  <div>
                    {this.state.allcategories.length > 0 ? (
                      this.state.allcategories.map((category) => (
                           
                            <div className="card mb-2">
                            <div className="card-body">
                            <Link 
                            title={category.Category+" Coupons & Promotion Codes"}
                            
                            to={"/coupons/" +  window.$categories[category.Category]} style={{textDecoration:"none",color:"inherit"}}>                              
                              <h4>{category.Category} </h4>
                            </Link>
                        
                            <div className="cards-grid-container  pt-2" style={{paddingBottom:"0px"}}>
                            {category.sub_categories.length > 0 ? (
                              category.sub_categories.map(
                                (subCategory) => (
                                  <div className="cards-grid-item2" style={{height:"100px"}}>
                                    <Link
                                      to={
                                        "/coupons/" +
                                        window.$categories[category.Category ]+
                                        "/" +
                                        window.$categories[subCategory.SubCategory]
                                      }
                                      className="no_decoration cards-grid-link2 "  
                                      title={this.Capitalize(subCategory.SubCategory)+" Coupons & Promotion Codes"}
                                    >
                                    
                                      <span className="cards-grid-icon-wrapper2" style={{flex:"unset !important",paddingBottom:"0px !important;"}}>
                                        <img
                                        
                                        alt={subCategory.SubCategory+ " Coupons & Promotion Codes"}
                                          className="cards-grid-icon img-category" 
                                          src={window.$site_url + 'category_images/sub/'+this.getCategorySlug(this.getLowerCase(subCategory.SubCategory))+'.png'} 
                                          ></img>
                                      </span>
                                      <span className="cards-grid-title " style={{textTransform:"capitalize"}}>
                                      {subCategory.SubCategory}
                                      </span>
                                    </Link>
                                  </div>
                                )
                              )
                            ) : (
                              <div></div>
                            )}
                            </div>

                            <p className="mb-0">
                              <Link to={"/coupons/" +  window.$categories[category.Category]} title={category.Category+ " Coupons & Promotion Codes"}>
                                View all {category.Category} Coupons.
                              </Link>
                            </p>
                          </div>
                            
                        </div>
                           
                         
                      ))
                    ) : (
                      <p></p>
                    )}
                    <div
                      className="card "
                      id="msg-div"
                      style={{ display: "none" }}
                    >
                      <div className="card-body">No Categories Found</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-3 ">
                <div className="card sticky_ad">

                <Advertisement ad_type=""></Advertisement>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div
                  className="container-fluid pt-0"
                  style={{ paddingRight: "0px", paddingLeft: "0px" }}
                >
                  <div className="card">
                    <div className="card-body">
                      <h4>Move to a Category</h4>
                      <Categories></Categories>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CategoryList;

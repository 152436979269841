import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { getUser, removeUserSession } from "../utils/Common";

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCategories = this.loadCategories.bind(this);
  }
  state = {
    selected: [],
    categories: [],
    allcategories: this.props.categories,
    active_category: "",
    selected_category: "",
    selected_category_name: "",
    search_keywords:[],
    activeMenu: "",
    menuItems: [
      ["/onlinestores", "Stores"],
      ["/onlineretailers", "Retailers"],
      ["/onlinebrands", "Brands"],
      ["/deals", "Deals"],
      
    ],
    coupon_types: [
      ["/coupons/coupon", "Coupon"],
      ["/coupons/promo", "Promo"],
      ["/coupons/free-shipping", "Free Shipping"],
      ["/coupons/clearance", "Clearance"],
      ["/coupons/weekly-ads", "Weekly Ad"],
      ["/coupons/today-ads", "Today's Ad"],
      ["/coupons/sale", "Sale"],
      ["/coupons/event", "Event"],
      ["/coupons/gift", "Gift"],
      ["/coupons/student", "Student"],
      ["/coupons/referral", "Referral"],
      ["/coupons/reward", "Reward"],
      ["/coupons/cash-back", "Cash Back"],
      ["/coupons/rebate", "Rebate"],
      ["/coupons/printable-coupon", "Printable Coupon"],
      ["/coupons/seasonal", "Seasonal"],
      ["/coupons/bogo", "BOGO"],
      ["/coupons/new-arrival", "New Arrival"]
      
    ]
  };

  handleLogout(){
    removeUserSession();
    window.location.href = '/login';
  }
  handleClick(event) {
    
    var term = document.querySelector(
      "#root > div > div:nth-child(1) > div > nav > form > div > div:nth-child(1) > input.rbt-input-main.form-control.rbt-input"
    ).value;
    var search_type = document.querySelector("#search_type").value;
 
    if (term && term !== null) {
      
      if(search_type == "deals"){
        window.location.href = "\\deals?q="+ term;
    
      }else{
        window.location.href = "\\coupons\\all?q="+ term;
    }
      }
      
  }
  componentDidMount(){
    
    this.loadCategories();
  }
  searchCouponsDeals() {
    if (this.state.selected.length > 0) {
      if (this.state.selected[0].value == 0) {
        window.location.href = "\\coupons\\" + window.$categories[this.state.selected[0].label];
      } else {
        window.location.href =
          "\\coupons\\" +
          window.$categories[this.state.selected[0].value] +
          "\\" +
          window.$categories[this.state.selected[0].label];
      }
    }
  }
  menuClickHandle(menu) {
    return this.setState({ activeMenu: menu });
  }

  loadCategories() {
    
  
 

    if (this.state.categories.length < 1) {

      var categoriess = localStorage.getItem("categories");
      
      if(categoriess){
         var cats = JSON.parse(categoriess);
         this.setState({ allcategories: cats });

         var categories = [];
         var i = 0;
         for (i = 0; i < cats.length; i++) {
           if (i == 0) {
             this.setState({ selected_category: cats[i].id });
             this.setState({ selected_category_name: cats[i].Category });
             this.setState({ active_category: cats[i].id });
           }
           if (cats[i].Category != "") {
             categories.push({
               id: cats[i].id,
               value: 0,
               label: cats[i].Category,
             });
           }
           var subCategories = cats[i].sub_categories;
           if (subCategories ) {
             
               var j = 0;
               for (j = 0; j < subCategories.length > 0; j++) {
                 categories.push({
                   id: 100 + j + i,
                   value: cats[i].Category,
                   label: subCategories[j].SubCategory,
                 });
               }
           }
         }
         
         if(this.state.search_keywords.length<1){


          axios
          .post(`http://adscombined.com/v1/public/api/SearchKeywordsList`)
          .then((response) => {
            if (response.data.search_keywords) {
              
              var keywords = response.data.search_keywords;
              var k = 0;
              keywords && keywords.map(item=>{
           
                k++;
                categories.push({
                  id:300+k+10,
                  value:item.mapped_to,
                  label: item.keyword
                })
              })
            }
    
          });
        }
          
          
         if (categories.length > 0) {
           this.setState({ categories: categories });
         }
      }else{

        if(this.state.allcategories.length<1){


          axios
          .get(`http://adscombined.com/v1/public/api/AllCategories`)
          .then((response) => {
            if (response.data.categories) {
              var cats = response.data.categories;
              this.setState({ allcategories: cats });
              var categories = [];
              var i = 0;
              for (i = 0; i < cats.length; i++) {
                if (i == 0) {
                  this.setState({ selected_category: cats[i].id });
                  this.setState({ selected_category_name: cats[i].Category });
                  this.setState({ active_category: cats[i].id });
                }
                if (cats[i].Category != "") {
                  categories.push({
                    id: cats[i].id,
                    value: 0,
                    label: cats[i].Category,
                  });
                }
                var subCategories = cats[i].sub_categories;
                if (subCategories ) {
                  
                    var j = 0;
                    for (j = 0; j < subCategories.length > 0; j++) {
                      categories.push({
                        id: 100 + j + i,
                        value: cats[i].Category,
                        label: subCategories[j].SubCategory,
                      });
                    }
                }
              }
              if(this.state.search_keywords.length<1){


                axios
                .post(`http://adscombined.com/v1/public/api/SearchKeywordsList`)
                .then((response) => {
                  if (response.data.search_keywords) {
                    
                    var keywords = response.data.search_keywords;
                    var k = 0;
                    keywords && keywords.map(item=>{
                 
                      k++;
                      categories.push({
                        id:300+k+10,
                        value:item.mapped_to,
                        label: item.keyword
                      })
                    })
                  }
          
                });
              }
              if (categories.length > 0) {
                this.setState({ categories: categories });
              }
            }
          });
  
        }else{
          
          var cats = this.state.allcategories;
          var categories = [];
              var i = 0;
              for (i = 0; i < cats.length; i++) {
                if (i == 0) {
                  this.setState({ selected_category: cats[i].id });
                  this.setState({ selected_category_name: cats[i].Category });
                  this.setState({ active_category: cats[i].id });
                }
                if (cats[i].Category != "") {
                  categories.push({
                    id: cats[i].id,
                    value: 0,
                    label: cats[i].Category,
                  });
                }
                var subCategories = cats[i].sub_categories;
                if (subCategories ) {
                  
                    var j = 0;
                    for (j = 0; j < subCategories.length > 0; j++) {
                      categories.push({
                        id: 100 + j + i,
                        value: cats[i].Category,
                        label: subCategories[j].SubCategory,
                      });
                    }
                }
              }
              if(this.state.search_keywords.length<1){


                axios
                .post(`http://adscombined.com/v1/public/api/SearchKeywordsList`)
                .then((response) => {
                  if (response.data.search_keywords) {
                    
                    var keywords = response.data.search_keywords;
                    var k = 0;
                    keywords && keywords.map(item=>{
                 
                      k++;
                      categories.push({
                        id:300+k+10,
                        value:item.mapped_to,
                        label: item.keyword
                      })
                    })
                  }
          
                });
              }
              if (categories.length > 0) {
                this.setState({ categories: categories });
              }
  
        }

      }

     
      
    }
  }
  setCategory(catid, catname) {
    this.setState({ selected_category: catid });
    this.setState({ selected_category_name: catname });
    this.setState({ active_category: catid });
  }
 
  render() {
    const ref = React.createRef();
    return (
      <div className="nav-background">
        <div className=" col-md-12 pb-0 pr-5">
         
            {!this.props.user? 
              <ul className="mb-0" style={{display:"inline-flex",float:"right",listStyle:"none"}}>
                 <li>
                <Link to="/register" style={{fontSize:"13px",color:"white"}} className="pr-1">Register</Link>
                </li>
                <li className="pr-1" style={{fontSize:"15px",color:"white"}} >|</li>
                <li>
                <Link to="/login" style={{fontSize:"13px",color:"white"}} className="pr-0">Login</Link>
                </li>
                
              </ul>
            :
            ""
            }
            {this.props.user?
              <ul className="mb-0" style={{display:"inline-flex",float:"right",listStyle:"none"}}>
                <li>
                <Link to="/profile" style={{fontSize:"13px",color:"white"}} className="pr-1">My Account</Link>
                </li>
                <li className="pr-1" style={{fontSize:"15px",color:"white"}} >|</li>
                <li>
                <a href="#" style={{fontSize:"13px",color:"white"}} onClick={this.handleLogout}  className="pr-0">Logout</a>
                </li>
              </ul>
            :
            ""
            }
       
         
        </div>
          <nav
            className="navbar container navbar-expand-lg navbar-light nav-background pt-0"
            style={{ paddingLeft: "0px", paddingRight: "0px",color:"white !important"}}
          >
            
            <div className="col-md-3 font-white">
              <Link className="navbar-brand1 p-0" to="/"   onClick={() => this.menuClickHandle('/home')}>
                {/* <b
                  style={{
                    fontSize: "28px !important;",
                    fontFamily: "'Shojumaru', cursive",
                  }}
                >
                  ADS
                </b>
                &nbsp;
                <small
                  style={{
                    fontSize: "26px",
                    fontFamily: "'Finger Paint', cursive",
                  }}
                >
                  Combined
                </small> */}
                <img alt="Ads Combined providing best coupons and deals" src={process.env.PUBLIC_URL + "/main_logo.png"} className="navbar-brand pb-0" style={{maxHeight:"65px"}}></img>
              </Link>
            </div>
            <div className="col-md-5">
            <div className="col-md-12 pl-0 " style={{marginTop: "5%"}}>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav   ">
                  {this.state.menuItems.map((item) =>
                    this.state.activeMenu == item[0] ? (
                      <li
                        key={item[0].toString()}
                        className="nav-item navbar-active ml-2 "
                        style={{ color: "rgb(255, 255, 255) !important" }}
                      >
                        <Link
                          className="nav-link p-1 font-white"
                          onClick={() => this.menuClickHandle(item[0])}
                          to={item[0]}
                          style={{ color: "rgb(255, 255, 255) !important" }}
                        >
                          {item[1]}
                          <span className="sr-only">(current)</span>
                        </Link>
                      </li>
                    ) : (
                      <li    key={item[0].toString()} className="nav-item ml-2 ">
                        <Link
                          className="nav-link p-1 font-white"
                          onClick={() => this.menuClickHandle(item[0])}
                          to={item[0]}
                        >
                          {item[1]}
                          <span className="sr-only">(current)</span>
                        </Link>
                      </li>
                    )
                  )}
               
                  <li className="ml-2 ">
                  <div className="dropdown">
                  {
                        this.state.activeMenu == "/coupons/all" ?
                    <Link className="dropbtn dropdown-toggle nav-link active-link  p-1 font-white" to={'/coupons/all'}  onClick={() => this.menuClickHandle('/coupons/all')}>Coupons</Link>
                   :
                    <Link className="dropbtn dropdown-toggle nav-link  p-1 font-white" to={'/coupons/all'}  onClick={() => this.menuClickHandle('/coupons/all')}>Coupons</Link>
                  }
                    <div className="dropdown-content ml-0" style={{minWidth:"30rem",marginLeft:"0rem",float:"inherit",marginTop:"0px",borderRadius:"0px"}}>
                    <div
                      className="dropdown-menu-medium"
                    >
                      <div
                        className="col-sm-12"
                        id="mega-category-menu1"
                        style={{
                          borderRight: "1px solid lightgray",
                          overflowY: "scroll",
                          height: "max-content",
                        }}
                      > 
                       
                          <h6 >
                          <a 
                            className="font-color"
                            href={"/coupons/all"}
                            >
                            <b>
                              All Coupons
                            </b>  
                          </a>
                          </h6>
                        
                        <ul className="p-0">
                            {this.state.coupon_types.map((ctype) =>
                              <a
                              key={ctype.toString()}
                              href={ctype[0]} 
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                }}
                                className="pill invert mr-1  ml-1 mt-2"
                              >
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {ctype[1]}
                                </span>
                              </a>
                             
                            )}
                          </ul>
                          <br/>
                          <a
                            
                            href={"/coupons/brands"}
                            >
                            <b>Coupons and Promos Offered by Brands</b> 
                          </a>
                          <br/>
                      </div>
                    </div>
                    </div>
                    </div>
                    </li>
                  <li className="ml-2">
                  <div className="dropdown">
                    {
                        this.state.activeMenu == "/allcategories" ?
                        <Link className="dropbtn dropdown-toggle nav-link active-link  p-1 font-white" to={'/allcategories'}  onClick={() => this.menuClickHandle('/allcategories')}>Categories</Link>
                        :
                        <Link className="dropbtn dropdown-toggle nav-link  p-1 font-white" to={'/allcategories'}  onClick={() => this.menuClickHandle('/allcategories')}>Categories</Link>
                    }
                   
                    <div className="dropdown-content" style={{marginTop:"0px",borderRadius:"0px"}} >
                    <div
                      className="dropdown-menu-large row"
                    >
                      <div
                        className="col-sm-4"
                        id="mega-category-menu1"
                        style={{
                          borderRight: "1px solid lightgray",
                          overflowY: "scroll",
                          height: "340px",
                        }}
                      >
                        <ul className="p-0">
                          {this.state.allcategories.map((category) =>
                            this.state.active_category == "" ||
                            this.state.active_category == category.id ? (
                              <li
                                className="dropdown-header navbar-active"
                                onMouseOver={() =>
                                  this.setCategory(
                                    category.id,
                                    category.Category
                                  )
                                }
                              >
                                <div href="#" style={{cursor:"pointer"}} title={category.Category} >{category.Category}</div>
                              </li>
                            ) : (
                              <li
                                className="dropdown-header"
                                onMouseOver={() =>
                                  this.setCategory(
                                    category.id,
                                    category.Category
                                  )
                                }
                              >
                                <div style={{cursor:"pointer"}} title={category.Category} href="#">{category.Category}</div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <div className="col-sm-8">
                        <h4>
                          <a
                          style={{cursor:"pointer"}}
                            className="category_link"
                            title={this.state.selected_category_name+" Coupons"}
                            href={"/coupons/" + window.$categories[this.state.selected_category_name]}
                          >
                            {this.state.selected_category_name}
                          </a>
                        </h4>
                        {this.state.allcategories.map((category) =>
                          this.state.selected_category != "" &&
                          this.state.selected_category == category.id ? (
                            category.sub_categories.length > 0 ? (
                              category.sub_categories.map(
                                (subCategory) =>
                                  subCategory.SubCategory != "" ? (
                                    <a
                                      href={
                                        "/coupons/" +
                                        window.$categories[category.Category] +
                                        "/" +
                                        window.$categories[subCategory.SubCategory]
                                      }
                                      className="no_decoration"
                                      title={ window.$categories[category.Category] + " "+ window.$categories[subCategory.SubCategory]+ " Coupons" }
                                    >
                                      <button
                                      title= {subCategory.SubCategory+ " Coupons"}
                                        style={{
                                          cursor: "pointer",
                                          width: "fit-content",
                                        }}
                                        className="pill invert mr-1  ml-1 mt-2"
                                      >
                                        <span style={{ whiteSpace: "nowrap" }}>
                                          {subCategory.SubCategory}
                                        </span>
                                      </button>
                                    </a>
                                  ) : (
                                    <span></span>
                                  )
                              )
                            ) : (
                              <span>
                                <b> No SubCategories.</b>
                              </span>
                            )
                          ) : (
                            <span></span>
                          )
                        )}
                        {this.state.selected_category_name != "" ? (
                          <p>
                            <br />

                            <a
                              title={ "All "+this.state.selected_category_name+ " Coupons"}
                              href={"/coupons/" + window.$categories[this.state.selected_category_name]}
                            >
                              View all {this.state.selected_category_name} Coupons.
                            </a>
                          </p>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                    </div>
                  </div>
                  </li>
                 
                </ul>
              
              </div>
            </div>
            </div>
            <form
              className="col-md-4 my-2 my-lg-0 pl-0 pr-0 ml-3 mr-3"
              style={{ display: "inline-flex" }}
            ><select id="search_type" className="search-form-control  p-0" style={{background: "#f56f36", color: "white",border:"0px",borderRadius: '0px', borderRight: '0px',width:'86px !important'}}>
            <option value="coupons">Coupons</option>
            <option value="deals">Deals</option>
          </select>
              <Typeahead
                style={{ width: "inherit !important" }}
                id={"my-typehead-header"}
                minLength={1}
                onFocus={this.loadCategories}
                onChange={(selected) => {
                  this.setState({ selected });
                }}
                options={this.state.categories}
                selected={this.state.selected}
                placeholder="Search Coupons & Deals ..."
                ref={ref}
                className="col-md-8"
              />
             
        
              {/* <button
                onClick={this.handleClick}
                className=" btn btn-info"
                type="button"
                style={{ borderRadius: "0px" }}
              >
                <i className="fa fa-search fa-2"></i>
              </button> */}
              <IconButton aria-label="Search"  onClick={this.handleClick}>
<SearchIcon />
</IconButton>
              
            </form>
          </nav>
          {/* <div className="container">
          <nav
            className="navbar navbar-expand-lg navbar-light "
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
              // borderTop: "1px solid #efefef",
            }}
          >
            
          </nav>
        </div> */}

        </div>
    );
  }
}
export default Header;

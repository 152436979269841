import React, { PureComponent } from "react";

class AboutUs extends PureComponent {
  render() {
    return (
      <div>
            <h4>
                About Us
                </h4>
                <br></br>
        <div className="row h-100 align-items-center ">
      
          <div className="col-lg-6">
              
            <p className="font-italic text-muted mb-4">
           
            {window.$sitename}.com is the web's most popular rewards program that gives
              you free coupons and deals , inluding gift cards and cash backs
              for the everyday things you already do online. Earn points when
              you shop at your favorite retailers, watch entertaining videos,
              search the web, answer surveys and find great deals. Redeem points
              for gift cards to your favorite retailers like Amazon and Walmart
              or get cash back from Paypal. {window.$sitename} has already paid out over
              $438,937,760 in cash and free gift cards. Put cash back in your
              wallet. Join for free today. For more information please visit
              http://www.dealssite.softwarepattern.net.
            </p>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <img
              src="https://cdn.shopify.com/s/files/1/0066/4122/1716/articles/best_coupons_and_deals.png?v=1557213193"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
        <div className="bg-white py-5">
          <div className="container py-5">
            <div className="row align-items-center mb-5">
              <div className="col-lg-6 order-2 order-lg-1">
                <h4>
                Our Mission
                </h4>
                <p className="font-italic text-muted mb-4">
                Simply put, our mission is to help you save money everyday. Built on best-of-breed technology, we offer the best collection of coupons available online to help you find the best savings opportunities. At Savings.com, you are in control of a vast array of resources to help you find coupons and save money. From big-ticket items like desktop computers and jewelry to electronics, toys and gifts, we help you search for the best deals available on the products and services you want. It’s that simple.
                </p>
                
              </div>
              <div className="col-lg-5 px-5 mx-auto order-1 order-lg-2">
                <img
                alt="Mission"
                  src="https://res.cloudinary.com/mhmd/image/upload/v1556834139/img-1_e25nvh.jpg"
                  alt=""
                  className="img-fluid mb-4 mb-lg-0"
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-5 px-5 mx-auto">
                <img
                 alt="What we do?"
                  src="https://res.cloudinary.com/mhmd/image/upload/v1556834136/img-2_vdgqgn.jpg"
                  alt=""
                  className="img-fluid mb-4 mb-lg-0"
                />
              </div>
              <div className="col-lg-6">
                <h4>
                  What do we do?
                </h4>
                <p className="font-italic text-muted mb-4">
                We are a team of experts who are constantly innovating and coming up with new ways for our customers to save time and money. We are constantly striving to make sure that you get the best deals, the best of the best discounts and the best ever codes when you shop online. So we bring to you the most costly gadgets, fashion accessories, delicious food, health products, sports items, household products and the most desirable travel destinations in the best possible prices.
                </p>
               
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light py-5">
          <div className="container ">
            <div className="row mb-4">
              <div className="col-lg-5">
                <h4>Our team</h4>
                <p className="font-italic text-muted">
                  Meet the team.
                </p>
              </div>
            </div>
            <div className="row text-center">
              {/* Team item*/}
              <div className="col-md-3 mb-5">
                <div className="bg-white rounded shadow-sm py-5 px-4">
                  <img
                   title="Team 2"
                   alt="Our Team 2"
                    src="https://res.cloudinary.com/mhmd/image/upload/v1556834132/avatar-4_ozhrib.png"
                    alt=""
                    width={100}
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">Manuella Nevoresky</h5>
                  <span className="small text-uppercase text-muted">
                    CEO - Founder
                  </span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End*/}
              {/* Team item*/}
              <div className="col-md-3 mb-5">
                <div className="bg-white rounded shadow-sm py-5 px-4">
                  <img
                      title="Team 1"
                      alt="Our Team 1"
                    src="https://res.cloudinary.com/mhmd/image/upload/v1556834130/avatar-3_hzlize.png"
                    alt=""
                    width={100}
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">Samuel Hardy</h5>
                  <span className="small text-uppercase text-muted">
                    CEO - Founder
                  </span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End*/}
              {/* Team item*/}
              <div className="col-md-3 mb-5">
                <div className="bg-white rounded shadow-sm py-5 px-4">
                  <img
                    title="Team"
                    alt="Our Team"
                    src="https://res.cloudinary.com/mhmd/image/upload/v1556834133/avatar-2_f8dowd.png"
                    alt=""
                    width={100}
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">Tom Sunderland</h5>
                  <span className="small text-uppercase text-muted">
                    CEO - Founder
                  </span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End*/}
              {/* Team item*/}
              <div className="col-md-3 mb-5">
                <div className="bg-white rounded shadow-sm py-5 px-4">
                  <img
                    src="https://res.cloudinary.com/mhmd/image/upload/v1556834133/avatar-1_s02nlg.png"
                    alt=""
                    width={100}
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">John Tarly</h5>
                  <span className="small text-uppercase text-muted">
                    CEO - Founder
                  </span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;

import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { isBrowser, isTablet } from "react-device-detect";
import Skeleton from "react-loading-skeleton";

import TopDeals from "./TopDeals";
import TopOffers from "./TopOffers";
import TopStores from "./TopStores";
import TopSales from "./TopSales";
import SliderCoupons from "./SliderCoupons";
import HomeCashBack from "./HomeCashBack";
import HomeCategories from "./HomeCategories";
import HomeAdsCoupons from "./HomeAdsCoupons";
import SubscriptionSection from "./SubscriptionSection";
import Advertisement from "./Advertisement";

class Home extends PureComponent {
  constructor(props) {
    var shown_popup = localStorage.getItem("subscription_popup");
    var popup = false;
    if (JSON.parse(shown_popup)) {
      popup = JSON.parse(shown_popup).popup;
    }

    super(props);
    this.state = {
      show_cats: false,
      error: false,
      error_message: "",
      error_type: "",
      open: !true,
      total_deals: 0,
      total_coupons: 0,
      total_brands: 0,
      total_retailers: 0,
      total_stores: 0,
      colors: this.shuffle([
        "#212F3C",
        "#ff9900",
        "#0B6B90",
        "#22A9DD",
        "#6BCB6B",
        "#CD4CC3",
        "#E61212",
        "#e26b7f",
        "#605F34",
        "#f1c500",
        "#4E0404",
        "#8175c7",
      ]),
      colors2: [
        "#E61212",
        "#e26b7f",
        "#605F34",
        "#212F3C",
        "#ff9900",
        "#0B6B90",
        "#22A9DD",
        "#6BCB6B",
        "#CD4CC3",
        "#f1c500",
        "#4E0404",
        "#8175c7",
        "#212F3C",
        "#ff9900",
        "#0B6B90",
        "#22A9DD",
        "#6BCB6B",
        "#CD4CC3",
        "#E61212",
        "#e26b7f",
        "#605F34",
        "#f1c500",
        "#4E0404",
        "#8175c7",
        "#212F3C",
        "#ff9900",
        "#0B6B90",
        "#22A9DD",
        "#6BCB6B",
        "#CD4CC3",
        "#E61212",
        "#e26b7f",
        "#605F34",
        "#f1c500",
        "#4E0404",
        "#8175c7",
      ],
      colors3: [
        "#CD4CC3",
        "#f1c500",
        "#4E0404",
        "#8175c7",
        "#212F3C",
        "#ff9900",
        "#0B6B90",
        "#22A9DD",
        "#6BCB6B",
        "#CD4CC3",
        "#E61212",
        "#e26b7f",
        "#605F34",
        "#f1c500",
        "#4E0404",
        "#8175c7",
        "#212F3C",
        "#E61212",
        "#e26b7f",
        "#605F34",
        "#212F3C",
        "#ff9900",
        "#0B6B90",
        "#22A9DD",
        "#6BCB6B",
        "#ff9900",
        "#0B6B90",
        "#22A9DD",
        "#6BCB6B",
        "#CD4CC3",
        "#E61212",
        "#e26b7f",
        "#605F34",
        "#f1c500",
        "#4E0404",
        "#0B6B90",
        "#22A9DD",
        "#6BCB6B",
        "#8175c7",
      ],
    };
    this.checkShowCats = this.checkShowCats.bind(this);
    this.subscriptionRequest = this.subscriptionRequest.bind(this);
    this.sendSubscribed = this.sendSubscribed.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.shuffle = this.shuffle.bind(this);
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  sendSubscribed(email, alert_preference, alert_category) {
    axios
      .post(
        `http://adscombined.com/v1/public/api/Subscribe?email=${encodeURIComponent(
          email
        )}&alert_preference=${encodeURIComponent(
          alert_preference
        )}&alert_category=${encodeURIComponent(alert_category)}`
      )
      .then(
        (response) => {
          console.log(response.data);
          if (response.data.flag) {
            this.setState({
              error: true,
              error_type: "success",
              error_message: response.data.message,
            });
            localStorage.setItem(
              "subscription_popup",
              JSON.stringify({ popup: true })
            );
          } else {
            this.setState({ loading: false });
            this.setState({
              error: true,
              error_type: "success",
              error_message: "unable to subscribe at the moment.",
            });
          }
        },
        (error) => {
          this.setState({ loading: false });
          this.setState({
            error: true,
            error_type: "success",
            error_message: error.response.data.error["email"],
          });
        }
      );
  }

  openModal(coupon) {
    this.setState({ open: true, sepecial_coupon: coupon });
  }

  closeModal() {
    this.setState({ open: false });
  }

  subscriptionRequest() {
    if (this.state.error) {
      this.setState({ error: false });
    }
    var email = document.getElementById("email").value;
    var alert_preference = document.getElementById("alert_preference").value;
    var alert_category = "";

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      if (email && email != "" && alert_preference && alert_preference != "") {
        if (alert_preference && alert_preference == "coupons") {
          alert_category = document.getElementById("alert_category").value;
          if (!alert_category || alert_category == "") {
            this.setState({
              error: true,
              error_type: "danger",
              error_message:
                "For 'Coupons' as your alert preference, Favourite Category is required",
            });
          }

          this.sendSubscribed(email, alert_preference, alert_category);
        } else {
          this.sendSubscribed(email, alert_preference, alert_category);
        }
      } else {
        this.setState({
          error: true,
          error_type: "danger",
          error_message:
            "Email and Alert Preference are required for subscription",
        });
      }
    } else {
      this.setState({
        error: true,
        error_type: "danger",
        error_message:
          "Invalid email, please enter a valid email i.e. abc@mail.com",
      });
    }
  }

  checkShowCats() {
    var choice = document.getElementById("alert_preference").value;
    if (choice && choice == "coupons") {
      this.setState({ show_cats: true });
    } else {
      this.setState({ show_cats: false });
    }
  }

  componentDidMount() {
    // var counts = localStorage.getItem("all_counts");
    // if(!counts){

    axios
      .get(`http://adscombined.com/v1/public/api/AllCounts`)
      .then((response) => {
        if (response.data.flag) {
          this.setState({
            total_deals: response.data.total_deals,
            total_coupons: response.data.total_coupons,
            total_stores: response.data.total_stores,
            total_brands: response.data.total_brands,
            total_retailers: response.data.total_retailers,
          });
          // localStorage.setItem("all_counts",JSON.stringify(response.data));
        }
      });

    // }else{
    //   var site_counts = JSON.parse(counts);
    //   this.setState({

    //     total_deals:site_counts.total_deals,
    //     total_coupons:site_counts.total_coupons,
    //     total_stores:site_counts.total_stores,
    //     total_brands:site_counts.total_brands
    //   })
    // }
  }

  render() {
    const contentStyle = {
      width: isBrowser
        ? "60% !important"
        : isTablet
        ? "70% !important"
        : "90% !important",
    };
    return (
      <div>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          {...{ contentStyle }}
        >
          <div className="modal">
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
            <div className="row" style={{ padding: "20px", color: "#3a3939" }}>
              {" "}
              <div className="col-md-2"></div>
              <div className="col-md-8 pt-5 ">
                <center>
                  <div className="p-10">
                    <h2>
                      <b>Never Miss Offers!!!</b>
                    </h2>
                    <p>
                      Subscribe using email for up to the minute{" "}
                      <Link to={"/coupons/all"} title="All Coupons">
                        offers
                      </Link>{" "}
                      and{" "}
                      <Link to={"/deals"} title="All Deals">
                        deals
                      </Link>
                      .
                    </p>
                    <br></br>
                    <p>Login to update your subscription.</p>
                  </div>
                </center>

                {this.state.error ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className={
                          "alert alert-" +
                          this.state.error_type +
                          " alert-dismissible fade show"
                        }
                        role="alert"
                      >
                        {this.state.error_message}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}

                <div className="row" style={{ textAlign: "left !important" }}>
                  <div
                    className=" form-group  col-md-12"
                    style={{ textAlign: "left !important" }}
                  >
                    <TextField
                      id="email"
                      label="Your Email Address"
                      color="green"
                      variant="outlined"
                      style={{ background: "white", width: "100%" }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <b>Alert Preference</b>
                    <select
                      id="alert_preference"
                      className=" form-control"
                      onChange={this.checkShowCats}
                    >
                      <option value="deals">Deals</option>
                      <option value="coupons">Coupons</option>
                    </select>
                  </div>
                  {this.state.show_cats ? (
                    <div className="form-group col-md-12">
                      <b>Choose Favourite Category</b>
                      <select id="alert_category" className=" form-control">
                        {this.props.categories.map((category) => (
                          <option value={category.Category}>
                            {category.Category}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <center>
                      <br />
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={this.subscriptionRequest}
                      >
                        Subscribe
                      </button>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </Popup>
        <Helmet>
          <title>Home | {window.$sitename}</title>
          <meta
            name="description"
            content={
              "In " +
              window.$sitename +
              " you can find best Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks and Seasonal Coupons."
            }
          />
          <meta
            name="keywords"
            content={
              "Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks, Seasonal Coupons"
            }
          />
        </Helmet>
        <section className="main-container">
          <div className="container" style={{ padding: "0px" }}>
            <div className="row">
              <div className="col-md-12 ">
                <center>
                  <br />

                  {this.state.total_coupons || this.state.total_deals ? (
                    <h1
                      className="ac-h1"
                      title={
                        this.state.total_coupons +
                        " Coupons And " +
                        this.state.total_deals +
                        " Deals from " +
                        this.state.total_stores +
                        " online Stores and " +
                        this.state.total_brands +
                        " Brands"
                      }
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                    >
                      {this.state.total_coupons ?
                      <Link
                        to={"/coupons/all"}
                        title="All Coupons"
                        style={{
                          fontSize: "2.2rem",
                          fontStyle: "normal",
                          fontFamily: "cursive",
                          fontFamily: "'Knewave', cursive",
                          textDecoration: "none",
                        }}
                      >
                        <b>{this.state.total_coupons}&nbsp;Coupons</b>
                      </Link> : ""}
                      &nbsp;
                    
                      {this.state.total_deals && this.state.total_coupons?  <span style={{ fontFamily: " 'Finger Paint', cursive" }}>
                        {" "}
                        &{" "}
                      
                      </span>  :""}
                      &nbsp;
                     {this.state.total_deals  ?  <Link
                        to={"/deals"}
                        title="All Deals"
                        style={{
                          fontSize: "2.2rem",
                          fontStyle: "normal",
                          fontFamily: "cursive",
                          fontFamily: "'Knewave', cursive",
                          textDecoration: "none",
                        }}
                      >
                        <b>{this.state.total_deals} Deals</b>
                      </Link> : ""} 
                      <span style={{ fontFamily: " 'Finger Paint', cursive" }}>
                        &nbsp;from{" "}
                      </span>
                      <Link
                        to={"/onlinestores"}
                        style={{ fontFamily: " 'Finger Paint', cursive" }}
                        title="Online Stores"
                      >
                        {" "}
                        {this.state.total_stores} Online Stores
                      </Link>{" "}
                      <span style={{ fontFamily: " 'Finger Paint', cursive" }}>
                        {" "}
                        ,{" "}
                      </span>
                      <Link
                        style={{ fontFamily: "'Carter One', cursive" }}
                        to={"/onlinebrands"}
                        title="Online Brands"
                      >
                        {this.state.total_brands} Brands
                      </Link>
                      <span style={{ fontFamily: " 'Finger Paint', cursive" }}>
                        {" "}
                        &{" "}
                      </span>
                      <Link
                        style={{ fontFamily: "'Carter One', cursive" }}
                        to={"/onlineretailers"}
                        title="Online Retailers"
                      >
                        {this.state.total_retailers} Retailers
                      </Link>
                    </h1>
                  ) : (
                    <center>
                      <h1>
                        <Skeleton height={40} width={130}></Skeleton>&nbsp;{" "}
                        <Skeleton height={40} width={140}></Skeleton>
                        &nbsp;<Skeleton height={35} width={110}></Skeleton>
                        &nbsp;<Skeleton height={35} width={160}></Skeleton>
                      </h1>
                    </center>
                  )}
                </center>

                <center>
                  <br />
                  <div className="img-responsive col-md-12">
                    <Advertisement ad_type="full width"></Advertisement>
                  </div>
                  <br></br>
                </center>
              </div>
            </div>

            <div className="col-md-12">
              {isBrowser ? (
                <HomeCategories categories={this.props.categories} />
              ) : (
                <span></span>
              )}

              {/* <TopOffers color={this.state.colors[0]} coupon_type={"Coupon"} /> */}
              <SliderCoupons
                color={this.state.colors[4]}
                colors={this.state.colors2}
                coupon_type={["/coupons/coupon", "Coupon"]}
              />

              <HomeCashBack
                color={this.state.colors[2]}
                coupon_type={["/coupons/cash-back", "Cash Back"]}
              />

              <TopOffers
                color={this.state.colors[3]}
                coupon_type={["/coupons/bogo", "BOGO"]}
              />

              <HomeAdsCoupons
                color={this.state.colors[1]}
                coupon_type={["/coupons/event", "Event"]}
              />
              <center>
                <div className="img-responsive col-md-12">
                  <br></br>
                  <Advertisement ad_type="full width"></Advertisement>
                </div>
              </center>
              <TopSales
                color={this.state.colors[4]}
                colors={this.state.colors3}
                coupon_type={["/coupons/sale", "Sale"]}
              />

              <TopDeals />

              <center>
                <br></br>
                <div className="img-responsive col-md-12">
                  <Advertisement ad_type="full width"></Advertisement>
                </div>
                <br></br>
              </center>

              <TopStores />
            </div>
          </div>
        </section>
        <SubscriptionSection
          categories={this.props.categories}
        ></SubscriptionSection>
      </div>
    );
  }
}

export default React.memo(Home);

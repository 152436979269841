import React, { PureComponent } from "react";
import axios from "axios";
import Toast from "light-toast";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import ShowMore from "@tedconf/react-show-more";
import { isMobile, isBrowser, isTablet } from "react-device-detect";

import CouponTypeDisplay from "./CouponTypeDisplay";
import HomeCashbackSkeleton from "./skeletons/HomeCashbackSkeleton";
import AddtoFavourite from "./AddtoFavourite";

import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();

class HomeCashBack extends PureComponent {
  state = {
    loading: false,
    coupons: [],
    sepecial_coupon: [],
    sc_id: "",
    open: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      coupons: [],
      sepecial_coupon: [],
      sc_id: "",
      open: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);
    this.Capitalize = this.Capitalize.bind(this);
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(coupon) {
    if (this.state.sepecial_coupon.length < 1) {
      this.setState({ open: true, sepecial_coupon: coupon });
    }
  }

  closeModal() {
    this.setState({ open: false });
  }

  loadMore() {
    this.setState((prev) => {
      return { visible: prev.visible + 12 };
    });
  }

  redirectToStore(coupon) {
    Toast.info(
      <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {coupon.StoreURL}
      </div>,
      3000,
      () => {
        window.open(
          window.location.href.split("?")[0] + "?c=" + coupon.id,
          "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
          //this.openModal(coupon)
          (window.location.href = coupon.SourceUrl),
          2000
        );
      }
    );
  }

  componentDidMount() {
    var user_id = "";
    if(user ){
        user_id = "&user_id="+user.id;
    }
    const params = new URLSearchParams(window.location.search);
    if (params.has("c")) {
      var sc_id = "";
      sc_id = params.get("c");
    
      axios
        .get(
          `http://adscombined.com/v1/public/api/CouponDetails?coupon_id=${encodeURIComponent(
            sc_id
          )}${user_id}`
        )
        .then(
          (response) => {
            if (response.data.flag) {
              if (response.data.coupon_data) {
                const sepecial_coupon = response.data.coupon_data;

                this.openModal(sepecial_coupon);
              }
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    this.setState({ loading: true }, () => {
      axios
        .post(
          `http://adscombined.com/v1/public/api/FilterCoupons?filter=${encodeURIComponent(
            this.props.coupon_type[1]
          )}&Page=0&PerPage=15&Filter=${encodeURIComponent(
            this.props.coupon_type[1]
          )}${user_id}`
        )
        .then(
          (response) => {
            if (response.data.coupons) {
              var couponss = response.data.coupons.slice(0,6);
              // for(var i = 0; i<30; i++){

              //   if(i%2==0){
              //     couponss.push(couponss[0]);
              //   }else{
              //     couponss.push(couponss[1]);
              //   }

              // }
              if (!isBrowser && !isTablet) {
                var mobile_size = couponss.length <= 4 ? couponss.length : 4;
                couponss = couponss.slice(0, parseInt(mobile_size));
              }
              this.setState({ coupons: couponss });
              this.setState({ loading: false });
            } else {
              this.setState({ loading: false });
            }
          },
          (error) => {
            this.setState({ loading: false });
            console.log(error);
          }
        );
    });
  }

  shorten(text, max) {
    return text && text.length > max
      ? text.slice(0, max).split(" ").slice(0, -1).join(" ")
      : text;
  }

  render() {
    const contentStyle = {
      width: isBrowser
        ? "70% !important"
        : isTablet
        ? "70% !important"
        : "90% !important",
    };
    return (
      <div>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          {...{ contentStyle }}
        >
          <div className="modal">
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
            <div class="row" style={{ padding: "10px" }}>
              <div className="col-md-4 pl-0">
                <center>
                  <img
                    title={
                      this.state.sepecial_coupon.StoreName +
                      " Coupons, Promotion Codes & Deals"
                    }
                    alt={
                      this.state.sepecial_coupon.StoreName +
                      " Coupons, Promotion Codes & Deals"
                    }
                    src={
                      this.state.sepecial_coupon.Logo
                        ? window.$logos_url +
                          this.state.sepecial_coupon.Logo.replace(".PNG",".png")
                        : process.env.PUBLIC_URL + "/default_logo.png"
                    }
                    width="100"
                    height="100"
                    id="store_logo"
                  ></img>
                  <h5
                    title={
                      this.state.sepecial_coupon.StoreName +
                      " Coupons, Promotion Codes & Deals"
                    }
                  >
                    <b style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </b>
                  </h5>
                </center>
              </div>

              <div className="col-md-8 p-3" style={{ padding: "inherit" }}>
              <AddtoFavourite type={"heart"} source={"shomecash"} favourite_id = {this.state.sepecial_coupon.favourite_id} id={this.state.sepecial_coupon.id} favourite_type={"coupon"} is_favourite={this.state.sepecial_coupon.favourite} />
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "teal",
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.sepecial_coupon.Offer &&
                  this.state.sepecial_coupon.Offer !== "none" &&
                  this.state.sepecial_coupon.Offer !== "None" ? (
                    <CouponTypeDisplay
                      source="couponpage"
                      coupontype={this.state.sepecial_coupon.Offer}
                    />
                  ) : (
                    <CouponTypeDisplay
                      source="couponpage"
                      coupontype={this.state.sepecial_coupon.Type}
                    />
                  )}
                </span>

                <h4 title={this.state.sepecial_coupon.Title}>
                  <span id="coupon_title">
                    {this.state.sepecial_coupon.Title}
                  </span>
                </h4>

                <br />
                <b></b>
                {this.state.sepecial_coupon.Code &&
                this.state.sepecial_coupon.Code != "None" ? (
                  <span>
                    <input
                      id="code-value"
                      type="text"
                      className="code-copy"
                      value={this.state.sepecial_coupon.Code}
                      style={{
                        borderRadius: "0px",
                        width: "fit-content",
                        cursor: "copy",
                      }}
                      readOnly
                    />

                    <span>
                      <button
                        className="btn"
                        onClick={this.myFunction}
                        onMouseOut={this.outFunc}
                        id="myTooltip"
                        style={{ verticalAlign: "inherit" }}
                      >
                        Copy Code
                      </button>
                    </span>

                    <br />
                    <br />
                  </span>
                ) : (
                  <div></div>
                )}

                <a href={this.state.sepecial_coupon.SourceUrl}>
                  <b>
                    {" "}
                    Continue to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </span>
                  </b>{" "}
                </a>
              </div>
            </div>
          </div>
        </Popup>
        
      
         <div className="card mt-3">
              <div className="card-body p-0">
        <div className="row "  style={{
              background: this.props.color}}>
          <div 
            className="col-md-2  d-flex justify-content-center align-items-center"  
            style={{
            
              padding:"0px"
            }}>
              <center >
                <h3>
                <Link
                    className="mt-2"
                    to={this.props.coupon_type[0]}
                    title={"View All " + this.props.coupon_type[1]} style={{width:"70%",color:"white",fontSize:"30px",fontFamily:"'Frijole', cursive"}}>
                  <b style={{fontSize:"55px"}}>Cash</b>
                  <p>
                  Back
                    </p>
                </Link>
                
                </h3>
            
              </center>
          </div>
          <div className="col-md-10 " style={{paddingTop:"10px", paddingBottom:"10px"}}>

          {isBrowser ? (
          this.state.loading ? (
            <HomeCashbackSkeleton title= {this.props.coupon_type[1]} items={[1, 2, 3, 4, 5, 6]} />
          ) : (
                <div className="row">
                  <ShowMore items={this.state.coupons} by={6}>
                    {({ current, onMore }) => (
                      <React.Fragment>
                        {this.state.coupons.length > 0 ? (
                          current.map((coupon) => (
                            <div
                              className="card-deck pl-1 pr-1 col-md-2 mt-2 clickable"
                              style={{}}
                              key={coupon.id}
                              title={coupon.Title}
                            >
                                <center>
                              <div
                                className="card  single-deal"
                                style={{ borderRadius: "5px",minHeight:"211px" }}
                              >
                                <AddtoFavourite type={"heart"} source={"homecash"} id={coupon.id} favourite_id = {coupon.favourite_id} favourite_type={"coupon"} is_favourite={coupon.favourite} />
                                {coupon.Logo &&
                                coupon.Logo != "Logo Name" &&
                                coupon.Logo != "none" ? (
                                    <center>
                                         <img
                                            alt={
                                            this.Capitalize(coupon.StoreName) +
                                            " Coupons, Promotion Codes & Deals"
                                            }
                                            className="lazy loaded mt-2 mb-1"
                                            width={90}
                                            height={90}
                                            src={window.$logos_url + coupon.Logo.replace(".PNG",".png")}
                                            style={{ objectFit: "contain" }}
                                            data-loaded="true"
                                        />
                                    </center>
                                 
                                ) : (
                                    <center>
                                  <img
                                  className="lazy loaded mt-2 mb-1 "
                                    width={90}
                                    height={90}
                                    src={process.env.PUBLIC_URL + "/default_logo.png"}
                                    alt={
                                      this.Capitalize(coupon.StoreName) +
                                      " Coupons, Promotion Codes & Deals"
                                    }
                                    data-loaded="true"
                                    style={{ objectFit: "contain" }}
                                  />
                                  </center>
                                )}
                                <div>
                                  
                                    <small
                                      onClick={() =>
                                        this.redirectToStore(coupon)
                                      }
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: "14px !important",
                                        color: this.props.color,
                                      }}
                                    >
                                      {coupon.Offer &&
                                      coupon.Offer != "None" &&
                                      coupon.Offer != "none" ? (
                                        <h5 style={{ fontWeight: 'bold', background: '#ece9e9', border: '2px solid #e0d9d9 !important',  width: 'fit-content', padding: '5px', borderRadius: '6px' }}>
                                          {coupon.Offer}
                                        </h5>
                                      ) : (
                                        <h5 style={{ fontWeight: 'bold', background: '#ece9e9', border: '2px solid #e0d9d9 !important',  width: 'fit-content', padding: '5px', borderRadius: '6px' }}>
                                          {coupon.Type}
                                        </h5>
                                      )}
                                    </small>
                                
                                </div>
                                <div
                                  className="card-body"
                                  style={{
                                    padding: "10px !important",
                                    padding: "0.8rem",
                                  }}
                                >
                                  <Link
                                    className="logo  cust_link featured"
                                    to={"coupons/" + coupon.StoreURL}
                                    title={
                                      coupon.StoreName +
                                      " Coupons, Promotion Codes & Deals"
                                    }
                                  ></Link>
                                  <div
                                    onClick={() => this.redirectToStore(coupon)}
                                    title={"Click to get " + coupon.Title}
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      marginTop: "0.25rem",
                                      marginBottom: "0.25rem !important",
                                      lineBreak:"anywhere",
                                      textAlign:"justify"
                                    }}
                                  >
                                    {" "}
                                    {coupon.Title && coupon.Title.length < 56
                                      ? coupon.Title
                                      : this.shorten(coupon.Title, 56) + ".."}
                                  </div>

                                  <p>
                                    <Link
                                      to={
                                        "/coupons/" +
                                        window.$categories[coupon.Category]
                                      }
                                      style={{
                                        position: "absolute",
                                        bottom: "5px",
                                      }}
                                      title={
                                        coupon.Category +
                                        " Coupons & Promotion Codes"
                                      }
                                    >
                                      <small>{coupon.Category}</small>
                                    </Link>
                                  </p>
                                </div>
                              </div>
                              </center>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <p>No {this.props.coupon_type[1]} available.</p>
                          </div>
                        )}
                        {!onMore ? (
                          <div className="col-md-12 mt-2">
                            {/* <center>
                              <Link
                                to={this.props.coupon_type[0]}
                                className="btn "
                                style={{
                                  background: this.props.color,
                                  color: "white",
                                }}
                              >
                                View All {this.props.coupon_type[1]}
                              </Link>
                            </center> */}
                          </div>
                        ) : (
                          <div className="col-md-12 mt-2">
                            <center>
                              <button
                                className="btn "
                                style={{
                                  background: this.props.color,
                                  color: "white",
                                }}
                                disabled={!onMore}
                                onClick={() => {
                                  if (!!onMore) onMore();
                                }}
                              >
                                Show More
                              </button>
                            </center>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </ShowMore>
                </div>
          )
        ) : isTablet ? (
          this.state.loading ? (
            <HomeCashbackSkeleton title= {this.props.coupon_type[1]} items={[1, 2, 3, 4]} />
          ) : (
            
                <div className="row">
                  <ShowMore items={this.state.coupons} by={18}>
                    {({ current, onMore }) => (
                      <React.Fragment>
                        {this.state.coupons.length > 0 ? (
                          current.map((coupon) => (
                            <div
                            className="card-deck pl-1 pr-1 col-md-2 mt-2 clickable"
                            style={{}}
                            key={coupon.id}
                            title={coupon.Title}
                          >
                              <center>
                            <div
                              className="card  single-deal"
                              style={{ borderRadius: "5px",minHeight:"211px" }}
                            >
                              <AddtoFavourite type={"heart"} source={"homecash"} favourite_id = {coupon.favourite_id} id={coupon.id} favourite_type={"coupon"} is_favourite={coupon.favourite}/>
                              {coupon.Logo &&
                              coupon.Logo != "Logo Name" &&
                              coupon.Logo != "none" ? (
                                  <center>
                                       <img
                                          alt={
                                          this.Capitalize(coupon.StoreName) +
                                          " Coupons, Promotion Codes & Deals"
                                          }
                                          className="lazy loaded mt-2 mb-1"
                                          width={90}
                                          height={90}
                                          src={window.$logos_url + coupon.Logo.replace(".PNG",".png")}
                                          style={{ objectFit: "contain" }}
                                          data-loaded="true"
                                      />
                                  </center>
                               
                              ) : (
                                  <center>
                                <img
                                className="lazy loaded mt-2 mb-1"
                                  width={90}
                                  height={90}
                                  src={process.env.PUBLIC_URL + "/default_logo.png"}
                                  alt={
                                    this.Capitalize(coupon.StoreName) +
                                    " Coupons, Promotion Codes & Deals"
                                  }
                                  data-loaded="true"
                                  style={{ objectFit: "contain" }}
                                />
                                </center>
                              )}
                              <div>
                                
                                  <small
                                    onClick={() =>
                                      this.redirectToStore(coupon)
                                    }
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "14px !important",
                                      color: this.props.color,
                                    }}
                                  >
                                    {coupon.Offer &&
                                    coupon.Offer != "None" &&
                                    coupon.Offer != "none" ? (
                                      <h5 style={{ fontWeight: 'bold', background: '#ece9e9', border: '2px solid #e0d9d9 !important',  width: 'fit-content', padding: '5px', borderRadius: '6px' }}>
                                        {coupon.Offer}
                                      </h5>
                                    ) : (
                                      <h5 style={{ fontWeight: 'bold', background: '#ece9e9', border: '2px solid #e0d9d9 !important',  width: 'fit-content', padding: '5px', borderRadius: '6px' }}>
                                        {coupon.Type}
                                      </h5>
                                    )}
                                  </small>
                              
                              </div>
                              <div
                                className="card-body"
                                style={{
                                  padding: "10px !important",
                                  padding: "0.8rem",
                                }}
                              >
                                <Link
                                  className="logo  cust_link featured"
                                  to={"coupons/" + coupon.StoreURL}
                                  title={
                                    coupon.StoreName +
                                    " Coupons, Promotion Codes & Deals"
                                  }
                                ></Link>
                                <div
                                  onClick={() => this.redirectToStore(coupon)}
                                  title={"Click to get " + coupon.Title}
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    marginTop: "0.25rem",
                                    marginBottom: "0.25rem !important",
                                    lineBreak:"anywhere",
                                    textAlign:"justify"
                                  }}
                                >
                                  {" "}
                                  {coupon.Title && coupon.Title.length < 56
                                    ? coupon.Title
                                    : this.shorten(coupon.Title, 56) + ".."}
                                </div>

                                <p>
                                  <Link
                                    to={
                                      "/coupons/" +
                                      window.$categories[coupon.Category]
                                    }
                                    style={{
                                      position: "absolute",
                                      bottom: "5px",
                                    }}
                                    title={
                                      coupon.Category +
                                      " Coupons & Promotion Codes"
                                    }
                                  >
                                    <small>{coupon.Category}</small>
                                  </Link>
                                </p>
                              </div>
                            </div>
                            </center>
                          </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <p>No Offers available.</p>
                          </div>
                        )}
                        {!onMore ? (
                          <div className="col-md-12 mt-2">
                            {/* <center>
                              <Link
                                to={this.props.coupon_type[0]}
                                className="btn "
                                style={{
                                  background: this.props.color,
                                  color: "white",
                                }}
                              >
                                View All {this.props.coupon_type[1]}
                              </Link>
                            </center> */}
                          </div>
                        ) : (
                          <div className="col-md-12 mt-2">
                            <center>
                              <button
                                className="btn"
                                style={{
                                  background: this.props.color,
                                  color: "white",
                                }}
                                disabled={!onMore}
                                onClick={() => {
                                  if (!!onMore) onMore();
                                }}
                              >
                                Show More
                              </button>
                            </center>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </ShowMore>
                </div>
          )
        ) : this.state.loading ? (
          <HomeCashbackSkeleton title= {this.props.coupon_type[1]} items={[1]} />
        ) : (
         
              <div className="row">
              {this.state.coupons.length > 0 ? (
                this.state.coupons.map((coupon) => (
                    <div
                    className="card-deck pl-1 pr-1 col-md-2 mt-2 clickable"
                    style={{}}
                    key={coupon.id}
                    title={coupon.Title}
                  >
                      <center>
                    <div
                      className="card  single-deal"
                      style={{ borderRadius: "5px",minHeight:"211px" }}
                    >
                      <AddtoFavourite type={"heart"} id={coupon.id} source={"homecash"} favourite_id = {coupon.favourite_id} favourite_type={"coupon"} is_favourite={coupon.favourite}/>
                      {coupon.Logo &&
                      coupon.Logo != "Logo Name" &&
                      coupon.Logo != "none" ? (
                          <center>
                              <img
                                alt={
                                this.Capitalize(coupon.StoreName) +
                                " Coupons, Promotion Codes & Deals"
                                }
                                className="lazy loaded mt-2 mb-1"
                                width={90}
                                height={90}
                                src={window.$logos_url + coupon.Logo.replace(".PNG",".png")}
                                style={{ objectFit: "contain" }}
                                data-loaded="true"
                            />
                        </center>
                       
                      ) : (
                          <center>
                        <img
                        className="lazy loaded mt-2 mb-1"
                          width={90}
                          height={90}
                          src={process.env.PUBLIC_URL + "/default_logo.png"}
                          alt={
                            this.Capitalize(coupon.StoreName) +
                            " Coupons, Promotion Codes & Deals"
                          }
                          data-loaded="true"
                          style={{ objectFit: "contain" }}
                        />
                        </center>
                      )}
                      <div>
                        
                          <small
                            onClick={() =>
                              this.redirectToStore(coupon)
                            }
                            style={{
                              textTransform: "uppercase",
                              fontSize: "14px !important",
                              color: this.props.color,
                            }}
                          >
                            {coupon.Offer &&
                            coupon.Offer != "None" &&
                            coupon.Offer != "none" ? (
                              <h5 style={{ fontWeight: 'bold', background: '#ece9e9', border: '2px solid #e0d9d9 !important',  width: 'fit-content', padding: '5px', borderRadius: '6px' }}>
                                {coupon.Offer}
                              </h5>
                            ) : (
                              <h5 style={{ fontWeight: 'bold', background: '#ece9e9', border: '2px solid #e0d9d9 !important',  width: 'fit-content', padding: '5px', borderRadius: '6px' }}>
                                {coupon.Type}
                              </h5>
                            )}
                          </small>
                      
                      </div>
                      <div
                        className="card-body"
                        style={{
                          padding: "10px !important",
                          padding: "0.8rem",
                        }}
                      >
                        <Link
                          className="logo  cust_link featured"
                          to={"coupons/" + coupon.StoreURL}
                          title={
                            coupon.StoreName +
                            " Coupons, Promotion Codes & Deals"
                          }
                        ></Link>
                        <div
                          onClick={() => this.redirectToStore(coupon)}
                          title={"Click to get " + coupon.Title}
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem !important",
                            lineBreak:"anywhere",
                            textAlign:"justify"
                          }}
                        >
                          {" "}
                          {coupon.Title && coupon.Title.length < 50
                            ? coupon.Title
                            : this.shorten(coupon.Title, 50) + ".."}
                        </div>

                        <p>
                          <Link
                            to={
                              "/coupons/" +
                              window.$categories[coupon.Category]
                            }
                            style={{
                              position: "absolute",
                              bottom: "5px",
                            }}
                            title={
                              coupon.Category +
                              " Coupons & Promotion Codes"
                            }
                          >
                            <small>{coupon.Category}</small>
                          </Link>
                        </p>
                      </div>
                    </div>
                    </center>
                  </div>
                ))
               
              ) : (
                <div className="col-md-12">
                  <p>No Offers available.</p>
                </div>
              )}
               </div>
        )}
          </div>
        </div>
        </div>
        </div>
      </div>
    );
  }
}
export default React.memo(HomeCashBack);

import React,{PureComponent} from "react";
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import axios from "axios";
import {Link} from "react-router-dom";

class UnSubscribe extends PureComponent{

    constructor(props){
        super(props);

        this.state={
            is_unsubscirbed: false,
            error_type:'',
            message:'',
            show_reason_text:false,
            reason:"",
            email:"",
            feedback:false
        }
        this.handleRadio = this.handleRadio.bind(this);
        this.handleFeedback = this.handleFeedback.bind(this);
    }
    
    
    
    handleFeedback(){

        if(this.state.reason!=""){

            var reason = this.state.reason;

            if(reason == "other"){
                var text = document.getElementById("reason_text").value;
                if(text==""){
                    reason = "";
                    this.setState({error_type:"warning",message:"Please fill in the reason."});
                }else{
                    reason = text;
                }
            }
            if(reason !=""){
                axios
                .get(`http://3.131.110.247/AdsComAdmin/public/api/unsubscribe_feedback?email=${encodeURIComponent(this.state.email)}&reason=${encodeURIComponent(reason)}`)
                .then(
                (response) => {
                    if(response.data.flag){
                                    
                        this.setState({message:response.data.message,feedback:true});
                    } else {
                        this.setState({error_type:"danger",message:response.data.message});
                    }
                },
                (error) => {
                 
                    this.setState({error_type:"danger",message:error.response});
                }
                );
            }
            
        }else{
            this.setState({error_type:"warning",message:"Please select at least one reason."});
        }

    }


    handleRadio(reason_text){
        if(reason_text!=""){
            this.setState({reason:reason_text});
            if(reason_text=="other"){
                this.setState({show_reason_text:true});
            }else{
                this.setState({show_reason_text:false});
            }
        }
    }
    componentDidMount(){

        if(this.props.match.params.token!=""){

            axios
            .get(`http://3.131.110.247/AdsComAdmin/public/api/unsubscribe?subscription_token=${this.props.match.params.token}`)
            .then(
            (response) => {
                if(response.data.flag){
                    window.history.pushState({}, document.title, "/" );            
                    this.setState({error_type:"success",message:response.data.message,email:response.data.email});
                } else {
                    
                    this.setState({error_type:"danger",message:response.data.message});
                }
            },
            (error) => {
             
                this.setState({error_type:"danger",message:error.response});
            }
            );
        }
    }
    
    render(){

        return(

            <div>
            <section className="main-container">
                <div className="container" style={{ padding: "0px" }}>
                    <br />
                    

                    <div className="row pb-3">
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-6">
                            <div className="card">


                                <div className="card-body">


                                
                                <h3 className="p-3" style={{textAlign:"center",background:"",color:"#f56f36",fontWeight:"bold"}}>Unsubscribe </h3>
                                {this.state.feedback ? 
                                    <h4>{this.state.message}</h4>
                                :
                                <span>
                                    {this.state.error_type && this.state.message!="" ?
                                        <div  className={"alert alert-"+this.state.error_type+" alert-dismissible fade show"} role="alert">
                                            {this.state.message}
                                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                              <span aria-hidden="true">×</span>
                                          </button>
                                      </div>
                                    :
                                    <span>

                                    </span>
                                    }
                                   
                                    {this.state.error_type == "success" ||  this.state.error_type == "warning"?
                                        <div>
                                             <div className="col-md-12 pt-2 pb-2">
                                                You will no longer recieve emails from this mailing list. But if you want to resubscribe you can <Link to={"/login"}><b>Login</b></Link> to your account or {" "}
                                                <Link to={"/register"}><b>Register</b></Link> an account here.
                                                <br>
                                                </br>
                                                <br>
                                                </br>
                                                <b>
                                                If you have a moment please let us know why you unsubscribed:
                                                </b>
                                                <br></br>
                                            </div>
                                            <div className="col-md-8 pt-6 pb-2">
                                                <span className="ml-3"> 
                                                    <input type="radio" name="reason" onClick={()=>this.handleRadio("I no logner want to receive these emails.")}></input> I no logner want to receive these emails.
                                                </span>
                                                <br></br>
                                                <span className="ml-3">
                                                    <input type="radio" name="reason" onClick={()=>this.handleRadio("I never signed up for this mailing list")}></input> I never signed up for this mailing list.
                                                </span>
                                                <br></br>
                                                <span className="ml-3">
                                                    <input type="radio" name="reason" onClick={()=>this.handleRadio("The emails are inappropriate.")}></input> The emails are inappropriate.
                                                </span>
                                                <br></br>
                                                <span className="ml-3">
                                                    <input type="radio" name="reason" onClick={()=>this.handleRadio("The emails are spam and should be reported.")}></input> The emails are spam and should be reported.
                                                </span>
                                                <br></br>
                                                <span className="ml-3">
                                                    <input type="radio" name="reason" onClick={()=>this.handleRadio("other")}></input> Other (fill in the reason below)
                                                </span>
                                                <br></br>
                                                {this.state.show_reason_text?
                                                    <textarea id="reason_text" className="form-control ml-3 mt-2" cols="15" rows="5" placeholder="Other Reason"></textarea>
                                                :
                                                    <span></span>
                                                }
                                                <br></br>
                                            </div>
                                            
                                            <div className="col-md-4 pt-6 pb-2 ml-3">
                                                <button type="button" id="button-login" className="btn btn-info col-md-12"  
                                                 onClick={this.handleFeedback}
                                                >
                                                Submit
                                                </button>
                                            </div>
                                            
                                        </div>
                                    :
                                    <span></span>
                                    }
                                   </span>

                                }
                                </div>
                            </div>
                        </div>  
                        <div className="col-md-3">
                        </div>                      
                    </div>

                </div>
            </section>
        </div>
        )
    }
}

export default React.memo(UnSubscribe);
import React, { PureComponent } from "react";
import Skeleton from "react-loading-skeleton";

class CategoriesSkeleton extends PureComponent{

    render(){

        return(

            <div>
                <div className="no-decoration">
                    <Skeleton className="ml-2" width={80} height={40} count={3}></Skeleton>
                </div>
                <span className="mt-1">     
                    <Skeleton width={280}/>
                </span>
            </div>
        );
    }
}
export default CategoriesSkeleton;
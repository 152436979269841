import React from 'react';
import Layout from "./Layout";


import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  
  return (
    <div>
       <Router  >
     {/* {window.location.pathname == "/site_map" ? 
        <Route 
        path="/site_map" component={SiteMap}
      />
      :  */}
      <Layout></Layout> 
   
       
        
      </Router>
    </div>
  );
}

export default App;

import React, { PureComponent } from "react";
import axios from "axios";
import { isMobile, isBrowser,isTablet } from "react-device-detect";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import Advertisement from "./Advertisement";
import { BrowserRouter as Router, Switch, Route, Link ,withRouter} from "react-router-dom";


import StoreCategories from "./StoreCategories";
import StoreListSkeleton from "./skeletons/StoreListSkeleton";

class StoresList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total:0,
      stores: [],
      loading: false,
      count_filter: 0,
      
      selected_category: null,
      selected_subcategory: null,

      selected_filter: "",
      offset: 0,
      perPage: isBrowser ? 60 : isTablet ? 30 : 15,
      currentPage: 0,
      visible:20
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.getCategoryFromSlug = this.getCategoryFromSlug.bind(this);
  }
  
  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 20};
    });
  }

  getCategoryFromSlug(value) { 
    for (var prop in window.$categories) { 
      if (window.$categories.hasOwnProperty(prop)) { 
          if (window.$categories[prop] === value) 
          return prop; 
      } 
    } 
  }


  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  setSelectedFilter(filter) {
    this.setState({ selected_filter: filter,currentPage:0 });
    this.receivedData();
  }

  checkStartWith(filter, storeTitle) {
    if (filter.startsWith("0-9")) {
      for (var i = 0; i < 9; i++) {
        if (storeTitle.lastIndexOf(i, 0) === 0) {
          return true;
        }
      }
      return false;

    } else {
     
      storeTitle = storeTitle.toLowerCase();
      if (storeTitle.lastIndexOf(filter, 0) === 0) {
        return true;
      } else {
        return false;
      }
      //return storeTitle.startsWith(filter);
    }
  }

  componentDidMount() {
  
    this.receivedData();
  }

  receivedData() {
    var category_filter = "";
  
    if(this.props.match.params.category && this.props.match.params.category !=""){
     
      category_filter +=`&Category=${encodeURIComponent(this.getCategoryFromSlug(this.props.match.params.category) )}`;
      this.setState({selected_category:this.getCategoryFromSlug(this.props.match.params.category)});
    
    }

    if(this.props.match.params.sub_category && this.props.match.params.sub_category !=""){
      category_filter += `&SubCategory=${encodeURIComponent(this.getCategoryFromSlug(this.props.match.params.sub_category.toString()))}`;
      this.setState({selected_subcategory:this.getCategoryFromSlug(this.props.match.params.sub_category.toString()) })
    }
    
    
    this.setState({visible:20});    
    
    this.setState({ loading: true }, () => {

      axios.get(`http://adscombined.com/v1/public/api/AllStores?Type=${encodeURIComponent(this.props.type)}&Page=${encodeURIComponent(
                this.state.currentPage
              )}&PerPage=${encodeURIComponent(this.state.perPage)}&Filter=${encodeURIComponent(this.state.selected_filter)}`+category_filter).then(
        (response) => {
          if (response.data.stores) {
            const stores = response.data.stores;

            this.setState({
              total: response.data.total_stores,
              stores,
              pageCount: Math.ceil(
                response.data.total_stores / this.state.perPage
              ),
            });
            this.setState({ loading: false });
          } else {
            this.setState({
              total:0,
              stores:[],
              pageCount:0,
              loading: false });
            //document.getElementById("no_store_msg").style.display = "block";
          }
        },
        (error) => {
          this.setState({ loading: false });
          console.log(error);
        }
      );
    });
  }
  
  
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
    window.scrollTo(0, 0);
  };
  
  
  render() {
    const filters = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];

    const filterlist = filters.map((filter) => (
      <button
      key={filter.toString()}
      title={this.props.type+"s starting with '"+this.Capitalize(filter)+"'"}
        onClick={() => this.setSelectedFilter(filter)}
        style={{ cursor: "pointer" }}
        className={this.state.selected_filter == filter ? "teal pill invert mr-1  ml-1 mt-2 " : "pill invert mr-1  ml-1 mt-2"}
      >
        <span style={{ whiteSpace: "nowrap" }}>{filter}</span>
      </button>
    ));

    var  count = 0;
    
    return (
      <div>
        
               
           
        <Helmet>
          <title>All {this.props.type}s | {window.$sitename}</title>
          <meta
            name="description"
            content={
              "In " +
              window.$sitename +
              " you can find best Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks and Seasonal Coupons."
            }
          />
          <meta
            name="keywords"
            content={
              "Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks, Seasonal Coupons"
            }
          />
        </Helmet>
        <section className="main-container">
          <div className="container " style={{ padding: "0px" }}>
            
          <div className="col-md-12 mb-3">
              <Advertisement  ad_type="full width"></Advertisement>
            </div>

            <div className="row ">
              <div className="col-md-8 ">
                <nav aria-label="breadcrumb">
                  <ol
                    className="breadcrumb"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <li className="breadcrumb-item">
                      <Link to="">Home</Link>
                    </li>

                    {this.state.selected_category && this.state.selected_category!=""  || this.state.selected_subcategory && this.state.selected_subcategory!=""  ? 

                      (
                      <li className="breadcrumb-item " aria-current="page">
                        <a href={this.props.type == "Brand" ? "/onlinebrands" :  ( this.props.type == "Retailer" ? "/onlineretailers" : "/onlinestores") } >
                          All {" "+this.props.type+"s"} 
                        </a>
                      </li>
                      )
                    :
                      <li className="breadcrumb-item active" aria-current="page">
                        All {" "+this.props.type+"s"} 
                      </li>
                    }
                    {this.state.selected_category && this.state.selected_category!="" ?
                       (
                        <li className={"breadcrumb-item "+(this.state.selected_subcategory && this.state.selected_subcategory!="" ? "": "active")} aria-current="page">
                         {!this.state.selected_subcategory? 
                           this.state.selected_category
                          :
                          <a href={this.props.type == "Brand" ? "/onlinebrands" :  ( this.props.type == "Retailer" ? "/onlineretailers" : "/onlinestores")+"/"+window.$categories[this.state.selected_category]  } >
                            {this.state.selected_category}
                          </a>
                          }
                         
                        </li>
                        )
                        :""
                    }

                    { this.state.selected_subcategory && this.state.selected_subcategory!="" ?
                       (
                        <li className="breadcrumb-item active" aria-current="page">
                         
                            {this.state.selected_subcategory}
                          
                        </li>
                        )
                        :""
                    }

                  </ol>
                </nav>
              </div>
              <div className="col-md-9 "></div>
            </div>
          
            <div className="row ">
          
              <div className="col-md-8 ">
              <h1 className="ac-h1" title={this.props.type+"'s Best Deals and Coupons"} style={{fontSize:"2rem",fontWeight:"bold"}}>
                     {this.props.type+"'s"} Best <Link to={"/deals"} title="All Deals">Deals</Link> and <Link to={"/coupons/all"} title="All Coupons">Coupons</Link>
                  </h1>
                <div className="card  mb-2">
                  <div className="card-body">
                    
                  <h4 style={{textTransform:"capitalize"}}> 
                     
                    All {this.props.type}s 
                     {this.state.total>0 ? "("+this.state.total+")" : "" }</h4>
                    <button
                      title={" All "+this.props.type+"s"}
                      onClick={() => this.setSelectedFilter('')}
                      style={{ cursor: "pointer" }}
                      className={this.state.selected_filter == "" ? "teal pill invert mr-1  ml-1 mt-2 " : "pill invert mr-1  ml-1 mt-2"}
                    >  <span style={{ whiteSpace: "nowrap" }}>All</span>
                      </button>
                    <button
                      title={this.props.type+"s starting with '0-9'"}
                      onClick={() => this.setSelectedFilter("0-9")}
                      style={{ cursor: "pointer" }}
                      className={this.state.selected_filter == "0-9" ? "teal pill invert mr-1  ml-1 mt-2 " : "pill invert mr-1  ml-1 mt-2"}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>0-9</span>
                    </button>

                    {filterlist}

                    <br></br>
                   
                    {this.state.selected_filter=="" ?"" : <p ><br/>Showing results for <b style={{textTransform:"capitalize"}}><i>"{this.state.selected_filter}"</i></b>:</p>}
                    
                    {this.state.loading ? (
                      <StoreListSkeleton />
                    ) : (
                      <div>
                        <br></br>
                        <ul
                          className="featured-grid clearfix flex flex-1 flex-8-md "
                          style={{ padding: "0px" }}
                        >
                          {/* {this.state.stores.slice(0, this.state.visible).map((store) => */}
                          {this.state.stores.map((store) =>
                            this.state.selected_filter !== "" ? (
                              this.checkStartWith(
                                this.state.selected_filter,
                                store.StoreName
                              ) ? (
                                <li  key={store.id.toString()} className="storelist ml-2 mr-2" >
                                  <p>
                                    <Link
                                      className="logo cust_link featured"
                                      to={"/coupons/" + store.StoreURL}
                                      title={
                                        this.Capitalize(store.StoreName) +
                                        " Coupons, Promotion Codes & Deals"
                                      }
                                    >
                                      {store.Image ? (
                                        <img
                                          alt={
                                            this.Capitalize(store.StoreName) +
                                            " Coupons, Promotion Codes & Deals"
                                          }
                                          className="lazy loaded"
                                          width={115}
                                          height={100}
                                          src={window.$logos_url+store.Image.replace(".PNG",".png")}
                                          // style={{objectFit: "contain"}}
                                          data-loaded="true"
                                        />
                                      ) : (
                                        <img
                                          className="lazy loaded"
                                          width={115}
                                          height={100}
                                          src={process.env.PUBLIC_URL + "/default_logo.png"}
                                          alt={
                                            this.Capitalize(store.StoreName) +
                                            " Coupons, Promotion Codes & Deals"
                                          }
                                          data-loaded="true"
                                          // style={{objectFit: "contain"}}
                                        />
                                      )}
                                      <span className="coupon-count">
                                        <b
                                          style={{
                                            textTransform: "capitalize",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {store.StoreName}
                                        </b>
                                        <br></br>
                                        
                                        {store.TotalCouponsCount > 0 ? (
                                          store.TotalCouponsCount + " Coupons "
                                        ) : (
                                          <span></span>
                                        )}
                                        {store.DealsCount > 0 ? (
                                          store.DealsCount + " Deals "
                                        ) : (
                                          <span></span>
                                        )}
                                      </span>
                                    </Link>
                                  </p>
                                </li>
                              ) : (
                                <span></span>
                              )
                              
                            ) : (
                              <li className="storelist ml-2 mr-2">
                                <p>
                                  <Link
                                    className="logo  cust_link featured"
                                    to={"/coupons/" + store.StoreURL}
                                    title={
                                      this.Capitalize(store.StoreName) +
                                      " Coupons, Promotion Codes & Deals"
                                    }
                                  >
                                    {store.Image ? (
                                      <img
                                        alt={
                                          this.Capitalize(store.StoreName) +
                                          " Coupons, Promotion Codes & Deals"
                                        }
                                        className="lazy loaded"
                                        width={115}
                                        height={100}
                                        src={window.$logos_url+store.Image.replace(".PNG",".png")}
                                        data-loaded="true"
                                        // style={{objectFit: "contain"}}
                                      />
                                    ) : (
                                      <img
                                        className="lazy loaded"
                                        width={115}
                                        height={100}
                                        src={process.env.PUBLIC_URL + "/default_logo.png"}
                                        alt={
                                          this.Capitalize(store.StoreName) +
                                          " Coupons, Promotion Codes & Deals"
                                        }
                                        data-loaded="true"
                                        // style={{objectFit: "contain"}}
                                      />
                                    )}
                                    <span className="coupon-count">
                                      <b
                                        style={{
                                          textTransform: "capitalize",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {store.StoreName}
                                      </b>
                                      <br></br>
                                      {store.TotalCouponsCount > 0 ? (
                                          store.TotalCouponsCount + " Coupons "
                                        ) : (
                                          <span></span>
                                        )}
                                        {store.DealsCount > 0 ? (
                                          store.DealsCount + " Deals "
                                        ) : (
                                          <span></span>
                                        )}
                                    </span>
                                  </Link>
                                </p>
                              </li>
                            )
                            
                          )
                          
                          }
                          
                        </ul>
                        {/* {this.state.visible < this.state.stores.length &&
                            <center>
                                <button onClick={this.loadMore} type="button" className="btn btn-info">Load more</button>    
                            </center>
                          }  */}
                          <h5 id="no_store_msg" style={{ display: "none" }}>
                            No {this.props.type} Found.
                          </h5>
                      </div>
                    )}
                    { this.state.total > this.state.perPage &&
                      <span>
                        <span>Showing <b>{this.state.offset+1 }</b> to <b>{this.state.offset+this.state.perPage > this.state.total ? this.state.total : this.state.offset+this.state.perPage  }</b> out of <b>{this.state.total}</b> results</span>
                         <br/>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        forcePage={this.state.currentPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                      </span>
                     
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="container-fluid p-0 "
                  style={{ paddingRight: "0px", paddingLeft: "0px" }}
                >
                  <div className="card">
                    <div className="card-body">
                      
                      <StoreCategories selected_subcategory = {this.state.selected_subcategory} selected_category ={this.state.selected_category} record_type = {this.props.type} categories = {this.props.categories}></StoreCategories>
                    </div>
                  </div>
                </div>
                <br/>
                <div className="card">
                <Advertisement ad_type=""></Advertisement>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(StoresList);

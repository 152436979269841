import React, { PureComponent } from "react";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import Toast from "light-toast";
import Popup from "reactjs-popup";
import Helmet from "react-helmet";
import keywords from "./data/keywords.json";
import { isMobile, isBrowser,isTablet } from "react-device-detect";

import SearchDealsSkeleton from "./skeletons/SearchDealsSkeleton";
import Advertisement from "./Advertisement";
import AddtoFavourite from "./AddtoFavourite";

import { Link } from "react-router-dom";
import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();


class AllDeals extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      total: 0,
      loading: false,
      offset: 0,
      perPage: isBrowser ? 60 : 30,
      currentPage: 0,
      dealscount: 0,
      price1:null,
      price2:null,
      store_name:null,
     
      selected: [],
      selected2:[],
      selected_keyword:null,
      all_keywords:[],
      page_title:"",
      all_stores: "",
      sd_id: "",
      sepecial_deal: [],
      open: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);

    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);

    this.filterRecords = this.filterRecords.bind(this);
  }
  
  filterRecords(){

   var price1 = document.getElementById("price1").value;
   var price2 = document.getElementById("price2").value;
    var s_store = null;
    var s_keyword = null;

    
   if(price1 !="0" && price2 !="0" ){
      this.setState({
        price1:price1,
        price2:price2
      })
   }
 
   
  
   this.setState({
    deals:[],
    page_title:"",
    dealscount:0,
    pageCount: 0,
    currentPage:0,
   });

   this.receivedData();
  }

  shorten(text, max) {
    return text && text.length > max
      ? text.slice(0, max).split(" ").slice(0, -1).join(" ")
      : text;
  }

  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(deal) {
    if (this.state.sepecial_deal.length < 1) {
      this.setState({ open: true, sepecial_deal: deal });
    }
  }

  closeModal() {
    this.setState({ open: false });
  }

  redirectToStore(deal) {
    Toast.info(
      <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {deal.StoreURL}
      </div>,
      3000,
      () => {
        window.open(
          window.location.href.split("?")[0] + "?d=" + deal.id,
          "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
          //this.openModal(coupon)
          (window.location.href = deal.SourceUrl),
          2000
        );
      }
    );
  }

  receivedData() {

    const params = new URLSearchParams(window.location.search);
    var keywod= "";
    if(params.has("q")){
       keywod = params.get('q');
       this.setState({selected_keyword:keywod});
      window.history.pushState({}, document.title, "/deals" );
    }else{
      keywod= this.state.keyword;
    }

    if (params.has("d")) {
      var sd_id = "";
      sd_id = params.get("d");
    
      var user_id = "";
      if(user ){
          user_id = "&user_id="+user.id;
      }
      axios
        .get(
          `http://adscombined.com/v1/public/api/DealDetails?deal_id=${sd_id+user_id}`
        )
        .then(
          (response) => {
            if (response.data.flag) {
              if (response.data.deal_data) {
                const sepecial_deal = response.data.deal_data;

                this.openModal(sepecial_deal);
              }
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

    var filter = "";
       this.setState({ loading: true }, () => {

       

         if(this.state.price1 && this.state.price2){
           filter +="&Price1="+this.state.price1+"&Price2="+this.state.price2;
         }
         if(this.state.store_name){
           filter+="&StoreName="+this.state.store_name;
         }
         if(keywod && keywod!=""){
           filter+="&Keyword="+keywod;
         }
         
         var user_id = "";
          if(user ){
              user_id = "&user_id="+user.id;
          }

         axios
           .get(
             `http://adscombined.com/v1/public/api/AllDeals?Page=${
               this.state.currentPage
             }&PerPage=${this.state.perPage}${filter}${user_id}`
           )
           .then(
             (response) => {
               if (response.data) {
                 if (response.data.flag) {
                   const deals = response.data.deals;
                   this.setState({
                     deals,
                     page_title:response.data.PageTitle,
                     dealscount: response.data.total_deals,
                     pageCount: Math.ceil(
                       response.data.total_deals / this.state.perPage
                     ),
                   });
                 }else{
                   this.setState({
                    deals:[],
                    page_title:"",
                    dealscount:0,
                    pageCount: 0
                 });
                }
                

                 
                 this.setState({ loading: false });
               }
             },
             (error) => {
               this.setState({ loading: false });
             }
           );


           if(this.state.all_stores.length<1){

            axios
            .get(
              `http://adscombined.com/v1/public/api/DealStores`
            )
            .then(
              (response) => {
                if (response.data) {
                  if (response.data.flag) {
                    var all_stores = response.data.stores;
                    var stores = [];
                    for(var i = 0; i<all_stores.length;i++){
                      if(all_stores[i].StoreName){
                        stores.push(all_stores[i].StoreName);
                      }
                    } 
                    this.setState({all_stores:stores});
                  }
                 }
               }
            );
           }
           
       });
    
  }
  componentDidMount() {
    this.receivedData();
    this.setState({all_keywords:keywords});
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
    window.scrollTo(0, 0);
  };

  render() {
    var optionss = [
      'John',
      'Miles',
      'Charles',
      'Herbie',
    ];
    const ref = React.createRef();
    const ref1 = React.createRef();
    var add_count = 0;
    const contentStyle = { width: isBrowser ? '70% !important' : isTablet ? '70% !important': '90% !important' };
    return (
        <div>
        <section className="main-container">
       <div className="container" style={{ padding: "0px" }}>
      <br />
      <div className="col-md-12 mb-3">
              <Advertisement  ad_type="full width"></Advertisement>
            </div>
      <div className="row">
        <div className="col-md-12 ">
         {this.state.page_title!==""?
        <Helmet>
          <title>{this.state.page_title}</title>
        </Helmet>
        :
        <div>
           <Helmet>
            <title>Deals | {window.$sitename}</title>
          </Helmet>
        </div>
        }
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          {...{ contentStyle }}
        >
          <div className="modal">
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
            <div class="row" style={{ padding: "10px" }}>
              <div className="col-md-4 pl-0">
                <center>
                  <img
                  title={this.state.sepecial_deal.StoreImage+ " Coupons, Promotion Codes & Deals"}
                  alt={this.state.sepecial_deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                    src={
                      this.state.sepecial_deal.Logo
                        ? window.$logos_url+this.state.sepecial_deal.Logo.replace(".PNG",".png")
                        : process.env.PUBLIC_URL + "/default_logo.png"
                    }
                    width="100"
                    height="100"
                    id="store_logo"
                    style={{objectFit:"scale-down"}}
                  ></img>
                  <h5>
                    <b style={{ textTransform: "capitalize" }} title={this.state.sepecial_deal.StoreName+" Coupons, Promotion Codes & Deals"}>
                      {this.state.sepecial_deal.StoreName}
                    </b>
                  </h5>
                </center>
              </div>

              <div className="col-md-8 " style={{ padding: "inherit" }}>
              <AddtoFavourite type={"heart"} source={"salldeals"}  id={this.state.sepecial_deal.id} favourite_id = {this.state.sepecial_deal.favourite_id} favourite_type={"deal"}  is_favourite={this.state.sepecial_deal.favourite}/>
                {this.state.sepecial_deal.Image &&
                this.state.sepecial_deal.Image !== "none" &&
                this.state.sepecial_deal.Image !== "None" ? (
                  <span>
                    <img
                      className=" deals-img-popup pl-0 pr-0"
                      src={this.state.sepecial_deal.Image}
                     alt={this.state.sepecial_deal.Title}
                     title={this.state.sepecial_deal.Title}
                    ></img>

                    <br />
                    <br />
                  </span>
                ) : (
                  <span></span>
                )}

                <h4>
                {this.state.sepecial_deal.Saving ? 

                  <div
                      className=" "
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'bottom', 
                        width: 'fit-content',
                        position: 'absolute', 
                        top: '0px', 
                        right: '0px', 
                        textTransform: 'uppercase', 
                        fontSize:"18px",
                        textAlign:"center",
                        padding: '5px', 
                        color:"white",
                        paddingBottom: '25px', 
                        fontWeight: 700
                      }}
                  >
                      {this.state.sepecial_deal.Saving}  <br/> Save
                  </div>
                  :
                  this.state.sepecial_deal.PriceDifference && this.state.sepecial_deal.PriceDifference>0 ?
                    <div
                        className=" "
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                          backgroundSize: 'cover', 
                          backgroundPosition: 'bottom', 
                          width: 'fit-content',
                          position: 'absolute', 
                          top: '0px', 
                          fontSize:"18px",
                          color:"white",
                          right: '0px', 
                          textTransform: 'uppercase', 
                          textAlign:"center",
                          padding: '5px', 
                          paddingBottom: '25px', 
                          fontWeight: 700
                        }}
                    >
                        ${this.state.sepecial_deal.PriceDifference} <br/> Save
                    </div>
                    :
                    <div></div>
                  }
                 
                </h4>
                <h3 id="coupon_title">
                    {this.state.sepecial_deal.Title}
                  </h3>
                <br />
                <b></b>
                {this.state.sepecial_deal.Code &&
                this.state.sepecial_deal.Code != "None" ? (
                  <span>
                    <input
                      id="code-value"
                      type="text"
                      className="code-copy"
                      value={this.state.sepecial_deal.Code}
                      style={{
                        borderRadius: "0px",
                        width: "fit-content",
                        cursor: "copy",
                      }}
                      readOnly
                    />

                    <span>
                      <button
                        className="btn"
                        onClick={this.myFunction}
                        onMouseOut={this.outFunc}
                        id="myTooltip"
                        style={{ verticalAlign: "inherit" }}
                      >
                        Copy Code
                      </button>
                    </span>

                    <br />
                    <br />
                  </span>
                ) : (
                  <div></div>
                )}

                <a href={this.state.sepecial_deal.SourceUrl}>
                  <b>
                    {" "}
                    Continue to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_deal.StoreName}
                    </span>
                  </b>{" "}
                </a>
              </div>
            </div>
          </div>
        </Popup>
     
        <div className="mb-2 well well-sm border-div bg-white borderZero p-2">
     
            
            <div className=" row">
            <label className="col-md-2 mt-2" ><b >By Keyword:</b></label>
           
            {this.state.all_keywords.length>0? 
              <Typeahead
              id={"all-deals-search"}
              minLength="0"
              
              onChange={(selected) => {
                this.setState({ keyword:selected });
              }}
              options={this.state.all_keywords}
              
              onInputChange = {(text)=>{
                this.setState({ keyword:text });
           
              }
 
              }
              placeholder="Type keyword to search.."
              ref={(ref) => this._typeahead = ref}
              className="col-md-4"
              />
             
              :
               <div className="col-md-4">
               <Skeleton height={30}></Skeleton>
               </div>
            }
            <label className="col-md-2 mt-2" ><b >By Store or Brand:</b></label>
            {this.state.all_stores.length>0 ?
            
            <Typeahead
           
            minLength="1"
            
            onChange={(selected) => {
              this.setState({ store_name:selected });
            }}
            options={this.state.all_stores}
            onInputChange = {(text)=>{
              this.setState({ store_name:text });
         
            }}
            placeholder="Search Store or Brand.."
            ref={ref}
            className="col-md-4"
            />
           
            :
             <div className="col-md-4">
             <Skeleton height={30}></Skeleton>
             </div>
             }
        </div> 
        <br/>
        <div className="row">
             <label className="col-md-2 mt-2"><b >Price From:</b></label>
             <input type="number" step="5" id="price1" className="form-control col-md-1" min="0"></input>
              &nbsp;
             <b className="mt-2 "><center>To</center></b> 
             &nbsp;
             <input type="number" step="10" id="price2" className="form-control col-md-1" min="0"></input>
             <div className="col-md-1">
                < center>
                   <button className="btn btn-info " onClick={this.filterRecords}> Filter </button>
                </center>
             </div>
            
        </div>
        
         
        </div>
        <div
          className="container-fluid "
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
             <div className="row">
                  <div class="btn-group">
                     <button className="btn-info   btn-pills ml-2 ">
                        All Deals {this.state.dealscount > 0 ? "("+this.state.dealscount+")": ""}  
                    </button> 
                  &nbsp;&nbsp;&nbsp;  {this.state.dealscount >0 ? 
                  <span style={{paddingTop: "5px"}}>Showing <b>{this.state.offset+1 }</b> to <b>{this.state.offset+this.state.perPage > this.state.dealscount ? this.state.dealscount : this.state.offset+this.state.perPage  }</b> out of <b>{this.state.dealscount}</b> results    <br/></span>
                :
                  <span></span>
                }
                  </div>
                </div>
          <div className="row">
            <div className="col-md-9 p-0">
            {this.state.loading ? (
              <SearchDealsSkeleton  items={[1,2,3]}/>
            ) : (
              <div>
              
                  
            
                {this.state.deals.length > 0 ? (
                  <div>
                    <div className="row">
                  
                      {this.state.deals.map((deal) =>
                      
                      add_count > 11 ?

                        <div className="card-deck pl-1 pr-1 col-md-4 mt-2 clickable">
                          <Advertisement ad_type="column">

                          </Advertisement>
                      <span style={{display:"none"}}>{add_count=0}</span>
                        </div>

                      :
                      
                      deal.Image != "None" &&
                        deal.Image != "" &&
                        deal.Image != "none" &&
                        (deal.Title == "banner" ||
                          deal.Title == "None" ||
                          deal.Title == "") ? (
                          <div className="card-deck pl-1 pr-1 col-md-4 mt-2 clickable"  title={"Click to get "+deal.Title} >
                              <span style={{display:"none"}}>{add_count++}</span>
                              <div className="card  text-white  single-deal  " >
                              <AddtoFavourite type={"heart-deal"} source={"alldeals"}  favourite_id = {deal.favourite_id} id={deal.id} favourite_type={"deal"} is_favourite={deal.favourite} />
                              <span style={{display:"none"}}>{add_count++}</span>
                                <img
                                onClick={() => this.redirectToStore(deal)}
                                alt={deal.Title}
                                title={deal.Title}
                                  style={{
                                    height: "-webkit-fill-available",
                                    width: "100%",
                                    objectFit: "scale-down",
                                  }}
                                  src={deal.Image}
                                ></img>
                            </div>
                          </div>
                        ) : (
                          <div className="card-deck pl-1 pr-1 col-md-4 mt-2 clickable" >
                        <span style={{display:"none"}}>{add_count++}</span>
                      <div className="card  text-white  single-deal  " >
                      <AddtoFavourite type={"heart-deal"} source={"alldeals"} id={deal.id} favourite_id = {deal.favourite_id} favourite_type={"deal"} is_favourite={deal.favourite} />
                      <span style={{display:"none"}}>{add_count++}</span>
                      {deal.Saving ? 

                        <div style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                          backgroundSize: 'cover', 
                          backgroundPosition: 'bottom', 
                          width: 'fit-content',
                          position: 'absolute', 
                          top: '0px', 
                          right: '0px', 
                          textTransform: 'uppercase', 
                          textAlign:"center",
                          padding: '5px', 
                          paddingBottom: '20px', 
                          fontWeight: 700
                        }}> 
                          {deal.Saving} <br />Save
                        </div>
                        :
                        deal.PriceDifference && deal.PriceDifference>0 ?
                          <div
                              className=" "
                              style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                                backgroundSize: 'cover', 
                                backgroundPosition: 'bottom', 
                                width: 'fit-content',
                                position: 'absolute', 
                                textAlign:"center",
                                top: '0px', 
                                right: '0px', 
                                textTransform: 'uppercase', 
                                padding: '5px', 
                                paddingBottom: '20px', 
                                fontWeight: 700
                              }}
                          >
                              ${deal.PriceDifference}<br />Save
                          </div>
                          :
                          <div></div>
                        }
                        <img alt={"Click to get "+deal.Title} src={deal.Image} className="card-img" title={deal.Title} style={{height:"300px",width:"100%",objectFit:"scale-down"}}  onClick={() => this.redirectToStore(deal)}/>
                        
                        <div className="card-img-overlay p-1" style={{top:"unset",background:' #00000099'}}>
                      
                        <div className="details">
                        <Link
                          className="logo  cust_link featured"
                          to={"/coupons/" + deal.StoreURL}
                          title={
                            deal.StoreName +" Coupons, Promotion Codes & Deals"
                          }
                        >
                        {deal.Logo && deal.Logo!=="none" && deal.Logo!=="None" ?
                          (
                            <img src={window.$logos_url+deal.Logo.replace(".PNG",".png")}  height={70} width={70} 
                            title={deal.StoreName+" Coupons, Promotion Codes & Deals"}
                            alt={deal.StoreName+" Coupons, Promotion Codes & Deals"}
                            style={{
                              position:"absolute",
                              top:"-35px",
                              borderRadius:"5px",
                              background: "white",
                              objectFit:"fill"
                            }}
                            ></img>
                          )
                          :
                          (
                            <img src={process.env.PUBLIC_URL + "/default_logo.png"} 
                            title={deal.StoreName+" Coupons, Promotion Codes & Deals"}
                            alt={deal.StoreName+" Coupons, Promotion Codes & Deals"}
                            style={{
                              position:"absolute",
                              top:"-35px",
                              borderRadius:"5px",
                              background: "white",
                              objectFit:"fill"
                            }}
                            height={70} width={70} ></img>
                          )
                        }
                        </Link>
                            <div className="card-title mt-5 mb-0 "  >
                                <h3 style={{fontSize: "18px"}} title={"Click to get "+deal.Title}  onClick={() => this.redirectToStore(deal)}> {deal.Title && deal.Title.length <40 ? deal.Title : this.shorten(deal.Title,40)+".."}</h3>
                            </div>
                            <h5  style={{fontSize:"1.0rem !important"}}>
                              <span style={{ fontSize: "14px" }}>
                                  <span style={{ textDecoration: "line-through" }}>
                                  {deal.Price1}
                                  </span>{" "}
                                  &nbsp;
                              </span>
                              <b style={{ color: "limegreen",fontSize:"28px" }}>{deal.Price2}</b>&nbsp;
                            </h5>
                        </div>
                        </div>
                      </div>     
                      </div>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="card single-coupen mt-2">
                  <div className="card-body">
                    <h5>No Deals Found.</h5>
                  </div>
                </div>
                )}
                 <center>
                  {this.state.dealscount > this.state.perPage ? (
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      forcePage={this.state.currentPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      />
                  ) : (
                      <span></span>
                  )}
              </center>
              </div>
            )}
            </div>
            <div className="col-md-3 p-0">
              <div className="card sticky_ad" style={{marginTop:"30px"}}>

                <Advertisement ad_type=""></Advertisement>
              </div>    
            </div>
          </div>
          
       
         
        </div>
      </div>
      </div>
      </div>
      </section>
      </div>
    );
  }
}

export default AllDeals;

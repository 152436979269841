import React, { PureComponent } from "react";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import HomeCategoriesSkeleton from "./skeletons/HomeCategoriesSkeleton";

class HomeCategories extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      categories: [],
      loading: false,
      categories_to_filter: ["Photography","Business","Sports & Recreation"]
    };
    this.getCategorySlug = this.getCategorySlug.bind(this);
    this.checkCategory = this.checkCategory.bind(this);

  }
  
  checkCategory(categoryName){
    return this.state.categories_to_filter.includes(categoryName);
  }
  receivedData() {
    var categories = localStorage.getItem("categories");
    if(categories){
     
      this.setState({ categories : JSON.parse(categories) });
    }else{
      if (this.state.categories.length < 1) {
        this.setState({ loading: true }, () => {
      axios
        .get(`http://adscombined.com/v1/public/api/AllCategories`)
        .then(
          (response) => {
            if (response.data.categories) {
              var categories = response.data.categories;
              this.setState({ categories:categories });
              localStorage.setItem("categories",JSON.stringify(categories));
              this.setState({loading:false});
             
            }
          },
          (error) => {
            console.log(error);
            this.setState({loading:false});
          }
        );
      });
      }
    }
   
  }

  componentDidMount() {
    return this.receivedData();
  }

  getCategorySlug(category){
    category = category.replace("&","");
    category = category.replace("$","");
    category = category.replace(",","");
    category = category.replace(".","");
    
    var category = category.replace(/\s/g,"-");
    var category = category.replace("--","-");
    return category;
  }

  render() {
    return (
      <div className="card" id="categories-div">
        <div className="card-body pb-0" >
         
          
              
              {this.state.loading  ?
            (
                <HomeCategoriesSkeleton items={[1,2,3,4,5,6,7,8,9]}/>
            )
            :
            (<div className="row">
              <div className="col-md-12 p-0">
                <div className="cards-grid-container" style={{paddingBottom:"0px"}}>
                {this.state.categories.map(
                  (category) => ( 
                   !this.checkCategory(category.Category) ?

                    <div key={category.Category.toString()} className="cards-grid-item">
                      <Link
                        to={"/coupons/" + window.$categories[category.Category]}
                        className="cards-grid-link"
                        title={category.Category+" "+category.CouponsCount+" Coupons & Promotion Codes" }
                      >
                        <span className="cards-grid-icon-wrapper">
                          <img
                            className="cards-grid-icon img-category "
                            alt={category.Category+" Coupons & Promotion Codes"}
                            title={category.Category+" Coupons & Promotion Codes"}
                            src={window.$site_url + 'category_images/'+this.getCategorySlug(category.Category)+'.png'} 
                            ></img>
                        </span>
                        <span className="cards-grid-title fw-500" >
                          {category.Category}
                        </span>
                      </Link>
                    </div>
                    :
                    <span key={category.Category.toString()}></span>
                  )
                 
                )}
                </div>
                </div>
              
              </div>
                
            )}
            
          </div>
      </div>
    );
  }
}

export default React.memo(HomeCategories);

import React, { PureComponent } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Popup from "reactjs-popup";
import Toast from "light-toast";
import ReactPaginate from "react-paginate";
import { isMobile, isBrowser, isTablet } from "react-device-detect";
import Moment from "react-moment";

import CouponTypeDisplay from "./CouponTypeDisplay";
import CouponsSkeleton from "./skeletons/CouponsSkeleton";
import Advertisement from "./Advertisement";
import AddtoFavourite from "./AddtoFavourite";

import { getUser, removeUserSession } from "../utils/Common";

import qs from "qs";

const user = getUser();

class Coupens extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			coupons: [],
			coupons_count: props.category_counts,
			all_coupons: [],
			coupon_type: props.coupon_type,
			loading: false,
			offset: 0,
			perPage: isBrowser ? 60 : 30,
			currentPage: 0,
			checked: false,
			total_coupons: 0,
			sub_types_counts:null,
			date_updated: "",
			page_title: "",
			sub_type:"",
			sepecial_coupon: [],
			sc_id: "",
			open: false,
		};
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);

		this.redirectToStore = this.redirectToStore.bind(this);
		this.outFunc = this.outFunc.bind(this);
		this.myFunction = this.myFunction.bind(this);
		this.shorten = this.shorten.bind(this);
	}

	shorten(text, max) {
		return text && text.length > max
			? text.slice(0, max).split(" ").slice(0, -1).join(" ")
			: text;
	}

	myFunction() {
		var copyText = document.getElementById("code-value");
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy");

		var tooltip = document.getElementById("myTooltip");
		tooltip.innerHTML = "Copied: " + copyText.value;
	}

	outFunc() {
		var tooltip = document.getElementById("myTooltip");
		tooltip.innerHTML = "Copy Code";
	}

	openModal(coupon) {
		if (this.state.sepecial_coupon.length < 1) {
			this.setState({ open: true, sepecial_coupon: coupon });
		}
	}

	closeModal() {
		this.setState({ open: false });
	}

	redirectToStore(coupon) {
		Toast.info(
			<div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
				Redirecting to ... {coupon.StoreURL}
			</div>,
			3000,
			() => {
				window.open(
					window.location.href.split("?")[0] + "?c=" + coupon.id,
					"_blank"
				);
				//focus to thet window
				window.focus();
				setTimeout(
					//this.openModal(coupon)
					(window.location.href = coupon.SourceUrl),
					2000
				);
			}
		);
	}

	receivedData() {
		const params = new URLSearchParams(window.location.search);

		this.setState({ loading: true }, () => {
			var category_id = null;
			var sub_cat = null;

			var user_id = "";
			if (user) {
				user_id = "&user_id=" + user.id;
			}
			if (params.has("c")) {
				var sc_id = "";
				sc_id = params.get("c");

				axios
					.get(
						`http://adscombined.com/v1/public/api/CouponDetails?coupon_id=${encodeURIComponent(
							sc_id
						)}${user_id}`
					)
					.then(
						(response) => {
							if (response.data.flag) {
								if (response.data.coupon_data) {
									const sepecial_coupon = response.data.coupon_data;

									this.openModal(sepecial_coupon);
								}
							} else {
							}
						},
						(error) => {
							console.log(error);
						}
					);
			}
			if (this.props.cat_id && this.props.sub_cat) {
				this.setState({ checked: true });
				category_id = this.props.cat_id;
				sub_cat = this.props.sub_cat;

				var user_id = "";
				if (user) {
					user_id = "&user_id=" + user.id;
				}

				axios
					.get(
						`http://adscombined.com/v1/public/api/CategoryCoupons?category=${encodeURIComponent(
							category_id
						)}&sub_category=${encodeURIComponent(sub_cat)}&Page=${
							this.state.currentPage
						}&PerPage=${this.state.perPage}&Filter=${
							this.state.coupon_type
						}&SubType=${
							this.state.sub_type
						}${user_id}`
					)
					.then(
						(response) => {
							if (response.data.coupons) {
								if (response.data.coupons.length > 0) {
									var page_title = "";
									if (response.data.PageTitle !== "") {
										page_title = response.data.PageTitle;
									}
									const coupons = response.data.coupons;
									if (this.state.date_updated === "") {
										if (response.data.coupons.length > 0) {
											if (response.data.coupons[0].DateUpdated) {
												this.setState({
													date_updated: response.data.coupons[0].DateUpdated,
												});
											}
										}
									}

									this.setState({
										page_title: page_title,
										sub_types_counts:response.data.sub_types_counts,
										total_coupons: response.data.TotalCoupons,
										loading: false,
										coupons,
										pageCount: Math.ceil(
											response.data.TotalCoupons / this.state.perPage
										),
									});
									
								} else {
									this.setState({
										loading: false,
									});
								}
							} else {
								this.setState({
									loading: false,
								});
							}
						},
						(error) => {
							console.log(error);
							this.setState({
								loading: false,
							});
						}
					);
			} else {
				category_id = this.props.cat_id;
				var user_id = "";
				if (user) {
					user_id = "&user_id=" + user.id;
				}
				axios
					.get(
						`http://adscombined.com/v1/public/api/CategoryCoupons?category=${encodeURIComponent(
							category_id
						)}&Page=${this.state.currentPage}&PerPage=${
							this.state.perPage
						}&Filter=${this.state.coupon_type}&SubType=${this.state.sub_type}${user_id}`
					)
					.then(
						(response) => {
							//console.log(response);
							if (response.data.coupons) {
								if (response.data.coupons.length > 0) {
									const coupons = response.data.coupons;
									if (this.state.date_updated === "") {
										if (response.data.coupons.length > 0) {
											if (response.data.coupons[0].DateUpdated) {
												this.setState({
													date_updated: response.data.coupons[0].DateUpdated,
												});
											}
										}
									}
									var page_title = "";
									if (response.data.PageTitle !== "") {
										page_title = response.data.PageTitle;
									}
									this.setState({
										page_title: page_title,
										total_coupons: response.data.TotalCoupons,
										sub_types_counts:response.data.sub_types_counts,
										loading: false,
										coupons,
										pageCount: Math.ceil(
											response.data.TotalCoupons / this.state.perPage
										),
									});
								} else {
									this.setState({
										loading: false,
									});
								}
							}
						},
						(error) => {
							this.setState({
								loading: false,
							});
							console.log(error);
						}
					);
			}
		});
	}
	componentDidMount() {
		this.receivedData();
	}
	getStore(ctype,subtype="") {
		if (ctype != "") {
			this.setState(
				{
					coupon_type: ctype,
					offset: 0,
					sub_type:subtype,
					coupons: [],
					currentPage: 0,
				},
				() => {
					console.log("Form: ", this.state);

					this.receivedData();
				}
			);
		}
	}

	handlePageClick = (e) => {
		const selectedPage = e.selected;
		const offset = selectedPage * this.state.perPage;

		this.setState(
			{
				currentPage: selectedPage,
				offset: offset,
			},
			() => {
				this.receivedData();
			}
		);
		window.scrollTo(0, 0);
	};

	render() {
		var add_count = 0;
		//Promo, Free Shipping,Clearance,Weekly Ad,Todays Ad,Sale,Event,Gift,Coupon,Student,Referal,Reward,Cash Back,Rebate,Printable Coupon,Seasonal
		const contentStyle = {
			width: isBrowser
				? "70% !important"
				: isTablet
				? "70% !important"
				: "90% !important",
		};
		return (
			<div>
				<h1
					className="ac-h1"
					title={
						this.props.sub_cat
							? this.props.sub_cat + " Coupons"
							: this.props.cat_id + " Coupons"
					}
					style={{
						textTransform: "capitalize",
						fontSize: "2rem",
						fontWeight: "bold",
					}}
				>
					{this.props.sub_cat ? this.props.sub_cat : this.props.cat_id}{" "}
					<Link to={"/coupons/all"} title="All Coupons">
						Coupons
					</Link>
				</h1>
				{this.state.date_updated !== "" ? (
					<h5>
						Verified {this.props.store_id}{" "}
						<Link to={"/coupons/all"} title="All Coupons">
							Coupons
						</Link>{" "}
						for{" "}
						<Moment format="dddd, MMMM D, Y">{this.state.date_updated}</Moment>{" "}
					</h5>
				) : (
					<span></span>
				)}
				{this.state.page_title !== "" ? (
					<Helmet>
						<title>{this.state.page_title}</title>
					</Helmet>
				) : (
					<div></div>
				)}
				<Popup
					open={this.state.open}
					closeOnDocumentClick
					onClose={this.closeModal}
					{...{ contentStyle }}
				>
					<div className="modal">
						<a className="close" onClick={this.closeModal}>
							&times;
						</a>
						<div class="row" style={{ padding: "10px" }}>
							<div className="col-md-3">
								<center>
									<a
										className="logo  cust_link featured"
										href={"/coupons/" + this.state.sepecial_coupon.StoreURL}
										title={
											this.state.sepecial_coupon.StoreName +
											" Coupons, Promotion Codes & Deals"
										}
									>
										<img
											src={
												this.state.sepecial_coupon.Logo
													? window.$logos_url +
													  this.state.sepecial_coupon.Logo.replace(
															".PNG",
															".png"
													  )
													: process.env.PUBLIC_URL + "/default_logo.png"
											}
											width="100"
											height="100"
											id="store_logo"
											alt={
												this.state.sepecial_coupon.StoreName +
												" Coupons, Promotion Codes & Deals"
											}
											title={
												this.state.sepecial_coupon.StoreName +
												" Coupons, Promotion Codes & Deals"
											}
										></img>
										<h5>
											<b style={{ textTransform: "capitalize" }}>
												{this.state.sepecial_coupon.StoreName}
											</b>
										</h5>
									</a>
								</center>
							</div>

							<div className="col-md-9" style={{ padding: "inherit" }}>
								<AddtoFavourite
									type={"heart"}
									id={this.state.sepecial_coupon.id}
									source={"scatcoupon"}
									favourite_id={this.state.sepecial_coupon.favourite_id}
									favourite_type={"coupon"}
									is_favourite={this.state.sepecial_coupon.favourite}
								/>
								<span
									style={{
										fontSize: "22px",
										fontWeight: "bold",
										color: "teal",
										textTransform: "uppercase",
									}}
								>
									{this.state.sepecial_coupon.Offer &&
									this.state.sepecial_coupon.Offer !== "None" &&
									this.state.sepecial_coupon.Offer !== "none" ? (
										<CouponTypeDisplay
											coupontype={this.state.sepecial_coupon.Offer}
											source="couponpage"
										/>
									) : (
										<CouponTypeDisplay
											coupontype={this.state.sepecial_coupon.Type}
											source="couponpage"
										/>
									)}
								</span>

								<h3 id="coupon_title">{this.state.sepecial_coupon.Title}</h3>

								<br />
								<b></b>
								{this.state.sepecial_coupon.Code &&
								this.state.sepecial_coupon.Code != "None" ? (
									<span>
										<input
											id="code-value"
											type="text"
											className="code-copy"
											value={this.state.sepecial_coupon.Code}
											style={{
												borderRadius: "0px",
												width: "fit-content",
												cursor: "copy",
											}}
											readOnly
										/>

										<span>
											<button
												className="btn"
												onClick={this.myFunction}
												onMouseOut={this.outFunc}
												id="myTooltip"
												style={{ verticalAlign: "inherit" }}
											>
												Copy Code
											</button>
										</span>

										<br />
										<br />
									</span>
								) : (
									<div></div>
								)}

								<a href={this.state.sepecial_coupon.SourceUrl}>
									<b>
										{" "}
										Continue to{" "}
										<span style={{ textTransform: "capitalize" }}>
											{this.state.sepecial_coupon.StoreName}
										</span>
									</b>{" "}
								</a>
							</div>
						</div>
					</div>
				</Popup>
				<div className="mb-2  well well-sm border-div bg-white borderZero p-2">
					<div class="btn-group">
						<button
							onClick={() => this.getStore("all")}
							className={
								this.state.coupon_type === "all"
									? "btn-info btn-pills ml-2 mt-2 mb-2"
									: " btn-pills ml-2 mt-2 mb-2"
							}
						>
							All Offers
							{this.props.category_counts.TotalCouponsCount > 0
								? "(" + this.props.category_counts.TotalCouponsCount + ")"
								: ""}
						</button>
					</div>
					{this.props.category_counts.PromoCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Promo")}
								className={
									this.state.coupon_type === "Promo"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Promo ({this.props.category_counts.PromoCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.FreeShippingCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Free Shipping")}
								className={
									this.state.coupon_type == "Free Shipping"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Free Shipping ({this.props.category_counts.FreeShippingCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.ClearanceCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Clearance")}
								className={
									this.state.coupon_type == "Clearance"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Clearance ({this.props.category_counts.ClearanceCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.WeeklyAdCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Weekly Ad")}
								className={
									this.state.coupon_type == "Weekly Ad"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Weekly Ad ({this.props.category_counts.WeeklyAdCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.TodaysAdCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Today's Ad")}
								className={
									this.state.coupon_type == "Today's Ad"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Today's Ad ({this.props.category_counts.TodaysAdCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.SaleCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Sale")}
								className={
									this.state.coupon_type == "Sale"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Sale ({this.props.category_counts.SaleCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.EventsCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Event")}
								className={
									this.state.coupon_type == "Event"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Events ({this.props.category_counts.EventsCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.GiftsCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Gift")}
								className={
									this.state.coupon_type == "Gift"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Gifts ({this.props.category_counts.GiftsCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.CouponCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Coupon")}
								className={
									this.state.coupon_type == "Coupon"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Coupon ({this.props.category_counts.CouponCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.StudentCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Student")}
								className={
									this.state.coupon_type == "Student"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								{" "}
								Student ({this.props.category_counts.StudentCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.ReferralCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Referral")}
								className={
									this.state.coupon_type == "Referral"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Referral ({this.props.category_counts.ReferralCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.RewardCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Reward")}
								className={
									this.state.coupon_type == "Reward"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Reward ({this.props.category_counts.RewardCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.CashBackCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Cash Back")}
								className={
									this.state.coupon_type == "Cash Back"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								{" "}
								Cash Back ({this.props.category_counts.CashBackCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.RebateCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Rebate")}
								className={
									this.state.coupon_type == "Rebate"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Rebate ({this.props.category_counts.RebateCount})
							</button>
						</div>
					) : (
						<span></span>
					)}

					{this.props.category_counts.PrintableCouponCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Printable Coupon")}
								className={
									this.state.coupon_type == "Printable Coupon"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Printable Coupon (
								{this.props.category_counts.PrintableCouponCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.SeasonalCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("Seasonal")}
								className={
									this.state.coupon_type == "Seasonal"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								Seasonal ({this.props.category_counts.SeasonalCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.BOGOCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("BOGO")}
								className={
									this.state.coupon_type == "BOGO"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								BOGO ({this.props.category_counts.BOGOCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					{this.props.category_counts.NewArrivalCount > 0 ? (
						<div class="btn-group">
							<button
								onClick={() => this.getStore("New Arrival")}
								className={
									this.state.coupon_type == "New Arrival"
										? "btn-info btn-pills ml-2 mt-2 mb-2"
										: " btn-pills ml-2 mt-2 mb-2"
								}
							>
								New Arrival ({this.props.category_counts.NewArrivalCount})
							</button>
						</div>
					) : (
						<span></span>
					)}
					
					
					{!this.state.loading && this.state.sub_types_counts &&  (  this.state.coupon_type == "Event" ||   this.state.coupon_type=="Seasonal") ? 
            
							<h5 className="ml-2"><hr/><i>Avaiable {  this.state.coupon_type}s:</i></h5> 
						:""}

						{!this.state.loading && this.state.sub_types_counts &&  (  this.state.coupon_type == "Event" ||   this.state.coupon_type=="Seasonal")  &&  this.state.sub_types_counts.map(sub_type=>{
							return  sub_type.count && sub_type.count>0 ? 
							<button
							onClick={() => this.getStore(   this.state.coupon_type,sub_type.sub_type)}
							className={
							(  this.state.coupon_type == "Event" ||   this.state.coupon_type=="Seasonal") && this.state.sub_type == sub_type.sub_type
								? "btn-info btn-pills ml-2 mt-2 mb-2"
								: " btn-pills ml-2 mt-2 mb-2"
							}
							>
							{sub_type.sub_type} ({sub_type.count})
							</button> : "";
						})}
				</div>

				<div
					className="container-fluid "
					style={{ paddingRight: "0px", paddingLeft: "0px" }}
				>
					{this.state.loading ? (
						<div className="card single-coupen mt-2">
							<div className="card-body">
								<CouponsSkeleton />
							</div>
						</div>
					) : (
						<div>
							{this.state.coupons.length > 0 ? (
								<div>
									{this.state.coupons.map((coupon) =>
										add_count > 9 ? (
											<div className="card single-coupen mt-2">
												<div className="card-body">
													<Advertisement ad_type="full width"></Advertisement>
												</div>
												<span style={{ display: "none" }}>
													{(add_count = 0)}
												</span>
											</div>
										) : (
											<div className="card single-coupen mt-2">
												<span style={{ display: "none" }}>{add_count++}</span>
												<div className="card-body">
													<AddtoFavourite
														type={"heart"}
														id={coupon.id}
														source={"catcoupon"}
														favourite_id={coupon.favourite_id}
														favourite_type={"coupon"}
														is_favourite={coupon.favourite}
													/>
													{coupon.Image && coupon.Image !== "" ? (
														<img
															alt={coupon.Title}
															onClick={() => this.redirectToStore(coupon)}
															src={coupon.Image}
															style={{
																width: "100%",
																height: "150px",
																objectFit: "contain",
																cursor: "pointer",
															}}
															title={"Click to get " + coupon.Title}
														/>
													) : (
														<div className="row d-flex justify-content-start card-strip">
                            <div class="col-md-2" style={{background:"#d3d3d37a",paddingTop:"2% ",height:"138px",position:"relative"}}>

                                <h5
                                  className="deal-value  "
                                  onClick={() => this.redirectToStore(coupon)}
                                  title={"Click to get " + coupon.Title}
                                  style={{ 
                                    width:"100%",
                                   
                                    background:"transparent ",
                                    cursor: "pointer" }}
                                >
                                
                                  {coupon.Offer &&
                                  coupon.Offer != "None" &&
                                  coupon.Offer != "none" ? (
                                    <CouponTypeDisplay
                                      source="couponpage"
                                      coupontype={coupon.Offer}
                                    />
                                  ) : (
                                    <CouponTypeDisplay
                                      source="couponpage"
                                      coupontype={coupon.Type}
                                    />
                                  )}
                                    
                                </h5>
                               
                                {coupon.Logo ? 
                                    <img src={window.$logos_url+coupon.Logo}   className="category_coupon_image"
									title={coupon.StoreName}
                                    style={{objectFit:"cover",width:"100%", position:"absolute",height:"52px",left:"0", bottom:"0",maxHeight:"52px !important"}}>

                                      </img>
                                  :""}
                              </div>
															<div className="info col-md-10 ">
																<div className="row px-3 mb-2">
																	<h3
																		className="dark-text"
																		onClick={() => this.redirectToStore(coupon)}
																		style={{
																			cursor: "pointer",
																			fontSize: "1.5rem",
																		}}
																		title={"Click to get " + coupon.Title}
																	>
																		{coupon.Title}
																	</h3>
																	{/* <p className="mt-1 mr-4 extended-title">
                                            {coupon.Status}
                                          </p> */}
																</div>
																<div className="row px-3">
																	<p className="mb-1"></p>
																</div>

																<div className="px-3 category_coupon_display" style={{position:"absolute",bottom: "0",width: "90%"}}>
																	{coupon.updated_at ? (
																		<span style={{ fontSize: "12px" }}>
																			<i>
																				{" "}
																				Last seen:{" "}
																				<b>
																					<Moment format="MMMM D, Y">
																						{coupon.updated_at}
																					</Moment>
																				</b>
																			</i>
																			<br></br>{" "}
																		</span>
																	) : (
																		""
																	)}
																	<button
																		title={"Click to get " + coupon.Title}
																		onClick={() => this.redirectToStore(coupon)}
																		className="btn btn-info  "
																		target="blank"
																		style={{
																			borderRadius: "0px",
																			textDecoration: "none",
																			color: "white",
																		}}
																	>
																		Get this Coupon
																	</button>
                               
                                  
																	{coupon.created_at ? (
																		<div
																			className=" mt-3"
																			style={{
																				float: "right ",
																				fontSize: "12px",
																			}}
																		>
																			<i>
																				Found On:{" "}
																				<b>
																					<Moment format="MMMM D, Y">
																						{coupon.created_at}
																					</Moment>
																				</b>
																			</i>
																		</div>
																	) : (
																		""
																	)}
																</div>
															</div>
														</div>
													)}
												</div>
											</div>
										)
									)}
								</div>
							) : (
								<div className="card single-coupen mt-2">
									<div className="card-body">
										<h5>No Coupons Found.</h5>
									</div>
								</div>
							)}
						</div>
					)}

					{this.state.total_coupons > this.state.perPage ? (
						<ReactPaginate
							previousLabel={"Previous"}
							nextLabel={"Next"}
							breakLabel={"..."}
							forcePage={this.state.currentPage}
							breakClassName={"break-me"}
							pageCount={this.state.pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={this.handlePageClick}
							containerClassName={"pagination"}
							subContainerClassName={"pages pagination"}
							activeClassName={"active"}
						/>
					) : (
						<div></div>
					)}
				</div>
			</div>
		);
	}
}

export default Coupens;

import React, { PureComponent } from "react";
import Skeleton from 'react-loading-skeleton';

class HomeCategoriesSkeleton extends PureComponent{

   render(){

      return(
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="cards-grid-container" style={{paddingBottom:"0px"}} >
              {this.props.items.length > 0 ? (
                this.props.items.map(
                (item) => (            
                <div key={item} className="cards-grid-item" style={{border:"0px "}}>    
                  <Skeleton height={115}/>
                  <span className="cards-grid-title">
                  <center>
                <Skeleton count={1}/></center>
                  </span>
              </div>
                ))
              )
                :
                (<div></div>)
                }
              
              </div>
            </div>
           
          </div>

      );
   }
}

export default HomeCategoriesSkeleton;
import React, { useState } from "react";
import axios from "axios";
import clsx from "clsx";
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { setUserSession } from "../utils/Common";
import { GoogleLogin } from 'react-google-login';

const useStyles = makeStyles((theme) => ({
    root: {
     
    },
    margin: {
     
    },
    withoutLabel: {
      marginTop: theme.spacing(3)
    },
    textField: {
      width: "25ch"
    }
  }));

function Register (props){

    var loading = false;
    // handle button click of login form
    const handleRegister = () => {
        document.getElementById("button-login").innerHTML = "Processing...";
        document.getElementById("button-login").disabled = true;
        var name = document.getElementById("name").value;
        var username = document.getElementById("email").value;
        var password  = document.getElementById("password").value; 
        var confirm_password  = document.getElementById("confirm_password").value; 
        if(password == confirm_password){
            var error = "";
            if(username==""){
                error="Email ";
            }
            if(name==""){
               
                error+="Name ";
               
            }
            if(password==""){
                error+="Password";
            }
            if(error ==""){
                axios.post(`http://adscombined.com/v1/public/api/register?email=${encodeURIComponent(username)}&name=${encodeURIComponent(name)}&password=${encodeURIComponent(password)}&c_password=${encodeURIComponent(confirm_password)}`)
                .then( (response) => {
                    
                    console.log("--> " , response);
                    if(response.data.success != null){
                        
                        console.log("--> " , response);
                        document.getElementById("error").style.display="none";
                        document.getElementById("form-div").style.display="none";

                        document.getElementById("success").style.display="block";

                        document.getElementById("email").value=  "";
                        document.getElementById("password").value = "";
                        document.getElementById("confirm_password").value = "";
                        document.getElementById("name").value = "";
                        
                    }else{
                        console.log("--> " , response);
                        document.getElementById("button-login").innerHTML = "Create Account";
                        document.getElementById("button-login").disabled =false;
                        document.getElementById("error").style.display="block";
                        if(response.data.error){
                           
                            if(response.data.error.password)   {
                                
                                document.getElementById("error").innerHTML= response.data.error.password;

                            }
                            else if(response.data.error.c_password)   {

                                document.getElementById("error").innerHTML= response.data.error.c_password;
                            } 
                            else if(response.data.error.name)   {
                                
                                document.getElementById("error").innerHTML= response.data.error.name;
                            } 
                            else if(response.data.error.email)   {
                                
                                document.getElementById("error").innerHTML= response.data.error.email;
                            } 
                            
                        }else{
                            
                            document.getElementById("error").innerHTML= response.data.message;
                        }
                        
                    }
                }
                , (error) => {
                    document.getElementById("button-login").innerHTML = "Create Account";
                    document.getElementById("button-login").disabled =false;
                    
                    document.getElementById("error").style.display="block";
                    document.getElementById("error").innerHTML= "Error in processing request! Try again";
                }
                );
            }else{

                document.getElementById("button-login").innerHTML = "Create Account";
                document.getElementById("button-login").disabled =false;
                
                document.getElementById("error").style.display="block";
                document.getElementById("error").innerHTML= error+" cannot be empty!";
            }

        }else{

            document.getElementById("button-login").innerHTML = "Create Account";
            document.getElementById("button-login").disabled =false;
            
            document.getElementById("error").style.display="block";
            document.getElementById("error").innerHTML= "Password and Confirm password does not match!";

        }
    }

    const classes = useStyles();
    const [values, setValues] = React.useState({
      amount: "",
      password2: "",
      weight: "",
      weightRange: "",
      showPassword: false,
      showPassword2: false
    });
  
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };

    
    
      const handleClickShowPassword2 = () => {
        setValues({ ...values, showPassword2: !values.showPassword2 });
      };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const responseGoogle = (response) => {
        
        if(response.profileObj){
            var userDetails = null;
            
            if(response.profileObj.googleId && response.profileObj.googleId!=""){
                
                axios.post(`http://adscombined.com/v1/public/api/login?email=${encodeURIComponent(response.profileObj.email)}&google_login_id=${response.profileObj.googleId}&name=${encodeURIComponent(response.profileObj.name)}`)
                .then( (response) => {

                    if(response.data.flag){
                        setUserSession(response.data.user.token, response.data.user);
                        document.getElementById("error").style.display="none";
                        window.location.href="/favourites";
                        
                    }else{

                        document.getElementById("error").style.display="block";
                        document.getElementById("error").innerHTML= response.data.message;
                    }
                })
            }
        }

    }

  
    return (
       
        <div>
            <section className="main-container">
                <div className="container" style={{ padding: "0px" }}>
                    <br />
                    <div className="row">
                        <div className="col-md-12 ">
                            <nav aria-label="breadcrumb">
                                <ol
                                    className="breadcrumb"
                                    style={{ backgroundColor: "transparent" }}
                                >
                                    <li className="breadcrumb-item">
                                        <a href="\">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active " aria-current="page">
                                        Register
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row pb-3">
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    
                                    <h3 className="p-3" style={{textAlign:"center",background:"",color:"#f56f36",fontWeight:"bold"}}>Register</h3>
                                   
                                    <div id="error" style={{display:"none"}}  className={"alert alert-danger alert-dismissible fade show"} role="alert">
                                        
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div id="success"  style={{display:"none"}}  className={"alert alert-success alert-dismissible fade show"} role="alert">
                                        Account created you can <Link to="/login">Login</Link> now. 
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div> 
                                    <div id="form-div">
                                        <div className="col-md-12 pt-2 pb-2">
                                            <TextField id="name" label="Your Name"  color="green" variant="outlined" style={{width:"100%"}}/>
                                        </div>
                                        <div className="col-md-12 pt-2 pb-2">
                                            <TextField id="email" label="Your Email Address"  color="green" variant="outlined" style={{width:"100%"}}/>
                                        </div>
                                        <div className="col-md-12 pt-2 pb-2">
                                            <FormControl
                                                className={clsx(classes.margin, classes.textField)}
                                                variant="outlined"
                                                style={{width:"100%"}}
                                            >
                                                <InputLabel htmlFor="password">
                                                Password
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="password"
                                                    type={values.showPassword ? "text" : "password"}
                                                    value={values.password}
                                                    onChange={handleChange("password")}
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-md-12 pt-2 pb-2">
                                            <FormControl
                                                className={clsx(classes.margin, classes.textField)}
                                                variant="outlined"
                                                style={{width:"100%"}}
                                            >
                                                <InputLabel htmlFor="confirm_password">
                                                Confirm Password
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="confirm_password"
                                                    type={values.showPassword2 ? "text" : "password"}
                                                    value={values.password1}
                                                    onChange={handleChange("confirm_password")}
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword2}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-md-12 pt-4 pb-2">
                                            <button type="button" id="button-login" className="btn btn-info col-md-12"  onClick={handleRegister}>
                                            Create Account
                                            </button>
                                        </div>

                                        <center className="mt-2">OR</center>
                                       
                                        <div className="mt-2 col-md-12 " id="google-button-div">
                                           <GoogleLogin
                                               clientId="427160671438-i27q35akeoboijp4siiigpvqs1knosp7.apps.googleusercontent.com"
                                               buttonText="Continue with Google"
                                               onSuccess={responseGoogle}
                                               onFailure={responseGoogle}
                                               cookiePolicy={'single_host_origin'}
                                               className="col-md-12 pl-0 pr-0 btn-default "
                                           />
                                        </div>

                                        <div className="col-md-12 pt-4 pb-2">
                                            <div className=" mt-2 col-md-12">
                                                    Already have an account ?  {" "}
                                                    <Link  to="/login">
                                                    <b>Login</b>
                                                    </Link> here.
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className="col-md-4">
                        </div>                      
                    </div>

                </div>
            </section>
        </div>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
   
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
}

export default Register;
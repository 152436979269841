import React, { PureComponent } from "react";

class CouponTypeDisplay extends PureComponent{

    constructor(props){
        super(props);
    }
    getSmartText(coupontype){
        var response = "";
        switch(coupontype){
            case 'Sale':
            return (<div>{coupontype} </div>);
            break;
            case 'Promo':
                return (<div> {coupontype} </div>);
            break;
            case 'Free Shipping':
            var arr= coupontype.split(' ');
            if(this.props.source == "couponpage"){
                return ( <div> {arr[0]} <span className="type-text">{arr[1]}</span></div>);
            }else{
                return ( <div> {arr[0]} {arr[1]}</div>);
            }
            break;
            case 'Clearance':
                return (<div> {coupontype} </div>);
            break;
            case 'Weekly Ad':
                var arr= coupontype.split(' ');
                if(this.props.source == "couponpage"){
                    return ( <div> {arr[0]}  <span className="type-text">{arr[1]}</span></div>);
                }else{
                    return ( <div> {arr[0]} {arr[1]}</div>);
                }
            break;
            case "Today's Ad":
                var arr= coupontype.split(' ');
                if(this.props.source == "couponpage"){
                    return ( <div> {arr[0]}  <span className="type-text">{arr[1]}</span></div>);
                }else{
                    return ( <div> {arr[0]}  {arr[1]}</div>);
                }
                
            break;
            case 'Event':
                return (<div> {coupontype}</div>);
            break;
            case 'Gift':
                return (<div> {coupontype}</div>);
            break;
            case 'Coupon':
                return (<div>{coupontype}</div>);
            break;
            case 'Student':
                return (<div> {coupontype}</div>);
            break;
            case 'Referral':
                return (<div> {coupontype}</div>);
            break;

            case 'Reward':
                return (<div> {coupontype}</div>);
            case 'Cash Back':
                var arr= coupontype.split(' ');
                if(this.props.source == "couponpage"){
                    return (<div> {arr[0]} <span className="type-text">{arr[1]}</span> </div>);
                }else{
                    return (<div> {arr[0]} {arr[1]}</div>);
                }
            break;
            case 'New Arrival':
                var arr= coupontype.split(' ');
                if(this.props.source == "couponpage"){
                    return (<div> {arr[0]} <span className="type-text">{arr[1]}</span> </div>);
                }else{
                    return (<div> {arr[0]} {arr[1]}</div>);
                }
            break;
            case 'Rebate':
                return (<div>{coupontype}</div>);
            break;
            case 'BOGO':
                return (<div>{coupontype}</div>);
            break;

            case 'Printable Coupon':
                var arr= coupontype.split(' '); 
                if(this.props.source == "couponpage"){
                    return ( <div> <span className="type-text">{arr[0]}</span> {arr[1]} </div>);
                }else{
                    return ( <div> {arr[0]} {arr[1]} </div>);
                }
                
            break;
            case 'Seasonal':
                return (<div>{coupontype}</div>);
            break;
            default:
                if(coupontype.includes("up")){

                    var arr= coupontype.split(' '); 
                    if(this.props.source == "couponpage"){
                        return ( <div>  <span className="type-text">{arr[0]} {arr[1]}</span> {arr[2]}</div>);
                    }else{
                        return ( <div> {arr[0]} {arr[1]} {arr[2]}</div>);
                    }
                    

                }else{

                    var arr= coupontype.split(' '); 
                    if(this.props.source == "couponpage"){
                        return ( <div> {arr[0]} <span className="type-text">{arr[1]}</span></div>);
                    }else{
                        return ( <div> {arr[0]} {arr[1]}</div>);
                    }
                    
                }
               
            break;

        }

        return response;
    }
    render(){

        return (
           <span>
{this.getSmartText(this.props.coupontype)}
           </span>
        );
    }
}

export default CouponTypeDisplay;
import React, { PureComponent } from "react";

import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import TopStoresSkeleton from "./skeletons/TopStoresSkeleton";
import AddtoFavourite from "./AddtoFavourite";

class TopStores extends PureComponent {
  state = {
    loading: false,
    stores: [],
  };
  componentDidMount() {
    this.receivedData();
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  receivedData() {
    this.setState({ visible: 20 });
    
    var homepage_stores =  localStorage.getItem("homepage_stores");
    if(!homepage_stores){

      this.setState({ loading: true }, () => {
        axios
          .get(`http://adscombined.com/v1/public/api/HomepageStores`)
          .then(
            (response) => {
              if (response.data.stores) {
                var storess = response.data.stores;
                this.setState({ stores: storess });
                localStorage.setItem("homepage_stores", JSON.stringify(storess));
                this.setState({ loading: false });
              } else {
                this.setState({ loading: false });
              }
            },
            (error) => {
              this.setState({ loading: false });
              console.log(error);
            }
          );
      });
    }else{

      this.setState({stores:JSON.parse(homepage_stores)})
    }
  
  }
  
  render() {

    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 9,
      slidesToScroll: 1,
      initialSlide: 0,
      lazyLoad: true,
      autoplay: true,
      speed:3000,
      autoplaySpeed: 2500,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1124,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    // const responsive = {
    //     superLargeDesktop: {
    //       // the naming can be any, depends on you.
    //       breakpoint: { max: 4000, min: 3000 },
    //       items: 7
    //     },
    //     desktop: {
    //       breakpoint: { max: 3000, min: 1024 },
    //       items: 4
    //     },
    //     tablet: {
    //       breakpoint: { max: 1024, min: 464 },
    //       items: 3
    //     },
    //     mobile: {
    //       breakpoint: { max: 464, min: 0 },
    //       items: 2
    //     }
    //   };
    return (
    
          <div className="mt-2">
            
              
                {this.state.loading ? (
                  <TopStoresSkeleton items={[1,2,3,4,5,6,7,8,9]} />
                ) : (
                    this.state.stores.length > 0 ? (
                      <div>
                  <ul
                  className="featured-grid2 clearfix flex flex-2 flex-22 "
                  style={{ padding: "0px" }}
                >
                  <Slider {...settings}>
                  {this.state.stores && this.state.stores.map((store) => (
                        <li key={store.id} className="storelist  bg-white">
                          <p style={{marginTop:"auto",width:"90px"}}>
                            <Link
                              className="logo  cust_link featured"
                              to={"coupons/" + store.StoreURL}
                              title={
                                this.Capitalize(store.StoreName) +" Coupons, Promotion Codes & Deals"
                              }
                            >
                              {store.Image ? (
                                <img
                                
                                  alt = {
                                    this.Capitalize(store.StoreName) +" Coupons, Promotion Codes & Deals"
                                  }
                                  className="lazy loaded"
                                  width={85}
                                  height={80}
                                  src={window.$logos_url+store.Image.replace(".PNG",".png")}
                                  data-loaded="true"
                                    style={{objectFit: "contain"}}
                                />
                              ) : (
                                <img
                                alt = {
                                  this.Capitalize(store.StoreName) +" Coupons, Promotion Codes & Deals"
                                }
                                  className="lazy loaded"
                                  width={85}
                                  height={80}
                                  src={process.env.PUBLIC_URL + "/default_logo.png"}
                                  alt={
                                    this.Capitalize(store.StoreName)
                                  }
                                    style={{objectFit: "contain"}}
                                  data-loaded="true"
                                />
                              )}
                              <span width={85} className="coupon-count">
                                
                                {store.TotalCouponsCount > 0 ? (
                                  <span>{store.TotalCouponsCount + " Coupons "} </span>
                                ) : (
                                  <span></span>
                                )}
                                {store.DealsCount >0 && store.TotalCouponsCount>0 ? " & " :""}
                                {store.DealsCount > 0 ? (
                                  store.DealsCount + " Deals "
                                ) : (
                                  <span></span>
                                )}
                              </span>
                            </Link>
                          </p>
                        </li>
                      ))}           
                  </Slider>
                  </ul>
                  </div>
                    
                  ) : (
                    <p>No Stores available</p>
                  )
                )
                  }
            </div>
    );
  }
}

export default React.memo(TopStores);

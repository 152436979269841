import React from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import { PureComponent } from "react";
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import { getUser, removeUserSession } from "../utils/Common";
import { Skeleton } from "@material-ui/lab";
const user = getUser();

class EditSubscription extends PureComponent{

    constructor(props){
        super(props);
        this.state= {
            show_cats:false,
            error:false,
            error_message:"",
            error_type:"",
            categories:[],
            subscription:[],
            isLoading:false
        }
        this.checkShowCats= this.checkShowCats.bind(this);
        this.subscriptionRequest = this.subscriptionRequest.bind(this);
        this.sendSubscribed = this.sendSubscribed.bind(this);
        this.fetchName = this.fetchName.bind(this);
        
    }
    fetchName(email) {
        if(this.tfName){
            if(this.tfName.value == '') {
                // only update if user have not changed the input value. 
                this.tfName.value = email; 
            }
        }
    }
    componentDidMount(){

        var user_id = "";
        if(user ){
            user_id = "?&user_id="+user.id;
        }
        
        if(this.props.subscription_id && this.props.subscription_id!=""){
            this.setState({isLoading:true});
            axios
            .get(
              `http://adscombined.com/v1/public/api/subscription_list${user_id}`,{
                // headers: {
                //   'Accept' :'application/json',
                //   'Access-Control-Allow-Origin' :'*',
                //   'Authorization': `Bearer ${this.state.token}`
                // }
              }
            )
            .then(
              (response) => {
                if (response.data.flag) {
                  if (response.data.subscriptions) {
                    var subscriptions = response.data.subscriptions;
                        subscriptions.map((subs) =>{
                       
                            if(subs.id == this.props.subscription_id){
                               
                                this.setState({subscription:subs});
                               this.fetchName(subs.email);
                                if(subs.alert_preference == "coupons"){
                                   
                                    axios.get(`http://adscombined.com/v1/public/api/AllCategories`).then((response) => {
          
                                        if(response.data.categories){

                                           const categories = response.data.categories;
                                           this.setState({categories});
                                           
                                        }else{
                                        }
                                     }, (error) => {
                                        console.log(error);
                                     });
                                    this.setState({show_cats:true});
                                }
                            }
                        }
                    )
                    this.setState({isLoading:false});
                  }
                }
                this.setState({isLoading:false});
              },
              (error) => {
                this.setState({isLoading:false});
                console.log(error);
              }
            );
        }
    }
    sendSubscribed(email,alert_preference,alert_category,alert_frequency){
        document.getElementById('button-submit').disabled = true;
        document.getElementById('button-submit').innerHTML="Processing..";
        axios
        .get(
          `http://adscombined.com/v1/public/api/edit_subscription?user_id=${user.id}&subscription_id=${this.props.subscription_id}&email=${encodeURIComponent(email)}&alert_preference=${encodeURIComponent(alert_preference)}&alert_category=${encodeURIComponent(alert_category)}&frequency=${alert_frequency}`
        )
        .then(
          (response) => {

            if (response.data.flag) {
              
              this.setState({show_cats:false,error:true,error_type:"success",error_message:response.data.message});
             
             
            }else{
              this.setState({error:true,error_type:"success"});
            }
            document.getElementById('button-submit').disabled = false;
            document.getElementById('button-submit').innerHTML="Submit";
          },
          (error) => {
            document.getElementById('button-submit').disabled = false;
            document.getElementById('button-submit').innerHTML="Submit";
            this.setState({ loading: false });
            this.setState({
              error: true,
              error_type: "danger",
              error_message: "Error Occured Try Again!",
            });
          }
        )
    }


    subscriptionRequest(){
        
        if(this.state.error){
                        
            this.setState({error:false});
        }
        var email = document.getElementById('email').value;
        var alert_preference = document.getElementById('alert_preference').value;
        var alert_category = "";
        var alert_frequency = document.getElementById('alert_frequency').value;
       
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){

            if(email && email!="" && alert_preference && alert_preference!=""){

                if(alert_preference && alert_preference == "coupons"){
                    
                    alert_category = document.getElementById("alert_category").value;
                    if(!alert_category || alert_category == ""){
                        
                        this.setState({error:true,error_type:"danger",error_message:"For 'Coupons' as your alert preference, Favourite Category is required"});
                        
                    }
                    this.sendSubscribed(email,alert_preference,alert_category,alert_frequency);
                }else{
                    this.sendSubscribed(email,alert_preference,alert_category,alert_frequency);
                }

            }else{

                this.setState({error:true,error_type:"danger",error_message:"Email and Alert Preference are required for subscription"});
            }   
        }else{
            this.setState({error:true,error_type:"danger",error_message:"Invalid email, please enter a valid email i.e. abc@mail.com"});
        }
        
    }

    checkShowCats(){
        var choice = document.getElementById("alert_preference").value;
        if(choice && choice == "coupons"){
            axios.get(`http://adscombined.com/v1/public/api/AllCategories`).then((response) => {
          
            
                if(response.data.categories){
                   const categories = response.data.categories;
                   this.setState({categories});
                   
                
                }else{
                }
             }, (error) => {
                console.log(error);
             });
            this.setState({show_cats:true});
        }else{
            this.setState({show_cats:false});
        }
    }

    render(){
        var  coupon_types= [
            ["/coupons/coupon", "Coupon"],
            ["/coupons/promo", "Promo"],
            ["/coupons/free-shipping", "Free Shipping"],
            ["/coupons/clearance", "Clearance"],
            ["/coupons/weekly-ads", "Weekly Ad"],
            ["/coupons/today-ads", "Today's Ad"],
            ["/coupons/sale", "Sale"],
            ["/coupons/event", "Event"],
            ["/coupons/gift", "Gift"],
            ["/coupons/student", "Student"],
            ["/coupons/referral", "Referral"],
            ["/coupons/reward", "Reward"],
            ["/coupons/cash-back", "Cash Back"],
            ["/coupons/rebate", "Rebate"],
            ["/coupons/printable-coupon", "Printable Coupon"],
            ["/coupons/seasonal", "Seasonal"],
            ["/coupons/bogo", "BOGO"]
            
          ];
        
        const coupon_type_list = coupon_types.map((coupon) => (
            <span>
                <Link to={coupon[0]} title={"View All "+coupon[1]} style={{color:"white !important",textTransform:"lowercase"}}>
                    {coupon[1]}
                </Link>,&nbsp;
            </span>
            
        ));

        return(
            <div>
                <h4>Edit Subscription <Link style={{float:"right",fontSize:"15px"}} className="btn btn-info" to="/subscriptions">Back to Subscriptions</Link></h4>
                <div >
                    <div className=" text-center text-md-left">  
                    <div className="row  ">
                    
                        <div className="col-md-12" style={{boxShadow:" rgb(78, 78, 78) 1px 4px 8px;",background:"white",borderRadius:"0px",paddingTop: "15px",paddingBottom: "15px"}}> 
                            
                            {this.state.error? 
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className={"alert alert-"+this.state.error_type+" alert-dismissible fade show"} role="alert">
                                            {this.state.error_message}
                                            <button type="button" className="close"  data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <span></span>
                            }
                            
                            {this.state.isLoading ?
                                <span>
                                  <div className="row">
                            
                                    <Skeleton className=" col-md-5"   height={50}/>
                                    <div className="col-md-1"></div>
                                    <Skeleton className="col-md-6 "  height={50} />
                                    
                                    <Skeleton className="col-md-5 "  height={50} />
                                    <div className="col-md-1"></div>
                                    <Skeleton className=" col-md-6"  height={50} />
                                  
                                </div>
                                <div className="row">
                                   
                                    <Skeleton width={80} height={50}></Skeleton>
                                   
                                </div>
                            </span>
                            :
                            
                           <span>
                            <div className="row" onLoad={this.fetchName(this.state.subscription.email)}>
                            
                                <div className=" form-group  col-md-6">
                                <TextField id="email" label="Your Email Address" defaultValue={this.state.subscription.email} color="green" variant="outlined" style={{width:"100%"}}/>
                                  
                                </div>
                                <div className="form-group col-md-6">
                                    <b>Alert Preference</b>
                                    <select  id="alert_preference" className=" form-control"  onChange={this.checkShowCats} >
                                        <option value="deals" selected ={this.state.subscription.alert_preference == "deals" ? "selected" : ""}>Deals</option>
                                        <option value="coupons" selected ={this.state.subscription.alert_preference == "coupons" ? "selected" : ""}>Coupons</option>
                                    </select>
                                </div>
                               
                                {this.state.show_cats ?
                                    <div className="form-group col-md-6" id="category-div">
                                        <b>Choose Favourite Category</b>
                                    <select id="alert_category" className=" form-control">
                                    {this.state.categories.map(category =>
                                        
                                        <option value={category.Category} 
                                        selected ={this.state.subscription.alert_category == category.Category? "selected": ""}
                                        >{category.Category} </option>
                                    )
                                    }
                                    </select>
                                    </div>
                                    :   
                                    <span></span>
                                }
                                 <div className="form-group col-md-6">
                                    <b>Alert Frequency</b>
                                    <select  id="alert_frequency" className=" form-control"  >
                                        <option value="Daily" selected ={this.state.subscription.frequency == "Daily" ? "selected" : ""}>Daily</option>
                                        <option value="Weekly"  selected ={this.state.subscription.frequency == "Weekly" ? "selected" : ""}>Weekly</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <button type="button" id="button-submit" className="btn btn-info" onClick={this.subscriptionRequest}>
                                    Submit
                                    </button>
                                </div>
                            </div>
                                </span>
                             }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditSubscription;
// return the user data from the session storage
var CryptoJS = require("crypto-js");

export const getUser = () => {
  
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
   
  // return the token from the session storage
  export const getToken = () => {
    var token = localStorage.getItem('token') || null;
    if(token){
      var bytes = CryptoJS.AES.decrypt(token, window.$sitekey);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }else{
      return null;
    }
  
  }
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
   
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
   
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(token),  window.$sitekey).toString();
  
    localStorage.setItem('token', ciphertext);
    localStorage.setItem('user', JSON.stringify(user));
  }

   // set user from the session storage
   export const setUser = ( user) => {
   
    localStorage.setItem('user', JSON.stringify(user));
  }
import React, { Component,useState } from "react";
import axios from "axios";
import clsx from "clsx";
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { setUserSession } from "../utils/Common";
import { GoogleLogin } from 'react-google-login';

// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const useStyles = makeStyles((theme) => ({
    root: {
     
    },
    margin: {
     
    },
    withoutLabel: {
      marginTop: theme.spacing(3)
    },
    textField: {
      width: "25ch"
    }
  }));

function Login (props){

    var loading = false;
    // handle button click of login form
    const handleLogin = () => {
        document.getElementById("button-login").innerHTML = "Processing...";
        document.getElementById("button-login").disabled = true;
        var username = document.getElementById("email").value;
        var password  = document.getElementById("password").value; 
        if(username !="" && password!=""){
            axios.post(`http://adscombined.com/v1/public/api/login?email=${encodeURIComponent(username)}&password=${password}`)
            .then( (response) => {
                console.log("Response: " , response);
                if(response.data.flag){
                    
                    if(response.data.user.email_verified == 0){

                        setUserSession(response.data.user.token, response.data.user);
                        document.getElementById("error").style.display="none";
                         window.location.href="/favourites";

                    }else{

                        axios.get(`http://adscombined.com/v1/public/api/verify_email?email=${encodeURIComponent(username)}`)
                        .then( (response) => {
                            if(response.data.flag){

                                document.getElementById("button-login").innerHTML = "Login";
                                document.getElementById("button-login").disabled =false;
                                document.getElementById("error").style.display="block";
                                document.getElementById("error").innerHTML= response.data.message;
                            }else{

                                document.getElementById("button-login").innerHTML = "Login";
                                document.getElementById("button-login").disabled =false;
                                document.getElementById("error").style.display="block";
                                document.getElementById("error").innerHTML= response.data.message;
                            }

                        },(error) =>{
                            document.getElementById("button-login").innerHTML = "Login";
                                document.getElementById("button-login").disabled =false;
                                document.getElementById("error").style.display="block";
                                document.getElementById("error").innerHTML= response.data.error;
                            
                            }
                        );
                     

                    }
                    
                    
                  
                }else{
                    document.getElementById("button-login").innerHTML = "Login";
                    document.getElementById("button-login").disabled =false;
                    document.getElementById("error").style.display="block";
                    document.getElementById("error").innerHTML= response.data.error;
                  
                }
            }
            , (error) => {
                document.getElementById("button-login").innerHTML = "Login";
                document.getElementById("button-login").disabled =false;
                
                document.getElementById("error").style.display="block";
                document.getElementById("error").innerHTML= error;
            }
            );
        }
    }

    const classes = useStyles();
    const [values, setValues] = React.useState({
      amount: "",
      password: "",
      weight: "",
      weightRange: "",
      showPassword: false
    });
  
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    
    const responseGoogle = (response) => {
        console.log(response.profileObj);
        if(response.profileObj){
            var userDetails = null;
            
            if(response.profileObj.googleId && response.profileObj.googleId!=""){
                
                axios.post(`http://adscombined.com/v1/public/api/login?email=${encodeURIComponent(response.profileObj.email)}&google_login_id=${response.profileObj.googleId}&name=${encodeURIComponent(response.profileObj.name)}`)
                .then( (response) => {

                    if(response.data.flag){
                        setUserSession(response.data.user.token, response.data.user);
                        document.getElementById("error").style.display="none";
                        window.location.href="/favourites";
                        
                    }else{

                        document.getElementById("error").style.display="block";
                        document.getElementById("error").innerHTML= response.data.message;
                    }
                })
            }
        }
    }

     
    // const responseFacebook = (response) => {
    //     console.log(response);
    // }
  

  
    return (
        <div>
            <section className="main-container">
                <div className="container" style={{ padding: "0px" }}>
                    <br />
                    <div className="row">
                        <div className="col-md-12 ">
                            <nav aria-label="breadcrumb">
                                <ol
                                    className="breadcrumb"
                                    style={{ backgroundColor: "transparent" }}
                                >
                                    <li className="breadcrumb-item">
                                        <a href="\">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active " aria-current="page">
                                        Login
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row pb-5">
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    
                                    <h3 className="p-3" style={{textAlign:"center",background:"",color:"#f56f36",fontWeight:"bold"}}>Login</h3>
                                   
                                     <div id="error" style={{display:"none"}} className={"alert alert-danger alert-dismissible fade show"} role="alert">
                                        
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
      
                                    <div className="col-md-12 pt-2 pb-2">
                                        <TextField id="email" label="Your Email Address"  color="green" variant="outlined" style={{width:"100%"}}/>
                                    </div>
                                    <div className="col-md-12 pt-2 pb-2">
                                        <FormControl
                                            className={clsx(classes.margin, classes.textField)}
                                            variant="outlined"
                                            style={{width:"100%"}}
                                        >
                                            <InputLabel htmlFor="password">
                                               Password
                                            </InputLabel>
                                            <OutlinedInput
                                                id="password"
                                                type={values.showPassword ? "text" : "password"}
                                                value={values.password}
                                                onChange={handleChange("password")}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                                labelWidth={70}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-md-12 pt-4 pb-2">
                                        <p>
                                        <b className="mb-2" style={{float:"right",fontSize:"14px"}}><Link style={{color:"#f56f36"}} to="/forgot_password">Forgot Password?</Link></b>
                                            </p>
                                        <button type="button" id="button-login" className="btn btn-info col-md-12"  onClick={handleLogin}>
                                          Login
                                        </button>
                                       
                                        <div className=" mt-2 col-md-12">
                                            Do not have an account ?  {" "}
                                            <Link  to="/register">
                                            <b>Register</b>
                                            </Link> here.
                                        </div>
                                       
                                        <center className="mt-2">OR</center>
                                       
                                        <div className="mt-2 col-md-12 pl-0 pr-0" id="google-button-div">
                                            <GoogleLogin
                                                clientId="427160671438-i27q35akeoboijp4siiigpvqs1knosp7.apps.googleusercontent.com"
                                                buttonText="Continue with Google"
                                                onSuccess={responseGoogle}
                                                onFailure={responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                                className="col-md-12 pl-0 pr-0 btn-default "
                                             
                                            />
                                        </div>
                                        {/* <div className="mt-2 col-md-12 pl-0 pr-0">
                                            <FacebookLogin
                                                appId="177294337292406"
                                                autoLoad={true}
                                                fields="name,email,picture"
                                               
                                                callback={responseFacebook} 
                                                render={renderProps => (
                                                    <button className="btn btn-primary col-md-12 mt-2" onClick={renderProps.onClick} style={{textAlign:"left", backgroundColor:"#3b5998",borderColor:"#3b5998"}}><i className="fa  fa-facebook" style={{float:"left",padding:"0",margin:"0",fontSize:"20px ​!importan", }}></i> Continue with Facebook</button>
                                                  )}
                                                />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className="col-md-4">
                        </div>                      
                    </div>

                </div>
            </section>
        </div>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
   
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
}

export default Login;
import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import StoreDetailsSkeleton from "./skeletons/StoreDetailsSkeleton";
import BreadcrumSkeleton from "./skeletons/BreadcrumSkeleton";
import Deals from "./Deals";
import Coupens from "./Coupens";
import Advertisement from "./Advertisement";
import { Skeleton } from "@material-ui/lab";

class Store extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			storedetail: [],
			record_type: props.record_type,
			store_id: null,
			links: [],
			coupon_type: "all",
			sub_type:null,
			loading: false,
			expanded: ["2"],
		};

		this.handler = this.handler.bind(this);
		this.changeCouponType = this.changeCouponType.bind(this);
		this.getSocialLinks = this.getSocialLinks.bind(this);
	}

	getSocialLinks(links) {
		if (links) {
			return links.split("|");
		} else {
			return [];
		}
	}

	changeCouponType(type) {
		
	
			this.setState({ coupon_type: type });
		

		this.setState({ record_type: "coupons" });
	}

	showComponent(componentName) {
		this.setState({ record_type: componentName });
		this.setState({ coupon_type: "all" });
	}

	handler(coupon,sub_type=null) {

			this.setState({
				coupon_type: coupon,
				sub_type:sub_type
			});
		
	}

	renderComponent() {
		switch (this.state.record_type) {
			
			case "coupons":
				return (
					<Coupens
						store_id={this.state.store_id}
						store_info={this.state.storedetail}
						sub_type = {this.state.sub_type}
						store_name={this.state.storedetail.StoreName}
						coupon_type={this.state.coupon_type}
						action={this.handler}
					/>
				);
			case "deals":
				return (
					<Deals
						total={this.state.storedetail.DealsCount}
						store_id={this.state.store_id}
						store_name={this.state.storedetail.StoreName}
						store_info={this.state.storedetail}
					/>
				);
		}
	}

	componentDidMount(coupon = "") {
		const params = new URLSearchParams(window.location.search);

		if (params.has("d")) {
			this.setState({ record_type: "deals" });
		}
		if (params.has("c")) {
			this.setState({ record_type: "coupons" });
		}
		var store_id = "";
		if (this.props.store_id && this.props.store_id !== "") {
			store_id = this.props.store_id;
			if (!this.state.store_id) {
				this.setState({ store_id: store_id });
			}
		} else {
			store_id = this.state.store_id  ?  this.state.store_id : this.props.match.params.id;
			if (!this.state.store_id) {
				this.setState({ store_id: store_id });
			}
		}
	

		this.setState({ loading: true }, () => {
			axios
				.post(
					`http://adscombined.com/v1/public/api/StoreInformation?store_url=${encodeURIComponent(
						store_id 
					)}`
				)
				.then(
					(response) => {
						if (response.data.flag) {
							const storedetail = response.data.store_data;
							var sub_types_counts = response.data.sub_types_counts;
							var links = [];
							if (response.data.store_data && storedetail.SocialLinks) {
								links = JSON.parse(storedetail.SocialLinks.replace(/'/g, '"'));
							}

							this.setState({ links: links, storedetail,sub_types_counts:sub_types_counts });
							if (
								this.state.storedetail.TotalCouponsCount < 1 &&
								this.state.storedetail.DealsCount > 0
							) {
								this.setState({ record_type: "deals" });
							}
						}
						this.setState({ loading: false });
					},
					(error) => {
						this.setState({ loading: false });

						//console.log(error.response.data);
					}
				);
		});
	}
	Capitalize(str) {
		str = str.trim();
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>
						{this.state.storedetail.StoreName
							? this.Capitalize(this.state.storedetail.StoreName) +
							  "'s Deals and Coupons "
							: " Deals and Coupons "}{" "}
						|{window.$sitename}
					</title>
					<meta
						name="description"
						content={
							"In " +
							window.$sitename +
							" you can find best Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks and Seasonal Coupons for " +
							this.state.storedetail.StoreName +
							"."
						}
					/>
					<meta
						name="keywords"
						content={
							"Deals, Promos, Free Shipping, Clearance, Weekly Ads, Todays Ads, Sales, Events, Gifts, Coupons, Referal, Student, Rewards, Rebates, Printable Coupons, CashBacks, Seasonal Coupons"
						}
					/>
				</Helmet>
				<section className="main-container">
					<div className="container" style={{ padding: "0px" }}>
						<br />
						<div className="row">
							<div className="col-md-12 ">
								<nav aria-label="breadcrumb">
									{this.state.loading ? (
										<BreadcrumSkeleton />
									) : (
										<ol
											className="breadcrumb"
											style={{ backgroundColor: "transparent" }}
										>
											<li className="breadcrumb-item">
												<Link to="/">Home</Link>
											</li>
											<li className="breadcrumb-item">
												<Link to="/allstores">All Stores</Link>
											</li>
											<li
												className="breadcrumb-item active"
												aria-current="page"
												style={{ textTransform: "capitalize" }}
											>
												{this.state.storedetail.StoreName}
											</li>
										</ol>
									)}
								</nav>
							</div>
						</div>
						<div className="col-md-12 mb-3">
							<Advertisement ad_type="full width"></Advertisement>
						</div>
						<div className="row">
							<div className="col-md-4 ">
								{this.state.loading ? (
									<div className="card single-coupen mt-2">
										<div className="card-body">
											<StoreDetailsSkeleton />
										</div>
									</div>
								) : (
									<div>
										<div className="card">
											<div className="card-body">
												<center>
													{this.state.storedetail.Image ? (
														<img
															alt={
																this.state.storedetail.StoreName +
																" Coupons & Deals"
															}
															className="lazy loaded"
															width={100}
															height={100}
															src={
																window.$logos_url + this.state.storedetail.Image.replace(".PNG",".png")
															}
															data-loaded="true"
															style={{ objectFit: "contain" }}
														/>
													) : (
														<img
															className="lazy loaded"
															width={100}
															height={100}
															src={process.env.PUBLIC_URL + "/default_logo.png"}
															alt={
																this.state.storedetail.StoreName +
																" Coupons & Deals"
															}
															data-loaded="true"
															style={{ objectFit: "contain" }}
														/>
													)}
													<h3 style={{ textTransform: "capitalize" }}>
														{this.state.storedetail.StoreName}
													</h3>
												</center>

												<p>
													{/* <ul style={{marginLeft:"-22px"}}>
                                            
                                            <li>
                                                <a href="#"    onClick={() =>this.showComponent('coupons')}> Coupens</a>
                                            </li>
                                            <li>
                                                <a href="#"  onClick={() =>this.showComponent('deals')}> Deals </a>
                                            </li>
                                        </ul>
                                    */}
												</p>

												{this.state.storedetail.Description ? (
													<p>{this.state.storedetail.Description}</p>
												) : (
													<p></p>
												)}

												<br />

												{this.state.storedetail.Categories ? (
													<div className="p-1" style={{ fontStyle: "italic" }}>
														{this.state.storedetail.Categories.split(",").map(
															(Category) => (
																<b
																	className={` invert mr-1  mt-2  ml-1 no-decoration`}
																	style={{
																		color: "white",
																		background: "grey",
																		border: "1px solid",
																		padding: "8px 5px",
																		fontSize: "14px",
																		display: "inline-block",
																		borderRadius: "3px",
																		borderColor: "grey",
																		width: "fit-content",
																	}}
																>
																	<span style={{ whiteSpace: "nowrap" }}>
																		{Category}
																	</span>
																</b>
															)
														)}

														{this.state.storedetail.SubCategories.split(
															","
														).map((SubCategory) => (
															<b
																className={` invert mr-1 mt-2  ml-1 no-decoration`}
																style={{
																	background: "grey",
																	width: "fit-content",
																	border: "1px solid",
																	padding: "8px 5px",
																	borderRadius: "3px",
																	fontSize: "14px",
																	display: "inline-block",
																	borderColor: "grey",
																	color: "white ",
																}}
															>
																<span style={{ whiteSpace: "nowrap" }}>
																	{SubCategory}
																</span>
															</b>
														))}
													</div>
												) : (
													<span></span>
												)}
												<br />
												{this.state.links ? (
													<center>
														{this.state.links.Facebook &&
														this.state.links.Facebook !== "" ? (
															<a
																className="fa fa-facebook"
																href={this.state.links.Facebook}
															></a>
														) : (
															<span></span>
														)}
														{this.state.links.Twitter &&
														this.state.links.Twitter !== "" ? (
															<a
																className="fa fa-twitter"
																href={this.state.links.Twitter}
															></a>
														) : (
															<span></span>
														)}
														{this.state.links.Pintrest &&
														this.state.links.Pintrest !== "" ? (
															<a
																className="fa fa-pinterest-square"
																href={this.state.links.Pintrest}
															></a>
														) : (
															<span></span>
														)}
														{this.state.links.Instagram &&
														this.state.links.Instagram !== "" ? (
															<a
																className="fa fa-instagram"
																href={this.state.links.Instagram}
															></a>
														) : (
															<span></span>
														)}

														{this.state.links.Youtube &&
														this.state.links.Youtube !== "" ? (
															<a
																className="fa fa-youtube"
																href={this.state.links.Youtube}
															></a>
														) : (
															<span></span>
														)}
														{this.state.links.Spotify &&
														this.state.links.Spotify !== "" ? (
															<a
																className="fa fa-spotify"
																href={this.state.links.Spotify}
															></a>
														) : (
															<span></span>
														)}
														{this.state.links.Snapchat &&
														this.state.links.Snapchat !== "" ? (
															<a
																className="fa fa-snapchat-ghost "
																href={this.state.links.Snapchat}
															></a>
														) : (
															<span></span>
														)}
													</center>
												) : (
													<span></span>
												)}

												{/* {this.state.links.map((link)=>
                          <div>
                            <a href={link}>{link}</a>
                          </div>
                        )} */}
											</div>
										</div>
										<div className="card mt-2">
											<div className="card-body">
												<h5>
													<Link to={"/deals"} title="All Deals">
														Deals
													</Link>{" "}
													and{" "}
													<Link to={"/coupons/all"} title="All Coupons">
														Offers
													</Link>{" "}
													Available
												</h5>
												<br />
												<ul className="custom-nav">
													<Link
														to={"/deals/" + this.state.store_id}
														className="no-decoration "
														style={{ textDecoration: "none !important" }}
													>
														<li
															className={`custom-nav-li mt-1  ${
																this.state.record_type == "deals"
																	? "custom-nav-li-active"
																	: ""
															}`}
															style={{ cursor: "pointer" }}
															onClick={() => this.showComponent("deals")}
														>
															All Deals{" "}
															{this.state.storedetail.DealsCount > 0
																? "(" + this.state.storedetail.DealsCount + ")"
																: ""}
														</li>
													</Link>
													<Link
														to={"/coupons/" + this.state.store_id}
														className="no-decoration "
														style={{ textDecoration: "none !important" }}
													>
														<li
															className={`custom-nav-li mt-1   ${
																this.state.record_type == "coupons"
																	? "custom-nav-li-active"
																	: ""
															}`}
															style={{ cursor: "pointer" }}
															onClick={() => this.showComponent("coupons")}
														>
															All Offers{" "}
															{this.state.storedetail.TotalCouponsCount > 0
																? "(" +
																  this.state.storedetail.TotalCouponsCount +
																  ")"
																: ""}
														</li>
													</Link>
												</ul>
												<div className="ml-3">
													{this.state.storedetail.PromoCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Promo")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Promo"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Promo ({this.state.storedetail.PromoCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.FreeShippingCount > 0 ? (
														<button
															onClick={() =>
																this.changeCouponType("Free Shipping")
															}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Free Shipping"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Free Shipping (
																{this.state.storedetail.FreeShippingCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.ClearanceCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Clearance")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Clearance"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Clearance (
																{this.state.storedetail.ClearanceCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.WeeklyAdCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Weekly Ad")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Weekly Ad"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Weekly Ad (
																{this.state.storedetail.WeeklyAdCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.TodaysAdCount > 0 ? (
														<button
															onClick={() =>
																this.changeCouponType("Today's Ad")
															}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Today's Ad"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Today's Ad (
																{this.state.storedetail.TodaysAdCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.SaleCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Sale")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Sale"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Sale ({this.state.storedetail.SaleCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.EventsCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Event")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Event"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Event ({this.state.storedetail.EventsCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.GiftsCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Gift")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Gift"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Gift ({this.state.storedetail.GiftsCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.CouponCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Coupon")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Coupon"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Coupon ({this.state.storedetail.CouponCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.StudentCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Student")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Student"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Student ({this.state.storedetail.StudentCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.ReferalCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Referral")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Referral"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Referal ({this.state.storedetail.ReferalCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.RewardCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Reward")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Reward"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Reward ({this.state.storedetail.RewardCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.CashBackCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Cash Back")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Cash Back"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Cash Back (
																{this.state.storedetail.CashBackCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.RebateCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Rebate")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Rebate"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Rebate ({this.state.storedetail.RebateCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.PrintableCouponCount > 0 ? (
														<button
															onClick={() =>
																this.changeCouponType("Printable Coupon")
															}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Printable Coupon"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Printable Coupon (
																{this.state.storedetail.PrintableCouponCount})
															</span>
														</button>
													) : (
														<span></span>
													)}
														
													{this.state.storedetail.SeasonalCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("Seasonal")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "Seasonal"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																Seasonal ({this.state.storedetail.SeasonalCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.BOGOCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("BOGO")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "BOGO"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																BOGO ({this.state.storedetail.BOGOCount})
															</span>
														</button>
													) : (
														<span></span>
													)}

													{this.state.storedetail.NewArrivalCount > 0 ? (
														<button
															onClick={() => this.changeCouponType("New Arrival")}
															className={`pill invert mr-1  ml-1 mt-2  ${
																this.state.coupon_type == "New Arrival"
																	? "pill-active"
																	: ""
															}`}
															style={{
																cursor: "pointer",
																width: "fit-content",
															}}
														>
															<span style={{ whiteSpace: "nowrap" }}>
																New Arrival ({this.state.storedetail.NewArrivalCount})
															</span>
														</button>
													) : (
														<span></span>
													)}
												</div>
											</div>
										</div>
									</div>
								)}

								<br />
								<div className="card sticky_ad">
									<Advertisement ad_type=""></Advertisement>
								</div>
							</div>
							<div className="col-md-8">{this.renderComponent()}</div>
						</div>
					</div>
					<br />
				</section>
			</div>
		);
	}
}
export default Store;

import React, { PureComponent } from "react";

class ContactUs extends PureComponent {
  render() {
    return (
      <div>
          <h4>Contact Us</h4>
          <br></br>
         <p>Contact us and we'll get back to you within 24 hours.</p>
        <div id="contact" className="container-fluid">
          
          <div className="row bg-grey">
              
            <div className="col-sm-5   " style={{padding:"5%"}}>
              <h5>Address</h5>
              <p>
                <span className="glyphicon glyphicon-map-marker" /> A A C Road, Block-A Islamabad
              </p>
              <p>
                <span className="glyphicon glyphicon-phone" /> +92 515151515
              </p>
              <p>
                <span className="glyphicon glyphicon-envelope" />{" "}
                info@dealssite.com
              </p>
            </div>
            <div className="col-sm-7 slideanim " style={{padding:"5%"}}>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name"
                    type="text"
                    required
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    required
                  />
                </div>
              </div>
              <textarea
                className="form-control"
                id="comments"
                name="comments"
                placeholder="Message here"
                rows={5}
                defaultValue={""}
              />
              <br />
              <div className="row">
                <div className="col-sm-12 form-group">
                  <button className="btn btn-info pull-right" type="submit">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;

import React,{PureComponent} from 'react';
import Faq from 'react-faq-component';

const data = {
  title: "Accounts and Privacy",
  rows: [
    {
      title: "Does site cost me anything?",
      content: "Site is a completely free service—we don’t charge you a dime when you search our site, open an account, join our mailing list."
    },
    {
      title: "Does site share my information when I open an account?",
      content: "We value our users’ privacy and trust above all else. That’s why we never rent, sell, or share personal information about you with other people or non-affiliated companies, except to provide products or services you’ve requested when we have your permission. If at any time you would like to opt-out of our communications, please contact info@offers.com. See our <a href='http://dealssite.softwarepattern.net/terms-and-conditions' target='blank'>Terms of Use</a> and <a href='http://dealssite.softwarepattern.net/privacy-policy' target='blank'>Privacy Policy</a>."
    },
    {
      title: "How do I contact site Admin?",
      content: "If you do not find an answer to your query here on the FAQ page and require further assistance, please visit our <a href='http://dealssite.softwarepattern.net/contact-us' target='blank'>Contact Us</a> page for more info about how to reach DealsSite.com. "
    },
    ]
}
const data2 = {
    title: "How it works?",
    rows: [
      {
        title: "How do I use coupon codes?",
        content: "When ordering services or products online, companies and stores often provide special online coupon or promo codes for discounts you can apply to your purchase during checkout. Look for an input box labeled “coupon code”, “discount code”, or another variation, and paste or type the code you found on Offers.com in the text field. "
      },
      {
        title: "How do I submit an Offer?",
        content: "This feature is currently not available."
      },
      {
        title: "How i can find my favourite store or category?",
        content: "You can find all stores on our <a href='http://dealssite.softwarepattern.net/allstores'>Store Page</a> and all categories at our <a href='http://dealssite.softwarepattern.net/allcategories'>Categories Page</a>. "
      },
      ]
  }
class FAQs extends PureComponent{

    render(){

        return(
            <div>
                <h4>FAQs</h4>
                <br></br>
             <Faq data={data} styles={{
                    titleTextColor: "teal",
                    
                    rowContentTextSize: '14px',
                    rowTitleColor: "#4ab1b1"
                    
                    }} />
                    <br/>
                 <Faq data={data2} styles={{
                    titleTextColor: "teal",
                    
                    rowContentTextSize: '14px',
                    rowTitleColor: "#4ab1b1"
                    
                    }} />
            </div>
        );
    }
} 

export default FAQs;
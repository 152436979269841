import React from "react";
import clsx from "clsx";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { getUser,setUser} from "../utils/Common";

const user = getUser();
const useStyles = makeStyles((theme) => ({
  root: {
   
  },
  margin: {
   
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  
  textField: {
    width: "25ch"
  }
}));

function Profile (props){
    var new_name = "";
    const classes = useStyles();
    const [values, setValues] = React.useState({
      amount: "",
      password: "",
      weight: "",
      weightRange: "",
      showPassword: false,
      showPassword2: false,
      showPassword3: false
    });
  
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowPassword2 = () => {
      setValues({ ...values, showPassword2: !values.showPassword2 });
    };
    const handleClickShowPassword3 = () => {
      setValues({ ...values, showPassword3: !values.showPassword3 });
    };
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const updateProfile = () => {
      document.getElementById("button-submit").innerHTML = "Processing...";
      document.getElementById("button-submit").disabled = true;
      var username = document.getElementById("name").value;
      var password  = document.getElementById("password").value;
      var confirm_password  = document.getElementById("confirm_password").value;  
      var old_password = document.getElementById("old_password").value;
      var data = "";
      
      if(password!="" &&  confirm_password!="" && old_password!=""){
       
        if(password == confirm_password){
          data+="&newpassword="+encodeURIComponent(password)+"&oldpassword="+encodeURIComponent(old_password);
        }
        
      }
      if(username && username!=""){
        data+="&name="+encodeURIComponent(username);
      }
      if(data!=""){
        
      
        axios.get(`http://adscombined.com/v1/public/api/update_user?user_id=${user.id}${data}`)
        .then( (response) => {
            if(response.data.flag){
                
              setUser(response.data.user);
              document.getElementById("error").style.display="none";
             
                 window.location.href="/profile";
            }else{

                document.getElementById("button-submit").innerHTML = "Submit";
                document.getElementById("button-submit").disabled =false;
                document.getElementById("error").style.display="block";
                document.getElementById("error").innerHTML= response.data.error;
               
            }
        }
        , (error) => {
            document.getElementById("button-submit").innerHTML = "Submit";
            document.getElementById("button-submit").disabled =false;
            
            document.getElementById("error").style.display="block";
            document.getElementById("error").innerHTML= error;
        }
        );
        
      }

    }
    return (
        <div>
          <h4>
            My Profile
          </h4>
          
        <br></br>
          <div className="row">
            <div className="col-md-12">
              <div id="error" style={{display:"none"}} className={"alert alert-danger alert-dismissible fade show"} role="alert">
                                      
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">×</span>
                  </button>
              </div>
             
            </div>
            <div className="col-md-6">
              <TextField id="name" label="Your Name" defaultValue={user.name}    color="green" variant="outlined" style={{width:"100%"}}/>
            </div>

            <div className="col-md-6">
              <TextField  label="Your Email" disabled value={user.email} color="green" variant="outlined" style={{width:"100%"}}/>
            </div>
          </div>
          <br></br>
          <div className="row">
          <div className="col-md-6">
              <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  style={{width:"100%"}}
              >
                  <InputLabel htmlFor="old_password">
                     Old Password
                  </InputLabel >
                  <OutlinedInput
                      id="old_password"
                      type={values.showPassword3 ? "text" : "password"}
                      value={values.old_password}
                      onChange={handleChange("old_password")}
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword3}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          >
                          {values.showPassword3 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                      }
                      labelWidth={100}
                  />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  style={{width:"100%"}}
              >
                  <InputLabel htmlFor="password">
                      New Password
                  </InputLabel>
                  <OutlinedInput
                      id="password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange("password")}
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                      }
                      labelWidth={110}
                  />
              </FormControl>
            </div>

            <div className="col-md-6">
              <br/>
              <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  style={{width:"100%"}}
              >
                  <InputLabel htmlFor="confirm_password">
                     Confirm New Password
                  </InputLabel >
                  <OutlinedInput
                      id="confirm_password"
                      type={values.showPassword2 ? "text" : "password"}
                      value={values.confirm_password}
                      onChange={handleChange("confirm_password")}
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          >
                          {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                      </InputAdornment>
                      }
                      labelWidth={170}
                  />
              </FormControl>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-2">
              <button type="button" id="button-submit" title="Click to Update Profile" className="btn btn-info" onClick={updateProfile}>Submit</button>        
            </div>
          </div>
        </div>
    );
}
export default Profile;

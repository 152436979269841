import React, { PureComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
class StoreListSkeleton extends PureComponent{
    
    render(){
        return(
            <div>
                
            <br></br>
                <ul className="featured-grid  clearfix flex flex-4 flex-5-md " style={{padding:"0px"}}>
                <li className="storelist ml-2 mr-2 no_borders">
                        <p>
                            <a className="logo  cust_link featured" >
                                <span><Skeleton width={95} height={85}/></span>
                                <span className="coupon-count">
                                <b style={{textTransform:"capitalize",verticalAlign:"middle"}}> <Skeleton/></b><br></br>
                                <Skeleton/>
                                </span>
                            </a>
                            
                        </p>
                    </li>       
                    <li className="storelist ml-2 mr-2 no_borders">
                        <p>
                            <a className="logo  cust_link featured" >
                                <span><Skeleton width={95} height={85}/></span>
                                <span className="coupon-count">
                                <b style={{textTransform:"capitalize",verticalAlign:"middle"}}> <Skeleton/></b><br></br>
                                <Skeleton/>
                                </span>
                            </a>
                            
                        </p>
                    </li>   
                    <li className="storelist ml-2 mr-2 no_borders">
                        <p>
                            <a className="logo  cust_link featured" >
                                <span><Skeleton width={95} height={85}/></span>
                                <span className="coupon-count">
                                <b style={{textTransform:"capitalize",verticalAlign:"middle"}}> <Skeleton/></b><br></br>
                                <Skeleton/>
                                </span>
                            </a>
                            
                        </p>
                    </li>   
                    <li className="storelist ml-2 mr-2 no_borders">
                        <p>
                            <a className="logo  cust_link featured" >
                                <span><Skeleton width={95} height={85}/></span>
                                <span className="coupon-count">
                                <b style={{textTransform:"capitalize",verticalAlign:"middle"}}> <Skeleton/></b><br></br>
                                <Skeleton/>
                                </span>
                            </a>
                            
                        </p>
                    </li>   
                    <li className="storelist ml-2 mr-2 no_borders">
                        <p>
                            <a className="logo  cust_link featured" >
                                <span><Skeleton width={95} height={85}/></span>
                                <span className="coupon-count">
                                <b style={{textTransform:"capitalize",verticalAlign:"middle"}}> <Skeleton/></b><br></br>
                                <Skeleton/>
                                </span>
                            </a>
                            
                        </p>
                    </li>   
                   
                </ul>
            </div>
        )
    }
}
export default StoreListSkeleton;
import React,{PureComponent} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';

class FrogotPassword extends PureComponent{

    constructor(props){
        super(props);
        this.state={
            feedback:false,
            error_type:"",
            message:"",
            loading:false
        }
        this.handleFrogotPassword = this.handleFrogotPassword.bind(this);
    }

    handleFrogotPassword(){
         
        var email = document.getElementById("email").value;
        
        if(email!=""){

            this.setState({loading:true});
            axios
            .get(`http://adscombined.com/v1/public/api/recover_password?email=${encodeURIComponent(email)}`)
            .then(
            (response) => {
                if(response.data.flag){
                    
                    this.setState({error_type:"success",message:response.data.message,feedback:true});
                    window.history.pushState({}, document.title, "/login" );
                } else {
                    this.setState({error_type:"danger",message:response.data.message});
                }
                this.setState({loading:false});
                
            },
            (error) => {
                this.setState({loading:false});
                this.setState({error_type:"danger",message:"Problem in processing your request!"});
            }
            );
        }else{
            this.setState({error_type:"warning",message:"Please enter your email!"})
        }
    }
    componentDidMount(){
       
    }

    render(){

        return(
            <span>
                <section className="main-container">
                    <div className="container" style={{ padding: "0px" }}>
                        <br></br>
                        <div className="row pb-3">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-4">

                                <div className="card">
                                    <div className="card-body">

                          
                                    <h3 className="p-3" style={{textAlign:"center",background:"",color:"#f56f36",fontWeight:"bold"}}>Forgot Password</h3>
                                   
                                       {
                                            this.state.error_type && this.state.message!="" ?
                                                <div  className={"alert alert-"+this.state.error_type+" alert-dismissible fade show"} role="alert">
                                                    {this.state.message}
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>

                                            </div>
                                            :
                                            <span></span>
                                
                                        }
                                          
                                      <div className="row">
                                        <div className="col-md-12 pt-2 pb-2">
                                            <TextField id="email" label="Your Email Address"  color="green" variant="outlined" style={{width:"100%"}}/>
                                        </div>
                                        <div className="col-md-12 pt-4 pb-2">
                                        <button type="button" id="button-login" {...this.state.loading? "disabled": ""} className="btn btn-info col-md-12"  onClick={this.handleFrogotPassword}>
                                        {this.state.loading?  "Processing...": "Submit"}
                                        </button>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                    </div>
                </section>
            </span>
        )
    }

}

export default FrogotPassword;


import React, { PureComponent } from 'react';



class Advertisement extends PureComponent{
  
    render(){
        switch (this.props.ad_type) {
            case "full width":
                return(
                    <img className="img-responsive" src = "https://blogs-images.forbes.com/gabrielshaoolian/files/2017/07/quickbooks.png" style={{height:"100%", width:"100%"}}></img>
                )
                break;
            case "half width":
                return(
                    <img className="img-responsive" src = "https://cdn.searchenginejournal.com/wp-content/uploads/2018/08/Screen-Shot-2018-08-29-at-2.30.18-PM-760x400.png" style={{height:"100%", width:"100%"}}></img>
                )
                break;
            case "column":
                return(
                    <img  className="img-responsive" src = "https://sg-everydayonsales-infomartglobal.netdna-ssl.com/wp-content/uploads/2018/01/McDonalds-Prosperity-Breakfast-Promotion.jpg" style={{height:"100%", width:"100%"}}></img>
                )
                break;
            case "long":
                return(
                    <img src="https://i.imgur.com/MrOhrpp.png" className="img-responsive" style={{height:"100%", width:"100%"}}></img>
                )
            default:
                return(
                    <img className="img-responsive" src = "https://cdn.digitalreachagency.com/wp-content/uploads/2017/03/resp3.png" style={{height:"100%", width:"100%"}}></img>
                );
                break;
        }
        
            

        
    }

}


export default Advertisement;
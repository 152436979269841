import React, { PureComponent } from "react";
import Toast from "light-toast";
import axios from "axios";
import { isMobile, isBrowser,isTablet } from "react-device-detect";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Moment from "react-moment";


import TopSalesAndCouponsSkeleton from "./skeletons/TopSalesAndCouponsSkeleton";
import AddtoFavourite from "./AddtoFavourite";

import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();

class SliderCoupons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      coupons: [],
      sepecial_coupon: [],
      sc_id: "",
      open: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);
    this.Capitalize = this.Capitalize.bind(this);
  }
  componentDidMount() {
    this.receivedData();
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  redirectToStore(coupon) {
    Toast.info(
      <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {coupon.StoreURL}
      </div>,
      3000,
      () => {
        window.open(
          window.location.href.split("?")[0] + "?c=" + coupon.id,
          "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
          //this.openModal(coupon)
          (window.location.href = coupon.SourceUrl),
          2000
        );
      }
    );
  }

  shorten(text, max) {
    return text && text.length > max
      ? text.slice(0, max).split(" ").slice(0, -1).join(" ")
      : text;
  }

  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(coupon) {
    if (this.state.sepecial_coupon.length < 1) {
      this.setState({ open: true, sepecial_coupon: coupon });
    }
  }

  closeModal() {
    this.setState({ open: false });
  }
  receivedData() {
    var user_id = "";
    if(user ){
        user_id = "&user_id="+user.id;
    }
    this.setState({ visible: 20 });
    this.setState({ loading: true }, () => {
      axios
        .post(`http://adscombined.com/v1/public/api/FilterCoupons?filter=${encodeURIComponent(this.props.coupon_type[1])}&Page=0&PerPage=4&Filter=${encodeURIComponent(this.props.coupon_type[1])}${user_id}`)
        .then(
          (response) => {
            if (response.data.coupons) {
              var couponss_sales = response.data.coupons;
              var couponss_clearance = null;
              
              axios
              .post(`http://adscombined.com/v1/public/api/FilterCoupons?filter=${encodeURIComponent("Clearance")}&Page=0&PerPage=4&Filter=${encodeURIComponent("Clearance")}${user_id}`)
              .then(
                (response) => {
                  if (response.data.coupons) {
                    couponss_clearance = response.data.coupons;
                  }
                })
            //     var couponss = [];
            
                
              ///  couponss_sales.push(couponss_clearance);
               var couponss = couponss_sales;
              if(!isBrowser && !isTablet){
                var mobile_size = couponss.length <= 12 ? couponss.length : 12;
                couponss = couponss.slice(0, parseInt(mobile_size));
              }
              this.setState({ coupons: couponss });
             
              this.setState({ loading: false });
            } else {
              this.setState({ loading: false });
            }
          },
          (error) => {
            this.setState({ loading: false });
            console.log(error);
          }
        );
    });
  }

  render() {
    var count= 3;
    var settings = {
      dots: false,
      infinite: false,
    
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 1,
      lazyLoad: false,
      autoplay: false,
      // speed:3000,
      // autoplaySpeed: 2500,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1124,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 0,
          },
        },
      ],
    };

    return (
      <div className="mt-2">
          {isMobile ? 
          
          <div className="row mt-3">
            <div className="col-md-12 "  style={{
          
              background: this.props.color,
              border: "1px solid rgba(0,0,0,.125)",
              borderBottom: "0px",
              borderTopRightRadius: "50px"
            }}>
              <div className="see_more_background_yellow">
                <h3 className="row_title row_title_yellow" >
                  <b>
                  <Link
        
                  to={this.props.coupon_type[0]}
                  title={"View All " + this.props.coupon_type[1]+ "  & Clearance"} style={{    color:"white"}}>
                      {this.props.coupon_type[1]}  & Clearance
                  </Link>
                
                  </b>  </h3>
                
                <div id="clear"></div>
              </div>
           
            </div>
          </div>
          :
          <div className="row mt-3">
            <div className="col-md-2 "  style={{
          
              background: this.props.color,
              border: "1px solid rgba(0,0,0,.125)",
              borderBottom: "0px",
              borderTopRightRadius: "50px"
            }}>
              <div className="see_more_background_yellow">
                <h3 className="row_title row_title_yellow" style={{    color:"white"}}>
                  <b>
                  {this.props.coupon_type[1]}  & Clearance
                  </b>  </h3>
                
                <div id="clear"></div>
              </div>
            </div>  
            <div className="col-md-10">
              <Link
        
                  to={this.props.coupon_type[0]}
                  title={"View All " + this.props.coupon_type[1]+ "  & Clearance"} 
                  className="  own_warning see_more_title pull-right" 
                  style={{color:this.props.color,paddingTop:"24px"}}>
                    <b  className="mt-3">View All {">>"}</b>
                </Link>
            </div>
          </div>
          }
          <div className="card ">
            <div className="card-body" id="slider-coupon">
        {this.state.loading ? (
          <TopSalesAndCouponsSkeleton title={this.props.coupon_type[1]+" & Clearance"}  items={[1, 2, 3, 4]} />
        ) : this.state.coupons.length > 0 ? (
          <div className="row">
          
        
            
                {this.state.coupons.map((coupon) => (
                  <div className="col-md-3 pl-0" key={coupon.id}>
                    <section
                      style={{
                        marginLeft: "6px",
                        marginRight: "6px",
                        minWidth: "300px",
                       Width:"300px !important",
                        minHeight: "340px",
                        border: "1px solid rgba(0, 0, 0, 0.17)",
                      }}
                      className="profile_panel panel "
                    >
                      
                      <div
                        style={{ background:this.props.colors[count],    padding: "5px"}}
                        className="twt-feed blue-bg"
                      >
                         <AddtoFavourite type={"heart"} source={"topsales"}  favourite_id = {coupon.favourite_id} id={coupon.id} favourite_type={"coupon"} is_favourite={coupon.favourite} />
                        <br></br><span
                         title={"Click to get "+coupon.Title}
                           onClick={() => this.redirectToStore(coupon)}
                        style={{"cursor":"pointer",maxWidth:"280px"}}
                        >
                             <h5 style={{fontWeight:"bold",height:"48px"}}>
                             {coupon.Title && coupon.Title.length <50 ? coupon.Title : this.shorten(coupon.Title,50)+".."}
                         
                                 </h5>
                        </span>
                      
                        
                        <Link
                          className="logo  cust_link featured"
                          to={"coupons/" + coupon.StoreURL}
                          title={
                            coupon.StoreName +" Coupons, Promotion Codes & Deals"
                          }
                          style={{borderRadius:"50px",borderColor:"#f3f3f3",marginBottom:"-50px"}}
                        >
                          <img
                         style={{objectFit:"contain",background:"white",borderRadius:"37px",height:"80px",width:"80px"}}
                            src={
                              coupon.Logo
                                ? window.$logos_url  + coupon.Logo.replace(".PNG",".png")
                                : process.env.PUBLIC_URL + "/default_logo.png"
                            }
                            alt={coupon.StoreName}
                            title={coupon.StoreName+" Coupons, Promotion Codes & Deals"}
                          />
                        </Link>

                      </div>
                      <div className="weather-category twt-category" style={{    marginTop: "40px"}}>
                     
                     <center>
                    
                             <div
                               title={"Click to get "+coupon.Title}
                               onClick={() => this.redirectToStore(coupon)}
                                className="badge " 
                                style={{fontSize:"22px",color:"white",cursor:"pointer",background:this.props.colors[count]}}> 
                              {coupon.Offer && coupon.Offer!="None" && coupon.Offer!="none" ? (
                                coupon.Offer
                              ) : (
                               coupon.Type
                              )}
                             </div>
                        <p>
                        <Link
                                    className="logo  cust_link featured"
                                    to={"coupons/" + coupon.StoreURL}
                                    title={
                                        coupon.StoreName +" Coupons, Promotion Codes & Deals"
                                    }
                                    style={{fontSize:"20px",textTransform:"capitalize",color:this.props.colors[count]}}
                                >
                                  <b>{coupon.StoreName}</b>
                                    
                                </Link>

                        </p>
                          
                               
                            
                                </center>
                                <div className="row" style={{fontSize:"13px",paddingTop:"10px"}}>
                            {coupon.DateUpdated ? 
                                 <div class="col-md-6" style={{borderRight:"1px solid gray"}}>
                                <i> Last Seen: <br></br>
                                {" "}
                                 <b>
                                   <Moment format="MMMM D, Y" >
                                 {coupon.DateUpdated} 
                                   </Moment></b></i>
                                 </div>
                                 :""}
                                  
                                    {coupon.DateAdded ? 
                                      <div className="col-md-6">
                                       <i> Found On: <br></br>
                                      {" "}
                                     <b>
                                       <Moment format="MMMM D, Y" >
                                     {coupon.DateAdded} 
                                       </Moment></b></i>
                                       </div>
                                    : ""
                                    }
                                
                                   </div> 
                          

                      </div>  <span style={{display:"none"}}>{count++}</span>
                    </section>
                  </div>
                 
                ))}
              
            </div>
        
        ) : (
          <p>No Coupons available</p>
        )}
          </div>
          </div>
      </div>
    );
  }
}

export default React.memo(SliderCoupons);

import React, { PureComponent } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import Skeleton from 'react-loading-skeleton';
class CategoryDetailsSkeleton extends PureComponent{
    
    render(){
        return(
            <div>
                
                <div className="no-borders">
                    <div className="card-body">
                        <span>
                            <center>
                            <p>
                                <Skeleton width={100} height={100} />
                            </p>
                            <h3 style={{textTransform:"capitalize", width:"60%"}}><Skeleton/></h3>
                            <Skeleton style={{textTransform:"capitalize", width:"60%"}} count={1}/>
                            </center>
                            
                        </span>
                    </div>
                </div>
                <div className="no-borders mt-2">
                    <div className="card-body">
                        <h5  style={{textTransform:"capitalize", width:"60%"}}><Skeleton/></h5>
                       
                        
                        <TreeView
                        expanded={["2"]}
                        
                        >
                            <TreeItem nodeId="1">
                                <Skeleton/>
                            </TreeItem>
                            
                            <TreeItem nodeId="2" >
                               <Skeleton count={3}/>
                            </TreeItem>
                        </TreeView>
                        
                    </div>
                </div> 

                
            </div>
        )
    }
}

export default CategoryDetailsSkeleton;
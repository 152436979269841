import React, { PureComponent } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { isMobile, isBrowser, isTablet } from "react-device-detect";
import Popup from "reactjs-popup";
import { getUser, removeUserSession } from "../utils/Common";
import Icon from "@mdi/react";
import { mdiHeartPlusOutline, mdiHeartMinus } from "@mdi/js";

import LoginPopup from "./LoginPopup";
const user = getUser();

class AddtoFavourite extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isFavLoading: false,
			open: false,
			isRemoveLoading: false,
			icon_to_show: this.props.is_favourite &&  this.props.is_favourite !="" ? this.props.is_favourite : "No" ,
		};
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal() {
		this.setState({ open: true });
	}

	closeModal() {
		this.setState({ open: false });
	}

	removeFavourite(favourite_id, div_id) {
		if (user) {
			if (favourite_id && favourite_id !== "") {
				this.setState({ isFavLoading: true });
				axios
					.get(
						`http://adscombined.com/v1/public/api/remove_favourite?user_id=${user.id}&favourite_id=${favourite_id}`
					)
					.then(
						(response) => {
							if (response.data.flag) {
								var fav_div = document.getElementById(div_id);
								this.setState({ isFavLoading: false ,  icon_to_show: "No" });
								// fav_div.classList.remove("fa-heart");
								// fav_div.classList.add("fa-heart-o");
								document.getElementById(div_id + "_is_favourite").value = "No";
								document.getElementById(div_id + "_favourite_id").value = "";
                return;
								// this.props.action(true);
							}
						},
						(error) => {
							this.setState({ isFavLoading: false });
						}
					);
			}
		} else {
			return this.openModal();
		}
	}

	addFavouriteHandle(id, favourite_type, div_id) {
		if (user) {
			var is_favourite = document.getElementById(div_id + "_is_favourite")
				.value;
			var favourite_id = document.getElementById(div_id + "_favourite_id")
				.value;
			if (is_favourite == "Yes" && favourite_id != "") {
				this.removeFavourite(favourite_id, div_id);
			} else {
				this.setState({ isFavLoading: true });
				if (user) {
					if (user.id) {
						axios
							.get(
								`http://adscombined.com/v1/public/api/Favourite?user_id=${user.id}&id=${id}&type=${favourite_type}`
							)
							.then(
								(response) => {
									if (response.data.flag) {
										var fav_div = document.getElementById(div_id);
										this.setState({ isFavLoading: false, icon_to_show: "Yes" });
										// fav_div.classList.remove("fa-heart-o");
										// fav_div.classList.add("fa-heart");
										document.getElementById(div_id).title =
											" Remove From Favourites";

										document.getElementById(div_id + "_is_favourite").value =
											"Yes";
										document.getElementById(div_id + "_favourite_id").value =
											response.data.favourite_id;

										// this.props.action(true);
										return ;
									} else {
										this.setState({ isFavLoading: false });
									}
								},
								(error) => {
									this.setState({ isFavLoading: false });
								}
							);
					}
				}
			}
		} else {
			return this.openModal();
		}
	}

	render() {
		const contentStyle = {
			width: isBrowser
				? "70% !important"
				: isTablet
				? "70% !important"
				: "90% !important",
		};

		// if(this.props.is_favourite == "Yes"){
		//   return(

		//    <span>
		//      {this.state.isRemoveLoading ?

		//       <Skeleton  style={{height:"50px",width:"50px"}} className={this.props.type} ></Skeleton>

		//     :
		//     ""
		//     }
		//           <i
		//           style={ {display: this.state.isRemoveLoading? "none" : "block"}}
		//       id={this.props.id+this.props.favourite_type+this.props.type}
		//         onClick={()=>this.removeFavourite(this.props.id,this.props.favourite_type,this.props.favourite_id,this.props.id+this.props.favourite_type+this.props.type)}
		//        className={"fa "+this.props.type+" fa-heart"} title="Add to Favourites">

		//       </i>
		//       <input type="hidden"   id={this.props.id+this.props.favourite_type+this.props.type+"_is_favourite"}/>
		//       <input type="hidden"   id={this.props.id+this.props.favourite_type+this.props.type+"_favourite_id"}/>
		//    </span>
		//   );

		// }else{

		return (
			<span>
				{this.state.isFavLoading ? (
					<Skeleton
						style={{ height: "50px", width: "50px" }}
						className={this.props.type}
					></Skeleton>
				) : (
					""
				)}
			
				<i
					style={{ display: this.state.isFavLoading ? "none" : "block" }}
					id={
						this.props.source +
						this.props.id +
						this.props.favourite_type +
						this.props.type
					}
					onClick={() =>
						this.addFavouriteHandle(
							this.props.id,
							this.props.favourite_type,
							this.props.source +
								this.props.id +
								this.props.favourite_type +
								this.props.type
						)
					}
					className={
							"fa " + this.props.type + " "
					}
					title={
						this.props.is_favourite == "Yes"
							? "Remove from Favourites"
							: "Add to Favourites"
					}
				> 	
          {this.state.icon_to_show == "No" ? (
            <Icon path={mdiHeartPlusOutline} size={1} color="red"></Icon>
          ) : (
            <Icon path={mdiHeartMinus} size={1} color="red"></Icon>
          )}
        </i>
				<input
					type="hidden"
					value={this.props.is_favourite}
					id={
						this.props.source +
						this.props.id +
						this.props.favourite_type +
						this.props.type +
						"_is_favourite"
					}
				/>
				<input
					type="hidden"
					value={this.props.favourite_id}
					id={
						this.props.source +
						this.props.id +
						this.props.favourite_type +
						this.props.type +
						"_favourite_id"
					}
				/>

				<Popup
					open={this.state.open}
					closeOnDocumentClick
					onClose={this.closeModal}
					{...{ contentStyle }}
				>
					<div className="modal">
						<a className="close" onClick={this.closeModal}>
							&times;
						</a>
						<br></br>
						<LoginPopup></LoginPopup>
					</div>
				</Popup>
			</span>
		);

		// }
	}
}

export default withRouter(AddtoFavourite);

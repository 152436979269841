import React, { PureComponent } from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import Toast from "light-toast";
import Popup from "reactjs-popup";
import ShowMore from '@tedconf/react-show-more';
import { isMobile, isBrowser, isTablet } from "react-device-detect";
import Advertisement from "./Advertisement";
import AddtoFavourite from "./AddtoFavourite";

import TopDealsSkeleton from "./skeletons/TopDealsSkeleton";
import "react-rater/lib/react-rater.css";
import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();


class TopDeals extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      deals:[],
      sepecial_deal: [],
      sd_id: "",
      open: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);
  }
  
  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(deal) {
    if (this.state.sepecial_deal.length < 1) {
        this.setState({ open: true, sepecial_deal: deal });
    }
  }

  closeModal() {
    this.setState({ open: false });
  }

  redirectToStore(deal) {
    Toast.info(
        <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {deal.StoreURL}
        </div>,
        3000,
        () => {
        window.open(
            window.location.href.split("?")[0] + "?d=" + deal.id,
            "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
            //this.openModal(coupon)
            (window.location.href = deal.SourceUrl),
            2000
        );
        }
    );
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);

    if (params.has("d")) {
    var sd_id = "";
    sd_id = params.get("d");
    var user_id = "";
    if(user ){
      user_id = "&user_id="+user.id;
    }
      axios
        .get(`http://adscombined.com/v1/public/api/DealDetails?deal_id=${sd_id + user_id}`)
        .then(
          (response) => {
            if(response.data.flag){
                            
              if(response.data.deal_data ){
              const sepecial_deal = response.data.deal_data;
             
              this.openModal(sepecial_deal);
              }
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    var user_id = "";
    if(user ){
      user_id = "?&user_id="+user.id;
    }
    this.setState({ loading: true }, () => {
      axios
        .get(`http://adscombined.com/v1/public/api/HomepageDeals${user_id}`)
        .then(
          (response) => {
            if (response.data.deals) {
              var deals = response.data.deals;
              if(!isBrowser && !isTablet){
                var mobile_size = deals.length <= 10 ? deals.length : 10;
                deals = deals.slice(0, parseInt(mobile_size));
              }else{
                var web_size = deals.length <= 7 ? deals.length : 7;
                deals = deals.slice(0, parseInt(web_size));
              }
              this.setState({ deals:deals });
              this.setState({ loading: false });
            } else {
              this.setState({ loading: false });
            }
          },
          (error) => {
            this.setState({ loading: false });
            console.log(error);
          }
        );
    });
  }

  shorten(text,max) {
    return text && text.length > max ? text.slice(0,max).split(' ').slice(0, -1).join(' ') : text
  }

  getSaving(price1,price2){
    
    var  price11 =0;
    if(price1 !=="" && price1!=="none" && price1){
      price11  = price1.replace ( /[^\d.]/g, '' ); 
    } 
    var price22 = 0;
    if(price2!=="" && price2!=="none" && price2){
      
      price22  =price2.replace ( /[^\d.]/g, '' ); 
    }
    var perc = 0;
    if(price11 >0){
      perc = (price22 *100) / price11;
    }
    
    perc = perc.toFixed(0);
    return perc >0 ? perc : 0;
  }
  render() {
    const contentStyle = { width: isBrowser ? '70% !important' : isTablet ? '70% !important': '90% !important' };
    return (
      <div>
         <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          {...{ contentStyle }}
          >
          <div className="modal">
              <a className="close" onClick={this.closeModal}>
              &times;
              </a>
              <div class="row" style={{ padding: "10px" }}>
                  <div className="col-md-4 pl-0">
                      <center>
                      <img
                        title={this.state.sepecial_deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                        alt = {this.state.sepecial_deal.StoreName + " Coupons, Promotion Codes & Deals"}
                          src={
                           this.state.sepecial_deal.Logo
                              ? window.$logos_url+this.state.sepecial_deal.Logo.replace(".PNG",".png")
                              : process.env.PUBLIC_URL + "/default_logo.png"
                          }
                          width="100"
                          height="100"
                          id="store_logo"
                          style={{objectFit: "contain"}}
                      ></img>
                       <Link
                          className="logo  cust_link featured"
                          to={"coupons/" + this.state.sepecial_deal.StoreURL}
                          title={
                            this.state.sepecial_deal.StoreName +" Coupons, Promotion Codes & Deals"
                          }
                        >
                          <h5 title={this.state.sepecial_deal.StoreName+ " Coupons, Promotion Codes & Deals"}>
                              <b style={{ textTransform: "capitalize" }}>
                              {this.state.sepecial_deal.StoreName}
                              </b>
                          </h5>
                        </Link>
                   
                      </center>
                  </div>

                  <div className="col-md-8 pr-0" style={{ padding: "inherit" }}>
                  <AddtoFavourite source={"stopdeal"} favourite_id = {this.state.sepecial_deal.favourite_id} type={"heart"} id={this.state.sepecial_deal.id} favourite_type={"deal"} is_favourite={this.state.sepecial_deal.favourite} />
                    {this.state.sepecial_deal.Image && this.state.sepecial_deal.Image!=="none" && this.state.sepecial_deal.Image!=="None"
                    ?
                    (
                      <span>
                         <img
                         alt={this.state.sepecial_deal.Title}
                        className=" deals-img-popup  pr-0"
                        src={this.state.sepecial_deal.Image}
                        ></img>
                        
                        <br/><br/>
                      </span>
                     
                    )
                    :
                    (
                      <span></span>
                    )
                    }
                     
                      <h4>
                      {this.state.sepecial_deal.Saving ? 

                        <div
                            className=" "
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                              backgroundSize: 'cover', 
                              backgroundPosition: 'bottom', 
                              width: 'fit-content',
                              position: 'absolute', 
                              top: '0px', 
                              right: '0px', 
                              textTransform: 'uppercase', 
                              fontSize:"18px",
                              textAlign:"center",
                              padding: '5px', 
                              color:"white",
                              paddingBottom: '25px', 
                              fontWeight: 700
                            }}
                        >
                            {this.state.sepecial_deal.Saving}  <br/> Save
                        </div>
                        :
                        this.state.sepecial_deal.PriceDifference && this.state.sepecial_deal.PriceDifference>0 ?
                          <div
                              className=" "
                              style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                                backgroundSize: 'cover', 
                                backgroundPosition: 'bottom', 
                                width: 'fit-content',
                                position: 'absolute', 
                                top: '0px', 
                                fontSize:"18px",
                                color:"white",
                                right: '0px', 
                                textTransform: 'uppercase', 
                                textAlign:"center",
                                padding: '5px', 
                                paddingBottom: '25px', 
                                fontWeight: 700
                              }}
                          >
                              ${this.state.sepecial_deal.PriceDifference} <br/> Save
                          </div>
                          :
                          <div></div>
                        }
                      <h3 id="coupon_title">
                          {this.state.sepecial_deal.Title}
                      </h3>
                      </h4>

                      <br />
                      <b></b>
                      {this.state.sepecial_deal.Code && this.state.sepecial_deal.Code!="None" ? (
                      <span>
                          <input
                      id="code-value"
                      type="text"
                      className="code-copy"
                      value={this.state.sepecial_deal.Code}
                      style={{
                          borderRadius: "0px",
                          width: "fit-content",
                          cursor: "copy",
                      }}
                      readOnly
                      />

                      <span>
                      <button
                          className="btn"
                          onClick={this.myFunction}
                          onMouseOut={this.outFunc}
                          id="myTooltip"
                          style={{ verticalAlign: "inherit" }}
                      >
                          Copy Code
                      </button>
                      </span>

                      <br />
                      <br />
                      </span>
                      
                      ): (<div></div>)}
                      
                      <a href={this.state.sepecial_deal.SourceUrl}>
                      <b>
                          {" "}
                          Continue to{" "}
                          <span style={{ textTransform: "capitalize" }}>
                          {this.state.sepecial_deal.StoreName}
                          </span>
                      </b>{" "}
                      </a>
                  </div>
              </div>
          </div>
          </Popup>

          {isMobile? 
          
            <div className="row mt-3">
              <div className="col-md-12 "  style={{
            
                background:"black",
                border: "1px solid rgba(0,0,0,.125)",
                borderBottom: "0px",
                borderTopRightRadius: "50px"
              }}>
              <div className="see_more_background_yellow">
                <h3 className="row_title row_title_yellow" >
                  <b>
                  <Link
                  className="mt-2"
                        to={"/deals"}
                        title={"View All Deals"} style={{    color:"#f56f36"}}> Top Deals</Link> 
                  </b>  </h3>
                
                <div id="clear"></div>
              </div>
            
            </div>
          </div>
          :
         
            <div className="row mt-3">
          <div className="col-md-2 "  style={{
        
            background:"black",
            border: "1px solid rgba(0,0,0,.125)",
            borderBottom: "0px",
            borderTopRightRadius: "50px"
          }}>
          <div className="see_more_background_yellow">
            <h3 className="row_title row_title_yellow" style={{    color:"#f56f36"}}>
              <b>
              Top Deals
              </b>  </h3>
             
            <div id="clear"></div>
          </div>
        </div>  
        <div className="col-md-10">
        <Link
        className="mt-2"
                to={"/deals"}
                title={"View All Deals"} 
                className="  own_warning see_more_title pull-right" 
                style={{color:"black",paddingTop:"24px"}}>
                   <b>View All {">>"}</b>
              </Link>
        </div>
        </div>

          }
          
          {
          isBrowser ?
          (
            this.state.loading ? (
              <TopDealsSkeleton items={[1,2,3,4,5,6]} />
            ) : (
              <div className="card">
                <div className="card-body">
              
                  <div className="row">
                  
                  <div className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable"  >
                       
                    <div className="card  text-white  single-deal  " >

                      <Advertisement ad_type={"column"}></Advertisement>
                    </div>
                  </div>

              
                    { this.state.deals.length >0 ? (
                       
                       this.state.deals.map(deal => 
                        <div key={deal.id} className="card-deck pl-1 pr-1 col-md-3 mt-2 clickable"  >
                       
                        <div className="card  text-white  single-deal  " >
                        <AddtoFavourite type={"heart-deal"} source={"topdeal"} favourite_id = {deal.favourite_id} id={deal.id} favourite_type={"deal"} is_favourite={deal.favourite} />
                          {deal.Saving ? 
    
                          <div style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                            backgroundSize: 'cover', 
                            backgroundPosition: 'bottom', 
                            width: 'fit-content',
                            position: 'absolute', 
                            top: '0px', 
                            right: '0px', 
                            textTransform: 'uppercase', 
                            textAlign:"center",
                            padding: '5px', 
                            paddingBottom: '20px', 
                            fontWeight: 700
                          }}> 
                            {deal.Saving} <br />Save
                          </div>
                          :
                          deal.PriceDifference && deal.PriceDifference>0 ?
                            <div
                                className=" "
                                style={{
                                  backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                                  backgroundSize: 'cover', 
                                  backgroundPosition: 'bottom', 
                                  width: 'fit-content',
                                  position: 'absolute', 
                                  textAlign:"center",
                                  top: '0px', 
                                  right: '0px', 
                                  textTransform: 'uppercase', 
                                  padding: '5px', 
                                  paddingBottom: '20px', 
                                  fontWeight: 700
                                }}
                            >
                                ${deal.PriceDifference}<br />Save
                            </div>
                            :
                            <div></div>
                          }
                          <img src={deal.Image} title={"Click to get "+deal.Title} className="card-img" alt={deal.Title} style={{objectFit:"scale-down",height:"300px",width:"100%"}} onClick={() => this.redirectToStore(deal)}/>
                          
                          <div className="card-img-overlay p-1" style={{top:"unset",background:' #00000099'}}>
                         
                          <div className="details">
                          <Link
                              className="logo  cust_link featured"
                              to={"coupons/" + deal.StoreURL}
                              title={
                                deal.StoreName +" Coupons, Promotion Codes & Deals"
                              }
                            >

                            {deal.Logo && deal.Logo!=="none" && deal.Logo!=="None" ?
                            (
                              <img src={window.$logos_url+deal.Logo.replace(".PNG",".png")}
                              title={deal.StoreName+ "  Coupons, Promotion Codes & Deals"}
                              alt = {deal.StoreName+"  Coupons, Promotion Codes & Deals"}
                              height={70} width={70} 
                              style={{
                                position:"absolute",
                                top:"-35px",
                                borderRadius:"5px",
                                background: "white",
                                objectFit:"fill"
                              }}
                              ></img>
                            )
                            :
                            (
                              <img src={process.env.PUBLIC_URL + "/default_logo.png"} 
                              title={deal.StoreName+ "  Coupons, Promotion Codes & Deals"}
                              alt = {deal.StoreName+"  Coupons, Promotion Codes & Deals"}
                              style={{
                                position:"absolute",
                                top:"-35px",
                                borderRadius:"5px",
                                background: "white",
                                objectFit:"fill"
                              }}
                              height={70} width={70} ></img>
                            )
                          } 
                        </Link>
                          
                                <div className="card-title mt-5 mb-0 " onClick={() => this.redirectToStore(deal)} >
                                    <h3 title={"Click to get "+deal.Title} style={{fontSize: "18px"}}> {deal.Title && deal.Title.length <55 ? deal.Title : this.shorten(deal.Title,55)+".."}</h3>
                                </div>
                             
                              
                             
    
                               <h5  style={{fontSize:"1.0rem !important"}}>
                                  <span style={{ fontSize: "14px" }}>
                                      <span style={{ textDecoration: "line-through" }}>
                                      {deal.Price1}
                                      </span>{" "}
                                      &nbsp;
                                  </span>
                                  <b style={{ color: "#00e809" ,fontSize:"28px"}}>{deal.Price2}</b>&nbsp;
                                 
                                  </h5>
                              
                          </div>
                          </div>
                        </div>     
                        </div> 
                  
                          
                      )
                         
                    ):
                    (
                        <div className="col-md-12">
                            <p>No Deals available.</p>
                        </div>
                    )
                            
                    }
                  
                      <div className="col-md-12 mt-2">
                        <center >
                          <Link
                          to="/deals"
                          className="btn btn-info"
                          >View All Deals
                          </Link>
                        </center>
                      </div>
                     
                     
                    
                
                    </div>
                  </div>
                </div>
            )
          )
          :
          (
            isTablet  ? 
            this.state.loading ? (
              <TopDealsSkeleton items={[1,2,3,4]} />
              ) : (
              <div className="card">
                <div className="card-body">
                
                  <div className="row">
                  <ShowMore
                    items={this.state.deals}
                    by={12}
                  >
                     {({
                      current,
                      onMore,
                    }) => (
                      <React.Fragment>
                    { this.state.deals.length >0 ? (
                       
                       current.map(deal => 
                        <div key={deal.id} className="card-deck pl-1 pr-1  w-25 mt-2 clickable" title={"Click to get "+deal.Title}  >
                       
                        <div className="card  text-white  single-deal  " >
                        <AddtoFavourite type={"heart-deal"} source={"topdeal"} favourite_id = {deal.favourite_id} id={deal.id} favourite_type={"deal"}  is_favourite={deal.favourite} />
                          {deal.Saving ? 
    
                          <div  style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                            backgroundSize: 'cover', 
                            backgroundPosition: 'bottom', 
                            width: 'fit-content',
                            position: 'absolute', 
                            top: '0px', 
                            right: '0px', 
                            textTransform: 'uppercase', 
                            textAlign:"center",
                            padding: '5px', 
                            paddingBottom: '20px', 
                            fontWeight: 700
                          }}> 
                            {deal.Saving} <br />Save
                          </div>
                          :
                          deal.PriceDifference && deal.PriceDifference>0 ?
                            <div
                                className=" "
                                style={{
                                  backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                                  backgroundSize: 'cover', 
                                  backgroundPosition: 'bottom', 
                                  width: 'fit-content',
                                  position: 'absolute', 
                                  textAlign:"center",
                                  top: '0px', 
                                  right: '0px', 
                                  textTransform: 'uppercase', 
                                  padding: '5px', 
                                  paddingBottom: '20px', 
                                  fontWeight: 700
                                }}
                            >
                                ${deal.PriceDifference}<br />Save
                            </div>
                            :
                            <div></div>
                          }
                          <img  onClick={() => this.redirectToStore(deal)} title={"Click to get "+deal.Title} src={deal.Image} alt={deal.Title} className="card-img"  style={{objectFit:"scale-down",height:"250px",width:"100%"}} />
                          
                          <div className="card-img-overlay p-1" style={{top:"unset",background:' #00000099'}}>
                         
                          <div className="details">
                          <Link
                              className="logo  cust_link featured"
                              to={"coupons/" + deal.StoreURL}
                              title={
                                deal.StoreName +" Coupons, Promotion Codes & Deals"
                              }
                            >
                          {deal.Logo && deal.Logo!=="none" && deal.Logo!=="None" ?
                            (
                              <img src={window.$logos_url+deal.Logo.replace(".PNG",".png")}
                              title={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                              alt={deal.StoreName+ "Coupons, Promotion Codes & Deals"}
                              height={70} width={70} 
                              style={{ 
                                position:"absolute",
                                top:"-35px",
                                borderRadius:"5px",
                                background: "white"
                              }}
                              ></img>
                            )
                            :
                            (
                              <img src={process.env.PUBLIC_URL + "/default_logo.png"}
                              title={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                              alt={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                              style={{
                                position:"absolute",
                                top:"-35px",
                                borderRadius:"5px",
                                background: "white"
                              }}
                              height={70} width={70} ></img>
                            )
                          }
                          </Link>
                                <div className="card-title mt-5 mb-0 " onClick={() => this.redirectToStore(deal)} >
                                    <h3 title={"Click to get "+deal.Title} style={{fontSize: "18px"}}> {deal.Title && deal.Title.length <55 ? deal.Title : this.shorten(deal.Title,55)+".."}</h3>
                                </div>
                             
                              
                             
    
                               <h5  style={{fontSize:"1.0rem !important"}}>
                                  <span style={{ fontSize: "14px" }}>
                                      <span style={{ textDecoration: "line-through" }}>
                                      {deal.Price1}
                                      </span>{" "}
                                      &nbsp;
                                  </span>
                                  <b style={{ color: "#00e809" ,fontSize:"28px"}}>{deal.Price2}</b>&nbsp;
                                 
                                  </h5>
                              
                          </div>
                          </div>
                        </div>     
                        </div> 
                  
                          
                      )
                         
                    ):
                    (
                        <div className="col-md-12">
                            <p>No Deals available.</p>
                        </div>
                    )
                            
                    }
                    {!onMore ? 
                      <div className="col-md-12 mt-2">
                        <center >
                          <Link
                          to="/deals"
                          className="btn btn-info"
                          >View All Deals
                          </Link>
                        </center>
                      </div>
                      :
                      <div className="col-md-12 mt-2">
                        <center >
                        <button
                        className="btn btn-info"
                        
                          disabled={!onMore}
                          onClick={() => { if (!!onMore) onMore(); }}
                        >
                          Show More
                        </button>
                      </center>
                      </div>
                     
                    }
                     
                    </React.Fragment>
                  )}
                  </ShowMore>
                    </div>
                  </div>
                </div>
              )
            :
             
            this.state.deals.length >0 ? (
                       
              this.state.deals.map(deal => 
               <div key={deal.id} className="card-deck pl-1 pr-1 col-md-3-deal mt-2 clickable" title={"Click to get "+deal.Title} >
              
               <div className="card  text-white   single-deal  " >
                <AddtoFavourite type={"heart-deal"} source={"topdeal"} favourite_id = {deal.favourite_id} id={deal.id} favourite_type={"deal"}  is_favourite={deal.favourite} />
                 {deal.Saving ? 

                 <div style={{
                   backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                   backgroundSize: 'cover', 
                   backgroundPosition: 'bottom', 
                   width: 'fit-content',
                   position: 'absolute', 
                   top: '0px', 
                   right: '0px', 
                   textTransform: 'uppercase', 
                   textAlign:"center",
                   padding: '5px', 
                   paddingBottom: '20px', 
                   fontWeight: 700
                 }}> 
                   {deal.Saving} <br />Save
                 </div>
                 :
                 deal.PriceDifference && deal.PriceDifference>0 ?
                   <div
                       className=" "
                       style={{
                         backgroundImage: `url(${process.env.PUBLIC_URL + "/bookmark.png"})`, 
                         backgroundSize: 'cover', 
                         backgroundPosition: 'bottom', 
                         width: 'fit-content',
                         position: 'absolute', 
                         textAlign:"center",
                         top: '0px', 
                         right: '0px', 
                         textTransform: 'uppercase', 
                         padding: '5px', 
                         paddingBottom: '20px', 
                         fontWeight: 700
                       }}
                   >
                       ${deal.PriceDifference}<br />Save
                   </div>
                   :
                   <div></div>
                 }
                 <img src={deal.Image} className="card-img" onClick={() => this.redirectToStore(deal)} alt={deal.Title} style={{objectFit:"scale-down",height:"250px",width:"100%"}}/>
                 
                 <div className="card-img-overlay p-1" style={{top:"unset",background:' #00000099'}}>
                
                  <div className="details">
                  <Link
                    className="logo  cust_link featured"
                    to={"coupons/" + deal.StoreURL}
                    title={
                      deal.StoreName +" Coupons, Promotion Codes & Deals"
                    }
                  >
                  {deal.Logo && deal.Logo!=="none" && deal.Logo!=="None" ?
                    (
                      <img 
                      src={window.$logos_url+deal.Logo.replace(".PNG",".png")}  height={70} width={70} 
                      title={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                      alt={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                      style={{
                        position:"absolute",
                        top:"-35px",
                        borderRadius:"5px",
                        background: "white",
                        objectFit:"fill"
                      }}
                      ></img>
                    )
                    :
                    (
                      <img src={process.env.PUBLIC_URL + "/default_logo.png"} 
                      title={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                      alt={deal.StoreName+ " Coupons, Promotion Codes & Deals"}
                      style={{
                        position:"absolute",
                        top:"-35px",
                        borderRadius:"5px",
                        background: "white",
                        objectFit:"fill"
                      }}
                      height={70} width={70} ></img>
                    )
                  }
                  </Link>
                  
                  <div className="card-title mt-5 mb-0 "  >
                      <h3 onClick={() => this.redirectToStore(deal)} title={"Click to get "+deal.Title} style={{fontSize: "18px"}}> {deal.Title && deal.Title.length <55 ? deal.Title : this.shorten(deal.Title,55)+".."}</h3>
                  </div>
                    <h5  style={{fontSize:"1.0rem !important"}}>
                      <span style={{ fontSize: "14px" }}>
                          <span style={{ textDecoration: "line-through" }}>
                          {deal.Price1}
                          </span>{" "}
                          &nbsp;
                      </span>
                      <b style={{ color: "#00e809" ,fontSize:"28px"}}>{deal.Price2}</b>&nbsp;
                    
                   </h5>
                     
                  </div>
                </div>
              </div>     
            </div> 
         
                 
             )
                
           ):
           (
               <div className="col-md-12">
                   <p>No Deals available.</p>
               </div>
           )
                   
           
          )
        }
 
      </div>
    );
  }
}
export default TopDeals;

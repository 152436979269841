import React, { PureComponent } from 'react';
import axios from 'axios';
import {engineVersion, isBrowser, isMobile,isTablet} from "react-device-detect";

import { Link} from "react-router-dom";
class Footer extends PureComponent {
    constructor(props){
        super(props);
        this.state ={
            category1: ["Apparel & Accessories","Arts & Entertainment","Automotive & Mechanics","Baby & Nursery","Beauty","Books, Music & Video","Business","Computers & Software","Education & Training","Electronics","Flowers & Gifts","Food & Beverage"],
            category2: ["Health & Medicine","Holiday & Seasonal","Home & Garden","Sports & Recreation","Toys & Games","Travel","Wireless, Broadband & Cable","Pet Care & Supplies","Photography"],
            top_stores:[
                {
                  "id": 1711,
                  "StoreName": "bibloo",
                  "Image": "bibloo.png",
                  "TotalCouponsCount": 2,
                  "DealsCount": 7398,
                  "StoreURL": "bibloo.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "Men,Women"
                },
                {
                  "id": 1260,
                  "StoreName": "basicslife",
                  "Image": "basics-life.png",
                  "TotalCouponsCount": 13,
                  "DealsCount": 1742,
                  "StoreURL": "basicslife.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing"
                },
                {
                  "id": 1698,
                  "StoreName": "Beyond Polish",
                  "Image": "beyondpolish.png",
                  "TotalCouponsCount": 34,
                  "DealsCount": 1382,
                  "StoreURL": "beyondpolish.com",
                  "Categories": "Beauty",
                  "SubCategories": "MakeUp"
                },
                {
                  "id": 2320,
                  "StoreName": "artbeads",
                  "Image": "artbeads.png",
                  "TotalCouponsCount": 7,
                  "DealsCount": 1123,
                  "StoreURL": "artbeads.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "jewelry,jewelry"
                },
                {
                  "id": 195,
                  "StoreName": "Ashford",
                  "Image": "ashford.png",
                  "TotalCouponsCount": 7,
                  "DealsCount": 886,
                  "StoreURL": "ashford.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "sunglasses,Men,Women,jewelry,watches"
                },
                {
                  "id": 445,
                  "StoreName": "ben-sherman",
                  "Image": "ben-sherman.png",
                  "TotalCouponsCount": 20,
                  "DealsCount": 755,
                  "StoreURL": "bensherman.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing,shoes,bags,shoes"
                },
                {
                  "id": 1645,
                  "StoreName": "bellelily",
                  "Image": "bellelily.png",
                  "TotalCouponsCount": 32,
                  "DealsCount": 689,
                  "StoreURL": "bellelily.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing"
                },
                {
                  "id": 1188,
                  "StoreName": "Babesta",
                  "Image": "babesta.png",
                  "TotalCouponsCount": 22,
                  "DealsCount": 418,
                  "StoreURL": "babesta.com",
                  "Categories": "Baby & Nursery",
                  "SubCategories": "Babies,Baby Gear,Nursery"
                },
                {
                  "id": 914,
                  "StoreName": "cheapair",
                  "Image": "cheapair.png",
                  "TotalCouponsCount": 430,
                  "DealsCount": 0,
                  "StoreURL": "cheapair.com",
                  "Categories": "Travel",
                  "SubCategories": "Flights"
                },
                {
                  "id": 3928,
                  "StoreName": "Little Knits",
                  "Image": "littleknits.png",
                  "TotalCouponsCount": 326,
                  "DealsCount": 0,
                  "StoreURL": "littleknits.com",
                  "Categories": "Apparel & Accessories,Books, Music & Video",
                  "SubCategories": "bags,Books,Magazines"
                },
                {
                  "id": 6793,
                  "StoreName": "Albee Baby",
                  "Image": "albee-baby.png",
                  "TotalCouponsCount": 11,
                  "DealsCount": 237,
                  "StoreURL": "albeebaby.com",
                  "Categories": "Baby & Nursery",
                  "SubCategories": "Baby Gear,Nursery"
                },
                {
                  "id": 1907,
                  "StoreName": "brigettesboutique",
                  "Image": "brigettesboutique.png",
                  "TotalCouponsCount": 10,
                  "DealsCount": 216,
                  "StoreURL": "brigettesboutique.com",
                  "Categories": "Beauty",
                  "SubCategories": "Body Care,Skincare,Lips Care"
                },
                {
                  "id": 1650,
                  "StoreName": "beltedcow",
                  "Image": "belted-cow.png",
                  "TotalCouponsCount": 8,
                  "DealsCount": 194,
                  "StoreURL": "beltedcow.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing,leather,Accessories,belts"
                },
                {
                  "id": 2044,
                  "StoreName": "airportparkingreservations",
                  "Image": "airport-parking-reservations.png",
                  "TotalCouponsCount": 171,
                  "DealsCount": 0,
                  "StoreURL": "airportparkingreservations.com",
                  "Categories": "Travel",
                  "SubCategories": "Parking"
                },
                {
                  "id": 2299,
                  "StoreName": "arestravel",
                  "Image": "arestravel.png",
                  "TotalCouponsCount": 168,
                  "DealsCount": 0,
                  "StoreURL": "arestravel.com",
                  "Categories": "Travel",
                  "SubCategories": "Tours & Attractions,Hotels & Resorts,Vacations Packages,Vacations Rentals,Event Tickets"
                },
                {
                  "id": 1690,
                  "StoreName": "Betsey Johnson",
                  "Image": "betsey-johnson.png",
                  "TotalCouponsCount": 16,
                  "DealsCount": 122,
                  "StoreURL": "betseyjohnson.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "shoes,jewelry,Accessories,handbags"
                },
                {
                  "id": 6486,
                  "StoreName": "Wine Country Giftbaskets",
                  "Image": "winecountrygiftbaskets.png",
                  "TotalCouponsCount": 130,
                  "DealsCount": 0,
                  "StoreURL": "winecountrygiftbaskets.com",
                  "Categories": "Food & Beverage,Flowers & Gifts",
                  "SubCategories": "Beverages,Personal Gift-Giving"
                },
                {
                  "id": 7580,
                  "StoreName": "beautybrands",
                  "Image": null,
                  "TotalCouponsCount": 44,
                  "DealsCount": 79,
                  "StoreURL": "beautybrands.com",
                  "Categories": null,
                  "SubCategories": null
                },
                {
                  "id": 30,
                  "StoreName": "personalizationmall",
                  "Image": "personalization-mall.png",
                  "TotalCouponsCount": 120,
                  "DealsCount": 0,
                  "StoreURL": "personalizationmall.com",
                  "Categories": "Holiday & Seasonal,Home & Garden",
                  "SubCategories": "halloween,Home Decor"
                },
                {
                  "id": 6607,
                  "StoreName": "48hourprint",
                  "Image": "48hourprint.png",
                  "TotalCouponsCount": 0,
                  "DealsCount": 111,
                  "StoreURL": "48hourprint.com",
                  "Categories": "Arts & Entertainment",
                  "SubCategories": "Prints,Paintings"
                },
                {
                  "id": 7711,
                  "StoreName": "sale",
                  "Image": null,
                  "TotalCouponsCount": 110,
                  "DealsCount": 0,
                  "StoreURL": "1sale.com",
                  "Categories": null,
                  "SubCategories": null
                },
                {
                  "id": 7630,
                  "StoreName": "fullbeauty",
                  "Image": null,
                  "TotalCouponsCount": 109,
                  "DealsCount": 0,
                  "StoreURL": "onestopplus.com",
                  "Categories": null,
                  "SubCategories": null
                },
                {
                  "id": 7601,
                  "StoreName": "belts",
                  "Image": null,
                  "TotalCouponsCount": 0,
                  "DealsCount": 102,
                  "StoreURL": "belts.com",
                  "Categories": null,
                  "SubCategories": null
                },
                {
                  "id": 2060,
                  "StoreName": "achica",
                  "Image": "achica.png",
                  "TotalCouponsCount": 99,
                  "DealsCount": 0,
                  "StoreURL": "achica.com",
                  "Categories": "Home & Garden,Apparel & Accessories",
                  "SubCategories": "Kitchen Essentials,Bedding,clothing"
                },
                {
                  "id": 270,
                  "StoreName": "boohoo",
                  "Image": "boohoo.png",
                  "TotalCouponsCount": 98,
                  "DealsCount": 0,
                  "StoreURL": "us.boohoo.com",
                  "Categories": "Apparel & Accessories,Beauty",
                  "SubCategories": "clothing,shoes,shoes,Skincare"
                },
                {
                  "id": 1200,
                  "StoreName": "partycity",
                  "Image": "partycity.png",
                  "TotalCouponsCount": 95,
                  "DealsCount": 0,
                  "StoreURL": "partycity.com",
                  "Categories": "Baby & Nursery",
                  "SubCategories": "Baby Gear"
                },
                {
                  "id": 267,
                  "StoreName": "m&s",
                  "Image": "m&s.png",
                  "TotalCouponsCount": 94,
                  "DealsCount": 0,
                  "StoreURL": "marksandspencer.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "Accessories,Men,Women,ready-to-wear,womenswear,menswear"
                },
                {
                  "id": 226,
                  "StoreName": "minted",
                  "Image": "minted.png",
                  "TotalCouponsCount": 92,
                  "DealsCount": 0,
                  "StoreURL": "minted.com",
                  "Categories": "Arts & Entertainment,Books, Music & Video",
                  "SubCategories": "Arts,Stationery"
                },
                {
                  "id": 7602,
                  "StoreName": "beveragefactory",
                  "Image": null,
                  "TotalCouponsCount": 0,
                  "DealsCount": 92,
                  "StoreURL": "beveragefactory.com",
                  "Categories": null,
                  "SubCategories": null
                },
                {
                  "id": 1845,
                  "StoreName": "boots",
                  "Image": "boots.png",
                  "TotalCouponsCount": 87,
                  "DealsCount": 0,
                  "StoreURL": "boots.com",
                  "Categories": "Beauty,Health & Medicine",
                  "SubCategories": "Fragrance,Skincare,Health Care"
                },
                {
                  "id": 1685,
                  "StoreName": "Bestlacewigs",
                  "Image": "bestlacewigs.png",
                  "TotalCouponsCount": 11,
                  "DealsCount": 72,
                  "StoreURL": "bestlacewigs.com",
                  "Categories": "Beauty",
                  "SubCategories": "Hair Care"
                },
                {
                  "id": 5313,
                  "StoreName": "gifts",
                  "Image": "gifts.png",
                  "TotalCouponsCount": 83,
                  "DealsCount": 0,
                  "StoreURL": "gifts.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing,jewelry,bags,Accessories,ready-to-wear,seasonal,party,belts,handbags,jewelry"
                },
                {
                  "id": 5699,
                  "StoreName": "Smilebox",
                  "Image": "smilebox.png",
                  "TotalCouponsCount": 82,
                  "DealsCount": 0,
                  "StoreURL": "smilebox.com",
                  "Categories": "Arts & Entertainment",
                  "SubCategories": "Photo Gifts"
                },
                {
                  "id": 6,
                  "StoreName": "Amazon",
                  "Image": "amazon.png",
                  "TotalCouponsCount": 80,
                  "DealsCount": 0,
                  "StoreURL": "amazon.com",
                  "Categories": "Apparel & Accessories,Electronics,Toys & Games",
                  "SubCategories": "clothing,Smart Phones,Tablets,Watches,Home Audio,Appliances,Kitchen Appliances,Smart Home,Cameras,Headphones,Office Electronics,Electronics Accessories,Speakers,Mobile Accessories,Baby & Toddler Toys,Developmental Toys,Games"
                },
                {
                  "id": 3349,
                  "StoreName": "influenceu",
                  "Image": "influenceu.png",
                  "TotalCouponsCount": 78,
                  "DealsCount": 0,
                  "StoreURL": "influenceu.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing"
                },
                {
                  "id": 2261,
                  "StoreName": "Ansgear",
                  "Image": "ansgear.png",
                  "TotalCouponsCount": 76,
                  "DealsCount": 0,
                  "StoreURL": "ansgear.com",
                  "Categories": "Sports & Recreation",
                  "SubCategories": "Sports Equipment"
                },
                {
                  "id": 1566,
                  "StoreName": "Clintons Retail",
                  "Image": "clintons-retail.png",
                  "TotalCouponsCount": 74,
                  "DealsCount": 0,
                  "StoreURL": "clintonsretail.com",
                  "Categories": "Flowers & Gifts",
                  "SubCategories": "Cards,Gifts"
                },
                {
                  "id": 3683,
                  "StoreName": "kingsize",
                  "Image": "kingsize.png",
                  "TotalCouponsCount": 70,
                  "DealsCount": 0,
                  "StoreURL": "kingsize.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing"
                },
                {
                  "id": 3063,
                  "StoreName": "hautelook",
                  "Image": "hautelook.png",
                  "TotalCouponsCount": 69,
                  "DealsCount": 0,
                  "StoreURL": "hautelook.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing"
                },
                {
                  "id": 5795,
                  "StoreName": "Soufeel",
                  "Image": "soufeel.png",
                  "TotalCouponsCount": 69,
                  "DealsCount": 0,
                  "StoreURL": "soufeel.com",
                  "Categories": "Apparel & Accessories,Apparel & Accessories,Home & Garden",
                  "SubCategories": "jewelry,jewelry,Accessories,apparel,Home Decor,Kitchen Essentials,Bathroom,Accessories,Bedding"
                },
                {
                  "id": 5031,
                  "StoreName": "prezzybox",
                  "Image": "prezzybox.png",
                  "TotalCouponsCount": 65,
                  "DealsCount": 0,
                  "StoreURL": "prezzybox.com",
                  "Categories": "Flowers & Gifts",
                  "SubCategories": "Corporate Gifts"
                },
                {
                  "id": 502,
                  "StoreName": "debenhams",
                  "Image": "debenhams.png",
                  "TotalCouponsCount": 64,
                  "DealsCount": 0,
                  "StoreURL": "debenhams.com",
                  "Categories": "Apparel & Accessories,Apparel & Accessories",
                  "SubCategories": "clothing,Men,clothing,shoes,swimwear,shoes"
                },
                {
                  "id": 304,
                  "StoreName": "gourmetgiftbaskets",
                  "Image": "gourmetgiftbaskets.png",
                  "TotalCouponsCount": 64,
                  "DealsCount": 0,
                  "StoreURL": "gourmetgiftbaskets.com",
                  "Categories": "Food & Beverage",
                  "SubCategories": "Bucket Meals"
                },
                {
                  "id": 1695,
                  "StoreName": "bettycrocker",
                  "Image": "bettycrocker.png",
                  "TotalCouponsCount": 62,
                  "DealsCount": 0,
                  "StoreURL": "bettycrocker.com",
                  "Categories": "Food & Beverage",
                  "SubCategories": "Hot & Spicy,Cookies"
                },
                {
                  "id": 1306,
                  "StoreName": "cadburygiftsdirect",
                  "Image": "cadburygiftsdirect.png",
                  "TotalCouponsCount": 62,
                  "DealsCount": 0,
                  "StoreURL": "cadburygiftsdirect.co.uk",
                  "Categories": "Food & Beverage",
                  "SubCategories": "Gourmet Choclates"
                },
                {
                  "id": 165,
                  "StoreName": "bhphotovideo",
                  "Image": "b-h-photo-video.png",
                  "TotalCouponsCount": 10,
                  "DealsCount": 52,
                  "StoreURL": "bhphotovideo.com",
                  "Categories": "Electronics,Electronics",
                  "SubCategories": "Cameras,Cameras"
                },
                {
                  "id": 1234,
                  "StoreName": "balloonsfast",
                  "Image": "balloonsfast.png",
                  "TotalCouponsCount": 62,
                  "DealsCount": 0,
                  "StoreURL": "balloonsfast.com",
                  "Categories": "Arts & Entertainment",
                  "SubCategories": "Arts,Prints"
                },
                {
                  "id": 5065,
                  "StoreName": "privateislandparty",
                  "Image": "privacypop.png",
                  "TotalCouponsCount": 62,
                  "DealsCount": 0,
                  "StoreURL": "privateislandparty.com",
                  "Categories": "Flowers & Gifts",
                  "SubCategories": "Party Supplies,Decorations"
                },
                {
                  "id": 6150,
                  "StoreName": "Smartertravel",
                  "Image": "smartertravel.png",
                  "TotalCouponsCount": 59,
                  "DealsCount": 0,
                  "StoreURL": "smartertravel.com",
                  "Categories": "Travel",
                  "SubCategories": "Tours & Attractions,Destinations,Hotels & Resorts"
                },
                {
                  "id": 227,
                  "StoreName": "proflowers",
                  "Image": "proflowers.png",
                  "TotalCouponsCount": 59,
                  "DealsCount": 0,
                  "StoreURL": "proflowers.com",
                  "Categories": "Flowers & Gifts",
                  "SubCategories": "Flowers"
                },
                {
                  "id": 217,
                  "StoreName": "bluemercury",
                  "Image": "bluemercury.png",
                  "TotalCouponsCount": 59,
                  "DealsCount": 0,
                  "StoreURL": "bluemercury.com",
                  "Categories": "Beauty",
                  "SubCategories": "Body Care,Skincare,MakeUp"
                },
                {
                  "id": 72,
                  "StoreName": "Bed Bath & Beyond",
                  "Image": "bedbathbeyond.png",
                  "TotalCouponsCount": 58,
                  "DealsCount": 0,
                  "StoreURL": "bedbathandbeyond.com",
                  "Categories": "Home & Garden",
                  "SubCategories": "Furniture,Bedding"
                },
                {
                  "id": 284,
                  "StoreName": "worldmarket",
                  "Image": "world-market.png",
                  "TotalCouponsCount": 56,
                  "DealsCount": 0,
                  "StoreURL": "worldmarket.com",
                  "Categories": "Food & Beverage",
                  "SubCategories": "Coffee,Tea"
                },
                {
                  "id": 182,
                  "StoreName": "bakedbymelissa",
                  "Image": "bakedbymelissa.png",
                  "TotalCouponsCount": 55,
                  "DealsCount": 0,
                  "StoreURL": "bakedbymelissa.com",
                  "Categories": "Food & Beverage",
                  "SubCategories": "Cakes"
                },
                {
                  "id": 5720,
                  "StoreName": "Snapdeal",
                  "Image": "snapdeal.png",
                  "TotalCouponsCount": 55,
                  "DealsCount": 0,
                  "StoreURL": "snapdeal.com",
                  "Categories": "Apparel & Accessories,Electronics",
                  "SubCategories": "clothing,shoes,jewelry,sunglasses,bags,Accessories,Men,Women,ready-to-wear,womenswear,menswear,footwear,caps,apparel,swimwear,Appliances,Kitchen Appliances,Home Care,Office Electronics,Security & Surveillance,Electronics Accessories"
                },
                {
                  "id": 2009,
                  "StoreName": "buyagift",
                  "Image": "buyagift.png",
                  "TotalCouponsCount": 54,
                  "DealsCount": 0,
                  "StoreURL": "buyagift.co.uk",
                  "Categories": "Flowers & Gifts",
                  "SubCategories": "Gifts"
                },
                {
                  "id": 2053,
                  "StoreName": "accucut",
                  "Image": "accucut.png",
                  "TotalCouponsCount": 54,
                  "DealsCount": 0,
                  "StoreURL": "accucut.com",
                  "Categories": "Automotive & Mechanics",
                  "SubCategories": "Mechanical Tools"
                },
                {
                  "id": 83,
                  "StoreName": "Shindigz",
                  "Image": "shindigz.png",
                  "TotalCouponsCount": 54,
                  "DealsCount": 0,
                  "StoreURL": "shindigz.com",
                  "Categories": "Flowers & Gifts",
                  "SubCategories": "Party Supplies"
                },
                {
                  "id": 3498,
                  "StoreName": "jeulia",
                  "Image": "jeulia.png",
                  "TotalCouponsCount": 54,
                  "DealsCount": 0,
                  "StoreURL": "jeulia.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "jewelry,jewelry"
                },
                {
                  "id": 2276,
                  "StoreName": "aphrodite1994",
                  "Image": "aphrodite.png",
                  "TotalCouponsCount": 53,
                  "DealsCount": 0,
                  "StoreURL": "aphrodite1994.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing,shoes,shoes"
                },
                {
                  "id": 7549,
                  "StoreName": "ae",
                  "Image": null,
                  "TotalCouponsCount": 51,
                  "DealsCount": 0,
                  "StoreURL": "ae.com",
                  "Categories": null,
                  "SubCategories": null
                },
                {
                  "id": 4741,
                  "StoreName": "Personal Creations",
                  "Image": "personal-creations.png",
                  "TotalCouponsCount": 50,
                  "DealsCount": 0,
                  "StoreURL": "personalcreations.com",
                  "Categories": "Apparel & Accessories,Flowers & Gifts",
                  "SubCategories": "apparel,For Him,For Her,Gifts"
                },
                {
                  "id": 5129,
                  "StoreName": "puprwear",
                  "Image": "puprwear.png",
                  "TotalCouponsCount": 50,
                  "DealsCount": 0,
                  "StoreURL": "puprwear.com",
                  "Categories": "Pet Care & Supplies",
                  "SubCategories": "Dog"
                },
                {
                  "id": 4977,
                  "StoreName": "Posty Cards",
                  "Image": "posty-cards.png",
                  "TotalCouponsCount": 50,
                  "DealsCount": 0,
                  "StoreURL": "postycards.com",
                  "Categories": "Flowers & Gifts",
                  "SubCategories": "Cards"
                },
                {
                  "id": 146,
                  "StoreName": "asos",
                  "Image": "asos.png",
                  "TotalCouponsCount": 50,
                  "DealsCount": 0,
                  "StoreURL": "asos.com",
                  "Categories": "Apparel & Accessories,Apparel & Accessories,Beauty",
                  "SubCategories": "clothing,clothing,shoes,shoes,Body Care"
                },
               
              ],
            top_brands:[
                {
                  "id": 7108,
                  "StoreName": "Aerin",
                  "Image": "aerin.png",
                  "TotalCouponsCount": 12,
                  "DealsCount": 0,
                  "StoreURL": "aerin.com",
                  "Categories": "Beauty,Home & Garden",
                  "SubCategories": "MakeUp,Bath and Body,Home Decor"
                },
                {
                  "id": 7109,
                  "StoreName": "Antonymcosmetics",
                  "Image": "antonym.png",
                  "TotalCouponsCount": 4,
                  "DealsCount": 0,
                  "StoreURL": "antonymcosmetics.com",
                  "Categories": "Beauty",
                  "SubCategories": "MakeUp"
                },
                {
                  "id": 7111,
                  "StoreName": "Breadbeautysupply",
                  "Image": "breadbeautysupply.png",
                  "TotalCouponsCount": 0,
                  "DealsCount": 0,
                  "StoreURL": "breadbeautysupply.com",
                  "Categories": "Beauty",
                  "SubCategories": "Hair Care"
                },
                {
                  "id": 7114,
                  "StoreName": "Caudalie",
                  "Image": "caudalie.png",
                  "TotalCouponsCount": 6,
                  "DealsCount": 0,
                  "StoreURL": "en.caudalie.com",
                  "Categories": "Beauty",
                  "SubCategories": "MakeUp"
                },
                {
                  "id": 7115,
                  "StoreName": "Cleanbeauty",
                  "Image": "cleanbeauty.png",
                  "TotalCouponsCount": 6,
                  "DealsCount": 0,
                  "StoreURL": "cleanbeauty.com",
                  "Categories": "Beauty",
                  "SubCategories": "Fragrance"
                },
                {
                  "id": 7116,
                  "StoreName": "Daehair",
                  "Image": "dae.png",
                  "TotalCouponsCount": 2,
                  "DealsCount": 0,
                  "StoreURL": "daehair.com",
                  "Categories": "Beauty",
                  "SubCategories": "Hair Care"
                },
                {
                  "id": 7117,
                  "StoreName": "Drsturm",
                  "Image": "drsturm.png",
                  "TotalCouponsCount": 2,
                  "DealsCount": 0,
                  "StoreURL": "en.drsturm.com",
                  "Categories": "Beauty",
                  "SubCategories": "Moisturizers,Skincare"
                },
                {
                  "id": 7118,
                  "StoreName": "Duobrand",
                  "Image": "duobrand.png",
                  "TotalCouponsCount": 2,
                  "DealsCount": 0,
                  "StoreURL": "duobrand.com",
                  "Categories": "Automotive & Mechanics",
                  "SubCategories": "Wheels & Tires"
                },
                {
                  "id": 7112,
                  "StoreName": "Elcompanies",
                  "Image": "elcompany.png",
                  "TotalCouponsCount": 0,
                  "DealsCount": 0,
                  "StoreURL": "elcompanies.com",
                  "Categories": "Beauty",
                  "SubCategories": "Skincare,MakeUp,Hair Care"
                },
                {
                  "id": 7119,
                  "StoreName": "Erborian",
                  "Image": "erborian.png",
                  "TotalCouponsCount": 0,
                  "DealsCount": 0,
                  "StoreURL": "usa.erborian.com",
                  "Categories": "Beauty",
                  "SubCategories": "Skincare"
                },
                {
                  "id": 7120,
                  "StoreName": "Farsali",
                  "Image": "farsali.png",
                  "TotalCouponsCount": 4,
                  "DealsCount": 0,
                  "StoreURL": "farsali.com",
                  "Categories": "Beauty",
                  "SubCategories": "Body Care,Skincare,MakeUp"
                },
                {
                  "id": 7121,
                  "StoreName": "FormBeauty",
                  "Image": "formbeauty.png",
                  "TotalCouponsCount": 0,
                  "DealsCount": 0,
                  "StoreURL": "formbeauty.com",
                  "Categories": "Beauty",
                  "SubCategories": "Hair Care"
                },
                {
                  "id": 7122,
                  "StoreName": "Gisou",
                  "Image": "gisou1.png",
                  "TotalCouponsCount": 4,
                  "DealsCount": 0,
                  "StoreURL": "gisou.com",
                  "Categories": "Beauty",
                  "SubCategories": "Hair Care"
                },
                {
                  "id": 7123,
                  "StoreName": "Goop",
                  "Image": "goop.png",
                  "TotalCouponsCount": 2,
                  "DealsCount": 0,
                  "StoreURL": "goop.com",
                  "Categories": "Apparel & Accessories,Beauty",
                  "SubCategories": "clothing,shoes,Skincare,MakeUp,Hair Care"
                },
                {
                  "id": 7124,
                  "StoreName": "Hereticparfum",
                  "Image": "hereticparfum.png",
                  "TotalCouponsCount": 4,
                  "DealsCount": 0,
                  "StoreURL": "hereticparfum.com",
                  "Categories": "Beauty",
                  "SubCategories": "Fragrance"
                },
                {
                  "id": 7125,
                  "StoreName": "iconiclondoninc",
                  "Image": "iconic.png",
                  "TotalCouponsCount": 0,
                  "DealsCount": 0,
                  "StoreURL": "row.iconiclondoninc.com",
                  "Categories": "Beauty",
                  "SubCategories": "MakeUp,Lips Care"
                },
                {
                  "id": 7126,
                  "StoreName": "Kenzo",
                  "Image": "kenzo.png",
                  "TotalCouponsCount": 16,
                  "DealsCount": 0,
                  "StoreURL": "kenzo.com",
                  "Categories": "Apparel & Accessories",
                  "SubCategories": "clothing,Accessories,Men,Women"
                },
                {
                  "id": 7127,
                  "StoreName": "Kerastase",
                  "Image": "kerastase.png",
                  "TotalCouponsCount": 0,
                  "DealsCount": 0,
                  "StoreURL": "kerastase.com",
                  "Categories": "Beauty",
                  "SubCategories": "Hair Care"
                },
                {
                  "id": 7128,
                  "StoreName": "Lawlessbeauty",
                  "Image": "lawlessbeauty.png",
                  "TotalCouponsCount": 5,
                  "DealsCount": 0,
                  "StoreURL": "lawlessbeauty.com",
                  "Categories": "Beauty",
                  "SubCategories": "MakeUp"
                },
                {
                  "id": 7110,
                  "StoreName": "Mybkr",
                  "Image": "mybkr.png",
                  "TotalCouponsCount": 12,
                  "DealsCount": 0,
                  "StoreURL": "mybkr.com",
                  "Categories": "Beauty",
                  "SubCategories": "Skincare,MakeUp"
                },
                {
                  "id": 7129,
                  "StoreName": "Obagi",
                  "Image": "obagi.png",
                  "TotalCouponsCount": 1,
                  "DealsCount": 0,
                  "StoreURL": "obagi.com",
                  "Categories": "Beauty",
                  "SubCategories": "Skincare,MakeUp"
                },
                {
                  "id": 7130,
                  "StoreName": "Peaceoutskincare",
                  "Image": "peaceout.png",
                  "TotalCouponsCount": 5,
                  "DealsCount": 0,
                  "StoreURL": "peaceoutskincare.com",
                  "Categories": "Beauty",
                  "SubCategories": "Skincare"
                },
                {
                  "id": 7131,
                  "StoreName": "Pinksugar",
                  "Image": "pinksugar.png",
                  "TotalCouponsCount": 1,
                  "DealsCount": 0,
                  "StoreURL": "pinksugar.com.ph",
                  "Categories": "Beauty",
                  "SubCategories": "MakeUp"
                }],
            loading:false,
            redirect:null,
            countries:[
                ["USA","http://dealssite.softwarepattern.net/"]
            ]
        }
        
    }
    
    componentDidMount(){
        var top_stores =  localStorage.getItem("top_stores");
        if(!top_stores){

            axios.get(`v1/public/api/HomepageStores`).then((response) => {
        
                
                if(response.data.stores){
                    const stores = response.data.stores;
                    var storeslist = [];
                    var i = 0; 
                    var count = stores.length;
                    
                    for(i =0;i<10;i++){
                        if(i < count){
                            if(stores[i].StoreName!=""){
                                storeslist.push({"id": stores[i].id, "StoreName":stores[i].StoreName});
                            }
                        
                        }
                    }
                    localStorage.setItem("top_stores",JSON.stringify(storeslist));
                    this.setState({top_stores:storeslist});
                }
                
            }, (error) => {
                console.log(error);
            });
        }else{
            
           return this.setState({top_stores:JSON.parse(top_stores)});
        }


        // if(this.props.categories){
            
        //     if(this.state.category1.length<1 ){

        //         axios.get(`http://adscombined.com/v1/public/api/AllCategories`).then((response) => {
           
        //             if(response.data.categories){
        //                 const categories = response.data.categories;
        //                 var half_length = Math.ceil(categories.length / 2);    
        //                 const category1 = categories.splice(0,half_length+1);
        //                 const category2 = categories;
        //                 this.setState({category1});
        //                 this.setState({category2});
        //             }
        //         }, (error) => {
                  
        //             console.log(error);
        //         });
    
        //     }
        // }else{
            
        //     const categories = this.state.all_categories;
        //     var half_length = Math.ceil(categories.length / 2);    
        //     const category1 = categories.splice(0,half_length+1);
        //     const category2 = categories;
        //     this.setState({category1});
        //     this.setState({category2});
        // }
        
       
    }
    render(){
      
            return(
                <div>
                    
                    <footer className=" page-footer  nav-background font-small  " > 
                        <div className="container-fluid container text-center text-md-left"> 
                    
                        { (isTablet || isBrowser)?
                            <div className="row  ">
                        
                                <div className="col-md-2 mx-auto">
                                    <h5 className="font-weight-bold  font-color" >Top Stores</h5>
                                    <ul className="list-unstyled">
                                    {this.state.top_stores && this.state.top_stores.slice(0,10).map(store =>
                                        <li key={store.id.toString()}>
                                            <a title={store.StoreName+"  Coupons, Promotion Codes & Deals"} href={'/coupons/'+store.StoreURL}>
                                                {store.StoreName}
                                            </a>
                                        </li>
                                    )
                                    }
                                    
                                    </ul>
                                </div>
                                <hr className="clearfix w-100 d-md-none" />
                                <div className="col-md-2 mx-auto"> 
                                    <h5 className="font-weight-bold  font-color">Top Brands</h5>
                                    <ul className="list-unstyled">
                                    {this.state.top_brands && this.state.top_brands.slice(0,10).map(store =>
                                        <li key={store.id.toString()}>
                                            <a title={store.StoreName+"  Coupons, Promotion Codes & Deals"} href={'/coupons/'+store.StoreURL}>
                                                {store.StoreName}
                                            </a>
                                        </li>
                                    )
                                    }
                                    </ul>
                                </div>
                                <hr className="clearfix w-100 d-md-none" />
                                <div className="col-md-2 mx-auto">
                                    <h5 className="font-weight-bold  font-color" >Categories</h5>
                                    <ul className="list-unstyled">
                                    { this.state.category1.map(cat =>
                                        <li key={cat.toString()}>
                                            <a title={cat+" Coupons & Promotion Codes"} href={'/coupons/'+window.$categories[cat]}>
                                                {cat}
                                            </a>
                                        </li>
                                    )
                                    }
                                    </ul>
                                </div>
                                <hr className="clearfix w-100 d-md-none" />
                                <div className="col-md-2 mx-auto">
                                    <h5 className="font-weight-bold  font-color" >Categories</h5>
                                    <ul className="list-unstyled">
                                        { this.state.category2.map(cat =>
                                            <li key={cat.toString()}>
                                                <a title={cat+" Coupons & Promotion Codes"} href={'/coupons/'+window.$categories[cat]}>
                                                    {cat}
                                                </a>
                                            </li>
                                        )
                                        }
                                    </ul>
                                </div>
                                <hr className="clearfix w-100 d-md-none" />
                                <div className="col-md-2 mx-auto">
                                    <h5 className="font-weight-bold font-color" >General</h5>
                                    <ul className="list-unstyled">
                                        <li>
                                            <Link  to={'/about-us'}>
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/contact-us'}>
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/faqs'}>
                                                FAQs
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/terms-and-conditions'}>
                                                Terms and Conditions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/privacy-policy'}>
                                                Privacy Policy
                                            </Link>
                                        </li>
                                    </ul>
                                    
                                    <div className="line-seperator"></div>
                                    <h5 className="font-weight-bold  font-color" >Deals Sites</h5>
                                <select className="input-style">
                                    { this.state.countries.map(country =>
                                        <option key={country.toString()} value={country[1]}>{country[0]}</option>     
                                    )}
                                </select>
                                <div className="line-seperator"></div>
                                <h5 className="font-weight-bold mt-3 font-color" >Mobile Apps</h5>
                                
                                  <a href="" target="blank">
                                    <img  style={{width:"44%"}} src={process.env.PUBLIC_URL+"/google_play_icon.png"}></img>
                                  </a>

                                  <a href="" target="blank">
                                    <img  style={{width:"49%"}} src={process.env.PUBLIC_URL+"/app_store_icon1.png"}></img>
                                  </a>
                                <div className="line-seperator"></div>
                                <h5 className="font-weight-bold mt-3 font-color" >Find Us</h5>
                                <center>
                                  <a
                                    className="fa fa-facebook"
                                    style={{borderRadius:"50%",padding:"6px",width:"38px"}}
                                    href=""
                                  ></a>
                                  <a
                                    className="fa fa-twitter"
                                    style={{borderRadius:"50%",padding:"6px",width:"38px"}}
                                    href=""
                                  ></a>
                                  <a 
                                  className="fa fa-youtube"
                                  style={{borderRadius:"50%",padding:"6px",width:"38px"}}
                                    href="">
                                  </a>
                                  <a
                                    className="fa fa-instagram"
                                    style={{borderRadius:"50%",padding:"6px",width:"38px"}}
                                    href=""
                                  ></a>
                                  </center>
                                    <br></br>
                                </div>
                                
                            </div> 
                        :
                        <div>

                            <div className="w-50 mx-auto">
                                    <h5 className="font-weight-bold   font-color ">Categories</h5>
                                    <ul className="list-unstyled">
                                    { this.state.category1.map(cat =>
                                        <li>
                                            <a title={cat+" Coupons"} href={'/coupons/'+window.$categories[cat]}>
                                                {cat}
                                            </a>
                                        </li>
                                    )
                                    }
                                    </ul>
                                </div>
                                <hr className="w-50 clearfix w-100 d-md-none" />
                                <div className="col-md-2 mx-auto">
                                    <h5 className="font-weight-bold   font-color">Categories</h5>
                                    <ul className="list-unstyled">
                                        { this.state.category2.map(cat =>
                                            <li>
                                                <a title={cat+" Coupons"} href={'/coupons/'+window.$categories[cat]}>
                                                    {cat}
                                                </a>
                                            </li>
                                        )
                                        }
                                    </ul>
                                </div>
                            </div>
                        } 
                        
                        </div> 
                        <div className="footer-copyright text-center py-3 ">
                        © 2020 Copyright {" "}
                        <a style={{color:"#fff"}} href="http://www.adscombined.com/">adscombined.com</a> 
                        </div>  
                    </footer>
                </div>
                
            )
        
       
    }
}
export default Footer;
import React, { PureComponent } from "react";
import axios from "axios";
import Toast from "light-toast";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import ShowMore from "@tedconf/react-show-more";
import { isMobile, isBrowser, isTablet } from "react-device-detect";

import CouponTypeDisplay from "./CouponTypeDisplay";
import TopAdsSkeleton from "./skeletons/TopAdsSkeleton";
import AddtoFavourite from "./AddtoFavourite";

import { getUser, removeUserSession } from "../utils/Common";
const user = getUser();

class HomeAdsCoupons extends PureComponent {
  state = {
    loading: false,
    coupons: [],
    sepecial_coupon: [],
    sc_id: "",
    open: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      coupons: [],
      sepecial_coupon: [],
      sc_id: "",
      count: 0,
      open: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.redirectToStore = this.redirectToStore.bind(this);
    this.outFunc = this.outFunc.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.shorten = this.shorten.bind(this);
    this.Capitalize = this.Capitalize.bind(this);
    this.updateColorCount = this.updateColorCount.bind(this);
  }

  updateColorCount() {
    this.setState({ count: this.state.count++ });
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  myFunction() {
    var copyText = document.getElementById("code-value");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy Code";
  }

  openModal(coupon) {
    if (this.state.sepecial_coupon.length < 1) {
      this.setState({ open: true, sepecial_coupon: coupon });
    }
  }

  closeModal() {
    this.setState({ open: false });
  }

  loadMore() {
    this.setState((prev) => {
      return { visible: prev.visible + 12 };
    });
  }

  redirectToStore(coupon) {
    Toast.info(
      <div style={{ height: "70px", fontSize: "20px", padding: "10px" }}>
        Redirecting to ... {coupon.StoreURL}
      </div>,
      3000,
      () => {
        window.open(
          window.location.href.split("?")[0] + "?c=" + coupon.id,
          "_blank"
        );
        //focus to thet window
        window.focus();
        setTimeout(
          //this.openModal(coupon)
          (window.location.href = coupon.SourceUrl),
          2000
        );
      }
    );
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.has("c")) {
      var sc_id = "";
      sc_id = params.get("c");
      var user_id = "";
      if(user ){
        user_id = "&user_id="+user.id;
      }
      axios
        .get(
          `http://adscombined.com/v1/public/api/CouponDetails?coupon_id=${encodeURIComponent(
            sc_id
          )}${user}`
        )
        .then(
          (response) => {
            if (response.data.flag) {
              if (response.data.coupon_data) {
                const sepecial_coupon = response.data.coupon_data;

                this.openModal(sepecial_coupon);
              }
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    this.setState({ loading: true }, () => {
      var user_id = "";
      if(user ){
        user_id = "&user_id="+user.id;
      }
      axios
        .post(
          `http://adscombined.com/v1/public/api/FilterCoupons?filter=${encodeURIComponent(
            this.props.coupon_type[1]
          )}&Page=0&PerPage=9&Filter=${encodeURIComponent(
            this.props.coupon_type[1]
          )}${user_id}`
        )
        .then(
          (response) => {
            if (response.data.coupons) {
              var couponss_weekly = response.data.coupons;
              var couponss_daily = null;

              axios
                .post(
                  `http://adscombined.com/v1/public/api/FilterCoupons?filter=${encodeURIComponent(
                    "Today's Ad"
                  )}&Page=0&PerPage=9&Filter=${encodeURIComponent("Clearance")}${user_id}`
                )
                .then((response) => {
                  if (response.data.coupons) {
                    couponss_daily = response.data.coupons;
                  }
                });
              //     var couponss = [];

              ///  couponss_sales.push(couponss_clearance);
              var couponss = couponss_weekly.slice(0, 6);
              if (!isBrowser && !isTablet) {
                var mobile_size = couponss.length <= 4 ? couponss.length : 4;
                couponss = couponss.slice(0, parseInt(mobile_size));
              }
              this.setState({ coupons: couponss });
            
              this.setState({ loading: false });
            } else {
              this.setState({ loading: false });
            }
          },
          (error) => {
            this.setState({ loading: false });
            console.log(error);
          }
        );
    });
  }

  shorten(text, max) {
    return text && text.length > max
      ? text.slice(0, max).split(" ").slice(0, -1).join(" ")
      : text;
  }

  render() {
    var count = 5;
    const contentStyle = {
      width: isBrowser
        ? "70% !important"
        : isTablet
        ? "70% !important"
        : "90% !important",
    };
    return (
      <div>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          {...{ contentStyle }}
        >
          <div className="modal">
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
            <div class="row" style={{ padding: "10px" }}>
              <div className="col-md-4 pl-0">
                <center>
                  <img
                    title={
                      this.state.sepecial_coupon.StoreName +
                      " Coupons, Promotion Codes & Deals"
                    }
                    alt={
                      this.state.sepecial_coupon.StoreName +
                      " Coupons, Promotion Codes & Deals"
                    }
                    src={
                     this.state.sepecial_coupon.Logo
                        ? window.$logos_url +
                          this.state.sepecial_coupon.Logo.replace(".PNG",".png")
                        :  process.env.PUBLIC_URL + "/default_logo.png"
                    }
                    width="100"
                    height="100"
                    id="store_logo"
                  ></img>
                  <h5
                    title={
                      this.state.sepecial_coupon.StoreName +
                      " Coupons, Promotion Codes & Deals"
                    }
                  >
                    <b style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </b>
                  </h5>
                </center>
              </div>

              <div className="col-md-8 p-3" style={{ padding: "inherit" }}>
              <AddtoFavourite type={"heart"} source={"sadscoupons"} id={this.state.sepecial_coupon.id} favourite_type={"coupon"} favourite_id = {this.state.sepecial_coupon.favourite_id} is_favourite={this.state.sepecial_coupon.favourite}/>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "teal",
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.sepecial_coupon.Offer &&
                  this.state.sepecial_coupon.Offer !== "none" &&
                  this.state.sepecial_coupon.Offer !== "None" ? (
                    <CouponTypeDisplay
                      source="couponpage"
                      coupontype={this.state.sepecial_coupon.Offer}
                    />
                  ) : (
                    <CouponTypeDisplay
                      source="couponpage"
                      coupontype={this.state.sepecial_coupon.Type}
                    />
                  )}
                </span>

                <h4 title={this.state.sepecial_coupon.Title}>
                  <span id="coupon_title">
                    {this.state.sepecial_coupon.Title}
                  </span>
                </h4>

                <br />
                <b></b>
                {this.state.sepecial_coupon.Code &&
                this.state.sepecial_coupon.Code != "None" ? (
                  <span>
                    <input
                      id="code-value"
                      type="text"
                      className="code-copy"
                      value={this.state.sepecial_coupon.Code}
                      style={{
                        borderRadius: "0px",
                        width: "fit-content",
                        cursor: "copy",
                      }}
                      readOnly
                    />

                    <span>
                      <button
                        className="btn"
                        onClick={this.myFunction}
                        onMouseOut={this.outFunc}
                        id="myTooltip"
                        style={{ verticalAlign: "inherit" }}
                      >
                        Copy Code
                      </button>
                    </span>

                    <br />
                    <br />
                  </span>
                ) : (
                  <div></div>
                )}

                <a href={this.state.sepecial_coupon.SourceUrl}>
                  <b>
                    {" "}
                    Continue to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {this.state.sepecial_coupon.StoreName}
                    </span>
                  </b>{" "}
                </a>
              </div>
            </div>
          </div>
        </Popup>
        {isMobile? 

          <div className="row mt-3">
            <div
              className="col-md-12 "
              style={{
                background: this.props.color,
                border: "1px solid rgba(0,0,0,.125)",
                borderBottom: "0px",
                borderTopRightRadius: "50px",
              }}
            >
              <div className="see_more_background_yellow">
                <h3
                  className="row_title row_title_yellow"
                 
                >
                  <b>
                      <Link
                        className="mt-2"
                        to={this.props.coupon_type[0]}
                        title={"View All " + this.props.coupon_type[1] + " & Today's Ad"}  style={{ color: "white" }}>
                        {this.props.coupon_type[1]}
                      </Link>
                    
                  </b>{" "}
                </h3>
              
                <div id="clear"></div>
              </div>
           
            </div>
          </div>
        :
          <div className="row mt-3">
            <div
              className="col-md-3 "
              style={{
                background: this.props.color,
                border: "1px solid rgba(0,0,0,.125)",
                borderBottom: "0px",
                borderTopRightRadius: "50px",
              }}
            >
              <div className="see_more_background_yellow">
                <h3
                  className="row_title row_title_yellow"
                  style={{ color: "white" }}
                >
                  <b>{this.props.coupon_type[1]}s</b>{" "}
                </h3>

                <div id="clear"></div>
              </div>
            </div>
            <div className="col-md-9 ">
              <Link
                className="mt-2"
                to={this.props.coupon_type[0]}
                title={"View All " + this.props.coupon_type[1] + " & Today's Ad"}
                className="  own_warning see_more_title pull-right"
                style={{ color: this.props.color, paddingTop: "24px" }}
              >
                <b>View All {">>"}</b>
              </Link>
            </div>
          </div>
        }
        
        {isBrowser ? (
          this.state.loading ? (
            <TopAdsSkeleton items={[1, 2, 3, 4, 5, 6]} />
          ) : (
            <div className="card " style={{borderColor: "rgba(0, 0, 0, 0.2)",
            boxShadow: "10px 10px 10px 10px rgba(0.2, 0.1, 0.2, 0.1)"}}>
              <div className="card-body">
                <div className="row">
                  <ShowMore items={this.state.coupons} by={6}>
                    {({ current, onMore }) => (
                      <React.Fragment>
                        {this.state.coupons.length > 0 ? (
                          current.map((coupon) => (
                            <div
                            key={coupon.id}
                              className="card-deck pl-1 pr-1 col-md-2 mt-2 clickable"
                              style={{}}
                              title={coupon.Title}
                            >
                              <div
                                className="card  single-deal"
                                style={{ borderRadius: "5px" }}
                              >
                                <AddtoFavourite type={"heart"} source={"adscoupons"} id={coupon.id} favourite_id = {coupon.favourite_id} favourite_type={"coupon"} is_favourite={coupon.favourite} />
                                <div
                                  className="home-offer-text "
                                  style={{ background: "inherit" }}
                                >
                                  <center className="coupon-type-text" style={{padding:"5%"}}>
                                    <Link
                                      className="logo  cust_link featured"
                                      to={"coupons/" + coupon.StoreURL}
                                      title={
                                        coupon.StoreName +
                                        " Coupons, Promotion Codes & Deals"
                                      }
                                    >
                                      {coupon.Logo &&
                                      coupon.Logo != "Logo Name" &&
                                      coupon.Logo != "none" ? (
                                        <div
                                          className="center deals-img"
                                          title={
                                            coupon.StoreName +
                                            " Coupons, Promotion Codes & Deals"
                                          }
                                          style={{
                                            height: "90px",
                                            width: "90px",
                                            backgroundColor: "white",
                                            // border: "1px solid lightgray",
                                            backgroundImage:
                                              "url(" +
                                              window.$logos_url +
                                              coupon.Logo.replace(".PNG",".png") +
                                              ")",
                                          }}
                                        ></div>
                                      ) : (
                                        <div
                                          className="center deals-img"
                                          title={
                                            coupon.StoreName +
                                            " Coupons, Promotion Codes & Deals"
                                          }
                                          style={{
                                            height: "90px",
                                            backgroundColor: "white",
                                            width: "90px",
                                            // border: "1px solid lightgray",
                                            backgroundImage:
                                              "url("+ process.env.PUBLIC_URL + "/default_logo.png"+")",
                                          }}
                                        ></div>
                                      )}
                                    </Link>
                                  </center>
                                </div>
                                <div
                                  className="card-body"
                                  style={{
                                    padding: "10px !important",
                                    padding: "0.8rem",
                                  }}
                                >
                                  <small
                                    onClick={() => this.redirectToStore(coupon)}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {coupon.Offer &&
                                    coupon.Offer != "None" &&
                                    coupon.Offer != "none" ? (
                                      <h5
                                        className="badge"
                                        style={{
                                          fontWeight: "bold",
                                          color: "white",
                                          fontSize: "12px",
                                          background: this.props.color,
                                        }}
                                      >
                                        {coupon.Offer}
                                      </h5>
                                    ) : (
                                      <h5
                                        className="badge"
                                        style={{
                                          fontWeight: "bold",
                                          color: "white",
                                          fontSize: "12px",
                                          background: this.props.color,
                                        }}
                                      >
                                        {coupon.Type}
                                      </h5>
                                    )}
                                  </small>
                                  <div
                                    onClick={() => this.redirectToStore(coupon)}
                                    title={"Click to get " + coupon.Title}
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      marginTop: "0.25rem",
                                      marginBottom: "0.25rem !important",
                                    }}
                                  >
                                    {" "}
                                    {coupon.Title && coupon.Title.length < 40
                                      ? coupon.Title
                                      : this.shorten(coupon.Title, 40) + ".."}
                                  </div>

                                  <p>
                                    <Link
                                      to={
                                        "/coupons/" +
                                        window.$categories[coupon.Category]
                                      }
                                      style={{
                                        position: "absolute",
                                        bottom: "5px",
                                      }}
                                      title={
                                        coupon.Category +
                                        " Coupons & Promotion Codes"
                                      }
                                    >
                                      <small>{coupon.Category}</small>
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <p>No {this.props.coupon_type[1]} available.</p>
                          </div>
                        )}
                        {!onMore ? (
                          <div className="col-md-12 mt-2">
                            {/* <center >
                          <Link
                          to={this.props.coupon_type[0]}
                          className="btn "
                          style={{background:this.props.color,color:"white"}}
                          >View All {this.props.coupon_type[1]}
                          </Link>
                        </center> */}
                          </div>
                        ) : (
                          <div className="col-md-12 mt-2">
                            <center>
                              <button
                                className="btn "
                                style={{
                                  background: this.props.color,
                                  color: "white",
                                }}
                                disabled={!onMore}
                                onClick={() => {
                                  if (!!onMore) onMore();
                                }}
                              >
                                Show More
                              </button>
                            </center>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </ShowMore>
                </div>
              </div>
            </div>
          )
        ) : isTablet ? (
          this.state.loading ? (
            <TopAdsSkeleton items={[1, 2, 3, 4]} />
          ) : (
            <div className="card " style={{borderColor: "rgba(0, 0, 0, 0.2)",
            boxShadow: "10px 10px 10px 10px rgba(0.2, 0.1, 0.2, 0.1)"}}>
              <div className="card-body">
                <div className="row">
                  <ShowMore items={this.state.coupons} by={18}>
                    {({ current, onMore }) => (
                      <React.Fragment>
                        {this.state.coupons.length > 0 ? (
                          current.map((coupon) => (
                            <div
                            key={coupon.id}
                              className="card-deck pl-1 pr-1 col-md-2 mt-2 clickable"
                              style={{}}
                              title={coupon.Title}
                            >
                              <div
                                className="card  single-deal"
                                style={{ borderRadius: "5px" }}
                              >
                                <AddtoFavourite type={"heart"} source={"adscoupons"} id={coupon.id} favourite_id = {coupon.favourite_id} favourite_type={"coupon"} is_favourite={coupon.favourite} />
                                <div
                                  className="home-offer-text "
                                  style={{ background: "inherit" }}
                                >
                                  <center className="coupon-type-text" style={{padding:"5%"}}>
                                    <Link
                                      className="logo  cust_link featured"
                                      to={"coupons/" + coupon.StoreURL}
                                      title={
                                        coupon.StoreName +
                                        " Coupons, Promotion Codes & Deals"
                                      }
                                    >
                                      {coupon.Logo &&
                                      coupon.Logo != "Logo Name" &&
                                      coupon.Logo != "none" ? (
                                        <div
                                          className="center deals-img"
                                          title={
                                            coupon.StoreName +
                                            " Coupons, Promotion Codes & Deals"
                                          }
                                          style={{
                                            marginTop: "-25px",
                                            height: "90px",
                                            width: "90px",
                                            backgroundColor: "white",
                                            // border: "1px solid lightgray",
                                            backgroundImage:
                                              "url(" +
                                              window.$logos_url+ 
                                              coupon.Logo.replace(".PNG",".png") +
                                              ")",
                                          }}
                                        ></div>
                                      ) : (
                                        <div
                                          className="center deals-img"
                                          title={
                                            coupon.StoreName +
                                            " Coupons, Promotion Codes & Deals"
                                          }
                                          style={{
                                            marginTop: "-25px",
                                            height: "90px",
                                            backgroundColor: "white",
                                            width: "90px",
                                            // border: "1px solid lightgray",
                                            backgroundImage:
                                              "url("+ process.env.PUBLIC_URL + "/default_logo.png"+")",
                                          }}
                                        ></div>
                                      )}
                                    </Link>
                                  </center>
                                </div>
                                <div
                                  className="card-body"
                                  style={{
                                    padding: "10px !important",
                                    padding: "0.8rem",
                                  }}
                                >
                                  <small
                                    onClick={() => this.redirectToStore(coupon)}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {coupon.Offer &&
                                    coupon.Offer != "None" &&
                                    coupon.Offer != "none" ? (
                                      <h5
                                        className="badge"
                                        style={{
                                          fontWeight: "bold",
                                          color: "white",
                                          fontSize: "12px",
                                          background: this.props.color,
                                        }}
                                      >
                                        {coupon.Offer}
                                      </h5>
                                    ) : (
                                      <h5
                                        className="badge"
                                        style={{
                                          fontWeight: "bold",
                                          color: "white",
                                          fontSize: "12px",
                                          background: this.props.color,
                                        }}
                                      >
                                        {coupon.Type}
                                      </h5>
                                    )}
                                  </small>
                                  <div
                                    onClick={() => this.redirectToStore(coupon)}
                                    title={"Click to get " + coupon.Title}
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      marginTop: "0.25rem",
                                      marginBottom: "0.25rem !important",
                                    }}
                                  >
                                    {" "}
                                    {coupon.Title && coupon.Title.length < 40
                                      ? coupon.Title
                                      : this.shorten(coupon.Title, 40) + ".."}
                                  </div>

                                  <p>
                                    <Link
                                      to={
                                        "/coupons/" +
                                        window.$categories[coupon.Category]
                                      }
                                      style={{
                                        position: "absolute",
                                        bottom: "5px",
                                      }}
                                      title={
                                        coupon.Category +
                                        " Coupons & Promotion Codes"
                                      }
                                    >
                                      <small>{coupon.Category}</small>
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <p>No Offers available.</p>
                          </div>
                        )}
                        {!onMore ? (
                          <div className="col-md-12 mt-2">
                            {/* <center >
                          <Link
                          to={this.props.coupon_type[0]}
                          className="btn "
                          style={{background:this.props.color,color:"white"}}
                          >View All  {this.props.coupon_type[1]}
                          </Link>
                        </center> */}
                          </div>
                        ) : (
                          <div className="col-md-12 mt-2">
                            <center>
                              <button
                                className="btn"
                                style={{
                                  background: this.props.color,
                                  color: "white",
                                }}
                                disabled={!onMore}
                                onClick={() => {
                                  if (!!onMore) onMore();
                                }}
                              >
                                Show More
                              </button>
                            </center>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </ShowMore>
                </div>
              </div>
            </div>
          )
        ) : this.state.loading ? (
          <TopAdsSkeleton items={[1, 2, 3, 4]} />
        ) : (
          <div className="card " style={{borderColor: "rgba(0, 0, 0, 0.2)",
          boxShadow: "10px 10px 10px 10px rgba(0.2, 0.1, 0.2, 0.1)"}}>
            <div className="card-body">
              <div className="row"></div>
              {this.state.coupons.length > 0 ? (
                this.state.coupons.map((coupon) => (
                  <div
                  key={coupon.id}
                    className="card-deck pl-1 pr-1 col-md-2 mt-2 clickable"
                    style={{}}
                    title={coupon.Title}
                  >
                    <div
                      className="card  single-deal"
                      style={{ borderRadius: "5px" }}
                    >
                      <AddtoFavourite type={"heart"} source={"adscoupons"} id={coupon.id} favourite_type={"coupon"} favourite_id = {coupon.favourite_id} is_favourite={coupon.favourite} />
                      <div
                        className="home-offer-text "
                        style={{ background: "inherit" }}
                      >
                        <center className="coupon-type-text" style={{padding:"5%"}}>
                          <Link
                            className="logo  cust_link featured"
                            to={"coupons/" + coupon.StoreURL}
                            title={
                              coupon.StoreName +
                              " Coupons, Promotion Codes & Deals"
                            }
                          >
                            {coupon.Logo &&
                            coupon.Logo != "Logo Name" &&
                            coupon.Logo != "none" ? (
                              <div
                                className="center deals-img"
                                title={
                                  coupon.StoreName +
                                  " Coupons, Promotion Codes & Deals"
                                }
                                style={{
                                  marginTop: "-25px",
                                  height: "90px",
                                  width: "90px",
                                  backgroundColor: "white",
                                  // border: "1px solid lightgray",
                                  backgroundImage:
                                    "url(" +
                                    window.$logos_url +
                                    coupon.Logo.replace(".PNG",".png") +
                                    ")",
                                }}
                              ></div>
                            ) : (
                              <div
                                className="center deals-img"
                                title={
                                  coupon.StoreName +
                                  " Coupons, Promotion Codes & Deals"
                                }
                                style={{
                                  marginTop: "-25px",
                                  height: "90px",
                                  backgroundColor: "white",
                                  width: "90px",
                                  // border: "1px solid lightgray",
                                  backgroundImage:
                                    "url("+ process.env.PUBLIC_URL + "/default_logo.png"+")",
                                }}
                              ></div>
                            )}
                          </Link>
                        </center>
                      </div>
                      <div
                        className="card-body"
                        style={{
                          padding: "10px !important",
                          padding: "0.8rem",
                        }}
                      >
                        <small
                          onClick={() => this.redirectToStore(coupon)}
                          style={{ textTransform: "uppercase" }}
                        >
                          {coupon.Offer &&
                          coupon.Offer != "None" &&
                          coupon.Offer != "none" ? (
                            <h5
                              className="badge"
                              style={{
                                fontWeight: "bold",
                                color: "white",
                                fontSize: "12px",
                                background: this.props.color,
                              }}
                            >
                              {coupon.Offer}
                            </h5>
                          ) : (
                            <h5
                              className="badge"
                              style={{
                                fontWeight: "bold",
                                color: "white",
                                fontSize: "12px",
                                background: this.props.color,
                              }}
                            >
                              {coupon.Type}
                            </h5>
                          )}
                        </small>
                        <div
                          onClick={() => this.redirectToStore(coupon)}
                          title={"Click to get " + coupon.Title}
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem !important",
                          }}
                        >
                          {" "}
                          {coupon.Title && coupon.Title.length < 40
                            ? coupon.Title
                            : this.shorten(coupon.Title, 40) + ".."}
                        </div>

                        <p>
                          <Link
                            to={
                              "/coupons/" + window.$categories[coupon.Category]
                            }
                            style={{ position: "absolute", bottom: "5px" }}
                            title={
                              coupon.Category + " Coupons & Promotion Codes"
                            }
                          >
                            <small>{coupon.Category}</small>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-md-12">
                  <p>No Offers available.</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default React.memo(HomeAdsCoupons);

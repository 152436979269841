import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

window.$sitekey = "a3323sdfdssdasj89asdj99asd7lsa";
window.$sitename = " ADS Combined";
window.$site_url = "http://adscombined.com/";
window.$logos_url = "http://adscombined.com/admin/public/storage/";


window.$categories = {
  "Apparel & Accessories": "apparel-accessories",
  clothing: "clothing",
  shoes: "shoes",
  jewelry: "jewelry",
  "sunglasses & Eyewear": "sunglasses-eyewear",
  bags: "bags",
  leather: "leather",
  Accessories: "accessories",
  Men: "men",
  Women: "women",
  "ready-to-wear": "ready-to-wear",
  womenswear: "womenswear",
  menswear: "menswear",
  footwear: "footwear",
  caps: "caps",
  apparel: "apparel",
  "casual shoes": "casual-shoes",
  swimwear: "swimwear",
  "seasonal clothing": "seasonal-clothing",
  party: "party",
  wedding: "wedding",
  bridal: "bridal",
  "mother of bridal": "mother-of-bridal",
  "mother of groom": "mother-of-groom",
  belts: "belts",
  handbags: "handbags",
  shapewears: "shapewears",
  "For Him": "for-him",
  "For Her": "for-her",
  watches: "watches",
  sleepwear: "sleepwear",
  "Gloves & Mittens": "gloves-mittens",
  Hats: "hats",
  "Shoe Care": "shoe-care",
  "Garment Care": "garment-care",
  Costumes: "costumes",
  Uniforms: "uniforms",
  "Arts & Entertainment": "arts-entertainment",
  Arts: "arts",
  Entertainment: "entertainment",
  Prints: "prints",
  "Canvas Prints": "canvas-prints",
  "Photo Gifts": "photo-gifts",
  "Photo Books": "photo-books",
  Paint: "paint",
  "Canvas and Surfaces": "canvas-and-surfaces",
  "Drawing and Illustration": "drawing-and-illustration",
  "Brushes and Painting Tools": "brushes-and-painting-tools",
  "Easels and Furnitures": "easels-and-furnitures",
  "Framing and Matborad": "framing-and-matborad",
  "ceramics and sculpture": "ceramics-and-sculpture",
  "Studios and Supplies": "studios-and-supplies",
  Streaming: "streaming",
  "TV Shows": "tv-shows",
  Media: "media",
  movie: "movie",
  Film: "film",
  Cinema: "cinema",
  News: "news",
  Paintings: "paintings",
  Exhibitions: "exhibitions",
  "Automotive & Mechanics": "automotive-mechanics",
  "Auto Body Parts & Mirrors": "auto-body-parts-mirrors",
  "Headlights & Lighting": "headlights-lighting",
  "Engine & Drivetrain": "engine-drivetrain",
  "Brakes & Suspension & Steering": "brakes-suspension-steering",
  "Wheels & Tires": "wheels-tires",
  "Oil Change Specials": "oil-change-specials",
  Batteries: "batteries",
  "Exterior Parts & Car Care": "exterior-parts-car-care",
  "Interior Parts & Accessories": "interior-parts-accessories",
  Motorcycles: "motorcycles",
  "Wash & Wax": "wash-wax",
  Apparel: "apparel",
  "Protective Films": "protective-films",
  "Mechanical Tools": "mechanical-tools",
  "Baby & Nursery": "baby-nursery",
  Summer: "summer",
  Winter: "winter",
  Babies: "babies",
  Toddler: "toddler",
  Kids: "kids",
  "Matching Outfits": "matching-outfits",
  "Baby Toys": "baby-toys",
  "Baby Gear": "baby-gear",
  Nursery: "nursery",
  Clothing: "clothing",
  Beauty: "beauty",
  "Body Care": "body-care",
  "Bath & Shower": "bath-shower",
  Moisturizers: "moisturizers",
  Fragrance: "fragrance",
  "Hand Soap": "hand-soap",
  "Hand Sanitizers": "hand-sanitizers",
  candles: "candles",
  "Air Freshners": "air-freshners",
  Skincare: "skincare",
  MakeUp: "makeup",
  "Hair Care": "hair-care",
  "Bath and Body": "bath-and-body",
  "Tools and Brushes": "tools-and-brushes",
  "Lips Care": "lips-care",
  "Books, Music & Video": "books-music-video",
  Calendars: "calendars",
  Puzzles: "puzzles",
  Notecards: "notecards",
  Postcards: "postcards",
  Stationery: "stationery",
  Pens: "pens",
  Pencils: "pencils",
  Journals: "journals",
  Notebooks: "notebooks",
  Books: "books",
  Music: "music",
  eBooks: "ebooks",
  Textbooks: "textbooks",
  Magazines: "magazines",
  Newspapers: "newspapers",
  "Wedding Cards": "wedding-cards",
  Envelopes: "envelopes",
  Business: "business",
  Bank: "bank",
  "Money Transfers": "money-transfers",
  "Career Services": "career-services",
  Insurance: "insurance",
  Legal: "legal",
  "Tax Services": "tax-services",
  "Office Supplies": "office-supplies",
  "Computers & Software": "computers-software",
  Laptops: "laptops",
  Desktops: "desktops",
  Printers: "printers",
  "Ink & Toner": "ink-toner",
  "Display & Accessories": "display-accessories",
  Workstations: "workstations",
  Monitors: "monitors",
  Servers: "servers",
  "Data Storage Devices": "data-storage-devices",
  "Handhelds & PDAs": "handhelds-pdas",
  Netbooks: "netbooks",
  "Network & Equipment": "network-equipment",
  Software: "software",
  Tablets: "tablets",
  "Anti Virus": "anti-virus",
  "Antivirus software": "antivirus-software",
  "Accounting software": "accounting-software",
  "Business Software": "business-software",
  "Help Desk": "help-desk",
  Themes: "themes",
  "SEO Tools": "seo-tools",
  "Education & Training": "education-training",
  Courses: "courses",
  Training: "training",
  "Better Living": "better-living",
  "Economics & Finance": "economics-finance",
  History: "history",
  Professional: "professional",
  Religion: "religion",
  Science: "science",
  Electronics: "electronics",
  "Smart Phones": "smart-phones",
  Watches: "watches",
  "Home Audio": "home-audio",
  TVs: "tvs",
  Appliances: "appliances",
  "Kitchen Appliances": "kitchen-appliances",
  Laundary: "laundary",
  "Home Care": "home-care",
  "Smart Home": "smart-home",
  Cameras: "cameras",
  Camcorders: "camcorders",
  Projectors: "projectors",
  "Scanners & Copiers & Fax": "scanners-copiers-fax",
  Calculators: "calculators",
  Headphones: "headphones",
  "Office Electronics": "office-electronics",
  "Security & Surveillance": "security-surveillance",
  "Electronics Accessories": "electronics-accessories",
  "eBook Readers": "ebook-readers",
  Speakers: "speakers",
  "Mobile Accessories": "mobile-accessories",
  "Flowers & Gifts": "flowers-gifts",
  "Holiday & Occasions": "holiday-occasions",
  "Corporate Gifts": "corporate-gifts",
  "Wedding & Anninersary": "wedding-anninersary",
  "Personal Gift-Giving": "personal-gift-giving",
  Flowers: "flowers",
  "Party Supplies": "party-supplies",
  Cards: "cards",
  "Get Well": "get-well",
  Birthday: "birthday",
  Sympathy: "sympathy",
  Decorations: "decorations",
  Gifts: "gifts",
  "Food & Beverage": "food-beverage",
  "Food & Beverages": "food-beverage",
  "Ice Cream": "ice-cream",
  "Hot & Spicy": "hot-spicy",
  Coffee: "coffee",
  "Gourmet Choclates": "gourmet-choclates",
  "Nuts & Sweets": "nuts-sweets",
  Bars: "bars",
  Frozen: "frozen",
  Snacks: "snacks",
  Tea: "tea",
  Pizza: "pizza",
  "Fast Food": "fast-food",
  Sandwiches: "sandwiches",
  Burgers: "burgers",
  Fries: "fries",
  Chicken: "chicken",
  "Bucket Meals": "bucket-meals",
  Combos: "combos",
  Cookies: "cookies",
  seafood: "seafood",
  Beverages: "beverages",
  Restaurants: "restaurants",
  Cakes: "cakes",
  Steaks: "steaks",
  Fruits: "fruits",
  "Health & Medicine": "health-medicine",
  "Vitamins & Supplements": "vitamins-supplements",
  "Protein & Fitness": "protein-fitness",
  Digestion: "digestion",
  "Healthy Weight": "healthy-weight",
  "Superfoods & Groceries": "superfoods-groceries",
  "Herbs & Natural Remedies": "herbs-natural-remedies",
  "Natural Beauty & Skin": "natural-beauty-skin",
  "Cholesterol Health": "cholesterol-health",
  "Anti-Aging": "anti-aging",
  "Women's Health": "women-s-health",
  "Weight Loss": "weight-loss",
  "Heart Health": "heart-health",
  "Men's Health": "men-s-health",
  "Blood Sugar": "blood-sugar",
  "Oral Health": "oral-health",
  "Stress Health": "stress-health",
  "Foot Care": "foot-care",
  Mood: "mood",
  Dental: "dental",
  "Eye Care": "eye-care",
  Pyysicians: "pyysicians",
  "Digestive Support": "digestive-support",
  "Health Care": "health-care",
  Medicines: "medicines",
  "Holiday & Seasonal": "holiday-seasonal",
  graduation: "graduation",
  "4th of july": "4th-of-july",
  "back to school": "back-to-school",
  "black friday": "black-friday",
  christmas: "christmas",
  "columbus day": "columbus-day",
  "cyber monday": "cyber-monday",
  easter: "easter",
  "fall fashion": "fall-fashion",
  "fathers day": "fathers-day",
  halloween: "halloween",
  "labor day": "labor-day",
  "memorial day": "memorial-day",
  "mothers day": "mothers-day",
  "new years resolutions": "new-years-resolutions",
  "presidents day": "presidents-day",
  "patricks day": "patricks-day",
  summer: "summer",
  "super bowl": "super-bowl",
  "valentines day": "valentines-day",
  spring: "spring",
  "earth day": "earth-day",
  "labour day": "labour-day",
  thanksgiving: "thanksgiving",
  "green monday": "green-monday",
  "free shipping day": "free-shipping-day",
  "super saturday": "super-saturday",
  "boxing day": "boxing-day",
  tradition: "tradition",
  festival: "festival",
  celebration: "celebration",
  anniversary: "anniversary",
  eid: "eid",
  ramadan: "ramadan",
  "new year": "new-year",
  birthday: "birthday",
  "Home & Garden": "home-garden",
  "Home Decor": "home-decor",
  Drinkware: "drinkware",
  "Kitchen Essentials": "kitchen-essentials",
  Serveware: "serveware",
  "Candles & Scents": "candles-scents",
  "Collectibles & Figurines": "collectibles-figurines",
  "Specialty Food": "specialty-food",
  "Game Night": "game-night",
  "Pictures & Frames": "pictures-frames",
  Bathroom: "bathroom",
  Lighting: "lighting",
  Fans: "fans",
  Flooring: "flooring",
  Furniture: "furniture",
  Outdoor: "outdoor",
  HVAC: "hvac",
  Fencing: "fencing",
  Indoor: "indoor",
  "Cleaners & Vacuums": "cleaners-vacuums",
  Bedding: "bedding",
  Garden: "garden",
  "Sports & Recreation": "sports-recreation",
  "Outdoor Gear": "outdoor-gear",
  Shooting: "shooting",
  Hunting: "hunting",
  RVing: "rving",
  Camping: "camping",
  Fishing: "fishing",
  Boating: "boating",
  Footwear: "footwear",
  "Active Sport": "active-sport",
  Cycling: "cycling",
  WaterSports: "watersports",
  "Snow Sports": "snow-sports",
  "Warm Gear": "warm-gear",
  "Cold Gear": "cold-gear",
  "Silicone Rings": "silicone-rings",
  Boxers: "boxers",
  "Fanny Packs": "fanny-packs",
  Flags: "flags",
  Decals: "decals",
  "Insulated Tumblers": "insulated-tumblers",
  Shields: "shields",
  "Head Wear": "head-wear",
  "Trips & Schools": "trips-schools",
  "Bikes & Scooters": "bikes-scooters",
  Skates: "skates",
  "Fitness Items & Classes": "fitness-items-classes",
  "Gym & Yoga": "gym-yoga",
  "Health Clubs": "health-clubs",
  "Sports Equipment": "sports-equipment",
  "Toys & Games": "toys-games",
  "Active Play & Outdoor": "active-play-outdoor",
  "Activity Pads": "activity-pads",
  "Baby & Toddler Toys": "baby-toddler-toys",
  "Developmental Toys": "developmental-toys",
  "Dolls and Dollhouses": "dolls-and-dollhouses",
  Games: "games",
  Magic: "magic",
  "Musical Instruments": "musical-instruments",
  "Playspaces & Room Decor": "playspaces-room-decor",
  "Pretend Play": "pretend-play",
  Puppets: "puppets",
  "Seasonal & Religious": "seasonal-religious",
  "Stuffed Animals & Plush Toys": "stuffed-animals-plush-toys",
  "Outdoor Play": "outdoor-play",
  "Indoor Play": "indoor-play",
  Educational: "educational",
  "Holiday & Celebrations": "holiday-celebrations",
  Fairies: "fairies",
  "Robotics & Monsters & Space Toys": "robotics-monsters-space-toys",
  "Sewing & Knitting": "sewing-knitting",
  "Water & Pool": "water-pool",
  "Winter & Snow": "winter-snow",
  "Portable Electronic Games": "portable-electronic-games",
  "Musical Instrument": "musical-instrument",
  "Gaming Accessories": "gaming-accessories",
  Travel: "travel",
  Parking: "parking",
  "Tours & Attractions": "tours-attractions",
  Destinations: "destinations",
  Flights: "flights",
  "Hotels & Resorts": "hotels-resorts",
  Cars: "cars",
  "Vacations Packages": "vacations-packages",
  "Vacations Rentals": "vacations-rentals",
  Cruises: "cruises",
  "Group Travel": "group-travel",
  "Last Minute Hotel": "last-minute-hotel",
  "Visa & Passport Assistance": "visa-passport-assistance",
  "Business Class Flights": "business-class-flights",
  "Event Tickets": "event-tickets",
  "Vans & Trucks": "vans-trucks",
  "Wireless, Broadband & Cable": "wireless-broadband-cable",
  InternetTV: "internettv",
  VPN: "vpn",
  Broadband: "broadband",
  "Cell Phone": "cell-phone",
  "Music Streaming": "music-streaming",
  "Web Hosting": "web-hosting",
  "Wireless Services": "wireless-services",
  "Internet Service Providers": "internet-service-providers",
  "Pet Care & Supplies": "pet-care-supplies",
  Dog: "dog",
  Cat: "cat",
  Horse: "horse",
  Bird: "bird",
  Fish: "fish",
  Ferret: "ferret",
  Rabbit: "rabbit",
  "Small Pets": "small-pets",
  Reptile: "reptile",
  Supplies: "supplies",
  "Foods & Treats": "foods-treats",
  "Healtnessh & Well": "healtnessh-well",
  "Flea & Tick": "flea-tick",
  Nutrition: "nutrition",
  "Feeding & Watering": "feeding-watering",
  Grooming: "grooming",
  "Collars & Leashes": "collars-leashes",
  Kennels: "kennels",
  Shelters: "shelters",
  Toys: "toys",
  "Waste Management": "waste-management",
  "Apparel & Footwear": "apparel-footwear",
  Photography: "photography",
  "Wall Art": "wall-art",
  "Slim Aarpms": "slim-aarpms",
  Photographers: "photographers",
  Collections: "collections",
  Photos: "photos",
  Illustrations: "illustrations",
  Framing: "framing",
  Printing: "printing",
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
